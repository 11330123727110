import { FilterIcon } from 'lucide-react';
import DropDown from '../dropDown/DropDown.jsx';
import styles from './filter.module.css';

const Filter = ({ onFilterChange = () => { }, filterValues = [], value }) => {
    const filterChangeHandler = (selectedValue) => {
        onFilterChange(selectedValue);
    }
    return (< div className={styles.filter} >
        <div className={styles.select}>
            <FilterIcon />
            <DropDown onDropDownChange={filterChangeHandler} options={filterValues} defaultValue={value}/>
        </div>
    </div >)

}

export default Filter;
