// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configMapper_configMapper__48OIb{
  border-radius: 10px;
  box-shadow: 0px 0px 22px 0px #00000038;
  padding: 1rem;
  margin: 1rem auto;
}

.configMapper_configMapperWrapper__UiF59{
    margin: 0 auto;
    width: 90%;
}
/* width */
div::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  /* Track */
  div::-webkit-scrollbar-track {
    background: #D9D9D9; 
    border-radius: 12px;
    
}


/* Handle */
div::-webkit-scrollbar-thumb {
    background: #4371EC; 
    border-radius: 12px;
  }
  
  /* Handle on hover
  div::-webkit-scrollbar-thumb:hover {
    background: #555; 
  } */
  
.configMapper_formSubmitWrapper__Q0GkP{
    padding: 2rem 0;
    text-align: right;
}

.configMapper_formSubmitWrapper__Q0GkP input{
    background: linear-gradient(88.9deg, #6328DD 0.83%, #446CEB 47.73%, #0FC1DC 99.56%);
    border-radius: 12px;
    border: 1px solid blue;
    border:none;
    color: white;
    cursor: pointer;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    outline: none;
    padding: 7px 10px;
    text-align: left;
}

`, "",{"version":3,"sources":["webpack://./src/views/configMapper/layout/configMapper.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,sCAAsC;EACtC,aAAa;EACb,iBAAiB;AACnB;;AAEA;IACI,cAAc;IACd,UAAU;AACd;AACA,UAAU;AACV;IACI,UAAU;IACV,WAAW;EACb;;EAEA,UAAU;EACV;IACE,mBAAmB;IACnB,mBAAmB;;AAEvB;;;AAGA,WAAW;AACX;IACI,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;;;KAGG;;AAEL;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,mFAAmF;IACnF,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".configMapper{\n  border-radius: 10px;\n  box-shadow: 0px 0px 22px 0px #00000038;\n  padding: 1rem;\n  margin: 1rem auto;\n}\n\n.configMapperWrapper{\n    margin: 0 auto;\n    width: 90%;\n}\n/* width */\ndiv::-webkit-scrollbar {\n    width: 6px;\n    height: 6px;\n  }\n  \n  /* Track */\n  div::-webkit-scrollbar-track {\n    background: #D9D9D9; \n    border-radius: 12px;\n    \n}\n\n\n/* Handle */\ndiv::-webkit-scrollbar-thumb {\n    background: #4371EC; \n    border-radius: 12px;\n  }\n  \n  /* Handle on hover\n  div::-webkit-scrollbar-thumb:hover {\n    background: #555; \n  } */\n  \n.formSubmitWrapper{\n    padding: 2rem 0;\n    text-align: right;\n}\n\n.formSubmitWrapper input{\n    background: linear-gradient(88.9deg, #6328DD 0.83%, #446CEB 47.73%, #0FC1DC 99.56%);\n    border-radius: 12px;\n    border: 1px solid blue;\n    border:none;\n    color: white;\n    cursor: pointer;\n    font-family: Outfit;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 24px;\n    outline: none;\n    padding: 7px 10px;\n    text-align: left;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"configMapper": `configMapper_configMapper__48OIb`,
	"configMapperWrapper": `configMapper_configMapperWrapper__UiF59`,
	"formSubmitWrapper": `configMapper_formSubmitWrapper__Q0GkP`
};
export default ___CSS_LOADER_EXPORT___;
