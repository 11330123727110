// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.tooltip_tooltipText__ziCit {
  width: 185px;
  background-color: #4470EC;
  color: black;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  transition: opacity 0.3s;
  box-shadow: 0px 0px 22px 0px #00000038;
}
.tooltip_tooltipBackGroundColor__LKCBn {
  background-color: var(--color-dari-cyan);;
}
.tooltip_tooltipText__ziCit:after {
   content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;

}

.tooltip_tooltipContainer__pfgNe:hover .tooltip_tooltipText__ziCit {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/tooltip/tooltip.module.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,SAAS;EACT,2BAA2B;EAC3B,UAAU;EACV,wBAAwB;EACxB,sCAAsC;AACxC;AACA;EACE,wCAAwC;AAC1C;AACA;GACG,WAAW;EACZ,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;EACnB,uDAAuD;;AAEzD;;AAEA;EACE,UAAU;AACZ","sourcesContent":["\n.tooltipText {\n  width: 185px;\n  background-color: #4470EC;\n  color: black;\n  text-align: center;\n  border-radius: 10px;\n  padding: 5px;\n  position: absolute;\n  z-index: 1;\n  top: 125%;\n  left: 50%;\n  transform: translateX(-50%);\n  opacity: 1;\n  transition: opacity 0.3s;\n  box-shadow: 0px 0px 22px 0px #00000038;\n}\n.tooltipBackGroundColor {\n  background-color: var(--color-dari-cyan);;\n}\n.tooltipText:after {\n   content: \"\";\n  position: absolute;\n  top: -10px;\n  left: 50%;\n  margin-left: -5px;\n  border-width: 5px;\n  border-style: solid;\n  border-color: transparent transparent black transparent;\n\n}\n\n.tooltipContainer:hover .tooltipText {\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipText": `tooltip_tooltipText__ziCit`,
	"tooltipBackGroundColor": `tooltip_tooltipBackGroundColor__LKCBn`,
	"tooltipContainer": `tooltip_tooltipContainer__pfgNe`
};
export default ___CSS_LOADER_EXPORT___;
