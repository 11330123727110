import React from 'react';
import styles from './integrationCard.module.css';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import { Switch } from '../../../commonComponents/switch/Switch.jsx';
import { useIntegrationCardFunc } from '../functionality/IntegrationCard.func';
import { Ellipsis } from 'lucide-react';
import assets from '../../../assets';

const IntegrationCard = ({ data }) => {
    const {
        name,
        lastSync,
        nextSync,
    } = data;
    const {
        statuses,
        scheduleCount,
        isAutoPilot,
        toggleAutoPilot,
        handleToggleStatus,
        addScheduleCount
    } = useIntegrationCardFunc();

    const { autoPilotOff, autoPilotOn, system, netsuit, blackClock, blueClock, folder } = assets;

    return (
        <div id="integration_card" className={styles.integrationCard}>
            <div className={styles.nameContainer}>
                <img className={styles.folderImage} src={folder} alt='' />
                <div>
                    <Typography variant='title'>{name}</Typography>
                    <a href='/' className={styles.viewLogs}>View Logs</a>
                </div>
            </div>

            <div className={styles.statusContainer}>
                <div className={styles.statusInnerContainer}>
                    <div className={styles.statusItem}>
                        <Typography>Pipe Status</Typography>
                        <div className={styles.switch}>
                            <Switch handleToggle={() => handleToggleStatus('pipeStatus')} isChecked={statuses.pipeStatus} />
                        </div>
                    </div>
                    <div className={styles.statusItem}>
                        <Typography>Console Logs</Typography>
                        <div className={styles.switch}>
                            <Switch handleToggle={() => handleToggleStatus('consoleLogs')} isChecked={statuses.consoleLogs} />
                        </div>
                    </div>
                    <div className={`${ styles.statusItem } ${ styles.systemContainer }`}>
                        <Typography>Systems</Typography>
                        <div className={styles.systemImageContainer}>
                            <img className={styles.systemImage} src={system} alt='' />
                            <img className={styles.systemImage} src={netsuit} alt='' />
                        </div>
                    </div>
                </div>

            </div>

            <div className={styles.flexContainer}>
                <div className={styles.scheduleManager}>
                    <div className={styles.scheduleInnerContainer}>
                        <Typography>Schedule Manager</Typography>
                        <div className={styles.scheduleCounter}>
                            <Typography className={styles.scheduleCount}>{scheduleCount} Schedule</Typography>
                            <button className={styles.addCount} onClick={addScheduleCount}>+</button>
                        </div>
                    </div>
                </div>

                <div className={styles.autoPilotContainer}>
                    <Typography>Auto Pilot Mode</Typography>
                    <div className={styles.autoPilot}>
                        <div role='switch' onClick={() => isAutoPilot && toggleAutoPilot()} className={`${ isAutoPilot ? styles.autoPilotOnShow : styles.autoPilotOnHide } ${ styles.autoPilotStatus }`}>
                            <Typography>ON</Typography>
                            <img src={autoPilotOn} />
                        </div>
                        <div role='switch' onClick={() => !isAutoPilot && toggleAutoPilot()} className={`${ isAutoPilot ? styles.autoPilotOffHide : styles.autoPilotOffShow } ${ styles.autoPilotStatus }`}>
                            <img src={autoPilotOff} />
                            <Typography>Off</Typography>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.syncContainer}>
                <div className={styles.syncTimeContainer}>
                    <div className={styles.syncTime}>
                        <img className={styles.clock} alt='' src={blackClock} />
                        <div>
                            <Typography className={styles.syncTimeTitle}>Last Sync</Typography>
                            <Typography className={styles.syncTimeTimer}>{lastSync}</Typography>
                        </div>
                    </div>
                    <div className={styles.syncTime}>
                        <img className={styles.clock} alt='' src={blueClock} />
                        <div>
                            <Typography className={styles.syncTimeTitle}>Next Sync</Typography>
                            <Typography className={styles.syncTimeTimer}>{nextSync}</Typography>
                        </div>
                    </div>
                </div>
                <button className={styles.syncNowBtn}>Sync Now</button>
            </div>
            <Ellipsis className={styles.menuIcon} />
        </div>
    )
};

export default IntegrationCard;