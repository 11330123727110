import React, { useEffect, useState } from 'react';
import { capitalizeCamelCaseWords, getDateLocale } from '../../helpers';
import useCustomAxiosCallTwo from '../../hooks/useCustomAxiosCallTwo';
const useListItemTable = (uriEndPoint = {}, filter = '', tableType = '',mappings={},header = [], rowData = [], ) => {
    const [ state, setState ] = useState({ data:[], headerData:[] });
    const [ error, setError ] = useState('');
    const { callApi } = useCustomAxiosCallTwo();
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ pageSize, setPageSize ] = useState(10)
    let displayTitle;
    switch (filter) {
        case 'matched':
            displayTitle = 'Matched Items';
            break;
        case 'shipnotice':
            displayTitle = 'Shipnotice Items';
            break;
        case 'inboundshipment':
            displayTitle = 'Shipment Items';
            break;

        default:
            break;
    }
    const getTableData = async () => {
        const newUrl = `${ uriEndPoint.url }?page=${ currentPage + 1 }&per_page=${ pageSize }&item_type=${ tableType }`;
        setState((prevState) => {
            return { ...prevState,
                loading:true
            }
        })
        const response = await callApi({
            uriEndPoint: {
                ...uriEndPoint,
                url: newUrl
            }
        });
        if (response && response.status === 200) {
            if(filter !== '' && filter!== 'missmatched'){
                setState((prevState) => {
                    return {
                        ...prevState,
                        data: response?.data?.data?.items || [],
                        headerData: response?.data?.header_data || [],
                        totalRecords: response?.data?.total_count || 0,
                        title:displayTitle,
                        loading:false
                    }
                })
            }else{
                if (filter === 'missmatched' || filter === ''){
                    switch (tableType) {
                        case 'shipnoticeItem':{
                            setState((prevState) => {
                                return {
                                    ...prevState,
                                    data: response?.data?.data?.shipnoticeItem || [],
                                    headerData: response?.data?.header_data?.header_data_1 || [],
                                    totalRecords: response?.data?.total_count?.shipnotice_count || 0,
                                    title: 'Shipnotice Items',
                                    loading: false
                                }
                            })
                        }
                            break;
                        case 'shipmentitem':{
                            setState((prevState) => {
                                return {
                                    ...prevState,
                                    data: response?.data?.data?.shipmentItem || [],
                                    headerData: response?.data?.header_data?.header_data_2 || [],
                                    totalRecords: response?.data?.total_count?.shipment_count || 0,
                                    title: 'Shipment Items',
                                    loading: false
                                }
                            })
                        }
                            break;

                        default:
                            break;
                    }
                }
            }
        } else {
            setError('Something Went Wrong. Please Try Again Later');
        }
    }

    const columns = React.useMemo(() => (header.length > 0 ? header : state.headerData).map((key) => ({
        field: key,
        headerName: capitalizeCamelCaseWords(key.replaceAll('_', ' ')),
        flex: 1
    })))
    function flattenObject(obj, result = {}) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (typeof obj[ key ] === 'object' && obj[ key ] !== null) {
                    flattenObject(obj[ key ], result);
                } else {
                    result[ key ] = obj[ key ];
                }
            }
        }
        return result;
    }
    const flattenedData = React.useMemo(() => (rowData.length > 0 ? rowData : state.data).map((item, index) => {
        const flattenedItem = { id: index + 1 };
        columns.forEach(columnObj => {
            let key = columnObj.field;
            if (mappings && key in mappings) {
                let displayData = item;
                const mappingKeys = mappings[ key ].split('.');
                mappingKeys.map(mappingKey => {
                    displayData = (typeof displayData === 'object') ? displayData?.[ mappingKey ] : displayData;
                })
                flattenedItem[ key ] = displayData;
            } else {
                if (typeof item[ key ] === 'object' && item[ key ] !== null) {
                    Object.assign(flattenedItem, flattenObject(item[ key ]))
                    Object.assign(flattenedItem, item);
                } else if ((typeof item[ key ] === 'string') && item[ key ].includes('-04:00')) {
                    flattenedItem[ key ] = getDateLocale(item[ key ])
                } else if (Array.isArray(item[ key ])) {
                    flattenedItem[ key ] = item[ key ].toString();
                }
                else {
                    flattenedItem[ key ] = item[ key ];
                }
            }
        });
        return flattenedItem;
    }), [ state.data ]);
    useEffect(() => {
        getTableData();
    }, [ currentPage, filter ]);

    return {  columns, data: flattenedData, state, error, currentPage, setPageSize, setCurrentPage }
}

export default useListItemTable;