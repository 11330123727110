// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WorkflowQueuePopup_workflowQueuePopupContainer__8-zuF{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.WorkflowQueuePopup_workflowQueuePopupContainer__8-zuF .WorkflowQueuePopup_content__jZ3iA{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.WorkflowQueuePopup_workflowQueuePopupContainer__8-zuF .WorkflowQueuePopup_content__jZ3iA p{
    background-color: transparent;
    background-image: linear-gradient(90deg, var(--color-white), var(--color-white)), linear-gradient(90deg, var(--color-skyblue-300), var(--color-cyan));
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 1px solid transparent;
    padding: 5px 10px;
}

.WorkflowQueuePopup_workflowQueuePopupContainer__8-zuF .WorkflowQueuePopup_gridContainer__xu\\+\\+N thead tr th {
    padding: 5px;
}
td{
    padding: 5px;
}

.WorkflowQueuePopup_workflowQueuePopupContainer__8-zuF .WorkflowQueuePopup_gridContainer__xu\\+\\+N .WorkflowQueuePopup_headingContainer__AGohr{
    display: flex;
    gap: 10px;
    justify-content: space-between;
}
.WorkflowQueuePopup_dropdownBody__0tt9N{
    right: 0 !important;
    left: -100px;
    top: 37px;
}`, "",{"version":3,"sources":["webpack://./src/components/workflowQueuePopup/layout/WorkflowQueuePopup.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,6BAA6B;IAC7B,qJAAqJ;IACrJ,wCAAwC;IACxC,6BAA6B;IAC7B,6BAA6B;IAC7B,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,8BAA8B;AAClC;AACA;IACI,mBAAmB;IACnB,YAAY;IACZ,SAAS;AACb","sourcesContent":[".workflowQueuePopupContainer{\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n\n.workflowQueuePopupContainer .content{\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n}\n.workflowQueuePopupContainer .content p{\n    background-color: transparent;\n    background-image: linear-gradient(90deg, var(--color-white), var(--color-white)), linear-gradient(90deg, var(--color-skyblue-300), var(--color-cyan));\n    background-clip: padding-box, border-box;\n    background-origin: border-box;\n    border: 1px solid transparent;\n    padding: 5px 10px;\n}\n\n.workflowQueuePopupContainer .gridContainer thead tr th {\n    padding: 5px;\n}\ntd{\n    padding: 5px;\n}\n\n.workflowQueuePopupContainer .gridContainer .headingContainer{\n    display: flex;\n    gap: 10px;\n    justify-content: space-between;\n}\n.dropdownBody{\n    right: 0 !important;\n    left: -100px;\n    top: 37px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflowQueuePopupContainer": `WorkflowQueuePopup_workflowQueuePopupContainer__8-zuF`,
	"content": `WorkflowQueuePopup_content__jZ3iA`,
	"gridContainer": `WorkflowQueuePopup_gridContainer__xu++N`,
	"headingContainer": `WorkflowQueuePopup_headingContainer__AGohr`,
	"dropdownBody": `WorkflowQueuePopup_dropdownBody__0tt9N`
};
export default ___CSS_LOADER_EXPORT___;
