// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfigMapperV2_configMapperWrapper__AhFwL .ConfigMapperV2_stepSliderWrapper__r8iBi{
    margin:1rem 0;
}
`, "",{"version":3,"sources":["webpack://./src/views/ConfigMapperV2/layout/ConfigMapperV2.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB","sourcesContent":[".configMapperWrapper .stepSliderWrapper{\n    margin:1rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"configMapperWrapper": `ConfigMapperV2_configMapperWrapper__AhFwL`,
	"stepSliderWrapper": `ConfigMapperV2_stepSliderWrapper__r8iBi`
};
export default ___CSS_LOADER_EXPORT___;
