import React from 'react';
import styles from './input.module.css';

const Input = ({
    placeholder = '',
    variant = 'primary',
    className,
    type,
    value = '',
    name = '',
    onChange = () => {},
    onKeyDown = () => {},
    endIcon,
    ...restProps
} ) => {
    return (
        <div className={`${ className } ${ styles.inputContainer } ${ styles?.[ variant ] } `}>
            <input
                className={styles.input}
                placeholder={placeholder}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                {...restProps}
            />
            {endIcon}
        </div>
    )
}

export default Input;