import React from 'react';
import AppLoader from '../../../../appLoader';
import styles from './inventory.module.css'
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import Table from '../../../../commonComponents/table/Table.jsx';
import useTables from '../../../../commonComponents/table/useTables.func';
import StatCounter from '../../../../components/widgets/statCounter/layout/StatCounter.jsx';
import { Link } from 'react-router-dom';
import useInventoryWidgets from '../functionality/inventoryWidgets.func';
import Spinner from '../../../../commonComponents/spinner/Spinner.jsx';
import useTablesTwo from '../../../../commonComponents/table/useTablesTwo.func';

const Inventory = ()=>{
    const [ widgetData,widgetLoading ] = useInventoryWidgets({ url:'/widget-count', service:'collectivedata', type: 'inventory', fetch_by: 'type' });
    const { data,
        columns,
        loading, error,
        currentPage,
        setCurrentPage,totalRecords,
        setPageSize  } = useTablesTwo({ url: '/all-product', service: 'collectivedata' });

    const { data: dataMismatch,
        columns: columnMismatch,
        loading: loadingMismatch, error: errorMismatch,
        currentPage: currentPageMismatch,
        setCurrentPage: setCurrentPageMismatch,totalRecords: totalRecordsMismatch,
        setPageSize: setPageSizeMismatch  } = useTables({ url: '/get-inventory-mismatch', service: 'collectivedata' });

    const updatedColumns = columns.map((column) => {
        if (column.field === 'product_id') {
            return {
                ...column,
                renderCell: (params) => (
                    <Link to={`/inventory/${ params.row.product_id }`} state={{ productsDetail: data }}>
                        {params.row.product_id}
                    </Link>
                )
            };
        }
        return column;
    });
    return (
        <AppLoader>
            <div>
                <div className={styles.mainWrapper}>
                    <div className={styles.header}>
                        <Typography variant='gradient'>Inventory Overview</Typography>
                    </div>
                    {widgetLoading
                        ? <Spinner height={'10vh'}/>
                        :<div className={styles.statsContainer}>
                            <StatCounter  data={{ stat_name: 'Total Skus', count: widgetData?.total_sku?.count||0, icon: 'Layers', stat_level:widgetData?.total_sku?.flag }} />
                            <StatCounter data={{ stat_name: 'Stock In hand', count: widgetData?.stock_in_hand?.count||0,  icon: 'SignalHigh', stat_level: widgetData?.stock_in_hand?.flag }} />
                            <StatCounter  data={{ stat_name: 'Inbound Supply', count: widgetData?.inbound_supply?.count||0, icon: 'Signal', stat_level: widgetData?.inbound_supply?.flag }} />
                            <StatCounter data={{ stat_name: 'Low Qty Skus', count: widgetData?.low_qty_sku?.count||0,  icon: 'ReceiptIndianRupee', stat_level: widgetData?.low_qty_sku?.flag }} />
                        </div>}
                    <Typography variant='heading'>Inventory</Typography>
                    {(!loading && error) ?
                        <div className={styles.heading}><Typography variant='error'>{error}</Typography></div>:
                        <Table
                            loading={loading}
                            headerList={updatedColumns}
                            totalRecords={totalRecords}
                            setPageSize={setPageSize}
                            rowsData={data}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    }
                    <Typography variant='heading'>Inventory Mismatch</Typography>
                    {(!loadingMismatch && errorMismatch) ?
                        <div className={styles.heading}><Typography variant='error'>{error}</Typography></div>:
                        <Table
                            loading={loadingMismatch}
                            headerList={columnMismatch}
                            totalRecords={totalRecordsMismatch}
                            setPageSize={setPageSizeMismatch}
                            rowsData={dataMismatch}
                            currentPage={currentPageMismatch}
                            setCurrentPage={setCurrentPageMismatch}
                        />
                    }
                </div>
            </div>
        </AppLoader>
    )
}

export default Inventory;
