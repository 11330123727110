import React from 'react'
import AppLoader from '../../../appLoader'
import { useDispatch, useSelector } from 'react-redux';
import Typography from '../../../commonComponents/typography/Typography.jsx'
import styles from './ConfigMapperV2.module.css'
import StepSlider from '../../../components/stepSlider/layout/StepSlider.jsx'
import configStepData from '../../../dummyData/ConfigurationMapperV2/ConfigMapperStepsData.json'
import FormStep2 from '../../../components/formStep/layout/FormStep2.jsx'
import FormStep3 from '../../../components/formStep/layout/FormStep3.jsx'
import useConfigMapper from '../../configMapper/functionality/useConfigMapper.func'
import Spinner from '../../../commonComponents/spinner/Spinner.jsx'
import { selectConfigurationData } from '../../../redux/mappingConfiguration/Selector';
// import dummyData from '../data/step1DummyData.json'
// import configFormDummyData from '../data/configOFrmDatadummy.json'
import suggestionDummyData from '../data/getsuggestions.json'
import { useLocation } from 'react-router-dom';
import { capitalizeSnakeCaseWords } from '../../../helpers';
import { clearMappingConfiguration } from '../../../redux/mappingConfiguration/Slice';
const ConfigMapperV2 = () => {
    // States
    const [ activeStep, setActiveStep ] = React.useState(0)
    const [ configFormData, setConfigFormData ] = React.useState()
    const [ validateData, setValidateData ] = React.useState();
    const [ fetchedDataShown, setFetchedDataShown ] = React.useState({});

    // Redux
    const configurationStateData = useSelector(selectConfigurationData);
    // Getting system name from state
    const location = useLocation();
    console.log(location.state, 'stateVariable')
    const { systemName, tribe } = location.state || {};

    // Import FUnctions from hooks
    const { loading, data, getConfigData, getMongoCollections, collectionsLoading, collections } = useConfigMapper(systemName)

    // Declaring and assigning variables
    const stepsInfo = [
        { label:'Services' },
        { label:'Other Configurations' },
    ]
    const dispatch = useDispatch()
    const formSteps = [
        <FormStep2
            key={2}
            fieldInputData={[ ...configStepData[ 1 ].inputData ]}
            fieldLayout={[ ...configStepData[ 1 ].inputData ]}
            sliderData={configStepData[ 1 ]?.sliderData}
            fetchedData={data}
            configFormData={configFormData}
            // fetchedData={dummyData}
            // configFormData={configFormDummyData}
            setActiveFormStep={setActiveStep}
            setConfigFormData={setConfigFormData}
            validateData={validateData}
            setValidateData={setValidateData}
            activeStep={activeStep}
            fetchedDataShown={fetchedDataShown}
            setFetchedDataShown={setFetchedDataShown}
            collections={collections}
        />,
        <FormStep3
            key={3}
            fieldInputData={[ ...configStepData[ 2 ].inputData ]}
            sliderData={configStepData[ 2 ]?.sliderData}
            setConfigFormData={setConfigFormData}
            fetchedData={data}
            setActiveFormStep={setActiveStep}
            configFormData={configFormData}
        />
    ];
    // Consoles
    console.log(configFormData, 'configFormDataFormSubitIssue',configStepData[ activeStep ],loading, configurationStateData)
    console.log('#reduximplementations', configurationStateData, getConfigData )

    // Use Effects
    React.useEffect(()=>{
        dispatch(clearMappingConfiguration())
        if (tribe === 'database'){
            getMongoCollections()
        }
        setConfigFormData({ system:systemName })
        setValidateData(suggestionDummyData)
        getConfigData(systemName)
        console.log('#validateData')
    }, [])

    //JSX
    return <AppLoader>
        <section className={styles.configMapperWrapper}>
            <Typography variant='heading'>Configuration Mapping ({capitalizeSnakeCaseWords(systemName) })</Typography>
            <div className={styles.stepSliderWrapper}>
                <StepSlider activeStep={activeStep} stepsInfo={stepsInfo}/>
            </div>
            {loading || collectionsLoading ?<Spinner /> :formSteps[ activeStep ]}
        </section>
    </AppLoader>
}

export default ConfigMapperV2