import React, { useEffect, useState, forwardRef, useRef } from 'react';
import AppLoader from '../../appLoader';
import { toast } from 'react-toastify';
import styles from './productDetailsBulk.module.css';
import Typography from '../../commonComponents/typography/Typography.jsx';
import Input from '../../commonComponents/input/Input.jsx';
import LucideIcon from '../../commonComponents/lucideicon/LucideIcon.jsx';
// import { MenuItem, Select } from '@mui/material';
import Button from '../../commonComponents/button/Button.jsx';
import DropDown from '../../commonComponents/dropDown/DropDown.jsx';
import Table from '../../commonComponents/table/Table.jsx';
import useTablesTwo from '../../commonComponents/table/useTablesTwo.func';
import { useSelector } from 'react-redux';
import { selectOrganisationData } from '../../redux/organisation/Selector';
// import Modal from '../../commonComponents/modal/Modal.jsx';
import DialogWrapper from '../../commonComponents/DialogWrapper/DialogWrapper.jsx';
import Spinner from '../../commonComponents/spinner/Spinner.jsx';
import { useLocation, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import DateDropDown from '../../commonComponents/dateDropdown/DateDropdown.jsx';
import businessLogicData from '../../dummyData/BusinessLogic.json';
import {
    getDropdownOptions,
} from '../../helpers/index';
import { connectorDataSelector } from '../../redux/connector/Selector';

const ProductDetailsBulk = () => {
    const [ productDetail, setProductDetail ] = useState({});
    const [ reservedQuantity, setReservedQuantity ] = useState('');
    const [ quantityError, setQuantityError ] = useState('');
    const [ isAllocationOpen, setIsAllocationOpen ] = useState(false);
    const dropdownRefs = useRef({});
    const businessLogicRef = useRef(null);
    const keysData = [
        { name: 'Product Name', value: 'product_name' },
        { name: 'Product Price', value: 'product_price' },
    ];
    const connectors = useSelector(connectorDataSelector);
    const dropdownOptions = getDropdownOptions(connectors);

    const [ allocationRowsBulk, setAllocationRowsBulk ] = useState([
        {
            id: '1',
            key: keysData[ 0 ].value,
            value: '',
            operator: '',
            method: '',
            platform: dropdownOptions[ 0 ].value,
            allocation: '',
        },
    ]);
    console.log(allocationRowsBulk, 'allocationRowsBulk');
    const [ isFilter, setIsFilter ] = useState({
        start_date: 'dd/mm/yyyy',
        end_date: 'dd/mm/yyyy',
    });
    const { businessLogics } = businessLogicData;
    const [ isPercentageBulk, setIsPercentageBulk ] = useState(true);
    const [ isSubmittingBulk, setIsSubmittingBulk ] = useState(false);
    const [ apiErrorBulk, setApiErrorBulk ] = useState(null);
    const [ isOpenDropdowns, setIsOpenDropdowns ] = useState({});

    const organizationData = useSelector(selectOrganisationData);
    const orgId = organizationData?.organisationData?.encrypt_id;
    const { state } = useLocation();
    const { itemId } = useParams();
    const { productsDetail } = state;

    useEffect(() => {
        if (productsDetail.length > 0) {
            const filteredData = productsDetail.filter(
                (obj) => obj?.product_id === itemId
            );
            if (filteredData.length > 0) {
                setProductDetail(filteredData[ 0 ]);
            } else {
                console.log('No product found with this itemId', itemId);
            }
        }
    }, [ productsDetail, itemId ]);

    const handleAllocationBulkChange = (value, rowId) => {
        setAllocationRowsBulk((prevRows) =>
            prevRows.map((row) => {
                if (row.id === rowId) {
                    if (isPercentageBulk) {
                        if (
                            !value ||
                            (Number(value) >= 0 && Number(value) <= 100)
                        ) {
                            return { ...row, allocation: value };
                        }
                        return row;
                    } else {
                        if (!value || Number(value) >= 0) {
                            return { ...row, allocation: value };
                        }
                        return row;
                    }
                }
                return row;
            })
        );
    };
    const handlePushBulk = async () => {
        const invalidRows = allocationRowsBulk.some(
            (row) => !row.allocation || !row.platform
        );
        if (invalidRows || !productDetail?.sku) {
            return;
        }
        console.log(allocationRowsBulk, '#allocationRowsBulk');
        setIsSubmittingBulk(true);
        setApiErrorBulk(null);
        const allPlatformsSelected = allocationRowsBulk.some(
            (row) => row.platform === 'all'
        );
        let data = [
            {
                style_id: productDetail?.style_id,
                sales_channel_list: [],
            },
        ];

        if (allPlatformsSelected) {
            const allPlatformsData = allocationRowsBulk?.filter(
                (row) => row.platform === 'all'
            );

            dropdownOptions?.forEach((value) => {
                if (value?.value !== 'all') {
                    data[ 0 ].sales_channel_list.push({
                        name: value.value,
                        quantity: isPercentageBulk
                            ? allPlatformsData[ 0 ]?.allocation + '%'
                            : String(
                                Math.floor(
                                    Number(allPlatformsData[ 0 ]?.allocation)
                                )
                            ),
                        key: allPlatformsData[ 0 ]?.key,
                        value: allPlatformsData[ 0 ]?.value,
                        operator: allPlatformsData[ 0 ]?.operator,
                        method: allPlatformsData[ 0 ]?.method,
                    });
                }
            });
        } else {
            data = allocationRowsBulk.reduce((acc, row) => {
                // Find if the style_id is already in the accumulator
                const existingStyle = acc.find(
                    (item) => item.style_id === productDetail?.style_id
                );

                if (existingStyle) {
                    // Check if the sales channel already exists
                    const existingChannel =
                        existingStyle.sales_channel_list.find(
                            (channel) => channel.name === row.platform
                        );

                    if (!existingChannel) {
                        // Add the new sales channel if it doesn't exist
                        existingStyle.sales_channel_list.push({
                            name: row.platform,
                            quantity: isPercentageBulk
                                ? row.allocation + '%'
                                : String(Math.floor(Number(row.allocation))),
                            key: row.key,
                            value: row.value,
                            operator: row.operator,
                            method: row.method,
                        });
                    }
                } else {
                    // Add a new object for the style_id and its associated sales channels
                    acc.push({
                        style_id: productDetail?.style_id,
                        sales_channel_list: [
                            {
                                name: row.platform,
                                quantity: isPercentageBulk
                                    ? row.allocation + '%'
                                    : String(
                                        Math.floor(Number(row.allocation))
                                    ),
                                key: row.key,
                                value: row.value,
                                operator: row.operator,
                                method: row.method,
                            },
                        ],
                    });
                }
                return acc;
            }, []);
        }
        console.log(data, '#data');
        try {
            const response = await fetch(
                `${ process.env.REACT_APP_EKYAM_COLLECTIVE_DATA_BASE_URL }/inventory?type=style_based`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-organisation-id': orgId,
                    },
                    body: JSON.stringify({
                        data: data,
                    }),
                }
            );
            if (response.status === 200) {
                toast.success('Inventory Allocated', {
                    position: 'top-right',
                });
            } else if (!response.ok) {
                toast.error('Something went wrong', {
                    position: 'top-right',
                });
            }
            // Clear allocation inputs after successful push
            setAllocationRowsBulk([
                {
                    id: '1',
                    key: keysData[ 0 ].value,
                    value: '',
                    operator: '',
                    method: '',
                    platform: dropdownOptions[ 0 ].value,
                    allocation: '',
                },
            ]);
            handleClose();
        } catch (error) {
            setApiErrorBulk(error.message);
        } finally {
            setIsSubmittingBulk(false);
        }
    };

    const updateReserveQuantity = async () => {
        setQuantityError('');
        if (!reservedQuantity) {
            setQuantityError('Enter Reserved quantity');
            return;
        }
        const mutipleSku = relativesSKUTableData?.map((ele) => ele?.sku);
        console.log(mutipleSku);
        const url = `${ process.env.REACT_APP_EKYAM_COLLECTIVE_DATA_BASE_URL }/update-reserve-quantity/${ itemId }`;

        try {
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'x-organisation-id': orgId,
                },
                body: JSON.stringify({
                    reserve_quantity: Number(reservedQuantity),
                }),
            });
            if (response.status === 200) {
                toast.success('Low Quantity updated', {
                    position: 'top-right',
                });
                setReservedQuantity('');
            } else {
                toast.error('Something Went Wrong', {
                    position: 'top-right',
                });
            }
        } catch (error) {
            toast.error('Something Went Wrong', {
                position: 'top-right',
            });
            console.error('Request failed:', error);
        }
    };

    const handleOpenBulkAllocation = () => {
        setIsAllocationOpen(true);
    };

    const handleClose = () => {
        setIsAllocationOpen(false);
        setAllocationRowsBulk([
            {
                id: '1',
                key: keysData[ 0 ].value,
                value: '',
                operator: '',
                method: '',
                platform: dropdownOptions[ 0 ].value,
                allocation: '',
            },
        ]);
    };
    const [ relativeSKUsTableConfig, setRelativeSKUsTableConfig ] = useState({
        url: '',
        service: 'collectivedata',
    });

    // const options = [
    //     {
    //         name: 'All Sale Channels',
    //         value: 'all',
    //     },
    //     {
    //         name: 'Mirakl',
    //         value: 'mirakl',
    //     },
    //     {
    //         name: 'Amazon',
    //         value: 'amazon',
    //     },
    //     {
    //         name: 'Shopify',
    //         value: 'shopify',
    //     },
    // ...(connectors
    //     ?.filter((items) =>
    //         [ 'wallmart', 'amazon' ].includes(
    //             items?.machine_name.toLowerCase()
    //         )
    //     )
    //     .map((items) => ({
    //         name: capitalizeSnakeCaseWords(items?.name),
    //         value: items?.machine_name,
    //     })) || []),
    // ];
    const [ dropdownValues, setDropdownValues ] = useState(
        dropdownOptions.length > 0 ? dropdownOptions[ 0 ].value : ''
    );
    const dropdownOnChangeHandler = (value) => {
        setDropdownValues(value);
    };
    useEffect(() => {
        if (productDetail?.style_id) {
            setRelativeSKUsTableConfig({
                url: `/inventory/${ productDetail?.style_id }?sale_channel=${ dropdownValues }`,
                service: 'collectivedata',
            });
        }
    }, [ productDetail?.style_id, dropdownValues ]);

    const {
        columns: relativesSKUTableColumns,
        data: relativesSKUTableData,
        loading: relativesSKUTableLoading,
        error: relativesSKUTableError,
        currentPage: relativesSKUTableCurrentPage,
        totalRecords: relativesSKUTableTotalRecords,
        setCurrentPage: relativesSKUTableSetCurrentPage,
    } = useTablesTwo(relativeSKUsTableConfig);

    const handleRemoveClick = (index) => {
        // Create a new array excluding the element at the specified index
        const updatedList = allocationRowsBulk.filter((_, i) => i !== index);
        setAllocationRowsBulk(updatedList);
    };
    // eslint-disable-next-line react/display-name
    const CustomDateInput = forwardRef(
        ({ value, onClick, placeholder }, ref) => (
            <div
                className={styles.datepickerContainer}
                onClick={onClick}
                ref={ref}
            >
                <input
                    type="text"
                    value={value}
                    placeholder={placeholder}
                    readOnly
                    className={styles.datepicker}
                />
                <LucideIcon name={'CalendarDays'} className={styles.icon} />
            </div>
        )
    );
    const toggleDropdown = (index) => {
        setIsOpenDropdowns(() => ({
            [ index ]: !isOpenDropdowns[ index ],
        }));
    };
    const handleChange = (key, value, index, operation) => {
        console.log(key, value, index, operation, 'asdf');
        setAllocationRowsBulk((prevState) => {
            // When an index is provided, update the row at that index
            if (typeof index === 'number') {
                return prevState.map((row, idx) => {
                    if (idx === index) {
                        // If updating the operator, also update the method and reset the value
                        if (key === 'operator') {
                            // Optionally toggle your dropdown here
                            toggleDropdown(index);
                            return {
                                ...row,
                                method: operation,
                                operator: value,
                                value: '',
                            };
                        }
                        // If the operation indicates a numeric conversion, store a number
                        else if (operation === 'NumericOperations') {
                            return {
                                ...row,
                                [ key ]: Number(value),
                            };
                        }
                        // Otherwise, update the key with the new value
                        else {
                            return {
                                ...row,
                                [ key ]: value,
                            };
                        }
                    }
                    return row;
                });
            }
            // If no index is provided (or additional logic is needed), just return the previous state
            return prevState;
        });
    };

    const handleDate = (data, index, item) => {
        handleChange('value', data, index, item.method);
    };
    const handleDateOperation = (index, item) => {
        return item.operator === 'between' ? (
            <DateDropDown
                options={[]}
                className={styles.select}
                onDropDownChange={() => {}}
                setIsFilter={setIsFilter}
                defaultOption={
                    isFilter?.start_date && isFilter?.end_date
                        ? `${ isFilter?.start_date } - ${ isFilter?.end_date }`
                        : 'dd/mm/yyyy - dd/mm/yyyy'
                }
                handleDate={(data) => handleDate(data, index, item)}
                dropdownRefs={dropdownRefs}
                businessLogicRef={businessLogicRef}
            />
        ) : (
            <DatePicker
                selected={item.value}
                onChange={(date) =>
                    handleChange('value', date, index, item.method)
                }
                dateFormat="dd/MM/yyyy"
                placeholderText="dd/MM/yyyy"
                className={styles.select}
                customInput={<CustomDateInput />}
                maxDate={new Date()}
            />
        );
    };
    const handleAddClick = () => {
        // Create a new default condition
        const newCondition = {
            id: `cond_${ allocationRowsBulk.length + 1 }`, // Generate unique ID
            key: '',
            value: '',
            operator: '',
            method: '',
            platform: dropdownOptions[ 0 ].value, // You may want to set a default value here
            allocation: '',
        };

        // Update state by appending the new condition to the current array
        setAllocationRowsBulk([ ...allocationRowsBulk, newCondition ]);
    };
    console.log(allocationRowsBulk, 'allocationRowsBulk');
    return (
        <AppLoader>
            <div className={styles.productDetailsWrapper}>
                <div className={styles.productDetailsHeader}>
                    <div className={styles.imageWrapper}>
                        <img src={productDetail.image_url} alt="image" />
                    </div>
                    <div className={styles.leftContent}>
                        <Typography className={styles.title} variant="title">
                            Style-ID: {productDetail?.style_id}
                        </Typography>
                        <div className={styles.productDetails}>
                            <div className={styles.pdpDetails}>
                                Product Name : {productDetail?.product_name}
                            </div>
                            <div className={styles.pdpDetails}>
                                Description : {productDetail?.description}
                            </div>
                            <div className={styles.pdpDetails}>
                                Price : {productDetail?.price}
                            </div>
                            <div className={styles.pdpDetails}>
                                Total Quantity : {productDetail?.total_quantity}
                            </div>
                            <div className={styles.pdpDetails}>
                                Threshold Limit :{' '}
                                {productDetail?.threshold_limit}
                            </div>
                            <div className={styles.pdpDetails}>
                                Unallocated Quantity :{' '}
                                {productDetail?.unallocated_quantity}
                            </div>
                            <div className={styles.pdpDetails}>
                                Total Related SKUs :{' '}
                                {relativesSKUTableTotalRecords}
                            </div>
                            <div className={styles.pdpDetails}>
                                Low Stock : {productDetail?.low_stock}
                            </div>
                        </div>
                        <div className={styles.inputFieldsWrapper}>
                            <div>
                                <Input
                                    type="text"
                                    className={styles.inputField}
                                    value={reservedQuantity}
                                    placeholder="Enter a reserved QTY"
                                    onChange={(e) =>
                                        setReservedQuantity(e.target.value)
                                    }
                                />
                            </div>
                            <button
                                className={styles.inputButton}
                                onClick={updateReserveQuantity}
                            >
                                Submit
                            </button>
                        </div>
                        {quantityError && (
                            <span className={styles.errorMessage}>
                                {quantityError}
                            </span>
                        )}
                    </div>
                </div>
                <div>
                    <div className={styles.inventorySection}>
                        <div className={styles.headerWrapper}>
                            <div className={styles.dropdownSku}>
                                <Typography variant="heading">
                                    Related SKUs
                                </Typography>
                                <div>
                                    <DropDown
                                        options={dropdownOptions}
                                        defaultValue={dropdownValues}
                                        showDownIcon={true}
                                        onDropDownChange={
                                            dropdownOnChangeHandler
                                        }
                                    />
                                </div>
                            </div>
                            <Button
                                className={styles.uploadButton}
                                onClick={handleOpenBulkAllocation}
                            >
                                Update Style Quantity
                            </Button>
                        </div>
                        {relativesSKUTableLoading ? (
                            <Spinner height="15vh" />
                        ) : relativesSKUTableError ? (
                            <Typography variant="error">
                                {relativesSKUTableError}
                            </Typography>
                        ) : (
                            <Table
                                loading={relativesSKUTableLoading}
                                headerList={relativesSKUTableColumns}
                                totalRecords={relativesSKUTableTotalRecords}
                                rowsData={relativesSKUTableData}
                                currentPage={relativesSKUTableCurrentPage}
                                setCurrentPage={relativesSKUTableSetCurrentPage}
                                showAllColumns={true}
                            />
                        )}
                    </div>
                </div>
            </div>

            <DialogWrapper
                isOpen={isAllocationOpen}
                onClose={handleClose}
                width="60%"
                minHeight="62vh"
            >
                <div className={styles.allocationContainer}>
                    <div className={styles.allocationHeader}>
                        <Typography variant="title">
                            Inventory Allocation
                        </Typography>
                        <div className={styles.percentageToggle}>
                            <span>By Percentage</span>
                            <input
                                type="checkbox"
                                className={styles.toggle}
                                checked={isPercentageBulk}
                                onChange={(e) => {
                                    setIsPercentageBulk(e.target.checked);
                                    setAllocationRowsBulk(
                                        allocationRowsBulk.map((row) => ({
                                            ...row,
                                            allocation: '',
                                        }))
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <div className={styles.allocationFields}>
                        {allocationRowsBulk?.map((row, index) => {
                            const availableOptions = dropdownOptions.filter(
                                (option) =>
                                    !allocationRowsBulk
                                        ?.filter((r) => r.id !== row.id)
                                        ?.map((r) => r.platform)
                                        ?.includes(option.value)
                            );
                            console.log(availableOptions, 'availableOptions');
                            return (
                                // <div
                                //     key={row.id}
                                //     className={styles.allocationRow}
                                // >
                                <div key={index} className={styles.wrapper}>
                                    <div
                                        className={styles.bussinessLogicContent}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <DropDown
                                                options={availableOptions}
                                                className={styles.keySelect}
                                                defaultValue={row.platform}
                                                showDownIcon={true}
                                                onDropDownChange={(value) =>
                                                    handleChange(
                                                        'platform',
                                                        value,
                                                        index
                                                    )
                                                }
                                            />
                                            <DropDown
                                                options={keysData}
                                                className={styles.keySelect}
                                                defaultValue={row.key}
                                                showDownIcon={true}
                                                onDropDownChange={(value) =>
                                                    handleChange(
                                                        'key',
                                                        value,
                                                        index
                                                    )
                                                }
                                            />
                                            <div
                                                className={styles.logicHeading}
                                            >
                                                <div
                                                    className={
                                                        styles.dropdownOne
                                                    }
                                                >
                                                    <button
                                                        onClick={() =>
                                                            toggleDropdown(
                                                                index
                                                            )
                                                        }
                                                        className={
                                                            styles.dropdown
                                                        }
                                                    >
                                                        {row.operator ||
                                                            'Logic'}
                                                        <LucideIcon name="ChevronDown" />
                                                    </button>

                                                    {isOpenDropdowns[ index ] && (
                                                        <div
                                                            ref={(el) =>
                                                                (dropdownRefs.current[
                                                                    index
                                                                ] = el)
                                                            }
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                top: '0px',
                                                                left: '0',
                                                                transform:
                                                                    'translate(350px, 253px)',
                                                                backgroundColor:
                                                                    '#fff',
                                                                border: '1px solid #ccc',
                                                                zIndex: '1000',
                                                                maxHeight:
                                                                    '250px',
                                                                overflowY:
                                                                    'auto',
                                                            }}
                                                        >
                                                            {businessLogics?.map(
                                                                (
                                                                    category,
                                                                    ind
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            ind
                                                                        }
                                                                        style={{
                                                                            padding:
                                                                                '10px',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                fontWeight:
                                                                                    'bold',
                                                                                color: '#4A90E2',
                                                                                marginBottom:
                                                                                    '5px',
                                                                            }}
                                                                        >
                                                                            {
                                                                                category?.label
                                                                            }
                                                                        </div>
                                                                        {category?.subOptions.map(
                                                                            (
                                                                                option,
                                                                                idx
                                                                            ) => (
                                                                                <div
                                                                                    key={
                                                                                        idx
                                                                                    }
                                                                                    style={{
                                                                                        padding:
                                                                                            '5px 10px',
                                                                                        cursor: 'pointer',
                                                                                        borderRadius:
                                                                                            '4px',
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        handleChange(
                                                                                            'operator',
                                                                                            option,
                                                                                            index,
                                                                                            category?.operation
                                                                                        )
                                                                                    }
                                                                                    onMouseOver={(
                                                                                        e
                                                                                    ) =>
                                                                                        (e.target.style.backgroundColor =
                                                                                            '#f0f0f0')
                                                                                    }
                                                                                    onMouseOut={(
                                                                                        e
                                                                                    ) =>
                                                                                        (e.target.style.backgroundColor =
                                                                                            'transparent')
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        option
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className={styles.outputHeading}
                                            >
                                                {row.method ===
                                                'DateOperations' ? (
                                                        handleDateOperation(
                                                            index,
                                                            row
                                                        )
                                                    ) : (
                                                        <Input
                                                            type="text"
                                                            placeholder="value"
                                                            value={row.value}
                                                            name="name"
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    'value',
                                                                    e?.target
                                                                        ?.value,
                                                                    index,
                                                                    row.method
                                                                )
                                                            }
                                                            className={
                                                                styles.valueSelect
                                                            }
                                                        />
                                                    )}
                                            </div>
                                            <div
                                                className={
                                                    styles.percentageInput
                                                }
                                            >
                                                <Input
                                                    type="number"
                                                    className={
                                                        styles.inputField
                                                    }
                                                    placeholder={
                                                        isPercentageBulk
                                                            ? 'Enter % (0-100)'
                                                            : 'Enter quantity'
                                                    }
                                                    value={row.allocation}
                                                    onChange={(e) =>
                                                        handleAllocationBulkChange(
                                                            e.target.value,
                                                            row.id
                                                        )
                                                    }
                                                />
                                                {isPercentageBulk && (
                                                    <span
                                                        className={
                                                            styles.percentSign
                                                        }
                                                    >
                                                        %
                                                    </span>
                                                )}
                                            </div>
                                            {index !== 0 && (
                                                <div
                                                    className={
                                                        styles.removeIcon
                                                    }
                                                >
                                                    <LucideIcon
                                                        name="X"
                                                        size="24"
                                                        onClick={() =>
                                                            handleRemoveClick(
                                                                index
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.fieldGroup}>
                                        {/* <label>Allocation</label> */}
                                    </div>
                                </div>
                            );
                        })}

                        <div className={styles.buttonGroup}>
                            {!allocationRowsBulk?.some(
                                (condition) => condition?.platform === 'all'
                            ) && allocationRowsBulk?.length < dropdownOptions.length && (
                                <button
                                    className={styles.iconButton}
                                    onClick={handleAddClick}
                                >
                                    <LucideIcon name="Plus" size={16} />
                                </button>
                            )}
                            <Button
                                className={styles.pushButton}
                                onClick={() => handlePushBulk(handleClose)}
                                disabled={
                                    isSubmittingBulk ||
                                    allocationRowsBulk?.some(
                                        (row) => !row.allocation
                                    )
                                }
                            >
                                {isSubmittingBulk ? 'Pushing...' : 'Push'}
                            </Button>
                        </div>

                        {apiErrorBulk && (
                            <Typography
                                variant="error"
                                className={styles.errorMessage}
                            >
                                {apiErrorBulk}
                            </Typography>
                        )}
                    </div>
                </div>
            </DialogWrapper>
        </AppLoader>
    );
};

export default ProductDetailsBulk;