import React from 'react'
import styles from './dialogWrapper.module.css';
import LucideIcon from '../lucideicon/LucideIcon.jsx';

function DialogWrapper({ isOpen, onClose, children, width, minHeight, overflow }) {
    const handleOutsideModalClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    if (!isOpen) return null;
    return (
        <div
            className={styles.modalOverlayWrapper}
            onClick={handleOutsideModalClick}
        >
            <div
                className={styles.modalOverlay}
                style={{
                    width: width,
                    minHeight: minHeight,
                    overflow: overflow,
                }}
            >
                <div className={styles.header}>
                    <div className={styles.dotWrapper}>
                        <div className={styles.redDot} />
                        <div className={styles.orangeDot} />
                        <div className={styles.greenDot} />
                    </div>
                    <LucideIcon
                        name="X"
                        className={styles.closeIcon}
                        onClick={onClose}
                    />
                </div>
                <div className={styles.modal}>{children}</div>
            </div>
        </div>
    );
}

export default DialogWrapper