// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.visTimeline_timeLine__q2-jv .vis-item {
    border-radius: 10px;
    background-color: var(--color-white);
}
.visTimeline_timeLine__q2-jv {
    position: relative;
}

.visTimeline_popup__Uj6FN {
    position: absolute;
    top: 50%;
    left: 54%;
    transform: translate(-50%, -20%);
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    z-index: 1000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.visTimeline_popupContent__3VvGW {
    padding: 0.5rem;
}
.visTimeline_popupParagraph__uneZU{
    padding: 0.5rem 1rem;
}

.visTimeline_popupButton__tTAju{
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    margin:0.5rem 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/visTimeline/visTimeline.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,oCAAoC;AACxC;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,0CAA0C;AAC9C;;AAEA;IACI,eAAe;AACnB;AACA;IACI,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".timeLine :global(.vis-item) {\n    border-radius: 10px;\n    background-color: var(--color-white);\n}\n.timeLine {\n    position: relative;\n}\n\n.popup {\n    position: absolute;\n    top: 50%;\n    left: 54%;\n    transform: translate(-50%, -20%);\n    border-radius: 5px;\n    padding: 10px;\n    background-color: white;\n    z-index: 1000;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.popupContent {\n    padding: 0.5rem;\n}\n.popupParagraph{\n    padding: 0.5rem 1rem;\n}\n\n.popupButton{\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    cursor: pointer;\n    margin:0.5rem 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timeLine": `visTimeline_timeLine__q2-jv`,
	"popup": `visTimeline_popup__Uj6FN`,
	"popupContent": `visTimeline_popupContent__3VvGW`,
	"popupParagraph": `visTimeline_popupParagraph__uneZU`,
	"popupButton": `visTimeline_popupButton__tTAju`
};
export default ___CSS_LOADER_EXPORT___;
