import { useEffect, useState } from 'react'
import useCustomAxiosCallTwo from '../../../../hooks/useCustomAxiosCallTwo';

const useStatCounterIcon = (uriEndPoint={}) => {
    const [ loading, setLoading ] = useState(false)
    const [ data, setData ] = useState();
    const [ error, setError ]=useState();
    const { callApi } = useCustomAxiosCallTwo();
    const getTableData = async () => {
        setLoading(true)
        const newUrl = `${ uriEndPoint.url }?type=${ uriEndPoint.type }&fetch_by=${ uriEndPoint.fetch_by }`
        const response = await callApi({
            uriEndPoint: {
                ...uriEndPoint,
                url: newUrl
            }
        });
        if (response && response.status === 200) {
            setData(response.data)
        } else {
            setError(response?.data?.error)
        }
        setLoading(false);
    }
    useEffect(() => {
        if (uriEndPoint?.url?.length>0){
            getTableData();
            window.scrollTo(0, 0)
        }
    }, [ uriEndPoint.url ]);
    return (
        { loading, data, error }
    )
}

export default useStatCounterIcon