import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOrganisationData } from '../../../redux/organisation/Selector';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import { useNavigate } from 'react-router-dom';
export const useAuthProgressFunc = () => {
    const [ error, setError ] = useState('')
    const [ loading, setLoading ] = useState(true)
    const navigate = useNavigate();
    const { callApi } = useCustomAxiosCall();
    const organisation = useSelector(selectOrganisationData);
    const orgId = organisation?.organisationData?.id;

    const generateAccessToken = async (code, connector) =>{
        setError('');
        setLoading(true);
        if(!code){
            setError('Unable to fetch code');
            navigateToConnectorsPage();
        }
        const response = await callApi({
            uriEndPoint: {
                url: `/organization/${ orgId }/connector/dynamic/callback`,
                method: 'POST'
            },
            body: {
                name: connector,
                code
            }
        });
        if (response?.status !== 201) {
            if(response.data.message === 'ALREADY-CONNECTED'){
                setError('Already Connected')
            }else{
                setError('Something Went wrong.. Please try again later')
            }
        }
        if (response.data.status === 200 || response.data.status === 201) {
            navigate('/connector', {
                state: {
                    organisation_id: orgId
                } });
        } else {
            setError('Something Went Wrong!! Please check your credentials.')
        }
    }

    const navigateToConnectorsPage=()=>{
        setTimeout(() => {
            if(orgId){
                navigate('/connector', {
                    state: {
                        organisation_id: orgId
                    } });
            }else{
                navigate('/organisation')
            }
        }, 1500);
    }

    return { loading, error, generateAccessToken };
}