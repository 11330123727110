import styles from './adminDashboard.module.css';
import StatCounter from '../../../components/widgets/statCounter/layout/StatCounter.jsx';
import AppLoader from '../../../appLoader';
import data from '../../../dummyData/AdminDashboard.json';
import Table from '../../../components/widgets/table/layout/Table.jsx';
import StatCounterII from '../../../components/widgets/statCounterII/layout/StatCounterII.jsx';
import LineChart from '../../../commonComponents/charts/lineChart/LineChart.jsx';
import useAdminDashboard from '../functionality/AdminDashboard.func';
// import MultiBarChart from '../../../commonComponents/charts/barChart/BarChart.jsx';
import PieChart from '../../../commonComponents/charts/pieChart/PieChart.jsx';
import React, { useEffect, useState } from 'react';
import ReturnCounter from '../../../components/widgets/returnCounter/layout/ReturnCounter.jsx';
import DeliveryStat from '../../../components/widgets/deliveryStat/layout/DeliveryStat.jsx';
import useAdminDashboardTwo from '../functionality/AdminDashboardTwo.func';
import Spinner from '../../../commonComponents/spinner/Spinner.jsx';
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';

const AdminDashboard = () => {
    const [ isFilter, setIsFilter ] = useState({
        start_date: 'dd/mm/yyyy',
        end_date: 'dd/mm/yyyy',
        filter: false,
    });

    const [ url, setUrl ] = useState({});

    useEffect(() => {
        const newUrl = {
            url: '/widget-count',
            service: 'collectivedata',
            filter: isFilter.filter || false,
            start_date: isFilter.start_date || '',
            end_date: isFilter.end_date || '',
        }
        if (!isFilter.filter) {
            newUrl.type = 'webshop';
            newUrl.fetch_by = 'type';
        }

        setUrl(newUrl);
    }, [ isFilter ]);

    const {
        widgetData: webshopWidgetData,
        widgetLoading: webshopWidgetLoading,
        orderStatClickHandler
    } = useAdminDashboardTwo(url);
    const { lineChartContainerRef, lineChartWidth, lineChart1, pieChart, dataSources, products, orderShippedData, orderCancelledData, multiLineItems, ordersFulfilled } = useAdminDashboard();
    return (
        <AppLoader isFilter={isFilter} setIsFilter={setIsFilter}>
            <div className={styles.adminDashboard}>
                {webshopWidgetLoading
                    ? <Spinner height={'25vh'}/>
                    : <div className={styles.statsContainer} style={{
                        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))'
                    }}>
                        <StatCounter  data={{ stat_name: 'Completed Orders', count: webshopWidgetData?.completed_orders?.count||0, icon: 'ReceiptIndianRupee', stat_level:webshopWidgetData?.completed_orders?.flag  }} onClick={()=>{orderStatClickHandler('/orders/fulfillment')}} />
                        <StatCounter  data={{ stat_name: 'Pending Orders', count: webshopWidgetData?.pending_orders?.count||0, icon: 'HandCoins', stat_level:webshopWidgetData?.pending_orders?.flag }} onClick={()=>{orderStatClickHandler('/orders/pending')}} />
                        <StatCounter data={{ stat_name: 'Partial Orders', count: webshopWidgetData?.partial_orders?.count||0,  icon: 'ShoppingBag', stat_level:webshopWidgetData?.partial_orders?.flag }} onClick={()=>{orderStatClickHandler('/orders/partial')}} />
                        <StatCounter data={{ stat_name: 'Refund Pending Orders', count: webshopWidgetData?.refund_pending_orders?.count||0,  icon: 'ReceiptIndianRupee', stat_level:webshopWidgetData?.refund_pending_orders?.flag }} onClick={()=>{orderStatClickHandler('/orders/refundpending')} }/>
                        <StatCounter  data={{ stat_name: 'Shipped Orders', count: webshopWidgetData?.shipped_orders?.count||0, icon: 'Signal', stat_level: webshopWidgetData?.shipped_orders?.flag }} onClick={()=>{orderStatClickHandler('/orders/shipped')}} />
                        <StatCounter data={{ stat_name: 'Refund Closed Orders', count: webshopWidgetData?.refund_closed_orders?.count|0,  icon: 'Layers', stat_level:webshopWidgetData?.refund_closed_orders?.flag }} onClick={()=>{orderStatClickHandler('/orders/refundclosed')}}/>
                        <StatCounter data={{ stat_name: 'Total Orders', count: webshopWidgetData?.total_orders?.count|0,  icon: 'UserCheck', stat_level:webshopWidgetData?.total_orders?.flag }} onClick={()=>{orderStatClickHandler('/orders/total')}}/>
                    </div>
                }
                {/* line chart */}
                <div className={styles.statsLineWrapper}>
                    <div className={styles.lineChartContainer} ref={lineChartContainerRef}>
                        <div className={styles.header}>
                            <div className={styles.iconWrap}>
                                <LucideIcon name="SignalHigh"/>
                            </div>
                            <span className={styles.lineChartTitle}>Sales Performance Categories</span>
                        </div>
                        <div className={styles.ChartWrapper}>
                            {lineChart1?.data?.map((item, key)=>
                                <div key={item.name}  className={styles.labelWrapper}>
                                    <span className={styles.colorBox} style={{ backgroundColor: lineChart1?.colors?.[ key ] }}></span>
                                    <div className={styles.label}>{item?.name}</div>
                                </div>
                            )}
                        </div>
                        <LineChart
                            data={lineChart1?.data}
                            labels={lineChart1?.labels}
                            height={250}
                            width={lineChartWidth}
                            colors={lineChart1?.colors}
                        />
                    </div>
                    {webshopWidgetLoading
                        ? <Spinner height={'25vh'}/>
                        :<div className={styles.stats}>
                            <StatCounterII
                                data={ { stat_name:orderShippedData , dataValue:  webshopWidgetData   }  }
                            />
                            <StatCounterII
                                data={ { stat_name:multiLineItems, dataValue:  webshopWidgetData   }  }
                            />
                            <StatCounterII
                                data={ { stat_name:ordersFulfilled , dataValue:  webshopWidgetData   }  }
                            />
                            <StatCounterII
                                data={ { stat_name:orderCancelledData , dataValue:  webshopWidgetData   }  }
                            />
                        </div>}
                </div>
                <div  className={`${ styles.lineChartContainer } ${ styles.instructionWrapper }`}>
                    { <div className={styles.iconWrapper} >
                        <LucideIcon name="ShoppingBag"/>
                    </div>}
                    <div className={styles.textInstruction}>Enable your order routing rules, build business logic to fulfill orders from your stores
                        or multiple Dcs</div>
                </div>
                {/* data sources table  */}
                <Table products={products}  data={dataSources} title='Data Sources of Client' />

                {/* pie chart and delivery stat */}
                <div className={styles.pieChartStatCounterIIIWrapper}>
                    <div className={styles.pieChartContainer}>
                        <div>
                            <span className={styles.lineChartTitle}>Return Rate 2.17%</span>
                            <ReturnCounter data={data.return_stats}/>
                        </div>
                        <PieChart
                            data={pieChart?.data}
                            colors={pieChart?.colors}
                            innerRadius={0}
                            outerRadius={120}
                            height={240}
                            width={300}
                            showPercentage={false}
                        />
                    </div>
                    <DeliveryStat
                        id="deliveryStatus"
                        deliveryData={data.delivery_stats}
                        avgDelivery={data.avg_delivery_stat}
                    />
                </div>

                {/* bar chart and line chart */}
                {/* <div className={styles.barLineChartWrapper}>
                    <div className={styles.LineChart2BorderLeft}>
                        <MultiBarChart
                            title='Out of Stock Rate (OOS)'
                            height={200}
                            width={550}
                            data={barChart?.data}
                            labels={barChart?.labels}
                            colors={barChart?.colors}
                        />
                    </div>
                    <div className={styles.LineChart2Container}>
                        <LineChart
                            data={lineChart2?.data}
                            labels={lineChart2?.labels}
                            colors={lineChart2?.colors}
                            height={200}
                            width={550}
                        />
                    </div>
                </div> */}
            </div>
        </AppLoader>
    )
};

export default AdminDashboard;