import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { selectOrganisationData } from '../../../redux/organisation/Selector';
import { camelCaseWords } from '../../../helpers';

function useCustomLogicFunc({ setBusinessLogic, index, inputValue }) {
    console.log(inputValue, '#inputValue')
    const [ query, setQuery ] = useState('');
    const [ customQuery, setCustomQuery ] = useState('');

    const [ response, setResponse ] = useState('');
    const [ customResponse, setCustomResponse ] = useState('');

    const [ previewResponse, setPreviewResponse ] = useState('');
    const [ customPreviewResponse, setCustomPreviewResponse ] = useState('');

    const [ queryLoading, setQueryLoading ] = useState(false);
    const [ customQueryLoading, setCustomQueryLoading ] = useState(false);

    const [ isEditing, setIsEditing ] = useState(false);
    const [ isCustomEditing, setIsCustomEditing ] = useState(false);

    const organizationData = useSelector(selectOrganisationData);
    const orgId = organizationData?.organisationData?.encrypt_id;

    // Handle query input changes
    const handleQueryChange = (e) => setQuery(e.target.value);
    const handleCustomQueryChange = (e) => setCustomQuery(e.target.value);

    // Format response
    const formattedResponse = (data) =>
        typeof data === 'object' && data !== null ? JSON.stringify(data, null, 2) : data;

    // Send query to API
    const handleSendQuery = async ( item ) => {
        console.log(item, '#item')
        if (!query && !item) {
            toast.error('Please fill a query prompt', { position: 'top-right' });
            return;
        }
        setQueryLoading(true);
        try {
            const res = await fetch(
                `${ process.env.REACT_APP_EKYAM_CHATBOT_BASE_URL }/get-database-query`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-organisation-id': orgId,
                    },
                    body: JSON.stringify({ prompt: query || item }),
                }
            );
            if (!res.ok) throw new Error(`Server error : ${ res.statusText }`);

            const data = await res.json();

            setBusinessLogic((prevState) => {
                const updatedBusinessLogic = [ ...prevState.business_logic.conditions ];
                const values = updatedBusinessLogic?.map((items) => {
                    if (items?.method === 'RDBMSOperations') {
                        return (
                            {
                                ...items,
                                codePrompt: query,
                                aggregation: formattedResponse(data?.aggregation),
                                className: '',
                                codeSnippet: '',
                                key: '',
                                model_name: '',
                                operator: '',
                                value: ''
                            }
                        )
                    } else {
                        return items
                    }

                })
                return {
                    ...prevState,
                    business_logic: {
                        ...prevState.business_logic,
                        conditions: values
                    }
                };
            });

            setResponse(formattedResponse(data?.aggregation));
            setPreviewResponse(
                data?.preview && Object.keys(data.preview).length > 0
                    ? formattedResponse(data.preview)
                    : 'No data found'
            );
        } catch (error) {
            console.error('Error sending query:', error);
            toast.error('Something went wrong', { position: 'top-right' });
        } finally {
            setQueryLoading(false);
        }
    };

    const handleSendCustomQuery = async (item) => {
        if (!customQuery && !item) {
            toast.error('Please fill a query prompt', { position: 'top-right' });
            return;
        }
        setCustomQueryLoading(true);
        try {
            const res = await fetch(
                `${ process.env.REACT_APP_EKYAM_CHATBOT_BASE_URL }/get-code-snippet`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-organisation-id': orgId,
                    },
                    body: JSON.stringify({ prompt: customQuery || item }),
                }
            );
            if (!res.ok) throw new Error(`Server error: ${ res.statusText }`);

            const data = await res.json();
            console.log(index, '#index')
            setBusinessLogic((prevState) => {
                const updatedBusinessLogic = [ ...prevState.business_logic.conditions ];
                const values = updatedBusinessLogic?.map((items) => {
                    if (items?.method === 'CustomOperations') {
                        return (
                            {
                                ...items,
                                codePrompt: customQuery,
                                codeSnippet: formattedResponse(data?.full_code_snippet),
                                className: camelCaseWords(inputValue),
                                aggregation: '',
                                key: '',
                                model_name: '',
                                operator: '',
                                value: ''
                            }
                        )
                    } else {
                        return items
                    }

                })

                return {
                    ...prevState,
                    business_logic: {
                        ...prevState.business_logic,
                        conditions: values
                    }
                };
            });

            setCustomResponse(formattedResponse(data?.full_code_snippet));
            setCustomPreviewResponse(
                data?.sample_output && Object.keys(data.sample_output).length > 0
                    ? formattedResponse(data.sample_output)
                    : 'No data found'
            );
        } catch (error) {
            console.error('Error sending query:', error);
            toast.error('Something went wrong', { position: 'top-right' });
        } finally {
            setCustomQueryLoading(false);
        }
    };

    // edit
    const handleEditClick = () => {
        if (!response.trim()) {
            toast.error('Text empty', { position: 'top-right' });
        } else {
            setIsEditing(true);
        }
    };

    const handleCustomEditClick = () => {
        if (!customResponse.trim()) {
            toast.error('Text empty', { position: 'top-right' });
        } else {
            setIsCustomEditing(true);
        }
    };

    //copy
    const handleCopy = () => {
        if (!response) {
            toast.error('No text to copy', { position: 'top-right' });
            return;
        }
        navigator.clipboard
            .writeText(response)
            .then(() => toast.success('Text copied to clipboard', { position: 'top-right' }))
            .catch(() => toast.error('Failed to copy text', { position: 'top-right' }));
    };

    const handleCustomCopy = () => {
        if (!customResponse) {
            toast.error('No text to copy', { position: 'top-right' });
            return;
        }
        navigator.clipboard
            .writeText(customResponse)
            .then(() => toast.success('Text copied to clipboard', { position: 'top-right' }))
            .catch(() => toast.error('Failed to copy text', { position: 'top-right' }));
    };

    const handleResponseChange = (e) => setResponse(e.target.value);
    const handleCustomResponseChange = (e) => setCustomResponse(e.target.value);
    const handleBlur = () => setIsEditing(false);
    const handleCustomBlur = () => setIsCustomEditing(false);

    return {
        response,
        customPreviewResponse,
        queryLoading,
        customQueryLoading,
        isEditing,
        previewResponse,
        customResponse,
        isCustomEditing,
        handleQueryChange,
        handleCustomQueryChange,
        handleSendQuery,
        handleSendCustomQuery,
        handleEditClick,
        handleCustomEditClick,
        handleCopy,
        handleCustomCopy,
        handleResponseChange,
        handleCustomResponseChange,
        handleBlur,
        handleCustomBlur,
        setCustomPreviewResponse,
        setCustomResponse,
        setResponse,
        setPreviewResponse,
        query,
        customQuery,
        setQuery,
        setCustomQuery
    };
}

export default useCustomLogicFunc;
