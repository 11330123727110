import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCustomAxiosCallTwo from '../../../hooks/useCustomAxiosCallTwo';
import { toast } from 'react-toastify';

export const useWorkFlowMultipleFunc = () => {
    const [ connectedConnectors, setConnectedConnectors ] = useState([]);

    const [ workFlowLoading, setWorkFlowLoading ]=useState()
    const [ workFlowGetLoading, setWorkFlowGetLoading ]=useState()
    const [ workflows, setWorkFlows ]=useState()
    const navigate = useNavigate();
    const { state } = useLocation();
    const organisation_id = (state && 'organisation_id' in state) ? state?.organisation_id : null;
    const workflowId = state.workflowId;
    const { callApi } = useCustomAxiosCallTwo();

    if (!organisation_id) {
        navigate('/organisation')
    }

    const getConnectedConnectors = async () => {
        const response = await callApi({
            uriEndPoint: {
                url: `/organization/${ organisation_id }/connector`
            }
        });
        if (response?.status === 200) {
            setConnectedConnectors(response?.data?.data || [])
        }
    };
    const handleNodeName=(name)=>{
        let displayName;
        switch (name) {
            case 'microsoft_dynamics_365': displayName = 'dynamics365'; break;
            default: displayName = name
                break;
        }
        return displayName
    }
    const handleWorkFlow = async (flowData, id )=>{
        setWorkFlowLoading(true)
        const simplifiedEdges = flowData.map(({ source, target, operation }) => ({
            source:handleNodeName(source),
            target:handleNodeName(target),
            operation
        }));
        const payload={
            'flows': simplifiedEdges
        }
        // setConnectedConnectorsLoading(true)
        const response = await callApi({
            uriEndPoint: {
                url: `/update-workflow/${ id }`,
                service: 'config',
                method: 'PUT'
            },
            body: { 'data': payload },
        });
        if (response?.status === 200) {
            setWorkFlowLoading(false)
            toast.success('Workflow updated successfully', { position: 'top-right' })

        }
        else{
            setWorkFlowLoading(false)
            toast.error('Something Went Wrong', { position: 'top-right' })
        }
    }
    const handleConnectorName = (machine_name, name) => {
        let displayName;
        switch (machine_name) {
            case 'microsoft_dynamics_365': displayName = 'Dynamics 365'; break;
            case 'magento': displayName = 'Magento'; break;
            case 'shopify': displayName = 'Shopify'; break;
            case 'netsuite': displayName = 'Netsuite'; break;
            case 'whiplash': displayName = 'Whiplash'; break;
            case 'tiktok': displayName = 'Tiktok'; break;
            default: displayName = name
                break;
        }
        return displayName
    }
    const handleRunWorkFlow = async (workFlowId)=>{
        const response = await callApi({
            uriEndPoint: {
                url: '/run-workflow',
                service: 'config',
                headers: { 'x-workflow-id': workFlowId }
            },
        });
        console.log(response, 'responseasd')
        if (response?.status === 200) {
            toast.success('Workflow run successfully', { position: 'top-right' })
        } else{
            toast.error('Something went wrong', { position: 'top-right' })

        }
    }
    const getWorkflow = async () => {
        setWorkFlowGetLoading(true)
        const response = await callApi({
            uriEndPoint: {
                url: `/get-workflow/${ workflowId }`,
                service: 'config',
            },
        });
        if (response?.status === 200) {
            setWorkFlows(response?.data?.data)
            setWorkFlowGetLoading(false)
        }
        else{
            setWorkFlowGetLoading(false)
        }
    }
    useEffect(()=>{
        if (workflowId){
            getWorkflow();
        }
    }, [ workflowId ])
    useEffect(() => {
        getConnectedConnectors();
    }, [])
    const handleTribe = (system) => {
        let tribe;
        switch (system) {
            case 'microsoft_dynamics_365': tribe = 'erp'; break;
            case 'magento': tribe = 'erp'; break;
            case 'shopify': tribe = 'webshop'; break;
            case 'netsuite': tribe = 'erp'; break;
            case 'whiplash': tribe = '3pl'; break;
            case 'tiktok': tribe = 'webshop'; break;
            case 'shopware': tribe = 'webshop'; break;
            case 'bigcommerce': tribe = 'webshop'; break;
            case 'woocommerce': tribe = 'webshop'; break;
            case 'snowflake': tribe = 'webshop'; break;
            case 'salesforce': tribe = 'erp'; break;
            default: tribe = system
                break;
        }
        return tribe
    }
    return {
        connectedConnectors,
        handleWorkFlow,
        workFlowLoading,
        workFlowGetLoading,
        handleConnectorName,
        handleRunWorkFlow,
        workflowId,
        workflowData: workflows,
        handleTribe
    };
}