import React from 'react'
import styles from './CustomInputArrayV2.module.css'

const ArrayChildrenFields = ({ 
    onInputChange = () => {}, 
    fieldData={},
    parentFieldName='',
    parentFieldData={} 
}) => {
    const [ inputData, setInputData ] = React.useState({ name: fieldData?.field_name || '', value: fieldData?.field_value||'' });
    const inputChangeHandler = (e) => {
        const { name, value } = e.target;
        setInputData((prev) => {
            let updatedData
            if(name.includes('field_name[]')){
                updatedData = { ...prev, name: value };
                fieldData.field_name = value 
            }else{
                updatedData = { ...prev, value: value }; 
                fieldData.field_value = value 
            }
            onInputChange(updatedData);
            return updatedData;
        });
    };

    return (
        <div className={styles.inputWrapper}>
            <input name={'field_name[]'} onChange={inputChangeHandler} value={inputData?.name} id={`${ parentFieldName }#${ parentFieldData?.field_name }__array#field_name[]`}/>
            <input name={`${ parentFieldName }#${ parentFieldData?.field_name }__array#${ fieldData?.field_name }`} onChange={inputChangeHandler} value={inputData?.value} id={`${ parentFieldName }#${ parentFieldData?.field_name }__array#field_value[]`}/>
        </div>
    );
};

export default ArrayChildrenFields;
