// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter_filter__OpVPI .filter_select__TPLu3{
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/commonComponents/filter/filter.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,SAAS;IACT,yBAAyB;AAC7B","sourcesContent":[".filter .select{\n    align-items: center;\n    display: flex;\n    gap: 1rem;\n    justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `filter_filter__OpVPI`,
	"select": `filter_select__TPLu3`
};
export default ___CSS_LOADER_EXPORT___;
