import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './ConnectorSkeleton.module.css';

const ConnectorSkeleton = () => {
    return (
        <div className={styles.skeletonContainer}>
            {Array(10).fill({}).map((_, index) => (
                <Skeleton key={index} width="100%" height={114} borderRadius={10} />
            ))}
        </div>
    )
};

export default ConnectorSkeleton;