// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Typography_typography__3onQg {
    color: var(--color-black);
    font-family: Outfit;
    font-size: 16px;
}

.Typography_title__WiXu2 {
    font-size: 18px;
    font-weight: 600;
}
.Typography_header-700__Gc8HL {
    font-size: 18px;
    font-weight: 700;
    color: var(--color-light-black);

}
.Typography_heading__TXCIB {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
}

.Typography_gradient__etfT4 {
    background: -webkit-linear-gradient(88.9deg, #6328DD 0.83%, #446CEB 47.73%, #0FC1DC 99.56%);
    background-clip: text;
    font-size: 24px;
    font-weight: 700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Typography_primary__NEjUH {
    color: var(--primary-color);
    font-size: 10px;
    font-weight: 600;
}
.Typography_secondary__jnTKc {
    color: var(--color-blue);
    font-size: 10px;
    font-weight: 600;
}
.Typography_error-sm__zCt-r {
    color: var(--color-red);
    font-size: 10px;
    font-weight: 600;
}
.Typography_error__pLrZC{
    color: var(--color-red);
}

.Typography_caption__wnK8j {
    color: #AEB3C8;
    font-size: 16px;
}

@media screen and (max-width: 1024px) {
    .Typography_typography__3onQg {
        font-size: 12px;
    }

    .Typography_title__WiXu2 {
        font-size: 14px;
    }

    .Typography_heading__TXCIB {
        font-size: 18px;
    }
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/typography/Typography.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,+BAA+B;;AAEnC;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,2FAA2F;IAC3F,qBAAqB;IACrB,eAAe;IACf,gBAAgB;IAChB,6BAA6B;IAC7B,oCAAoC;AACxC;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,wBAAwB;IACxB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,uBAAuB;IACvB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".typography {\n    color: var(--color-black);\n    font-family: Outfit;\n    font-size: 16px;\n}\n\n.title {\n    font-size: 18px;\n    font-weight: 600;\n}\n.header-700 {\n    font-size: 18px;\n    font-weight: 700;\n    color: var(--color-light-black);\n\n}\n.heading {\n    font-size: 20px;\n    font-weight: 600;\n    line-height: 25px;\n}\n\n.gradient {\n    background: -webkit-linear-gradient(88.9deg, #6328DD 0.83%, #446CEB 47.73%, #0FC1DC 99.56%);\n    background-clip: text;\n    font-size: 24px;\n    font-weight: 700;\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n}\n\n.primary {\n    color: var(--primary-color);\n    font-size: 10px;\n    font-weight: 600;\n}\n.secondary {\n    color: var(--color-blue);\n    font-size: 10px;\n    font-weight: 600;\n}\n.error-sm {\n    color: var(--color-red);\n    font-size: 10px;\n    font-weight: 600;\n}\n.error{\n    color: var(--color-red);\n}\n\n.caption {\n    color: #AEB3C8;\n    font-size: 16px;\n}\n\n@media screen and (max-width: 1024px) {\n    .typography {\n        font-size: 12px;\n    }\n\n    .title {\n        font-size: 14px;\n    }\n\n    .heading {\n        font-size: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typography": `Typography_typography__3onQg`,
	"title": `Typography_title__WiXu2`,
	"header-700": `Typography_header-700__Gc8HL`,
	"heading": `Typography_heading__TXCIB`,
	"gradient": `Typography_gradient__etfT4`,
	"primary": `Typography_primary__NEjUH`,
	"secondary": `Typography_secondary__jnTKc`,
	"error-sm": `Typography_error-sm__zCt-r`,
	"error": `Typography_error__pLrZC`,
	"caption": `Typography_caption__wnK8j`
};
export default ___CSS_LOADER_EXPORT___;
