import React, { useEffect, useState } from 'react';
import AppLoader from '../../appLoader';
import { toast } from 'react-toastify';
import styles from './productDetails.module.css';
import Typography from '../../commonComponents/typography/Typography.jsx';
import Input from '../../commonComponents/input/Input.jsx';
import LineChart from '../../commonComponents/charts/lineChart/LineChart.jsx';
import StatCounter from '../../components/widgets/statCounter/layout/StatCounter.jsx';
import LucideIcon from '../../commonComponents/lucideicon/LucideIcon.jsx';
import Stopwatch from '../../commonComponents/stopwatch/Stopwatch.jsx';
import { useLocation, useParams } from 'react-router-dom';
import ProductDetailData from '../../dummyData/ProductDetails.json';
import VisTimeline from '../visTimeline/VisTimeline.jsx';
import useProductDetails from './functionality/useProductDetails.func';
import {
    calculateDaysFromMonths,
    formatedDate,
    getDateLocale,
    getDropdownOptions,
} from '../../helpers/index';
import Spinner from '../../commonComponents/spinner/Spinner.jsx';
import Button from '../../commonComponents/button/Button.jsx';
import DropDown from '../../commonComponents/dropDown/DropDown.jsx';
import Table from '../../commonComponents/table/Table.jsx';
import useTables from '../../commonComponents/table/useTables.func';
import { useSelector } from 'react-redux';
import { selectOrganisationData } from '../../redux/organisation/Selector';
import useTablesTwo from '../../commonComponents/table/useTablesTwo.func';
import Modal from '../../commonComponents/modal/Modal.jsx';
import DialogWrapper from '../../commonComponents/DialogWrapper/DialogWrapper.jsx';
import { useInventoryFunc } from '../../views/inventory/list/functionality/inventory.func';

const ProductDetails = () => {
    const [ productDetail, setProductDetail ] = useState({});
    const [ filterData, setFilterData ] = useState([]);
    const [ reservedQuantity, setReservedQuantity ] = useState({
        lowerThresholdQuantity: '',
        upperThresholdQuantity: ''
    });
    const organizationData = useSelector(selectOrganisationData);
    const orgId = organizationData?.organisationData?.encrypt_id;
    const { connectedConnectors } = useInventoryFunc();

    const {
        timelineDateOptions,
        timeLineOptions,
        timeLinePopupKey,
    } = ProductDetailData;
    const { state } = useLocation();
    const { itemId } = useParams();
    const { productsDetail } = state;
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const [ timelineDate, setTimelineDate ] = useState({
        start_date: formatedDate(new Date()),
        end_date: formatedDate(tomorrow),
    });
    // const [ platform, setPlatform ] = useState(dropdownOptions[ 0 ].value);
    const [ timeLine, setTimeLine ] = useState(timelineDateOptions[ 0 ].value);
    const [ isPercentage, setIsPercentage ] = useState(true);
    const [ isPercentageBulk, setIsPercentageBulk ] = useState(true);
    // const [ allocation, setAllocation ] = useState('');

    // Add new state for API status
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ isSubmittingBulk, setIsSubmittingBulk ] = useState(false);
    const [ apiError, setApiError ] = useState(null);

    const [ apiErrorBulk, setApiErrorBulk ] = useState(null);
    const dropdownOptions = getDropdownOptions(connectedConnectors);

    // Add new state for multiple allocation rows
    const [ allocationRows, setAllocationRows ] = useState([
        {
            id: 1,
            platform: dropdownOptions[ 0 ].value,
            allocation: '',
        }
    ]);
    const [ allocationRowsBulk, setAllocationRowsBulk ] = useState([
        {
            id: 1,
            platform: dropdownOptions[ 0 ].value,
            allocation: '',
        }
    ]);
    // Add state for table config
    const [ tableConfig, setTableConfig ] = useState({ url: '', service: 'collectivedata' });

    // Add states for inventory data
    const [ inventoryData, setInventoryData ] = useState([]);
    const [ inventoryColumns, setInventoryColumns ] = useState([]);
    const [ inventoryLoading, setInventoryLoading ] = useState(false);
    const [ inventoryError, setInventoryError ] = useState(null);
    const [ inventoryCurrentPage, setInventoryCurrentPage ] = useState(1);
    const [ inventoryTotalRecords, setInventoryTotalRecords ] = useState(0);
    const [ isAllocationOpen, setIsAllocationOpen ] = useState(false)
    // Add new state for modal
    const [ isReleaseModalOpen, setIsReleaseModalOpen ] = useState(false);
    const [ selectedInventory, setSelectedInventory ] = useState(null);
    const [ releaseQuantity, setReleaseQuantity ] = useState('');
    const [ releaseButton, setReleaseButton ] = useState('');
    const [ releaseError, setReleaseError ] = useState({});
    const [ quantityError, setQuantityError ] = useState('');
    const [ releasePercentage, setReleasePercentage ] = useState(true)
    const dropdownChangeHandler = (value, rowId) => {
        setAllocationRows(prevRows =>
            prevRows.map(row =>
                row.id === rowId ? { ...row, platform: value } : row
            )
        );
    };
    // Add new handler for bulk allocation dropdown
    const dropdownChangeBulkHandler = (value, rowId) => {
        setAllocationRowsBulk(prevRows =>
            prevRows.map(row =>
                row.id === rowId ? { ...row, platform: value } : row
            )
        );
    };

    // Add separate handler for bulk allocation input changes
    const handleAllocationBulkChange = (value, rowId) => {
        setAllocationRowsBulk(prevRows =>
            prevRows.map(row => {
                if (row.id === rowId) {
                    if (isPercentageBulk) {
                        if (!value || (Number(value) >= 0 && Number(value) <= 100)) {
                            return { ...row, allocation: value };
                        }
                        return row;
                    } else {
                        if (!value || Number(value) >= 0) {
                            return { ...row, allocation: value };
                        }
                        return row;
                    }
                }
                return row;
            })
        );
    };
    useEffect(() => {

        if (productsDetail.length > 0) {
            // Convert itemId to integer for comparison
            const filteredData = productsDetail.filter((obj) => {
                return obj?.product_id === itemId;
            });

            if (filteredData.length > 0) {
                setProductDetail(filteredData?.[ 0 ]);
            } else {
                console.log('No product found with this itemId', itemId);
            }
        }
    }, [ productsDetail, itemId ]);

    const parameters = {
        start_date: timelineDate.start_date,
        end_date: timelineDate.end_date,
        sku: productDetail?.sku,
        filter: filterData.length > 0 ? filterData.join(',') : filterData,
    };

    const {
        data: dataGrid,
        columns: columnsGridData,
        loading: loadingDataGrid,
        error: errorDataGrid,
        currentPage,
        setCurrentPage,
        totalRecords,
        setPageSize,
        rowData,
    } = useTables(
        { url: '/order-timeline', service: 'collectivedata', params: parameters },
        [],
        [],
        {}
    );
    const { data, loading, error } = useProductDetails({
        url: '/eta-shipnotice',
        service: 'collectivedata',
    });
    const shipmentData = data?.data?.[ 0 ] || {};

    // const handleChange = (e) => {
    //     setReservedQuantity(e.target.value);
    //     if (e.target.value && e.target.value > 0) {
    //         setQuantityError('');
    //     }
    // };
    const nextShipment = [
        { label: 'Shipment level No', value: shipmentData?.sender },
        { label: 'Quantity', value: shipmentData?.shipnotice_item_quantity },
        {
            label: 'Delivery Date',
            value: getDateLocale(shipmentData?.estimated_delivery_date),
        },
    ];
    const getTimeLineDetail = (value) => {
        const today = new Date();
        const date = new Date(today);
        date.setDate(today.getDate() - value);
        setTimelineDate({
            start_date: formatedDate(date),
            end_date: formatedDate(tomorrow),
        });
    };
    const handleTimelineFilter = (value) => {
        setTimeLine(value);
        if (value === 'Today') {
            setTimelineDate({
                start_date: formatedDate(today),
                end_date: formatedDate(tomorrow),
            });
        } else if (value.includes('Month')) {
            const getMonth = value.split(' ')[ 1 ];
            const getDays = calculateDaysFromMonths(getMonth);
            getTimeLineDetail(getDays);
        } else {
            const getDays = value.split(' ')[ 1 ];
            getTimeLineDetail(getDays);
        }
    };
    const handleFilter = (value) => {
        if (!filterData.includes(value)) {
            setFilterData([ ...filterData, value ]);
        } else {
            const updatedArray = filterData.filter((item) => item !== value);
            setFilterData(updatedArray);
        }
    };
    const handleAllocationChange = (value, rowId) => {
        setApiError(null)
        setAllocationRows(prevRows =>
            prevRows.map(row => {
                if (row.id === rowId) {
                    if (isPercentage) {
                        if (!value || (Number(value) >= 0 && Number(value) <= 100)) {
                            return { ...row, allocation: value };
                        }
                        return row;
                    } else {
                        if (
                            !value ||
                            (value >= 0 && value <= productDetail?.unallocated_quantity)
                        ) {
                            return { ...row, allocation: value };
                        }
                        else {
                            setApiError(`Allocation cannot exceed the current stock of ${ productDetail?.unallocated_quantity }.Please enter a valid amount.`)
                        }

                        return row;
                    }
                }
                return row;
            })
        );
    };

    // Add handler for adding new row
    const addAllocationRow = () => {
        // Get all currently selected platforms
        const selectedPlatforms = allocationRows.map(row => row.platform);
        // Find first available platform
        const availablePlatform = dropdownOptions.find(option =>
            !selectedPlatforms.includes(option.value)
        );

        if (availablePlatform) {
            setAllocationRows(prev => [
                ...prev,
                {
                    id: Math.max(...prev.map(row => row.id)) + 1,
                    platform: availablePlatform.value,
                    allocation: '',
                }
            ]);
        }
    };
    const addAllocationRowBulk = () => {
        // Get all currently selected platforms
        const selectedPlatforms = allocationRowsBulk.map(row => row.platform);
        // Find first available platform
        const availablePlatform = dropdownOptions.find(option =>
            !selectedPlatforms.includes(option.value)
        );

        if (availablePlatform) {
            setAllocationRowsBulk(prev => [
                ...prev,
                {
                    id: Math.max(...prev.map(row => row.id)) + 1,
                    platform: availablePlatform.value,
                    allocation: '',
                }
            ]);
        }
    };

    // Add handler for removing row
    const removeAllocationRow = (rowId) => {
        setAllocationRows(prev => prev.filter(row => row.id !== rowId));
    };

    // Modify the handlePush function to handle multiple rows
    const handlePushBulk = async (handleClose = () => { }) => {
        const invalidRows = allocationRowsBulk.some(row => !row.allocation || !row.platform);
        if (invalidRows || !productDetail?.sku) {
            return;
        }

        setIsSubmittingBulk(true);
        setApiErrorBulk(null);

        try {
            const response = await fetch('https://dev-app.ekyam.ai/e/api/collectivedata/inventory?type=style_based', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-organisation-id': orgId
                },
                body: JSON.stringify({
                    data: allocationRowsBulk.reduce((acc, row) => {
                        // Find if the sku is already in the accumulator
                        const existingSku = acc.find(item => item.sku === productDetail?.sku);
                        if (existingSku) {
                            // Add the sales channel and quantity to the existing sku entry
                            existingSku.sales_channel_list.push({
                                name: row.platform,
                                quantity: isPercentage ? row.allocation + '%' : String(Math.floor(Number(row.allocation)))
                            });
                        } else {
                            // Add a new object for the sku and its associated sales channels
                            acc.push({
                                style_id: productDetail?.style_id, // Ensure sku is included
                                sales_channel_list: [ {
                                    name: row.platform,
                                    quantity: isPercentage ? row.allocation + '%' : String(Math.floor(Number(row.allocation)))
                                } ]
                            });
                        }
                        return acc;
                    }, [])
                })
            });
            if (!response.ok) {
                throw new Error('Failed to update inventory');
            }

            // Clear allocation inputs after successful push
            setAllocationRowsBulk([ {
                id: 1,
                platform: dropdownOptions[ 0 ].value,
                allocation: '',
            } ]);
            handleClose()
        } catch (error) {
            setApiErrorBulk(error.message);
        } finally {
            setIsSubmittingBulk(false);
        }
    }
    const handlePush = async () => {
        const invalidRows = allocationRows.some(
            (row) => !row.allocation || !row.platform
        );
        if (invalidRows || !productDetail?.sku) {
            return;
        }

        setIsSubmitting(true);
        setApiError(null);
        const allPlatformsSelected = allocationRows.some(
            (row) => row.platform === 'all'
        );
        let data = [
            {
                sku: productDetail?.sku,
                sales_channel_list: [],
            },
        ];
        if (allPlatformsSelected) {
            const allPlatformsData = allocationRows.filter(
                (row) => row.platform === 'all'
            );
            dropdownOptions.forEach((value) => {
                if (value?.value !== 'all') {
                    data[ 0 ].sales_channel_list.push({
                        name: value.value,
                        quantity: isPercentage
                            ? allPlatformsData[ 0 ]?.allocation + '%'
                            : String(
                                Math.floor(
                                    Number(allPlatformsData[ 0 ]?.allocation)
                                )
                            ),
                    });
                }
            });

        } else {
            data = allocationRows.reduce((acc, row) => {
                // Find if the SKU already exists in the accumulator
                const existingSku = acc.find(
                    (item) => item.sku === productDetail?.sku
                );

                if (existingSku) {
                    // Check if the sales channel already exists
                    const existingChannel = existingSku.sales_channel_list.find(
                        (channel) => channel.name === row.platform
                    );

                    if (!existingChannel) {
                        // Add the new sales channel if it doesn't exist
                        existingSku.sales_channel_list.push({
                            name: row.platform,
                            quantity: isPercentage
                                ? row.allocation + '%'
                                : String(Math.floor(Number(row.allocation))),
                        });
                    }
                } else {
                    // Add a new object for the SKU and its associated sales channels
                    acc.push({
                        sku: productDetail?.sku, // Ensure SKU is included
                        sales_channel_list: [
                            {
                                name: row.platform,
                                quantity: isPercentage
                                    ? row.allocation + '%'
                                    : String(Math.floor(Number(row.allocation))),
                            },
                        ],
                    });
                }
                return acc;
            }, []);
        }
        try {
            const response = await fetch(
                `${ process.env.REACT_APP_EKYAM_COLLECTIVE_DATA_BASE_URL }/inventory`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-organisation-id': orgId,
                    },
                    body: JSON.stringify({
                        data: data,
                    }),
                }
            );
            if (response.status === 200) {
                toast.success('Inventory Allocated Successfully', {
                    position: 'top-right',
                });
            } else if (!response.ok) {
                toast.error('Something Went Wrong', {
                    position: 'top-right',
                });
            }

            // Clear allocation inputs after successful push
            setAllocationRows([
                {
                    id: 1,
                    platform: dropdownOptions[ 0 ].value,
                    allocation: '',
                },
            ]);
        } catch (error) {
            setApiError(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };
    // Update table config when SKU becomes available
    useEffect(() => {
        if (productDetail?.sku) {
            setTableConfig({
                url: `/get-channels-sku-data?sku=${ productDetail.sku }&sales_channel=bigcommerce%2Cshopify%2Camazon_seller_central`,
                service: 'collectivedata',
            });
        }
    }, [ productDetail?.sku ]);
    // Use the hook and update local state
    const tableData = useTablesTwo(tableConfig);
    useEffect(() => {
        const columnsWithAction = [
            ...tableData.columns,
            {
                field: 'actions',
                headerName: 'Actions',
                flex: 1,
                renderCell: (params) => (
                    <Button
                        onClick={() => handleReleaseClick(params.row)}
                        className={styles.releaseButton}
                        variant="contained"
                        size="small"
                    >
                        Release
                    </Button>
                )
            }
        ];

        setInventoryColumns(columnsWithAction);
        setInventoryData(tableData.data);
        setInventoryLoading(tableData.loading);
        setInventoryError(tableData.error);
        setInventoryCurrentPage(tableData.currentPage);
        setInventoryTotalRecords(tableData.totalRecords);
    }, [ tableData?.columns?.length ]);
    useEffect(() => {
        const columnsWithAction = [
            ...tableData.columns,
            {
                field: 'actions',
                headerName: 'Actions',
                flex: 1,
                renderCell: (params) => (
                    <Button
                        onClick={() => handleReleaseClick(params.row)}
                        className={styles.releaseButton}
                        variant="contained"
                        size="small"
                    >
                        Release
                    </Button>
                )
            }
        ];

        setInventoryColumns(columnsWithAction);
        setInventoryData(tableData.data);
        setInventoryLoading(tableData.loading);
        setInventoryError(tableData.error);
        setInventoryCurrentPage(tableData.currentPage);
        setInventoryTotalRecords(tableData.totalRecords);
    }, [ tableData?.columns?.length ]);
    // Add handlers for the modal
    const handleReleaseClick = (row) => {
        setSelectedInventory(row);
        setReleaseButton(row?.source)
        setReleaseQuantity('');
        setReleaseError({});
        setIsReleaseModalOpen(true);
    };

    const handleReleaseModalClose = () => {
        setIsReleaseModalOpen(false);
        setSelectedInventory(null);
        setReleaseQuantity('');
        setReleaseError({});
        setReleasePercentage(true)
    };

    const handleReleaseQuantityChange = (e) => {
        const value = e.target.value;
        if (!value || (Number(value) >= 0 && Number(value) <= 100)) {
            setReleaseQuantity(value);
        }
        setReleaseError({});
    };

    const handleReleaseSubmit = async () => {
        // Add validation
        if (!releaseQuantity) {
            setReleaseError({ quantity: 'Quantity is required' });
            return;
        }

        if (releaseQuantity < 0 || releaseQuantity > 100) {
            setReleaseError({ quantity: 'Quantity must be between 0 and 100' });
            return;
        }

        // Add your API call here
        try {
            const response = await fetch(
                `${ process.env.REACT_APP_EKYAM_COLLECTIVE_DATA_BASE_URL }/release-inventory`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-organisation-id': orgId,
                    },
                    body: JSON.stringify({
                        sku: productDetail?.sku,
                        sales_channel: releaseButton,
                        quantity: releasePercentage
                            ? releaseQuantity + '%'
                            : String(Math.floor(Number(releaseQuantity))),
                    }),
                }
            );
            if (response.status === 200) {
                toast.success('Inventory Released Successfully', {
                    position: 'top-right',
                });
            } else if (!response.ok) {
                toast.error('Something Went Wrong', {
                    position: 'top-right',
                });
            }
            handleReleaseModalClose();

            // Refresh the table data if needed
        } catch (error) {
            setReleaseError({ response: error.message });
        }
    };

    const updateReserveQuantity = async () => {
        setQuantityError('')
        if (!reservedQuantity?.lowerThresholdLimit && !reservedQuantity?.upperThresholdLimit) {
            setQuantityError('Enter Reserved quantity');
            return;
        }
        const url = `${ process.env.REACT_APP_EKYAM_COLLECTIVE_DATA_BASE_URL }/update-reserve-quantity/${ itemId }`;

        try {
            const body = {};
            if (reservedQuantity?.lowerThresholdLimit) {
                body.reserve_quantity = Number(reservedQuantity?.lowerThresholdLimit);
            }
            if (reservedQuantity.upperThresholdLimit) {
                body.upper_reserve_quantity = Number(reservedQuantity?.upperThresholdLimit);
            }
            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'x-organisation-id': orgId,
                },
                body: JSON.stringify(body),
            });
            if (response.status === 200) {
                toast.success('Quantity updated', {
                    position: 'top-right',
                });
                setReservedQuantity('');
            } else {
                toast.error('Something Went Wrong', {
                    position: 'top-right',
                });
            }
        } catch (error) {
            toast.error('Something Went Wrong', {
                position: 'top-right',
            });
            console.error('Request failed:', error);
        }

    };
    const handleClose = () => {
        setIsAllocationOpen(false)
    }
    const handlePercentage = (e) => {
        const isChecked = e.target.checked;
        setReleasePercentage(isChecked);
        if (isChecked) {
            setReleaseQuantity('');
        } else {
            setReleaseQuantity('');
        }
    };
    const handleInputChange = (e) => {
        setQuantityError('')
        const { name, value } = e.target;
        setReservedQuantity((prevState) => ({
            ...prevState,
            [ name ]: value,
        }));
    };
    return (
        <AppLoader>
            <div className={styles.productDetailsWrapper}>
                <div className={styles.productDetailsHeader}>
                    <div className={styles.imageWrapper}>
                        <img src={productDetail?.image_url} alt="" />
                    </div>
                    <div className={styles.leftContent}>
                        <Typography className={styles.title} variant="title">
                            {productDetail?.product_name}
                        </Typography>
                        <p className={styles.subHeading}>
                            {productDetail?.description}
                        </p>
                        <div className={styles.productDetails}>
                            <div className={styles.pdpDetails}>
                                SKU : {productDetail?.sku}
                            </div>
                            <div className={styles.pdpDetails}>
                                Price : {productDetail?.price}
                            </div>
                            <div className={styles.pdpDetails}>
                                Total Quantity : {productDetail?.quantity}
                            </div>
                            <div className={styles.pdpDetails}>
                                Threshold Limit : {productDetail?.threshold_limit}
                            </div>
                            <div className={styles.pdpDetails}>
                                Upper Threshold Limit : {productDetail?.upper_threshold_limit}
                            </div>
                            <div className={styles.pdpDetails}>
                                Unallocated Quantity : {productDetail?.unallocated_quantity}
                            </div>
                            <div className={styles.pdpDetails}>
                                Low Stock : {productDetail?.low_stock}
                            </div>
                        </div>
                        <div className={styles.inputfieldContainer}>
                            <div>
                                <div className={styles.inputFieldsWrapper}>
                                    <div>
                                        <Typography variant="title-12">Lower Threshold Limit</Typography>
                                        <Input
                                            type="text"
                                            className={styles.inputField}
                                            name="lowerThresholdLimit"
                                            value={reservedQuantity?.lowerThresholdLimit}
                                            placeholder="Enter a threshold limit"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div>
                                        <Typography variant="title-12">Upper Threshold Limit</Typography>
                                        <Input
                                            type="text"
                                            className={styles.inputField}
                                            name="upperThresholdLimit"
                                            value={reservedQuantity?.upperThresholdLimit}
                                            placeholder="Enter an upper threshold limit"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <button className={styles.inputButton} onClick={updateReserveQuantity}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        {quantityError && (
                            <span className={styles.errorMessage}>
                                {quantityError}
                            </span>
                        )}
                        {quantityError && (
                            <span className={styles.errorMessage}>
                                {quantityError}
                            </span>
                        )}
                    </div>
                </div>
                <div>
                    <div className={styles.chartWidgetWrapper}>
                        <div className={styles.chartWrapper}>
                            <div className={styles.chartHeader}>
                                <div className={styles.chartIcon}>
                                    <LucideIcon
                                        name={ProductDetailData.lineChart.icon}
                                    />
                                </div>
                                <Typography variant="title">
                                    {ProductDetailData.lineChart.title}
                                </Typography>
                            </div>
                            <LineChart
                                data={ProductDetailData.lineChart?.data}
                                // labels={lineChart.labels}
                                height={275}
                                // // width={400}
                                colors={ProductDetailData.lineChart?.colors}
                            />
                        </div>
                        <div className={styles.widgetWrapper}>
                            <div className={styles.widgets}>
                                {ProductDetailData.stats.map((obj, index) => (
                                    <StatCounter
                                        key={index}
                                        data={{
                                            stat_name: obj.stat_name,
                                            count: obj.count,
                                            icon: obj.icon,
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.pushProductNextShipmentWrapper}>
                        <div className={styles.pushProductsWrapper}>
                            <div className={styles.allocationContainer}>
                                <div className={styles.allocationHeader}>
                                    <Typography variant="title">
                                        Inventory Allocation
                                    </Typography>
                                    <div className={styles.percentageToggle}>
                                        <span>By Percentage</span>
                                        <input
                                            type="checkbox"
                                            className={styles.toggle}
                                            checked={isPercentage}
                                            onChange={(e) => {
                                                setIsPercentage(
                                                    e.target.checked
                                                );
                                                setAllocationRows(
                                                    allocationRows.map(
                                                        (row) => ({
                                                            ...row,
                                                            allocation: '',
                                                        })
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className={styles.allocationFields}>
                                    {allocationRows.map((row) => {
                                        const availableOptions =
                                            dropdownOptions.filter(
                                                (option) =>
                                                    !allocationRows
                                                        .filter(
                                                            (r) =>
                                                                r.id !== row.id
                                                        )
                                                        .map((r) => r.platform)
                                                        .includes(option.value)
                                            );

                                        return (
                                            <div
                                                key={row.id}
                                                className={styles.allocationRow}
                                            >
                                                <div
                                                    className={
                                                        styles.fieldGroup
                                                    }
                                                >
                                                    <label>Sales Channel</label>
                                                    <DropDown
                                                        options={
                                                            availableOptions
                                                        }
                                                        className={
                                                            styles.dropDown
                                                        }
                                                        defaultValue={
                                                            row.platform
                                                        }
                                                        showDownIcon={true}
                                                        onDropDownChange={(
                                                            value
                                                        ) =>
                                                            dropdownChangeHandler(
                                                                value,
                                                                row.id
                                                            )
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    className={
                                                        styles.fieldGroup
                                                    }
                                                >
                                                    <label>Current Stock</label>
                                                    <Input
                                                        type="text"
                                                        className={
                                                            styles.inputField
                                                        }
                                                        value={
                                                            productDetail?.unallocated_quantity
                                                        }
                                                        disabled
                                                    />
                                                </div>

                                                <div
                                                    className={
                                                        styles.fieldGroup
                                                    }
                                                >
                                                    <label>Allocation</label>
                                                    <div
                                                        className={
                                                            styles.percentageInput
                                                        }
                                                    >
                                                        <Input
                                                            type="number"
                                                            className={
                                                                styles.inputField
                                                            }
                                                            placeholder={
                                                                isPercentage
                                                                    ? 'Enter % (0-100)'
                                                                    : 'Enter quantity'
                                                            }
                                                            value={
                                                                row.allocation
                                                            }
                                                            onChange={(e) =>
                                                                handleAllocationChange(
                                                                    e.target
                                                                        .value,
                                                                    row.id
                                                                )
                                                            }
                                                        />
                                                        {isPercentage && (
                                                            <span
                                                                className={
                                                                    styles.percentSign
                                                                }
                                                            >
                                                                %
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>

                                                {allocationRows.length > 1 && (
                                                    <button
                                                        className={
                                                            styles.iconButton
                                                        }
                                                        onClick={() =>
                                                            removeAllocationRow(
                                                                row.id
                                                            )
                                                        }
                                                    >
                                                        <LucideIcon
                                                            name="Minus"
                                                            size={16}
                                                        />
                                                    </button>
                                                )}
                                            </div>
                                        );
                                    })}

                                    <div className={styles.buttonGroup}>
                                        {allocationRows.length <
                                            dropdownOptions.length && (
                                            <button
                                                className={styles.iconButton}
                                                onClick={addAllocationRow}
                                            >
                                                <LucideIcon
                                                    name="Plus"
                                                    size={16}
                                                />
                                            </button>
                                        )}
                                        <Button
                                            className={styles.pushButton}
                                            onClick={handlePush}
                                            disabled={
                                                isSubmitting ||
                                                allocationRows.some(
                                                    (row) => !row.allocation
                                                )
                                            }
                                        >
                                            {isSubmitting
                                                ? 'Pushing...'
                                                : 'Push'}
                                        </Button>
                                    </div>

                                    {apiError && (
                                        <Typography
                                            variant="error"
                                            className={styles.errorMessage}
                                        >
                                            {apiError}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <div className={styles.pushProductsWrapper}>
              <Input
                type="text"
                className={styles.inputField}
                name="reservedPushQty"
                placeholder="Enter Reserved Qty"
                onChange={handleChange}
                value={fields["reservedPushQty"]}
              />
              <DropDown
                options={dropdownOptions}
                className={styles.dropDown}
                defaultValue={platform}
                showDownIcon={true}
                bodyClassName={styles.dropDownBody}
                itemClassName={styles.dropdownItem}
                onDropDownChange={dropdownChangeHandler}
              />
              <Button className={`${styles.button}`}>Push</Button>
            </div> */}
                        <div className={styles.nextShipmentWrapper}>
                            {loading ? (
                                <Spinner height="15vh" />
                            ) : error ? (
                                <Typography variant="error">{error}</Typography>
                            ) : (
                                <>
                                    <div className={styles.nextShipment}>
                                        <div className={styles.innerHeader}>
                                            <div className={styles.iconWrapper}>
                                                <LucideIcon name="CalendarClock" />
                                            </div>
                                            <div
                                                className={
                                                    styles.nextShipmentTitle
                                                }
                                            >
                                                {
                                                    ProductDetailData
                                                        .nextShipment.title
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.stopWatch}>
                                            <Stopwatch
                                                time={
                                                    data?.data?.[ 0 ]
                                                        ?.estimated_delivery_date
                                                }
                                            />
                                            <div>
                                                <LucideIcon name="AlarmClock" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.shipmentDetails}>
                                        {nextShipment.map((obj, index) => (
                                            <div key={index}>
                                                <div className={styles.header}>
                                                    {obj.label}
                                                </div>
                                                <div className={styles.value}>
                                                    {obj.value}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.inventorySection}>
                    <Typography variant="heading">
                        Inventory Allocation
                    </Typography>
                    {inventoryLoading ? (
                        <Spinner height="15vh" />
                    ) : inventoryError ? (
                        <Typography variant="error">
                            {inventoryError}
                        </Typography>
                    ) : (
                        <Table
                            loading={inventoryLoading}
                            headerList={inventoryColumns}
                            totalRecords={inventoryTotalRecords}
                            // setPageSize={setInventoryPageSize}
                            rowsData={inventoryData}
                            currentPage={inventoryCurrentPage}
                            setCurrentPage={setInventoryCurrentPage}
                            showAllColumns={true}
                        />
                    )}
                </div>
                <div className={styles.timeLineContainer}>
                    <div className={styles.dropdownWrapper}>
                        <DropDown
                            id="dropdown"
                            options={timelineDateOptions}
                            className={styles.dropDown}
                            onDropDownChange={handleTimelineFilter}
                            defaultOption={timeLine}
                        />
                    </div>
                    {loadingDataGrid ? (
                        <Spinner height="15vh" />
                    ) : errorDataGrid ? (
                        <Typography variant="error">{errorDataGrid}</Typography>
                    ) : (
                        <>
                            <VisTimeline
                                timeLineData={rowData.event_data}
                                timeLineHeader={timeLinePopupKey}
                            />
                        </>
                    )}
                    <div className={styles.options}>
                        {timeLineOptions.map((option, index) => (
                            <div key={index} className={styles.option}>
                                <input
                                    type="checkbox"
                                    onClick={() => handleFilter(option.value)}
                                />{' '}
                                <span className={styles.filterLabel}>
                                    {option.name}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className={styles.timelineDetails}>
                        <Typography variant="heading">
                            Timeline Details
                        </Typography>
                        {loadingDataGrid ? (
                            <Spinner height="15vh" />
                        ) : errorDataGrid ? (
                            <Typography variant="error">
                                {errorDataGrid}
                            </Typography>
                        ) : (
                            <Table
                                loading={loadingDataGrid}
                                headerList={columnsGridData}
                                totalRecords={totalRecords}
                                rowsData={dataGrid}
                                setPageSize={setPageSize}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isReleaseModalOpen}
                onClose={handleReleaseModalClose}
                handleChange={handleReleaseQuantityChange}
                error={releaseError}
                value={{ quantity: releaseQuantity }}
                onSubmit={handleReleaseSubmit}
                releasePercentage={releasePercentage}
                handlePercentage={handlePercentage}
                isShowPercentage={true}
                title="By Percentage"
                modalFieldData={[
                    {
                        type: 'heading',
                        label: 'Release Inventory',
                        captionText: `Available Total Quantity: ${ selectedInventory?.total_quantity || 0
                        }`,
                    },
                    {
                        type: 'text',
                        label: 'Release Quantity',
                        name: 'quantity',
                        placeholder: releasePercentage
                            ? 'Enter % (0-100)'
                            : 'Enter a Quantity',
                    },
                ]}
            />
            <DialogWrapper
                isOpen={isAllocationOpen}
                onClose={handleClose}
                width="50%"
            >
                <div className={styles.allocationContainer}>
                    <div className={styles.allocationHeader}>
                        <Typography variant="title">
                            Inventory Allocation
                        </Typography>
                        <div className={styles.percentageToggle}>
                            <span>By Percentage</span>
                            <input
                                type="checkbox"
                                className={styles.toggle}
                                checked={isPercentageBulk}
                                onChange={(e) => {
                                    setIsPercentageBulk(e.target.checked);
                                    setAllocationRowsBulk(
                                        allocationRowsBulk.map((row) => ({
                                            ...row,
                                            allocation: '',
                                        }))
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <div className={styles.allocationFields}>
                        {allocationRowsBulk.map((row) => {
                            const availableOptions = dropdownOptions.filter(
                                (option) =>
                                    !allocationRowsBulk
                                        .filter((r) => r.id !== row.id)
                                        .map((r) => r.platform)
                                        .includes(option.value)
                            );

                            return (
                                <div
                                    key={row.id}
                                    className={styles.allocationRow}
                                >
                                    <div className={styles.fieldGroup}>
                                        <label>Sales Channel</label>
                                        <DropDown
                                            options={availableOptions}
                                            className={styles.dropDown}
                                            defaultValue={row.platform}
                                            showDownIcon={true}
                                            onDropDownChange={(value) =>
                                                dropdownChangeBulkHandler(
                                                    value,
                                                    row.id
                                                )
                                            }
                                        />
                                    </div>

                                    <div className={styles.fieldGroup}>
                                        <label>Current Stock</label>
                                        <Input
                                            type="text"
                                            className={styles.inputField}
                                            value={
                                                productDetail?.unallocated_quantity
                                            }
                                            disabled
                                        />
                                    </div>

                                    <div className={styles.fieldGroup}>
                                        <label>Allocation</label>
                                        <div className={styles.percentageInput}>
                                            <Input
                                                type="number"
                                                className={styles.inputField}
                                                placeholder={
                                                    isPercentageBulk
                                                        ? 'Enter % (0-100)'
                                                        : 'Enter quantity'
                                                }
                                                value={row.allocation}
                                                onChange={(e) =>
                                                    handleAllocationBulkChange(
                                                        e.target.value,
                                                        row.id
                                                    )
                                                }
                                            />
                                            {isPercentageBulk && (
                                                <span
                                                    className={
                                                        styles.percentSign
                                                    }
                                                >
                                                    %
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    {allocationRowsBulk.length > 1 && (
                                        <button
                                            className={styles.iconButton}
                                            onClick={() =>
                                                removeAllocationRow(row.id)
                                            }
                                        >
                                            <LucideIcon
                                                name="Minus"
                                                size={16}
                                            />
                                        </button>
                                    )}
                                </div>
                            );
                        })}

                        <div className={styles.buttonGroup}>
                            {allocationRowsBulk.length <
                                dropdownOptions.length && (
                                <button
                                    className={styles.iconButton}
                                    onClick={addAllocationRowBulk}
                                >
                                    <LucideIcon name="Plus" size={16} />
                                </button>
                            )}
                            <Button
                                className={styles.pushButton}
                                onClick={() => {
                                    handlePushBulk(handleClose);
                                }}
                                disabled={
                                    isSubmittingBulk ||
                                    allocationRowsBulk.some(
                                        (row) => !row.allocation
                                    )
                                }
                            >
                                {isSubmittingBulk ? 'Pushing...' : 'Push'}
                            </Button>
                        </div>

                        {apiErrorBulk && (
                            <Typography
                                variant="error"
                                className={styles.errorMessage}
                            >
                                {apiErrorBulk}
                            </Typography>
                        )}
                    </div>
                </div>
            </DialogWrapper>
        </AppLoader>
    );
};

export default ProductDetails;