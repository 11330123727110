import React, { useEffect, useRef, useState } from 'react'

import styles from './workflow.module.css';
import Button from '../../../commonComponents/button/Button.jsx';
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';
import Search from '../../../commonComponents/search/Search.jsx';
import AppLoader from '../../../appLoader';
import { useWorkflowFunc } from '../functionality/workflow.func';
import useTables from '../../../commonComponents/table/useTables.func';
import Table from '../../../commonComponents/table/Table.jsx';
import Modal from '../../../commonComponents/modal/Modal.jsx';
import createWorkflowData from '../../../dummyData/CreateWorkflow.json';
import ToggleSwitch from '../../../commonComponents/toggleSwitch/ToggleSwitch.jsx';

function Workflow() {
    const dropdownRef = useRef(null);

    const { data: customFlowData, handleKeyDown, searchKeyword, headerData, handleChange, workflow, workflowLoading, handleCreateWorkflow, createWorkFlowLoading, handleDelete, isOpen, setIsOpen, createWorkflow, setCreateWorkflow, error, setError, closeModal, rowClickHandler } = useWorkflowFunc()
    const { data,
        columns } = useTables({}, headerData, workflow, {});
    const [ isToggle, setIsToggle ]=useState(false)

    const [ workflowId, setWorkflowId ]=useState();
    const [ openDropdown, setOpenDropdown ] = useState(null);
    const options=[ 'Delete' ]
    const handleCreateOrganizationModal = () => {
        setIsOpen(true);
    }

    const handleSwitchChange = (value) => {
        setIsToggle(value);
    };

    const handleSubmit=()=>{
        handleCreateWorkflow(createWorkflow);
    }

    const handleCloseDropdown = () => {
        setOpenDropdown(null);
    };
    const handleChangeFlow=(e)=>{
        const target = e.target;
        setError({ ...error, [ target.name ]: '' });
        setCreateWorkflow({ ...createWorkflow, [ target.name ]: target.value })
    }

    const handleShowDialog = (event, rowId)=>{
        event.stopPropagation();
        const { pageX, pageY } = event;
        if (openDropdown?.id !== rowId || openDropdown?.x !== pageX || openDropdown?.y !== pageY) {
            const filterData = workflow?.[ (rowId.id) - 1 ];
            setWorkflowId(filterData._id)
            setOpenDropdown({ id: rowId, x: pageX, y: pageY });
        }
    }
    const updatedColumns = columns.map((column) => {
        if (column.field === 'schedule') {
            return {
                ...column,
                renderCell: () => (
                    <LucideIcon
                        name="CalendarDays"
                        size="18"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}/>
                )
            };
        }
        if (column.field === 'run') {
            return {
                ...column,
                renderCell: () => (
                    <LucideIcon
                        name="Play"
                        size="18"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    />
                )
            };
        }
        if (column.field === 'actions') {
            return {
                ...column,
                renderCell: (params) => (
                    <LucideIcon
                        name="Ellipsis"
                        size="18"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleShowDialog(e, params.row);
                        }}/>
                )
            };
        }
        if (column.field === 'on/Off') {
            return {
                ...column,
                renderCell: () => (
                    <div style={{ paddingTop:'10px' }}>
                        <ToggleSwitch value={isToggle}
                            onClick={(e) => e.stopPropagation()}
                            onChange={handleSwitchChange()} />
                    </div>
                )
            };
        }
        return column;
    });
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpenDropdown(null);
            }
        };
        if (openDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ openDropdown ]);
    return (
        <AppLoader>
            <div className={styles.resourcesHeader}>
                <div className={styles.resourcesTitle}>
                    Custom Flows
                </div>
                <div>
                    <Button className={styles.deleteButton}>
                        <LucideIcon name="Trash2" size={16} />
                        Delete integration
                    </Button>
                </div>
            </div>
            <div className={styles.customFlowWrapper}>
                {customFlowData.custom_flow.map((item) =>
                    // <div key={item.id} className={styles.customFlow} onClick={() => handleFlow(item.name)} >
                    <div key={item.id} className={styles.customFlow} >
                        <LucideIcon name={item.icon} size={20} />
                        {item.name}
                    </div>
                )}
            </div>
            <div className={styles.resourcesHeader}>
                <div className={styles.resourcesTitle}>
                    Flows
                </div>
                <div className={styles.buttonWrapper}>
                    <Search className={styles.search} onKeyDown={handleKeyDown} searchKeyword={searchKeyword} onChange={handleChange} />
                    <Button className={styles.createFlowButton} onClick={handleCreateOrganizationModal}>
                        <LucideIcon name="Plus" size={16} />
                        Create Flow
                    </Button>
                    <Button className={styles.loadDataButton}>
                        <LucideIcon name="Plus" size={16} />
                        Load Data
                    </Button>
                    <LucideIcon name="Ellipsis" size={16} />
                </div>
            </div>
            <Table
                headerList={updatedColumns}
                rowsData={data}
                totalRecords={data.length}
                loading={workflowLoading}
                showAllColumns={true}
                props={
                    {
                        onRowClick: (params) => { rowClickHandler(params) }
                    }
                }
            />
            {openDropdown && (
                <div
                    ref={dropdownRef}
                    className={styles.dropDownWrapper}
                    style={{ top: openDropdown.y, left: openDropdown.x }}
                >
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={styles.dropDownItem}
                            onClick={() => {
                                handleDelete(workflowId);
                                handleCloseDropdown();
                            }}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
            {isOpen && <Modal isOpen={isOpen} modalFieldData={createWorkflowData?.createWorkflow} error={error} value={createWorkflow} handleChange={handleChangeFlow} onSubmit={handleSubmit} onClose={closeModal} loading={createWorkFlowLoading} />}
        </AppLoader>
    )
}

export default Workflow