// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connectorCardII_mainWrapper__kk3lt {
    background-color: var(--color-white);
    box-shadow: 6px 12px 22px 2px #00000017;
    border-radius: 10px;
    width: 100%;
}
.connectorCardII_innerWrapper__MxV2\\+ {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 1rem;
    width: 100%;
    height: 100%;
}

.connectorCardII_mainWrapper__kk3lt img {
    width: 100px;
    height: 50px;
    object-fit: contain;
}
.connectorCardII_seperator__kUOm1 {
    background-color: var(--color-light-black);
    width: 0.5px;
    position: absolute;
    height: 50px;
}

.connectorCardII_name__HVfnl {
    text-transform: capitalize;
}
.connectorCardII_line__ziI\\+n {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1600px) {
    .connectorCardII_mainWrapper__kk3lt {
        gap: 0.8rem;
    }

    .connectorCardII_mainWrapper__kk3lt img {
        width: 60px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/connectorCardII/layout/connectorCardII.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,uCAAuC;IACvC,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,0CAA0C;IAC1C,YAAY;IACZ,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".mainWrapper {\n    background-color: var(--color-white);\n    box-shadow: 6px 12px 22px 2px #00000017;\n    border-radius: 10px;\n    width: 100%;\n}\n.innerWrapper {\n    align-items: center;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    gap: 1rem;\n    padding: 2rem 1rem;\n    width: 100%;\n    height: 100%;\n}\n\n.mainWrapper img {\n    width: 100px;\n    height: 50px;\n    object-fit: contain;\n}\n.seperator {\n    background-color: var(--color-light-black);\n    width: 0.5px;\n    position: absolute;\n    height: 50px;\n}\n\n.name {\n    text-transform: capitalize;\n}\n.line {\n    display: flex;\n    align-items: center;\n}\n\n@media screen and (max-width: 1600px) {\n    .mainWrapper {\n        gap: 0.8rem;\n    }\n\n    .mainWrapper img {\n        width: 60px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": `connectorCardII_mainWrapper__kk3lt`,
	"innerWrapper": `connectorCardII_innerWrapper__MxV2+`,
	"seperator": `connectorCardII_seperator__kUOm1`,
	"name": `connectorCardII_name__HVfnl`,
	"line": `connectorCardII_line__ziI+n`
};
export default ___CSS_LOADER_EXPORT___;
