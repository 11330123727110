// This component is for beverages demo only
import React from 'react'
import TableDialog from '../../../commonComponents/tableDialog/layout/TableDialog.jsx'
import Typography from '../../../commonComponents/typography/Typography.jsx'
import { capitalizeSnakeCaseWords } from '../../../helpers'
import styles from './WorkflowQueuePopup.module.css'
import Filter from '../../../commonComponents/filter/Filter.jsx'
export const QueueGrid = ({ label, data }) => {
    console.log('droipdownhandlem', label)
    const headers = data.headers
    const [ rows, setRows ] = React.useState([])
    const [ selectedItem, setSelectedDropdown ] = React.useState(data?.data?.[ 0 ]?.warehouse_name || '')
    const isDropdown = data?.is_dropdown
    let options = [  ]
    if(isDropdown) {
        data?.data?.map(warehouse =>{
            options.push({ value: warehouse?.warehouse_name, name: warehouse?.warehouse_name })
        });
    }
    console.log('dropdownwarehouse, label', label)
    console.log(setRows)
    React.useEffect(()=>{
        if(isDropdown && selectedItem!== ''){
            const selectedWarehouse = data?.data?.find(warehouse=>warehouse?.warehouse_name === selectedItem)
            setRows(selectedWarehouse?.skus_list?.data || [])
        } else {
            setRows(data?.data || [])
        }
    }, [ selectedItem ])

    return <div className={styles.gridContainer}>
        <div className={styles.headingContainer}>
            <Typography variant='title'> {capitalizeSnakeCaseWords(label)} </Typography>
            {isDropdown &&
                <Filter
                    onFilterChange={(val)=>{setSelectedDropdown(val)}}
                    filterValues={options}
                    value={selectedItem}
                />}
        </div>
        <TableDialog modalData={{ header: headers, rowData: rows }} />
    </div>
}