import { useState, useCallback } from 'react';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import { useSelector } from 'react-redux';
import { selectOrganisationData } from '../../../redux/organisation/Selector';

const useConfigMapper = () => {
    const organisation = useSelector(selectOrganisationData);
    const organisation_id = organisation?.organisationData?.id;
    const [ loading, setLoading ] = useState(false);
    const [ connectedConnectors, setConnectedConnectors ] = useState([]);
    const [ connectedConnectorsLoading, setConnectedConnectorsLoading ] = useState(true);
    const [ data, setData ] = useState({});
    const [ selectedTribe, setSelectedTribe ] = useState('')
    const [ selectedSystem, setSelectedSystem ] = useState('')
    const { callApi } = useCustomAxiosCall();
    const getConfigData = useCallback(async (tribe) => {
        setLoading(true);
        try {
            const response = await callApi({
                uriEndPoint: {
                    url: `/tribe-config/${ tribe }`,
                    method: 'GET',
                    service: 'config-mapper',
                },
            });

            if (response?.status === 200 && !response?.data?.error) {
                setData(response?.data?.data || {});
                return response?.data?.data;
            } else {
                console.error('Error fetching data:', response?.data?.error || 'Unknown error');
            }
        } catch (error) {
            console.error('API call failed:', error);
        } finally {
            setLoading(false);
        }
    }, [ callApi ]);

    const getConnectedConnectors = async () => {
        setConnectedConnectorsLoading(true)
        const response = await callApi({
            uriEndPoint: {
                url: `/organization/${ organisation_id }/connector`
            }
        });
        if (response?.status === 200) {
            setConnectedConnectors(response?.data?.data || [])
        }
        setConnectedConnectorsLoading(false)
    };

    return { data, loading, getConfigData, connectedConnectors, connectedConnectorsLoading, getConnectedConnectors, selectedSystem, setSelectedSystem, selectedTribe, setSelectedTribe };
};

export default useConfigMapper;
