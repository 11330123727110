// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myConnectors_myConnectors__mk-pq {
    margin: 2rem 0;
}

.myConnectors_connectorHeading__32-hH {
    border-bottom: 2px solid var(--color-skyblue);
    padding-bottom: 6px;
    width: -moz-fit-content;
    width: fit-content;
}

.myConnectors_breadcrumbs__e8AcW {
    align-items: center;
    display: flex;
    gap: 5px;
    margin: 1rem 0;
}

.myConnectors_connectorsGrid__BjqDV {
    display: grid;
    gap: 4rem 2rem;
    /* grid-template-columns: repeat(4, minmax(0, 1.5fr)); */
    margin: 2rem 0;
}

.myConnectors_breadcrumbIcon__UTk9W {
    width: 24px;
    height: 20px;
}

.myConnectors_currentBreadcrumb__RoL32 {
    font-weight: 500 !important;
}`, "",{"version":3,"sources":["webpack://./src/views/myConnectors/layout/myConnectors.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,6CAA6C;IAC7C,mBAAmB;IACnB,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,QAAQ;IACR,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,wDAAwD;IACxD,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".myConnectors {\n    margin: 2rem 0;\n}\n\n.connectorHeading {\n    border-bottom: 2px solid var(--color-skyblue);\n    padding-bottom: 6px;\n    width: fit-content;\n}\n\n.breadcrumbs {\n    align-items: center;\n    display: flex;\n    gap: 5px;\n    margin: 1rem 0;\n}\n\n.connectorsGrid {\n    display: grid;\n    gap: 4rem 2rem;\n    /* grid-template-columns: repeat(4, minmax(0, 1.5fr)); */\n    margin: 2rem 0;\n}\n\n.breadcrumbIcon {\n    width: 24px;\n    height: 20px;\n}\n\n.currentBreadcrumb {\n    font-weight: 500 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myConnectors": `myConnectors_myConnectors__mk-pq`,
	"connectorHeading": `myConnectors_connectorHeading__32-hH`,
	"breadcrumbs": `myConnectors_breadcrumbs__e8AcW`,
	"connectorsGrid": `myConnectors_connectorsGrid__BjqDV`,
	"breadcrumbIcon": `myConnectors_breadcrumbIcon__UTk9W`,
	"currentBreadcrumb": `myConnectors_currentBreadcrumb__RoL32`
};
export default ___CSS_LOADER_EXPORT___;
