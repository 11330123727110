// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connectorCardII_mainWrapper__kk3lt {
    align-items: center;
    background-color: var(--color-white);
    box-shadow: 6px 12px 22px 2px #00000017;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 1rem;
    width: 100%;
}

.connectorCardII_mainWrapper__kk3lt img {
    width: 50px;
    height: 50px;
}

.connectorCardII_seperator__kUOm1 {
    background-color: var(--color-light-black);
    height: 100%;
    width: 0.5px;
}

.connectorCardII_name__HVfnl {
    text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/connectorCardII/layout/connectorCardII.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,oCAAoC;IACpC,uCAAuC;IACvC,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,0CAA0C;IAC1C,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".mainWrapper {\n    align-items: center;\n    background-color: var(--color-white);\n    box-shadow: 6px 12px 22px 2px #00000017;\n    border-radius: 10px;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    gap: 1rem;\n    padding: 2rem 1rem;\n    width: 100%;\n}\n\n.mainWrapper img {\n    width: 50px;\n    height: 50px;\n}\n\n.seperator {\n    background-color: var(--color-light-black);\n    height: 100%;\n    width: 0.5px;\n}\n\n.name {\n    text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": `connectorCardII_mainWrapper__kk3lt`,
	"seperator": `connectorCardII_seperator__kUOm1`,
	"name": `connectorCardII_name__HVfnl`
};
export default ___CSS_LOADER_EXPORT___;
