// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfigMapperV2_configMapperWrapper__AhFwL .ConfigMapperV2_stepSliderWrapper__r8iBi{
    margin:1rem 0;
}
/* width */
div::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  /* Track */
  div::-webkit-scrollbar-track {
    background: #D9D9D9;
    border-radius: 12px;

}


/* Handle */
div::-webkit-scrollbar-thumb {
    background: #4371EC;
    border-radius: 12px;
  }`, "",{"version":3,"sources":["webpack://./src/views/ConfigMapperV2/layout/ConfigMapperV2.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA,UAAU;AACV;IACI,UAAU;IACV,WAAW;EACb;;EAEA,UAAU;EACV;IACE,mBAAmB;IACnB,mBAAmB;;AAEvB;;;AAGA,WAAW;AACX;IACI,mBAAmB;IACnB,mBAAmB;EACrB","sourcesContent":[".configMapperWrapper .stepSliderWrapper{\n    margin:1rem 0;\n}\n/* width */\ndiv::-webkit-scrollbar {\n    width: 6px;\n    height: 6px;\n  }\n\n  /* Track */\n  div::-webkit-scrollbar-track {\n    background: #D9D9D9;\n    border-radius: 12px;\n\n}\n\n\n/* Handle */\ndiv::-webkit-scrollbar-thumb {\n    background: #4371EC;\n    border-radius: 12px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"configMapperWrapper": `ConfigMapperV2_configMapperWrapper__AhFwL`,
	"stepSliderWrapper": `ConfigMapperV2_stepSliderWrapper__r8iBi`
};
export default ___CSS_LOADER_EXPORT___;
