import React, { useEffect, useState } from 'react'
import styles from './addRules.module.css'
import LucideIcon from '../../commonComponents/lucideicon/LucideIcon.jsx';
import Input from '../../commonComponents/input/Input.jsx';
import Button from '../../commonComponents/button/Button.jsx';
import Variation from '../variation/Variation.jsx';
import Rules from '../rules/Rules.jsx';

function AddRules({ isOpen, onClose }) {
    const [ variation, setVariation ] = useState({
        true: '',
        false: '',
    });
    const [ isValidJson, setIsValidJson ] = useState({
        true: '',
        false: '',
    });
    const [ rules, setRules ] = useState([    {
        rules: [ {
            categories: '',
            values: '',
        } ],
        productDetails: [ {
            condition:'',
            specification: '',
            values: '',
            quantity: '',
        } ],
        route_orders: ''
    },
    ]);
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [ isOpen ]);
    const handleAddMoreRules = () => {
        setRules([
            ...rules,
            {
                rules: [ {
                    categories: '',
                    values: '',
                } ],
                productDetails: [ {
                    specification: '',
                    values: '',
                    quantity: '',
                    condition:''
                } ],
            },
        ]);
    };
    const validateJson = (name, value) => {
        try {
            JSON.parse(value);
            setIsValidJson({ [ name ]: '' });
        } catch (error) {
            setIsValidJson({ [ name ]: error.message });
        }
    };

    const handleOutsideModalClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    }

    if (!isOpen) return null;
    return (
        <div className={styles.modalOverlayWrapper} onClick={handleOutsideModalClick}>
            <div className={styles.modalOverlay}>
                <div className={styles.header}>
                    <div className={styles.leftHeader}>
                        <div className={styles.title}>
                            Define input filter
                        </div>
                        <LucideIcon name="CircleHelp" size={16} className={styles.closeIcon}/>
                    </div>
                    <div  className={styles.rightHeader}>
                        <div className={styles.checkboxWrapper}>
                            <Input type="checkbox" className={styles.checkbox} />
                            <span className={styles.checkboxName}>Auto Preview</span>
                        </div>
                        <Button className={styles.previewButton}>Preview</Button>
                        <div className={styles.line} />
                        <LucideIcon name="LayoutGrid" className={styles.closeIcon}/>
                        <LucideIcon name="X" className={styles.closeIcon} onClick={onClose}/>
                    </div>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.ruleHeader}>
                        Rules
                    </div>
                    <div>
                        <Rules
                            updateRules={setRules}
                            rules={rules}
                            handleAddMoreRules={handleAddMoreRules}
                        />
                    </div>
                    <div className={styles.ruleContentContainer} >
                        <div className={styles.leftSideInput}>
                            <Variation variation={variation} setVariation={setVariation} validateJson={validateJson} isValidJson={isValidJson} />

                        </div>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button className={styles.button}>Save</Button>
                        <Button  className={styles.saveAndCloseButton}>Save & Close</Button>
                        <Button className={styles.button} onClick={onClose}>Close</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddRules