// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.organisationSkeleton_skeletonContainer__65pnn {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin: 2rem 0;
}`, "",{"version":3,"sources":["webpack://./src/components/skeletons/organisation/organisationSkeleton.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,gDAAgD;IAChD,cAAc;AAClB","sourcesContent":[".skeletonContainer {\n    display: grid;\n    gap: 1rem;\n    grid-template-columns: repeat(4, minmax(0, 1fr));\n    margin: 2rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeletonContainer": `organisationSkeleton_skeletonContainer__65pnn`
};
export default ___CSS_LOADER_EXPORT___;
