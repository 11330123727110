import React from 'react';
import AppLoader from '../../../appLoader';
import styles from './logger.module.css';
import Typography from '../../../commonComponents/typography/Typography.jsx';
// import LucideIcon from "../../../commonComponents/lucideicon/LucideIcon.jsx";
import loggerData from '../../../dummyData/Logger.json';
import DropDown from '../../../commonComponents/dropDown/DropDown.jsx';
import BarChartTime from '../../../commonComponents/charts/barChartTime/BarChartTime.jsx';
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';
import useLogger from '../functionality/logger.func';
import Table from '../../../commonComponents/table/Table.jsx';
import { useState } from 'react';
import LoggerPopup from '../../../commonComponents/loggerPopup/LoggerPopup.jsx';
const Logger = () => {
    const { data,
        updatedColumns,
        loading, error,
        currentPage,
        setCurrentPage,totalRecords,
        setPageSize  } = useLogger({ url:'/log', service: 'logger' });
    const [ open, setOpen ]=useState(false)
    const [ modalData, setmodalData ] = useState({})
    const onClose = () => {
        setOpen(false)
    }
    const rowClickHandler = (params) => {
        setmodalData(params)
        setOpen(true)
    }
    return <AppLoader>
        <div className={styles.headerWrapper}>
            <Typography variant="gradient">{loggerData.title}</Typography>
            <div className={styles.filterWrapper}>
                <DropDown options={[]} onDropDownChange={()=>{}} defaultOption={'Sort By'}/>
                <DropDown options={[]} onDropDownChange={()=>{}} defaultOption={'Filters'}/>
            </div>
        </div>
        <div className={styles.chartWrapper}>
            <BarChartTime data={loggerData?.barChart?.data} color={loggerData?.barChart?.color} startTimeString={'2021-01-01T16:25:00'}/>
        </div>
        <div className={styles.gridContainer}>
            <div className={styles.headerWrapper}>
                <div className={styles.header}><LucideIcon size={15} color={'#4669EA'} name={'ChevronLeft'}/><LucideIcon size={15} color={'#4669EA'} name={'SlidersHorizontal'}/> Hide Controls</div>
                <Typography variant="title">4.39k logs found</Typography>
            </div>

            {(!loading && error) ?
                <div className={styles.heading}><Typography variant="error">{error}</Typography></div>:
                <Table
                    loading={loading}
                    headerList={updatedColumns.length > 1 ? updatedColumns : [] }
                    totalRecords={totalRecords}
                    setPageSize={setPageSize}
                    rowsData={data}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    props={
                        {
                            onRowClick:(params)=>{rowClickHandler(params)}
                        }
                    }

                />
            }
            <LoggerPopup isOpen={open} onClose={onClose} width={'40%'} data={modalData}></LoggerPopup>
        </div>

    </AppLoader>
}

export default Logger;