import { useEffect, useState } from 'react';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import { useLocation } from 'react-router-dom';
import { capitalizeCamelCaseWords } from '../../../helpers';
import { toast } from 'react-toastify';
export const useConnectorCardIIFunc = ({
    defaultConnector,
    onConnectionSuccess = () => { }
}) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ redirectUrl, setRedirectUrl ] = useState('');
    const [ apiConnectors, setApiConnectors ] = useState({});
    const [ isLoading, setIsLoading ] = useState(false);
    const [ formFields, setFormFields ] = useState([]);

    const [ error, setError ] = useState({});
    useEffect(() => {

        if (redirectUrl) {
            window.open(redirectUrl);
        }
    }, [ redirectUrl ]);

    const [ connectorDetails, setConnectorDetals ] = useState({});

    const { callApi } = useCustomAxiosCall();

    const { state } = useLocation();

    const handleCreateConnectorModal = async (connector) => {
        setError({})
        let connectorDetail = defaultConnector.fields;
        const INIT_CONNECTOR_STATE = {};
        for (let field in connectorDetail) {
            if (connectorDetail[ field ].name) INIT_CONNECTOR_STATE[ connectorDetail[ field ].name ] = ''
        }
        setApiConnectors(INIT_CONNECTOR_STATE);
        setFormFields(defaultConnector.fields)
        setError(INIT_CONNECTOR_STATE);
        setIsOpen(true);
        setConnectorDetals(connector);
    }

    const closeModal = () => {
        setIsOpen(false);
        setApiConnectors({})
        setError({})
    };

    const handleValidation = () => {
        let formError = {};
        let isValidated = true;
        Object.keys(apiConnectors).forEach((key) => {
            if (!apiConnectors[ key ]) {
                formError[ key ] = `${ capitalizeCamelCaseWords(key) } is required`;
                isValidated = false;
            }
        })
        setError(formError)
        return isValidated;
    }

    const handleChange = (e) => {
        setError({})
        const target = e.target;
        setApiConnectors({ ...apiConnectors, [ target.name ]: target.value })
    };

    const handleSubmit = async () => {
        const isValidated = handleValidation();
        if (!isValidated) {
            return;
        }
        setIsLoading(true);
        // Adding Required Fields
        const requestBody = {
            name: connectorDetails.machineName || '',
            tribe: connectorDetails?.category || '',
            ...apiConnectors
        };
        // Adding additional fields
        const response = await callApi({
            uriEndPoint: {
                url: `/organization/${ state.organisation_id }/connector`,
                method: 'POST'
            },
            body: requestBody
        });

        if (defaultConnector.isRedirect && response.data?.message === 'CONNECTOR-CREATED') {
            setRedirectUrl(response?.data?.data?.url)
            setIsLoading(false)
            closeModal();
            return
        }
        if (response.data?.message === 'CONNECTOR-CREATED') {
            onConnectionSuccess();
            setIsLoading(false)
            toast.success('Connector connected successfully', { position: 'top-right', style: { textTransform: 'capitalize' } })
            closeModal();
        } else {
            setError({ response: response?.data?.message || 'Something Went Wrong' })
            setIsLoading(false)
            console.log('Error:', response);
        }
        setIsLoading(false)
    };

    return { handleSubmit, handleCreateConnectorModal, handleChange, isLoading, apiConnectors, isOpen, closeModal, data: formFields, error };

}