import React from 'react';
import styles from './table.module.css';
// Helper component to render any value: if it's an object/array, render a table recursively
const RenderValue = ({ value }) => {
    if (typeof value === 'object' && value !== null) {
        return <DynamicJsonTable data={value} />;
    }
    return <span>{String(value)}</span>;
};

const DynamicJsonTable = ({ data }) => {
    // If the data is an array:
    if (Array.isArray(data)) {
        if (data.length === 0) return <p>Empty Array</p>;

        // Check if every item is an object (not an array)
        const isObjectArray = data.every(
            (item) =>
                typeof item === 'object' &&
                item !== null &&
                !Array.isArray(item)
        );

        if (isObjectArray) {
            // Compute union of keys across all objects
            const headers = [
                ...new Set(data.flatMap((item) => Object.keys(item))),
            ];

            return (
                <table
                    border="1"
                    cellPadding="5"
                    className={styles.tableMainContainer}
                >
                    <thead>
                        <tr>
                            {headers.map((header) => (
                                <th key={header}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                {headers.map((header) => (
                                    <td key={header}>
                                        {item[ header ] !== undefined ? (
                                            <RenderValue value={item[ header ]} />
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else {
            // For arrays with primitives or mixed values, render each item in its own row.
            return (
                <table
                    border="1"
                    cellPadding="5"
                    className={styles.tableMainContainer}
                >
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <RenderValue value={item} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }
    }
    // If data is an object:
    else if (typeof data === 'object' && data !== null) {
        return (
            <table
                border="1"
                cellPadding="5"
                className={styles.tableMainContainer}
            >
                <tbody>
                    {Object.entries(data).map(([ key, value ]) => (
                        <tr key={key}>
                            <th
                                style={{
                                    textAlign: 'left',
                                    background: '#4371ec',
                                    padding: '5px',
                                    color: '#fff',
                                    border: '1px solid white',
                                }}
                            >
                                {key}
                            </th>
                            <td
                                style={{
                                    padding: '5px',
                                    backgroundColor: 'transparent',
                                    backgroundImage:
                                        'linear-gradient(90deg, var(--color-white), var(--color-white)), linear-gradient(90deg, var(--color-skyblue-300), var(--color-cyan))',
                                    backgroundClip: 'padding-box, border-box',
                                    backgroundOrigin: 'border-box',
                                    border: '1px solid transparent',
                                }}
                            >
                                <RenderValue value={value} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
    // For primitive data types, just display the value.
    return <span>{String(data)}</span>;
};

export default DynamicJsonTable;