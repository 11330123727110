// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.getCheckout_markdownWrapper__JjwJ1,
.getCheckout_textarea__bsFbw {
    background: transparent;
    background-image: linear-gradient(90deg, var(--color-white), var(--color-white)), linear-gradient(90deg, var(--color-skyblue-300), var(--color-cyan));
    background-clip: padding-box, border-box;
    background-origin: border-box;
    border: 1px solid transparent;
    border-image: linear-gradient(88.9deg, #6328DD 0.83%, #446CEB 47.73%, #0FC1DC 99.56%) 1;
    border-radius: 12px;
    padding: 10px;
    height: 300px;
    overflow: auto;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    resize: none;
}

.getCheckout_saveAndCloseButton__M1hze {
    border: 1px solid #4470ec;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 34px;
    border-radius: 5px;
    color: #fff;
    background-color: #4371ec;
    cursor: pointer;
    font-family: 'Outfit';
}

.getCheckout_mainWrapper__Ba38E {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: center;
    padding-top: 2rem;
}

.getCheckout_innerWrapper__ecZg4,
.getCheckout_markdownWrapper__JjwJ1 {
    width: 34%;
}

.getCheckout_markdownWrapper__JjwJ1 {
    word-break: break-all;
}`, "",{"version":3,"sources":["webpack://./src/components/getCheckoutPage/getCheckout.module.css"],"names":[],"mappings":"AAAA;;IAEI,uBAAuB;IACvB,qJAAqJ;IACrJ,wCAAwC;IACxC,6BAA6B;IAC7B,6BAA6B;IAC7B,uFAAuF;IACvF,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;;IAEI,UAAU;AACd;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".markdownWrapper,\r\n.textarea {\r\n    background: transparent;\r\n    background-image: linear-gradient(90deg, var(--color-white), var(--color-white)), linear-gradient(90deg, var(--color-skyblue-300), var(--color-cyan));\r\n    background-clip: padding-box, border-box;\r\n    background-origin: border-box;\r\n    border: 1px solid transparent;\r\n    border-image: linear-gradient(88.9deg, #6328DD 0.83%, #446CEB 47.73%, #0FC1DC 99.56%) 1;\r\n    border-radius: 12px;\r\n    padding: 10px;\r\n    height: 300px;\r\n    overflow: auto;\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    outline: none;\r\n    resize: none;\r\n}\r\n\r\n.saveAndCloseButton {\r\n    border: 1px solid #4470ec;\r\n    font-size: 14px;\r\n    font-weight: 400;\r\n    padding: 10px 34px;\r\n    border-radius: 5px;\r\n    color: #fff;\r\n    background-color: #4371ec;\r\n    cursor: pointer;\r\n    font-family: 'Outfit';\r\n}\r\n\r\n.mainWrapper {\r\n    display: flex;\r\n    align-items: center;\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 2rem;\r\n    justify-content: center;\r\n    padding-top: 2rem;\r\n}\r\n\r\n.innerWrapper,\r\n.markdownWrapper {\r\n    width: 34%;\r\n}\r\n\r\n.markdownWrapper {\r\n    word-break: break-all;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"markdownWrapper": `getCheckout_markdownWrapper__JjwJ1`,
	"textarea": `getCheckout_textarea__bsFbw`,
	"saveAndCloseButton": `getCheckout_saveAndCloseButton__M1hze`,
	"mainWrapper": `getCheckout_mainWrapper__Ba38E`,
	"innerWrapper": `getCheckout_innerWrapper__ecZg4`
};
export default ___CSS_LOADER_EXPORT___;
