import { useEffect, useState } from 'react';
import useCustomAxiosCallTwo from '../../../hooks/useCustomAxiosCallTwo';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectOrganisationData } from '../../../redux/organisation/Selector';
import { capitalizeCamelCaseWords } from '../../../helpers';
import { toast } from 'react-toastify';

export const useConfigWriter = (params) => {
    const organisation = useSelector(selectOrganisationData);
    const orgId = organisation?.organisationData?.id;
    const navigate=useNavigate();
    const { callApi } = useCustomAxiosCallTwo();
    const [ ekyamStandard, setEkyamStandard ]=useState();
    const [ configWriterLoading, setConfigWriterLoading ]=useState(false);
    const handleNodeName = (name) => {
        let displayName;
        switch (name) {
            case 'microsoft_dynamics_365': displayName = 'dynamics365'; break;
            default: displayName = name;
                break;
        }
        return displayName;
    }
    const handleId=(id)=>{
        return id.split('-').slice(1).join('-');
    }
    const handleWorkFlow = async () => {
        const simplifiedEdges = params?.data?.map(({ source, target, operation, business_logic, id }) => ({
            id: handleId(id),
            source: handleNodeName(source),
            target: handleNodeName(target),
            active:true,
            operation,
            business_logic
        }));
        const payload = {
            'flows': simplifiedEdges
        }
        const response = await callApi({
            uriEndPoint: {
                url: `/update-workflow/${ params.id }`,
                service: 'config',
                method: 'PUT'
            },
            body: { 'data': payload },
        });
        if (response){
            navigate(`/work-flow/${ params.id }`, {
                state: {
                    workflowId: params.id,
                    organisation_id: orgId
                }
            });
        }
    }
    const handleSubmit = async (value, params) => {
        console.log(value, 'asdewq')
        setConfigWriterLoading(true);
        const payload={
            data: {
                'tribe': params.tribe,
                'base_url': params.base_url,
                'service': Object.keys(params.endpoints)[ 0 ],
                'services': {
                    [ Object.keys(params.endpoints)[ 0 ] ]: {
                        'field_mappings': value,
                        'custom_field_mappings': {},
                        'end_url': '/graphql.json',
                        'collection_name': capitalizeCamelCaseWords(params?.tribe).concat(capitalizeCamelCaseWords(Object.keys(params?.endpoints)[ 0 ]))
                    }
                },
                'system' : params.system,
                'accessKeys': [
                    params.accessKeys
                ]
            }
        }

        const response = await callApi({
            uriEndPoint: {
                url: '/writer-config-mapper',
                method: 'POST',
                service: 'config'
            },
            body: payload
        });
        console.log(response, 'responseresponse')
        if (response?.status===200) {
            toast.success(response?.data?.msg, { position: 'top-right' })
            setConfigWriterLoading(false)
            handleWorkFlow();
        } else {
            toast.error('Something Went Wrong', { position: 'top-right' })
            setConfigWriterLoading(false)

        }
    };

    const getEkyamStardard=async()=>{
        const parameter={
            service: params?.sourceData?.service,
            system: params?.sourceData?.system,
            tribe: params?.sourceData?.tribe
        }
        const response = await callApi({
            uriEndPoint: {
                url: '/get-ekyam-standard',
                service: 'config',
                params: parameter
            },
        });
        console.log('Something', response);
        if (response?.status === 200) {
            setEkyamStandard(response?.data?.data)
        }
        else{
            toast.error('Something Went Wrong', { position: 'top-right' })
        }
    }
    useEffect(()=>{
        getEkyamStardard()
    },[])

    return { configWriterLoading, ekyamStandard, handleSubmit };

}