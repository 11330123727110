import React, { useEffect, useRef, useState } from 'react';
import styles from './dropDown.module.css';
import LucideIcon from '../lucideicon/LucideIcon.jsx';

const DropDown = ({ id='', onDropDownChange = () => { }, options = [], defaultValue = '', defaultOption, customsSyles = {}, className='', showDownIcon=false, bodyClassName='', itemClassName='' }) => {
    const [ isOpen, setOpen ] = useState(false);
    const [ selectedItem, setSelectedItem ] = useState(null);
    const toggleDropdown = () => { setOpen(!isOpen) };
    const handleItemClick = (value) => {
        selectedItem === value ? setSelectedItem(null) : setSelectedItem(value);
        onDropDownChange(value);
    }

    const btnRef = useRef()

    useEffect(() => {
        const closeDropdown = e => {
            if (!btnRef.current.contains(e.target)) {
                setOpen(false);
            }
        }
        document.body.addEventListener('click', closeDropdown)
        return () => document.body.removeEventListener('click', closeDropdown)
    }, [])
    const getDefaultOption=()=>{
        return(
            <div className={styles.groupByDate}>{defaultOption} <LucideIcon name={'ChevronDown'} className={styles.icon}/></div>
        )
    }
    return (
        <div id={id} className={`${ styles.dropdown } ${ className }`} onClick={toggleDropdown} ref={btnRef} style={customsSyles} key={defaultOption ? defaultOption : options.find(item => item.value === defaultValue)?.name || 'Default'}>
            <div className={styles.dropdownHeader} >
                {defaultOption ? getDefaultOption() : options.find(item => item.value === defaultValue)?.name || 'Default'}
                {showDownIcon && <LucideIcon name={'ChevronDown'} className={styles.icon}/>}
            </div>
            <div className={`${ styles.dropdownBody } ${ isOpen && styles.open } ${ bodyClassName }`}>
                {options.length > 0 ? options.map(item => (
                    <div key={item.value} className={`${ styles.dropdownItem } ${ itemClassName }`} onClick={e => handleItemClick(e.target.id)} id={item.value}>
                        {item.checkboxRequired && <input type='checkbox'/> } {item.name}
                    </div>
                )) : <div key='noData' className={styles.dropdownItem}>
                    No Data
                </div>}
            </div>
        </div>
    )
}

export default DropDown;