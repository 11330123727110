import React, { useEffect, useState } from 'react'
import { flattenNestedData, getColumns } from '../../../commonComponents/table/useTables.func';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';

export const useInventoryTable = (uriEndPoint, inventoryType) => {
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState([]);
    const [ error, setError ] = useState('');
    const { callApi } = useCustomAxiosCall();
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ totalRecords, setTotalRecords ] = useState(0);
    const [ headerData, setHeaderData ] = useState([]);
    const [ pageSize, setPageSize ] = useState(10);
    let displayTitle, flattenedData = [], columns = [];

    switch (inventoryType) {
        case 'low-quantity-sku': displayTitle = 'Low Quantity Skus'; break;
        case 'notreceived': displayTitle = 'Inbound Supply'; break;

        default: displayTitle = 'Inventory'; break;
    }

    `${ uriEndPoint.url }?page=${ currentPage + 1 }&per_page=${ pageSize }`
    const getTableData = async () => {
        let newUrl='';
        if (inventoryType === 'notreceived') {
            newUrl = `${ uriEndPoint.url }?type=${ inventoryType }&page=${ currentPage + 1 }&per_page=${ pageSize }`
        }
        else{
            newUrl = `${ uriEndPoint.url }?page=${ currentPage + 1 }&per_page=${ pageSize }`
        }
        const response = await callApi({
            uriEndPoint: {
                ...uriEndPoint,
                url: newUrl
            }
        });
        if (response && response.status === 200 && !response?.data?.error) {
            setData(response?.data?.data || [])
            setHeaderData(response?.data?.header_data || [])
            setTotalRecords(response?.data?.total_count || 0)
        } else {
            setError(response?.data?.error || 'Something Went Wrong. Please Try Again Later');
            setLoading(false);
        }
    }

    columns = React.useMemo(() => getColumns(headerData, []), [ headerData ])
    flattenedData = React.useMemo(() => { const flattenedData = flattenNestedData([], data, columns, { customer_id: 'customer.customer_id' }); setLoading(false); return flattenedData }, [ data ])

    useEffect(() => {
        setLoading(true);
        getTableData();
    }, [ currentPage ]);

    return { columns, data: flattenedData, loading, error, currentPage, setPageSize, totalRecords, setCurrentPage, displayTitle }

}