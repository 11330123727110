import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    connectors: []
};

const connectorData = createSlice({
    name: 'connectorData',
    initialState,
    reducers: {
        setConnectorData: (state, action) => {
            state.connectors = action.payload;
        }
    }
});

export const connectorDataReducer = connectorData.reducer;
export const { setConnectorData } = connectorData.actions;