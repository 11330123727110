import { MenuItem, Select } from '@mui/material';
import { capitalizeCamelCaseWords, capitalizeSnakeCaseWords } from '../../../helpers';
import React, { useEffect, useState } from 'react';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

const useConfigMapperField = () => {
    const [ loading, setLoading ] = useState(false)
    const { callApi } = useCustomAxiosCall();
    const getFieldComponent = (data, inputValues, onInputChange, parentField,selected=false) => {
        let inputName =  (parentField !== '')?`${ parentField }#${ data?.field_name }`:data?.field_name;
        inputName = (data?.field_type === 'array')?inputName+'#array':inputName
        const [ inputValue, setInputValue ] = useState(inputValues[ inputName ] || data?.field_value || '')
        const changeInputHandelr = (e)=>{
            setInputValue(e.target.value)
            onInputChange(e)
        }
    
        useEffect(()=>{
            setInputValue(inputValues[ inputName ] || data?.field_value || '')
        }, [ inputValues ])
        switch (data?.field_type) {
            case 'text':
                return <input
                    id={inputName}
                    key={inputName}
                    name={inputName}
                    value={(data?.field_value)?data?.field_value:inputValue}
                    label={capitalizeSnakeCaseWords(capitalizeCamelCaseWords(data?.field_name)) }
                    placeholder={'Enter '+data?.field_label ? data?.field_label :capitalizeSnakeCaseWords(capitalizeCamelCaseWords(data?.field_name))}
                    size='small'
                    readOnly={data?.read_only || false}
                    onChange={(e)=>{changeInputHandelr(e)}}
                />

            case 'dropdown':
                return (
                    <Select
                        labelId='tribe-label'
                        id={inputName}
                        key={inputName}
                        displayEmpty
                        size='small'
                        value={inputValue || data?.field_options[ 0 ]?.value}
                        name={inputName}
                        onChange={(e)=>{changeInputHandelr(e)}}
                    >
                        {data?.field_options.map((field_option) => (
                            <MenuItem value={field_option?.value} key={field_option?.value}>{field_option?.title}</MenuItem>
                        ))}
                    </Select>
                )

            case 'object':
                return (<div style={(selected )? { paddingLeft:'10px' }:{}}>
                    <Select
                        size='small'
                        value='object'
                        readOnly
                    >
                        <MenuItem value={'object'} key={'object'}>{'Object'}</MenuItem>
                    </Select>
                </div>
                )
           
            case 'array':
                return (<div style={(selected )? { paddingLeft:'10px' }:{}}>
                    <Select
                        size='small'
                        value='object'
                        readOnly
                    >
                        <MenuItem value={'object'} key={'object'}>{'Object'}</MenuItem>
                    </Select>
                </div>
                )
           
            case 'radio':
                return <div className='radioGroup'>
                    <input type='radio' name={inputName} value={true} checked={((data?.field_value || inputValue) === true) || ((data?.field_value || inputValue) === 'true')} onChange={(e)=>{changeInputHandelr(e)}}/> Yes
                    <input type='radio' name={inputName} value={false} checked={((data?.field_value || inputValue) === 'false') || ((data?.field_value || inputValue) === '')} onChange={(e)=>{changeInputHandelr(e)}}/> No
                </div>
                
            default:
                break;
        }
    }
    
    const convertToNested = (obj) => {
        const result = {};
      
        for (const key in obj) {
            if (key.includes('#')) {
                const keys = key.split('#');
                let current = result;
        
                keys.forEach((k, index) => {
                    if (!current[ k ]) {
                        current[ k ] = index === keys.length - 1 ? obj[ key ] : {};
                    }
                    current = current[ k ];
                });
            } else {
                result[ key ] = obj[ key ];
            }
        }
      
        return result;
    }

    const hasChidlren = (item) => {
        return Array.isArray(item.field_children) && item.field_children.length > 0;
    };

    const addCustomMappingEntry = (config, path, newField) => {
        const pathArray = path.split('#'); // Split the path by #
        let currentLevel = config
        // Traverse the config structure to the desired level
        pathArray.forEach((key) => {
            const foundItem = currentLevel.find(item => item.field_name === key);
            if (foundItem) {
                if (foundItem.field_children) {
                    currentLevel = foundItem.field_children;
                } else {
                    console.log(`Field ${ key } does not contain children.`);
                    return config
                }
            } else {
                console.log(`Field ${ key } not found.`);
                return config
            }
        });
        let fieldAlreadyPresent = false
        if(newField?.field_name){
            currentLevel.forEach((obj, i)=>{
                if(obj.field_name === newField?.field_name){
                    console.log('ALready Present', obj.field_name, newField?.field_name)
                    fieldAlreadyPresent = true
                    currentLevel[ i ] = newField
                }
            })
        }
        if(!fieldAlreadyPresent){
            currentLevel.push(newField)
        }

        return config
    
    }

    const updateCustomFieldConfigJson = (config, setConfig, rawData) => {
        // console.log(uniqueArray, 'readdatafromdb', rawData.services)
        for (const key in rawData?.services) {
            const fieldMappings = rawData?.services[ key ].field_mappings
            for(const key in fieldMappings){
                if(typeof fieldMappings[ key ] === 'object'){
                    const fieldChildren = {}
                    for(let nestedKey in fieldMappings[ key ]?.fields){
                        fieldChildren[ nestedKey ] = fieldMappings[ key ]?.fields[ nestedKey ]
                    }
                    let newKey = key+'__array'
                    fieldChildren[ 'data_accessor' ] = fieldMappings[ key ][ 'data_accessor' ]
                    fieldMappings[ newKey ] = fieldChildren 
                    delete fieldMappings[ key ]
                }
            }
        }
        const fieldDataJson = flattenJSON(rawData)
        // Generate unique array of parent fields from fieldDataJson
            
        const uniqueArray = Array.from(
            new Set(
                Object.keys(fieldDataJson)
                    .map(key => key.split('#').slice(0, -1).join('#'))
            )
        ).map(str => str.split('#'));
        uniqueArray.forEach((parentFieldArray) => {
            let currentLevel = config;

            parentFieldArray.forEach((key) => {
                if(key === 'totalCountApiEndpoints'){
                    const foundDataKeys = Object.keys(fieldDataJson)
                        .filter(key => key.includes('totalCountApiEndpoints'));
                    const fieldData = {
                        field_name:'totalCountApiEndpoints',
                        field_type:'object',
                        field_label:'Count API Endpoints',
                        can_add_fields:true,
                        field_children:[]
                    }
                    foundDataKeys.forEach(key => {
                        const fieldObj = {
                            field_type: 'custom',
                            field_id: uuid(),
                            field_name_value: key.split('#').pop(),
                            field_value: fieldDataJson[ key ]
                        };

                        fieldData?.field_children.push(fieldObj);
                    });
                    config.push(fieldData)
                }
                const foundItem = currentLevel.find(item => item.field_name === key);
                
                if (foundItem) {
                    // Proceed if the field allows adding children
                    if (foundItem?.can_add_fields) {
                        if (foundItem?.field_children?.length === 1 &&
                            !foundItem?.field_children[ 0 ]?.field_name_value) {
                            
                            // Reset field_children if no field_name_value exists
                            foundItem.field_children = [];
    
                            // Find the matching data keys from fieldDataJson
                            const parentKeyToBeFindInDataJson = parentFieldArray.join('#');
                            const foundDataKeys = Object.keys(fieldDataJson)
                                .filter(key => key.includes(parentKeyToBeFindInDataJson));
                            
                            // Add found data to field_children
                            foundDataKeys.forEach(key => {
                                const fieldObj = {
                                    field_type: 'custom',
                                    field_id: uuid(),
                                    field_name_value: key.split('#').pop(),
                                    field_value: fieldDataJson[ key ]
                                };
    
                                if (key.includes('accessKeys') || key.includes('queryParameter')) {
                                    fieldObj.name_field_type = 'dropdown';
                                }
    
                                foundItem?.field_children.push(fieldObj);
                            });
                        }
                    }
    
                    // Move deeper into the field_children if available
                    if (foundItem.field_children) {
                        currentLevel = foundItem.field_children;
                    }
                }
            });
        });

        setConfig(config)
    };
    
    const findFieldById = (data, fieldId) => {
        if (Array.isArray(data)) {
            for (let item of data) {
                const result = findFieldById(item, fieldId);
                if (result) {
                    return result;
                }
            }
        } else if (typeof data === 'object' && data !== null) {
            if (data.field_id === fieldId) {
                return data;
            }
    
            for (let key in data) {
                if (typeof data[ key ] === 'object') {
                    const result = findFieldById(data[ key ], fieldId);
                    if (result) {
                        return result;
                    }
                }
            }
        }
        return null;
    }

    const removeByFieldName = (data, fieldName) => {
        return data.filter(item => item.field_name !== fieldName);
    }

    const submitConfigData = async (formData, setIsSubmitted) => {
        const response = await callApi({
            uriEndPoint: {
                url: '/config-mapper',
                method: 'POST',
                service:'config-mapper'
            },
            body: { data:formData }
        });
        setLoading(false)
        if(response?.status !== 200 || response?.data?.error){
            toast.error(response?.data?.error || 'Something Went wrong. Please try again later')
            console.log(response)
            return
        }
        toast('Config Saved successfully')
        setIsSubmitted(true)
        return response
    }

    const flattenJSON = (json={}, parentKey = '', result = {})=> {
        for (const [ key, value ] of Object.entries(json)) {
            const newKey = parentKey ? `${ parentKey }#${ key }` : key;
    
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                flattenJSON(value, newKey, result);
            } else if (typeof value === 'object' && value !== null && Array.isArray(value)) {
                // If value is for field Type list
                result[ newKey+'__list' ] = value.join(',')
            }else {
                result[ newKey ] = value;
            }
        }
        return result;
    }

    const addEmptyObjectIfEmptyValue = (object, requiredFields) => {
        for (const requiredField of requiredFields) {
            if (!(requiredField in object)) {
                object[ requiredField ] = {};
            }
        }
    }

    return { addEmptyObjectIfEmptyValue, getFieldComponent,removeByFieldName, convertToNested, hasChidlren, addCustomMappingEntry, findFieldById, submitConfigData, loading, setLoading, flattenJSON, updateCustomFieldConfigJson }
}

export default useConfigMapperField