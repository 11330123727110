import { addEdge, applyEdgeChanges, applyNodeChanges, Controls, ReactFlow } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppLoader from '../../../appLoader';
import FlowPopup from '../../../commonComponents/flowPopup/FlowPopup.jsx';
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';
import { DestinationNode, SourceNode } from '../../../commonComponents/node/Node.jsx';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import { useWorkFlowMultipleFunc } from '../functionality/workFlowMuliple.func';
import styles from './workFlowMultiple.module.css';
import CustomEdge from '../../../commonComponents/customEdge/CustomEdge.jsx';
import Button from '../../../commonComponents/button/Button.jsx';
import { capitalizeCamelCaseWords } from '../../../helpers/index';
import Spinner from '../../../commonComponents/spinner/Spinner.jsx';
import { useNavigate } from 'react-router-dom';
import BusinessLogicPopup from '../../../commonComponents/businessLogicPopup/layout/BusinessLogicPopup.jsx';
import QueueTacker from '../../queueTracker/layout/QueueTracker.jsx';
import DeleteDialog from '../../../commonComponents/deleteDialog/DeleteDialog.jsx';
import { getServices } from '../functionality/helper'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Resizable } from 're-resizable';
import assets from '../../../assets';
import FlowPopupV2 from '../../../commonComponents/flowPopup/FlowPopupV2.jsx';
import { AiAgentSelector } from '../../../redux/AiAgentchatbot/Selector';
import AIAgentPopup from '../../../commonComponents/AIAgentPopup/AIAgentPopup.jsx';
import agentDetail from '../../../dummyData/AIAgentsDetails.json'
import { useSelector } from 'react-redux';
import { useDebounce } from '../../../hooks/useDebounce';
import aiAgentsData from '../../../dummyData/AIAgent.json';

const nodeTypes = {
    sourceNode: SourceNode,
    destinationNode: DestinationNode,
};
const WorkFlowMultiple = () => {
    const navigate = useNavigate()
    const [ nodes, setNodes ] = useState([]);
    const { connectedConnectors, handleWorkFlow, handleRunWorkFlow, workFlowLoading, workflowData, workflowId, workFlowGetLoading, handleTribe, getConfigWriter, setBusinessLogic, addConnector, setAddConnector, edges, setEdges, deleteDialog, setDeleteDialog, businessLogic, handleNode, createDynamicLogicalGroups, handleReduxState, workflowDatas, agentValues } = useWorkFlowMultipleFunc();
    const [ title, setTitle ] = useState('')
    const [ isOpen, setIsOpen ] = useState(false);
    const [ agentModal, setAgentModal ] = useState();
    const [ agentDetailModal, setAgentDetailModal ] = useState();
    const [ selectedAgentData, setSelectedAgentData ] = useState(null);
    const { aiIcon } = assets;
    // TODO: for demo purposes
    const [ queueTrackerData, setQueuTrackerData ] = React.useState([])
    //
    const aiAgentData = useSelector(AiAgentSelector);
    const detailKeys = Object.keys(agentDetail);
    const matchingKeys = agentValues?.filter((value) =>
        detailKeys.includes(value)
    );
    const containerRef = useRef(null);  // Create a ref for the container
    const [ gridHeight, setGridHeight ] = useState(200);
    // const handleClickOutside = () => {
    //     if (containerRef.current) {
    //         return setGridHeight(400); // Close the popup
    //     }
    // };
    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);
    const handleResize=()=>{
        setGridHeight(200)
        const transElement = document.querySelector('.trans');
        transElement.style.transition = 'height 1s, transform 1s';
        const onTransitionEnd = () => {
            transElement.style.transition = ''; // Remove the transition
            transElement.removeEventListener('transitionend', onTransitionEnd); // Cleanup the event listener
        };

        transElement.addEventListener('transitionend', onTransitionEnd);

    }
    useEffect(() => {
        // You can use the ref to directly manipulate styles or other properties
        if (containerRef.current) {
            // containerRef.current.style.height = `calc(-${ 10 }px + 100vh)`;
            containerRef.current.style.height = '100%';
            containerRef.current.style.transition = 'height 0.3s ease-in-out';
        }
    }, [ gridHeight ]);
    const [ selectedEdge, setSelectedEdge ] = useState(null)
    const [ error, setError ] = useState({
        'operation': '',
    })
    const [ isOpenPopup, setIsOpenPopup ] = useState(false)
    const handleDelete = (machine_name) => {
        let nodeValue = [];
        setNodes((prevNodes) => {
            nodeValue = prevNodes?.filter((node) => node?.data?.machine_name !== machine_name)
            return nodeValue;
        });
        setEdges((prevEdges) => {
            return prevEdges.filter((edge) => {
                const isSourceMatch = edge.source === machine_name;
                const isTargetMatch = edge.target === machine_name;
                return !(isSourceMatch || isTargetMatch);
            });
        });
        const edgeValue = edges.filter((edge) => {
            const isSourceMatch = edge.source === machine_name;
            const isTargetMatch = edge.target === machine_name;
            return !(isSourceMatch || isTargetMatch);
        });
        handleReduxState(edgeValue, nodeValue)
        setDeleteDialog({})
    };
    const handleFlow = (title, connector) => {
        setAddConnector((prevState) => ({
            ...prevState,
            [ title ]: [
                ...prevState[ title ],
                ...connector.map((item, index) => ({
                    id: item.machine_name,
                    type: title === 'source' ? 'sourceNode' : 'destinationNode',
                    data: { label: item.name, icon: item.icon, handleNode: handleNode, machine_name: item.machine_name },
                    position: title === 'source' ? { x: 150, y: addConnector.source.length === 0 ? index * 100 : (addConnector.source.length + index) * 100 } : { x: 520, y: addConnector.destination.length === 0 ? index * 100 : (addConnector.destination.length + index) * 100 },
                    tribe: item.tribe
                }))
            ]
        }));
    };
    useEffect(() => {
        if (typeof document !== 'undefined') {
            const reactFlowText = document.querySelector('.react-flow__attribution');
            if (reactFlowText) {
                reactFlowText.style.display = 'none';
            }
        }
    }, [ workFlowGetLoading ])
    useEffect(() => {
        if (addConnector?.source && addConnector?.destination && (Object.keys(addConnector?.source)?.length > 0 || Object.keys(addConnector?.destination)?.length > 0)) {
            const updatedNodes = [
                ...(nodes || []),
                ...addConnector.source,
                ...addConnector.destination,
            ];
            const uniqueNodes = updatedNodes.filter(
                (node, index, self) => index === self.findIndex((n) => n.id === node.id)
            );
            setNodes(uniqueNodes);
        }
    }, [ addConnector ]);
    const handleSourcePopup = (name) => {
        setTitle(name)
        setIsOpen(true)
    }
    const handleAgentPopup = () => {
        setAgentModal(true);
    };

    const handleAgentDetailPopup = (key) => {
        if (matchingKeys.includes(key)) {
            const agentDetail = aiAgentData.agents?.[ workflowId ]?.find(
                (agent) => agent === key
            );
            setSelectedAgentData(agentDetail);
            setAgentDetailModal(true);
        }
    };
    const onClose = () => {
        setIsOpen(false)
        setAgentModal(false);
        setAgentDetailModal(false)

    }
    const [ index, setIndex ] = useState(0)
    const handleEdgeClick = (event, edgeId) => {
        const edge = edges?.find((edge) => edge.id === edgeId)
        edges?.forEach((edge, index) => {
            if (edge.id === edgeId) {
                setIndex(index)
            }
        })
        setSelectedEdge(edge);
        setIsOpenPopup(true)
    };
    const edgeTypes = {
        custom: (props) => <CustomEdge {...props} onClick={handleEdgeClick} edges={edges} />,
    };
    const handleValidation = () => {
        let formError = {};
        let isValidate = false;
        Object.keys(businessLogic).forEach((key) => {
            if (businessLogic.operation.length === 0) {
                formError[ key ] = `${ key.charAt(0).toUpperCase() + key.slice(1) } is required`;
                isValidate = true;
            }
        })
        setError({ ...error, ...formError })
        return isValidate;
    }
    const onClosePopup = () => {
        setIsOpenPopup(false);
        setBusinessLogic({});
        setError({ ...error, 'operation': '' })
    }
    const handleOperation = async (isMapping = false) => {
        const tribe = handleTribe(selectedEdge.target);
        const system = selectedEdge.target;
        const service = businessLogic?.operation.length > 0 && getServices(businessLogic);
        const isValidate = handleValidation();
        if (!isValidate) {
            const getConfig = await getConfigWriter(tribe, system, service);
            console.log(getConfig)
            const conditions = businessLogic?.business_logic?.conditions || [];
            const result = createDynamicLogicalGroups(conditions);
            const finalResult = { ...businessLogic, business_logic: result }
            // eslint-disable-next-line no-unused-vars
            finalResult.business_logic.conditions = finalResult.business_logic.conditions.map(({ logic, ...rest }) => {
                return rest;
            });
            setEdges((prevEdges) => {
                const updatedEdges = prevEdges.map((edge) => {
                    if (edge && edge.id === selectedEdge.id) {
                        return {
                            ...edge, ...finalResult
                        }
                    }
                    return edge;
                });
                const parameter = {
                    tribe: tribe,
                    system: system,
                    service: service,
                    endpoints: businessLogic?.operation.length > 0 && getServices(businessLogic),
                    sourceData: {
                        tribe: handleTribe(selectedEdge?.source),
                        system: selectedEdge?.source,
                        service: businessLogic?.operation.length > 0 && getServices(businessLogic),
                    },
                    data: updatedEdges,
                    id: workflowId,
                };
                handleReduxState(updatedEdges, nodes, parameter)
                if (isMapping) {
                    navigate(`/writer-connect/${ workflowId }`)
                }
                else {
                    handleReduxState(updatedEdges, nodes, parameter)
                    onClosePopup();
                    setError({ ...error, 'operation': '' })
                }
                return updatedEdges;
            });
        }
    };
    const debouncedClick = useDebounce(handleReduxState, 300);
    useEffect(()=>{
        debouncedClick(edges, nodes)
    },[ nodes ])

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [ setNodes ],
    );

    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [ setEdges ],
    );
    const onConnect = useCallback((params) => {
        const newEdge = {
            ...params,
            data: { label: 'Operation' },
            type: 'custom',
            id: `edge-${ params.source }-${ params.target }`,
            operation: []
        };
        setEdges((eds) => addEdge(newEdge, eds));
        handleReduxState([ ...edges, newEdge ], nodes)
    }, [ setEdges, nodes, edges ]);
    const agentList = aiAgentData?.agents?.[ workflowId ] || [];

    const matchedAgents = aiAgentsData?.agentsData?.filter(agent => agentList?.includes(agent.value));
    return (
        <AppLoader>
            <div style={{ position: 'relative', height: '100%' }}>
                <div
                    ref={containerRef}
                    style={{
                        // Using ref to dynamically adjust height
                        height: `calc(-${ gridHeight }px + 100vh)`,
                        transition: ' height 0.5s ease ',
                    }}
                    onClick={handleResize}
                >
                    <div className={styles.headerWrapper}>
                        <Typography variant="header-700">
                            {capitalizeCamelCaseWords(
                                workflowData?.workflow_name
                            )}
                        </Typography>
                        <div className={styles.buttons}>
                            {matchedAgents?.map(
                                (items) =>
                                    items?.name && (
                                        <Button
                                            key={items.id}
                                            className={styles.agentButton}
                                            onClick={() =>
                                                handleAgentDetailPopup(
                                                    items.value
                                                )
                                            }
                                        >
                                            <img
                                                src={items?.image}
                                                alt={items?.name}
                                                width={24}
                                                height={24}
                                            />
                                            {items?.name}
                                        </Button>
                                    )
                            )}
                            <Button
                                className={styles.saveAndCloseButton}
                                onClick={() =>
                                    handleWorkFlow(edges, workflowId, nodes)
                                }
                            >
                                {workFlowLoading ? '...Loading' : 'Save'}
                            </Button>
                            <Button
                                className={styles.button}
                                onClick={() =>
                                    handleRunWorkFlow(edges, workflowId, setQueuTrackerData)
                                }
                            >
                                Run
                            </Button>
                        </div>
                    </div>
                    <div className={styles.flowsContainer}>
                        <div className={styles.flowsInner}>
                            <div
                                className={styles.source}
                                onClick={() => handleSourcePopup('source')}
                            >
                                <LucideIcon
                                    name="CloudDownload"
                                    size={28}
                                    className={styles.closeIcon}
                                />
                                <Typography className={styles.addTitle}>
                                    Add Source
                                </Typography>
                            </div>
                            <div className={styles.line} />
                            <div className={styles.circleButton}>
                                <LucideIcon
                                    name="Truck"
                                    size={18}
                                    color="#4470EC"
                                    className={styles.closeIcon}
                                />
                            </div>
                            <div className={styles.line} />
                            <div
                                className={styles.destination}
                                onClick={() => handleSourcePopup('destination')}
                            >
                                <LucideIcon
                                    name="CloudDownload"
                                    size={28}
                                    className={styles.closeIcon}
                                />
                                <Typography className={styles.addTitle}>
                                    Add Destination /Lookup
                                </Typography>
                            </div>
                        </div>
                        <div
                            className={styles.agent}
                            onClick={() => handleAgentPopup()}
                        >
                            <img src={aiIcon} />
                            <Typography className={styles.addTitle}>
                                AI Agents
                            </Typography>
                        </div>
                    </div>
                    {workFlowGetLoading ? (
                        <Spinner height={'65vh'} />
                    ) : (
                        <div
                            className={styles.workflow}
                            style={{ height: `calc(-${ 130 }px + 60vh)` }}
                        >
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onConnect={onConnect}
                                nodeTypes={nodeTypes}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                zoomOnScroll={true}
                                zoomOnPinch={false}
                                panOnScroll={false}
                                defaultZoom={1}
                                edgeTypes={edgeTypes}
                                attributionPosition={null}
                            >
                                <Controls />
                            </ReactFlow>
                        </div>
                    )}
                </div>
                {/* Bottom Drawer */}
                <Resizable
                    size={{ width: '100%', height: gridHeight }}
                    enable={{
                        top: true, // Enable resizing from the top
                        right: false,
                        bottom: false,
                        left: false,
                    }}
                    minHeight={200} // Minimum height
                    maxHeight={800} // Maximum height
                    onResizeStop={(e, direction, ref, d) => {
                        const newHeight = gridHeight + d.height;
                        setGridHeight(newHeight); // Update height dynamically
                    }}
                    style={{
                        position: 'absolute',
                        bottom: '0px',
                        border: '1px solid #ccc',
                        overflow: 'auto',
                        background: 'white',
                        borderTop: '3px solid rgb(204, 204, 204)'
                    }}
                    className="trans"
                >
                    <div style={{ height: '100%', width: '100%' }}>
                        <QueueTacker workflowId={workflowId} data={queueTrackerData} />
                    </div>
                </Resizable>
                {isOpenPopup && (
                    <BusinessLogicPopup
                        isOpen={isOpenPopup}
                        onClose={onClosePopup}
                        width="75%"
                        height="90%"
                        handleOperation={handleOperation}
                        businessLogic={businessLogic}
                        setBusinessLogic={setBusinessLogic}
                        workflowData={workflowDatas}
                        error={error}
                        index={index}
                    />
                )}
                {deleteDialog.isOpen === true && (
                    <DeleteDialog
                        handleDelete={handleDelete}
                        deleteDialog={deleteDialog}
                        setDeleteDialog={setDeleteDialog}
                    />
                )}
                <FlowPopup
                    isOpen={isOpen}
                    onClose={onClose}
                    title={title}
                    connectedConnectors={connectedConnectors}
                    setAddConnector={setAddConnector}
                    addConnector={addConnector}
                    handleFlow={handleFlow}
                    nodes={nodes}
                />
                <FlowPopupV2
                    isOpen={agentModal}
                    onClose={onClose}
                    workflowId={workflowId}
                    agentValues={agentValues}
                    matchedAgents={matchedAgents}
                />
                <AIAgentPopup
                    isOpen={agentDetailModal}
                    onClose={onClose}
                    data={agentDetail?.[ selectedAgentData ]}
                    name={selectedAgentData}
                />
            </div>
        </AppLoader>
    );
}
export default WorkFlowMultiple;