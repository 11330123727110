// src/BarChart.js
import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const BarChartTime = ({ data, startTimeString, color }) => {
    const svgRef = useRef();

    useEffect(() => {
        const svg = d3.select(svgRef.current);
        const { width, height } = svg.node().getBoundingClientRect();
        const margin = { top: 20, right: 20, bottom: 50, left: 40 };
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        // Generate time labels
        const startTime = startTimeString?new Date(startTimeString):new Date(); // Adjust the date as necessary
        const timeLabels = data.map((d, i) => {
            const time = new Date(startTime.getTime() + i * 60000); // Add i minutes to the start time
            return d3.timeFormat('%H:%M')(time);
        });

        const xScale = d3.scaleBand()
            .domain(timeLabels)
            .range([ 0, innerWidth ])
            .padding(0.1);

        const yScale = d3.scaleLinear()
            .domain([ 0, d3.max(data) ])
            .nice()
            .range([ innerHeight, 0 ]);

        svg.selectAll('g').remove();

        const g = svg.append('g')
            .attr('transform', `translate(${ margin.left },${ margin.top })`);

        g.selectAll('rect')
            .data(data)
            .join('rect')
            .attr('x', (d, i) => xScale(timeLabels[ i ]) + xScale.bandwidth() / 4) // Adjusted for centering
            .attr('y', d => yScale(d))
            .attr('width', xScale.bandwidth() / 2) // Adjusted for centering
            .attr('height', d => innerHeight - yScale(d))
            .attr('fill', color);

        const xAxis = d3.axisBottom(xScale);

        g.append('g')
            .attr('transform', `translate(0, ${ innerHeight })`)
            .call(xAxis)
            .selectAll('text')
            .attr('transform', 'rotate(-45)')
            .style('text-anchor', 'end');

        const yAxis = d3.axisLeft(yScale);

        g.append('g')
            .call(yAxis);
    }, [ data ]);

    return (
        <svg ref={svgRef} width="100%" height="200px"></svg>
    );
};

export default BarChartTime;
