import React from 'react'
import Table from '../../commonComponents/table/Table.jsx';
import useShipmntNoticeTable from './shipmentNoticeTable.func';

function ShipmentNoticeTable({ headerList, rowsData, mappings, urlData={}, totalRows='', props={}, hiddenColumns=[] }) {
    const { columns, data: flattenedData, loading, currentPage, setPageSize, totalRecords, setCurrentPage
    } = useShipmntNoticeTable(urlData, mappings, headerList, rowsData );
    return (
        <Table
            loading={loading}
            headerList={columns.length > 1 ? columns : []}
            totalRecords={(totalRows) ? totalRows :totalRecords}
            setPageSize={setPageSize}
            rowsData={flattenedData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            props={props}
            hiddenColumns={hiddenColumns}
        />
    )
}

export default ShipmentNoticeTable