import React from 'react'
import styles from './sidebar.module.css'
import sidebarTop from '../../../dummyData/Sidebar.json';
import { useLocation } from 'react-router-dom';

import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganisationData } from '../../../redux/organisation/Selector';
import { selectSidebarToggle } from '../../../redux/sidebar/Selector';
import { setSidebarToggle } from '../../../redux/sidebar/Slice';

const Sidebar = () => {
    const dispatch=useDispatch();
    const location = useLocation();
    const organisation = useSelector(selectOrganisationData);
    const sidebarToggle = useSelector(selectSidebarToggle);
    const orgId = organisation?.organisationData?.id;
    const handleSidebar=()=>{
        dispatch(setSidebarToggle(!sidebarToggle));
    }

    const handleClick =(name) => {
        if(name==='Sign out'){
            localStorage.removeItem('access_token')
        }
    }
    return (
        <>
            <div className={styles.sidebarWrapper}>
                <div
                    className={`${
                        sidebarToggle ? styles.openBar : ''
                    } ${ styles.topWrapper }`}
                >
                    <div className={styles.toggleSidebar}>
                        <LucideIcon
                            name={sidebarToggle ? 'MoveLeft' : 'MoveRight'}
                            onClick={handleSidebar}
                            size="20"
                        />
                    </div>
                    <div className={styles.innerDiv}>
                        {sidebarTop.sidebar.map((item) => (
                            <div
                                key={item.id}
                                className={styles.mainListWrapper}
                            >
                                <div className={styles.sidebarBottomWrapper}>
                                    {item.list.map((itemData) => (
                                        <Link
                                            id={itemData.id}
                                            to={itemData.link}
                                            onClick={() =>
                                                handleClick(itemData.name)
                                            }
                                            key={itemData.id}
                                            title={itemData.name}
                                            state={{ organisation_id: orgId }}
                                            className={`${ styles.field } ${
                                                location.pathname ===
                                                    (itemData.link ||
                                                        '/admin-dashboard') &&
                                                styles.activeField
                                            } `}
                                        >
                                            <LucideIcon
                                                name={itemData.icon}
                                                size="20"
                                            />
                                            {sidebarToggle && (
                                                <div
                                                    className={styles.fieldName}
                                                >
                                                    {itemData.name}
                                                </div>
                                            )}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;