import React from 'react';
import styles from './fileUpload.module.css';
import Button from '../../button/Button.jsx';
import useFileUpload from '../functionality/fileUpload.func';
import DialogWrapper from '../../DialogWrapper/DialogWrapper.jsx';
import LucideIcon from '../../lucideicon/LucideIcon.jsx';

const FileUpload = ({ open, onClose }) => {
    const { selectedFile, isUploading, handleFileChange, handleUpload, handleClose } = useFileUpload(onClose);

    if (!open) return null;

    return (
        <DialogWrapper isOpen={open} onClose={handleClose} width="30%">
            <div className={styles.dialogTitle}>Upload CSV / XLSX</div>
            <div className={styles.dialogContent}>
                <div className={styles.fileUploadBox}>
                    <input
                        type="file"
                        accept=".csv, .xlsx"
                        onChange={handleFileChange}
                        id="file-input"
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="file-input" className={styles.fileLabel}>
                        {selectedFile ? selectedFile.name
                            :<div>
                                <LucideIcon name="CloudUpload" size="35px"/>
                                <div>Select a CSV file to upload</div>
                                <div>or drag and drop it here</div>
                            </div>}
                    </label>
                </div>
            </div>
            <div className={styles.dialogActions}>
                <Button className={styles.uploadButton} onClick={handleUpload}>
                    {isUploading ? 'Uploading...' : 'Upload'}
                </Button>
                <Button className={styles.closeButton} onClick={handleClose}>
                    Close
                </Button>
            </div>
        </DialogWrapper>
    );
};

export default FileUpload;
