import { useEffect, useState } from 'react'
import useCustomAxiosCallTwo from '../../../hooks/useCustomAxiosCallTwo'

const useWarehouseOverviewDashboardTwo = (uriEndPoint = {}) => {
    const [ loading, setLoading ] = useState(false)
    const [ data, setData ] = useState()
    const [ error, setError ] = useState()
    const { callApi } = useCustomAxiosCallTwo();
    const getData = async () => {
        setLoading(true)
        let newUrl = '';
        if (uriEndPoint?.type) {
            newUrl = `${ uriEndPoint.url }?type=${ uriEndPoint.type }&fetch_by=${ uriEndPoint.fetch_by }`
        } else {
            newUrl = uriEndPoint.url;
        }
        const response = await callApi({
            uriEndPoint: {
                ...uriEndPoint,
                url: newUrl
            }
        });

        if (response && response?.status === 200) {
            setData(response.data)
        } else {
            setError(response?.data?.error || 'Something Went Wrong. Please Try Again Later')
        }
        setLoading(false);
    }
    useEffect(() => {
        getData();
        window.scrollTo(0, 0)
    }, []);
    return (
        { data, loading, error }
    )
}

export default useWarehouseOverviewDashboardTwo;