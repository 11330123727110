import React, { useEffect, useState } from 'react';
import connectorData from '../../dummyData/ConnectorLogo.json';
import { capitalizeCamelCaseWords } from '../../helpers/index';
import Button from '../button/Button.jsx';
import LucideIcon from '../lucideicon/LucideIcon.jsx';
import Typography from '../typography/Typography.jsx';
import styles from './flowPopup.module.css';

function FlowPopup({ isOpen, onClose, height, width, connectedConnectors, title, handleFlow, nodes }) {
    const [ selected, setSelected ]=useState([])
    const [ filteredConnector, setFilterConnector ]=useState(connectedConnectors)
    const handleSelectedConnector = (data, img, name) => {
        setSelected((prevState) => {
            const isSelected = prevState?.some((item) => item?.machine_name === data?.machine_name);

            if (isSelected) {
                return prevState?.filter((item) => item?.machine_name !== data?.machine_name);
            } else {
                return [
                    ...prevState,
                    { ...data, icon: img, name: name }
                ];
            }
        });
    };
    const handleConnector = (titleName) => {
        handleFlow(titleName, selected)
        onClose();
        setSelected([]);
    };
    useEffect(()=>{
        if ((nodes?.length>0) ){
            const filteredConnectors = connectedConnectors?.filter(
                connector => !nodes?.some(newCon => newCon?.id === connector?.machine_name)
            );
            setFilterConnector(filteredConnectors)
        }
        else{
            setFilterConnector(connectedConnectors)
        }
    }, [ connectedConnectors, nodes ])

    const handleConnectorName=(machine_name, name)=>{
        let displayName;
        switch (machine_name) {
            case 'microsoft_dynamics_365': displayName ='Dynamics 365'; break;
            case 'magento': displayName ='Magento'; break;
            case 'shopify': displayName ='Shopify'; break;
            case 'netsuite': displayName ='Netsuite'; break;
            case 'whiplash': displayName ='Whiplash'; break;
            case 'tiktok': displayName ='Tiktok'; break;
            default: displayName=name
                break;
        }
        return displayName
    }
    const handleOutsideModalClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose(e);
            setSelected([]);
        }
    }
    if (!isOpen) return null;
    const overlayStyles = {};
    height ? overlayStyles.height = height : null;
    width ? overlayStyles.width = width : null;
    return (
        <div className={styles.modalOverlayWrapper} onClick={handleOutsideModalClick}>
            <div className={styles.modalOverlay} style={overlayStyles}>
                <div className={styles.header}>
                    <Typography variant='title'>Select {capitalizeCamelCaseWords(title)} </Typography>
                    <div className={styles.button}>
                        <LucideIcon name="Search" size={18} className={styles.closeIcon} />
                        Chose Application
                    </div>
                </div>
                <div className={styles.innerContent}>
                    <Typography variant='title-14' className={styles.subTitle}>Connectors</Typography>
                    {filteredConnector?.length>0 ?
                        <div className={styles.widgets}>
                            {filteredConnector.map(data=>
                                connectorData?.connectorLogo?.map((item) =>

                                {
                                    return(data?.machine_name === item?.machine_name &&
                                    <div className={styles.connectors} onClick={()=>handleSelectedConnector(data, item.image, capitalizeCamelCaseWords(handleConnectorName(data.machine_name, data.name)))} >
                                        <img key={item?.machine_name}  className={`${ styles.logoImage } ${ selected?.some(s => s.machine_name === data?.machine_name) && styles.active }`} src={item?.image} alt={data?.machine_name} />
                                        <Typography variant='title-10'>{capitalizeCamelCaseWords(handleConnectorName(data.machine_name, data.name))}</Typography>
                                    </div>)
                                }

                                ))}
                        </div>
                        : <div >No Connectors</div>}

                </div>
                <div className={styles.buttonContainer}>
                    <Button className={styles.buttonClose} onClick={onClose}>Close</Button>
                    <Button className={styles.saveAndCloseButton} disabled={selected.length===0} onClick={()=>handleConnector(title)}>Next</Button>
                </div>
            </div>
        </div>
    )
}

export default FlowPopup;