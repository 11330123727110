import { useEffect, useState } from 'react';
import data from '../../../dummyData/CustomFlow.json';
import integrationData from '../../../dummyData/IntegrationFlow.json';
import useCustomAxiosCallTwo from '../../../hooks/useCustomAxiosCallTwo';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useWorkflowFunc = () => {
    const navigate=useNavigate();
    const { state } = useLocation();

    const organisation_id = (state && 'organisation_id' in state) ? state?.organisation_id : null;
    const [ searchKeyword, setSearchKeyword ] = useState('');
    const [ rowsData, setRowsData ] = useState([])
    const [ workflow, setWorkflow ]=useState()
    const [ workflowLoading, setWorkflowLoading ]=useState(false)
    const [ createWorkFlowLoading, setCreateWorkFlowLoading ]=useState(false)
    const [ isOpen, setIsOpen ] = useState(false);
    const [ createWorkflow, setCreateWorkflow ] = useState({
        workflow_name: '',
        description: ''
    })
    const [ error, setError ] = useState({
        workflow_name: '',
        description: ''
    })
    const headerData = [ 'workflow_name', 'description', 'errors', 'created_at', 'updated_at', 'last_run', 'schedule', 'run', 'on/Off', 'actions' ]
    const { callApi } = useCustomAxiosCallTwo();
    const handleChange = (event) => setSearchKeyword(event.target.value);
    const handleKeyDown = (event) => {
        if (event.key === ' ' || event.key === 'Enter') {
            console.log(searchKeyword);
        }
    }

    useEffect(() => {
        const rowValue = [];
        integrationData.length > 0 && integrationData.map((item) =>
            rowValue.push([
                { text: item.name }, { text: item.errors, icon: 'Dot', textStyle: { color: '#4470EC' }, iconStyle: { color: 'green' } }, { text: item.last_updated }, { text: item.last_run }, { text: item.mapping, icon: 'Waypoints' }, { text: item.schedule, icon: 'CalendarClock' }, { text: item.run, icon: 'Play' }, { text: 'ON', icon: 'Plane', align: 'right', iconStyle: { color: '#4470EC' } }, { text: '', icon: 'Ellipsis' } ]))
        setRowsData(rowValue)
    }, [ integrationData ])

    const getWorkflow=async()=>{
        setWorkflowLoading(true)
        const response = await callApi({
            uriEndPoint: {
                url: '/get-workflow',
                service: 'workflow',
            },
        });
        if (response?.status === 200) {
            setWorkflow(response?.data?.data)
            setWorkflowLoading(false)
        }
        else{
            setWorkflowLoading(false)
        }
    }

    const closeModal = () => {
        setCreateWorkflow({
            workflow_name: '',
            description: ''
        })
        setError({
            workflow_name: '',
            description: ''
        })
        setIsOpen(false);
    };

    const handleValidation = () => {
        let formError = {};
        let isValidate = false;
        Object.keys(createWorkflow).forEach((key) => {
            if (!createWorkflow.workflow_name) {
                console.log(key, 'asdfgg')
                formError[ key ] = `${ key.charAt(0).toUpperCase() + key.slice(1) } is required`;
                isValidate = true;
            }
        })
        setError({ ...error, ...formError })
        return isValidate;
    }

    const handleCreateWorkflow = async (createWorkflow)=>{
        const isValidate=handleValidation();
        console.log(createWorkflow, 'createWorkflow')
        if (!isValidate){
            setCreateWorkFlowLoading(true)
            const response = await callApi({
                uriEndPoint: {
                    url: '/business-workflow',
                    service: 'workflow',
                    method: 'POST'
                },
                body: { 'data': createWorkflow },
            });

            if (response?.status === 201) {
                toast.success('Workflow created successfully' || 'Something Went Wrong', { position: 'top-right' })

                setCreateWorkFlowLoading(false);
                navigate(`/work-flow/${ response.data?._id }`, {
                    state: {
                        workflowId: response?.data?._id,
                        organisation_id: organisation_id
                    }
                });
            } else{
                setCreateWorkFlowLoading(false);
                toast.error(response?.data?.msg || 'Something Went Wrong', { position: 'top-right' })

            }
        }
    }
    const handleDelete = async (id) => {
        const response = await callApi({
            uriEndPoint: {
                url: `/delete-workflow/${ id }`,
                service: 'workflow',
                method: 'DELETE',
            },
        });
        const result = response?.data;
        if (result.status==='success') {
            toast.success('Workflow deleted successfully' || 'Something Went Wrong', { position: 'top-right' })
            getWorkflow();
        } else {
            toast.error(response?.data?.detail || 'Something Went Wrong', { position: 'top-right' })
        }
    }
    const rowClickHandler = (params) => {
        const filterData = workflow[ (params?.id) - 1 ];
        if (params?.id) {
            navigate(`/work-flow/${ filterData?._id }`, {
                state: {
                    workflowId: filterData?._id,
                    organisation_id: organisation_id
                }
            });
        }
    }
    useEffect(()=>{
        getWorkflow();
    },[])

    return { data, handleChange, handleKeyDown, rowsData, headerData, workflow, workflowLoading, handleCreateWorkflow, createWorkFlowLoading, handleDelete, isOpen, setIsOpen, createWorkflow, setCreateWorkflow, error, setError, closeModal, rowClickHandler };
}
