// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .variation_textFieldWrapper__gRT2d {
        width: 100%;
        display: flex;
        gap: 20px;
        justify-content: space-between;
 }
 .variation_ruleHeader__FTNOA {
        display: flex;
        align-items: center;
        gap: 10px;
        box-shadow: 0px 7.32px 29.29px 0px var(--box-shadow);
        padding: 10px;
        margin: 25px 0px;
        font-family: Outfit;
        font-size: 18px;
        font-weight: 500;
    }

    .variation_inputContainer__La1Fi {
        width: 50%;
    }
    .variation_outputContainer__kxuTW {
        width: 50%;
    }
    .variation_textfield__jtWnc {
            border-radius: 4px;
    }
    .variation_textfield__jtWnc .MuiFormControl-root {
        width: 100%;

}`, "",{"version":3,"sources":["webpack://./src/components/variation/variation.module.css"],"names":[],"mappings":"CAAC;QACO,WAAW;QACX,aAAa;QACb,SAAS;QACT,8BAA8B;CACrC;CACA;QACO,aAAa;QACb,mBAAmB;QACnB,SAAS;QACT,oDAAoD;QACpD,aAAa;QACb,gBAAgB;QAChB,mBAAmB;QACnB,eAAe;QACf,gBAAgB;IACpB;;IAEA;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;IACA;YACQ,kBAAkB;IAC1B;IACA;QACI,WAAW;;AAEnB","sourcesContent":[" .textFieldWrapper {\n        width: 100%;\n        display: flex;\n        gap: 20px;\n        justify-content: space-between;\n }\n .ruleHeader {\n        display: flex;\n        align-items: center;\n        gap: 10px;\n        box-shadow: 0px 7.32px 29.29px 0px var(--box-shadow);\n        padding: 10px;\n        margin: 25px 0px;\n        font-family: Outfit;\n        font-size: 18px;\n        font-weight: 500;\n    }\n\n    .inputContainer {\n        width: 50%;\n    }\n    .outputContainer {\n        width: 50%;\n    }\n    .textfield {\n            border-radius: 4px;\n    }\n    .textfield :global(.MuiFormControl-root) {\n        width: 100%;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textFieldWrapper": `variation_textFieldWrapper__gRT2d`,
	"ruleHeader": `variation_ruleHeader__FTNOA`,
	"inputContainer": `variation_inputContainer__La1Fi`,
	"outputContainer": `variation_outputContainer__kxuTW`,
	"textfield": `variation_textfield__jtWnc`
};
export default ___CSS_LOADER_EXPORT___;
