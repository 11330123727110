import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './RootReducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [ 'user', 'organisation', 'dashboard', 'shipNotice', 'collectiveData', 'notification', 'tutorial' ],
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);
