import React from 'react';
import styles from './connectors.module.css';
import AppLoader from '../../../appLoader';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import { useConnectorsFunc } from '../functionality/Connectors.func';
import ConnectorCardII from '../../../components/connectorCardII/layout/ConnectorCardII.jsx';
import ConnectorSkeleton from '../../../components/skeletons/connector/ConnectorSkeleton.jsx';
import ConnectorCard from '../../../components/connectorCard/layout/ConnectorCard.jsx';
import ConnectedConnectorSkeleton from '../../../components/skeletons/connectedConnector/ConnectedConenctorSkeleton.jsx';
import { capitalizeSnakeCaseWords } from '../../../helpers';

const Connectors = () => {
    const { defaultConnctors, connectedConnectors, defaultConnctorsLoading, connectedConnectorsLoading, handleConnectConnector } = useConnectorsFunc();
    let connectedConnectorsContent=[];
    for (const key in defaultConnctors) {
        if (Object.hasOwnProperty.call(defaultConnctors, key)) {
            connectedConnectorsContent.push(
                <div key={key}>
                    <Typography className={styles.subTitle}>{capitalizeSnakeCaseWords(key)} Connectors</Typography>
                    {
                        defaultConnctorsLoading ? <ConnectorSkeleton /> : (
                            <div className={styles.connectorsGrid}>
                                {defaultConnctors?.[ key ]?.map((connector) => (
                                    <ConnectorCardII handleConnectConnector={handleConnectConnector} key={connector._id} connector={connector} />
                                ))}
                            </div>
                        )
                    }
                </div>
            )

        }
    }
    const renderConnectedConnectors = () => connectedConnectors.length > 0 && <div className={styles.connectedConnctorsContainer}>
        <Typography className={styles.connectorHeading} variant='heading'>
            Active Connectors
        </Typography>
        <div className={styles.connectedConnectorsGrid}>
            {
                connectedConnectors.map((connector) => (
                    <ConnectorCard key={connector._id} connector={connector} />
                ))
            }
        </div>
    </div>

    return (
        <AppLoader>
            <div className={styles.connectorsWrapper}>

                {connectedConnectorsLoading ? (
                    <ConnectedConnectorSkeleton />
                ) : (
                    renderConnectedConnectors()
                )}

                <div className={styles.defaultConnectorsWrapper}>
                    <Typography className={`${ styles.subTitle } ${ styles.title }`}>Other Available Connectors</Typography>
                    {connectedConnectorsContent}
                </div>
            </div>
        </AppLoader>
    );
};

export default Connectors;