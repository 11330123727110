import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser } from '../redux/user/Slice';
import { useNavigate } from 'react-router-dom';
import { selectOrganisationData } from '../redux/organisation/Selector';

const useCustomAxiosCall = () => {
    const organisation = useSelector(selectOrganisationData);
    const orgId = organisation?.organisationData?.encrypt_id;
    const BASE_URL = 'https://app.ekyam.ai/e/api/';
    const chatGPT_BASE_URL = `${ BASE_URL }chat-bot`;
    const ADMIN_BASE_URL = `${ BASE_URL }admin`;
    const NETSUITE_BASE_URL = `${ BASE_URL }app-netsuite`;
    const INBOUND_SHIPMENT_BASE_URL = `${ BASE_URL }inbound-shipment`;
    const SHIP_NOTICE_BASE_URL = `${ BASE_URL }ship-notice`;
    const COLLECTIVEDATA_BASE_URL = `${ BASE_URL }collectivedata`;
    const PRODUCTS_BASE_URL = `${ BASE_URL }products`;
    const WHIPLASH_SERVICE_BASE_URL = `${ BASE_URL }whiplash-app`;
    const ORDERS_SERVICE_BASE_URL = `${ BASE_URL }orders`;
    const LOGGER_SERVICE_BASE_URL = `${ BASE_URL }logger`;
    const CONFIG_MAPPER_BASE_URL = 'https://app.ekyam.ai/v/api/config'
    // const CONFIG_MAPPER_BASE_URL = 'http://localhost:8000'
    const databaseName = organisation?.organisationData?.databaseName;

    const accessToken = (typeof window !== 'undefined' && window) && window.localStorage.getItem('access_token');
    const dispatch = useDispatch();
    const navigate = useNavigate()

    // can be moved under the user auth hook
    const logoutUser = () => {
        window.localStorage.removeItem('access_token');
        dispatch(clearUser());
        navigate('/');
    };
    const getDefaultHeaders = () => ({
        'Authorization': `Bearer ${ accessToken }`,
        'x-organisation-id': orgId && orgId,
        'x-d-token': databaseName
    });

    const callAxiosApi = ({
        uriEndPoint = {
            url: '', method: 'GET', service: ''
        },
        body,
    }) => {
        let baseURL = ADMIN_BASE_URL
        switch (uriEndPoint.service) {
            case 'netsuite':
                baseURL = NETSUITE_BASE_URL
                break;
            case 'ship-notice':
                baseURL = SHIP_NOTICE_BASE_URL
                break;
            case 'collectivedata':
                baseURL = COLLECTIVEDATA_BASE_URL
                break;
            case 'inbound-shipment':
                baseURL = INBOUND_SHIPMENT_BASE_URL
                break;
            case 'products':
                baseURL = PRODUCTS_BASE_URL
                break;
            case 'whiplash':
                baseURL = WHIPLASH_SERVICE_BASE_URL
                break
            case 'orders':
                baseURL = ORDERS_SERVICE_BASE_URL
                break
            case 'logger':
                baseURL = LOGGER_SERVICE_BASE_URL
                break
            case 'chat_gpt' :
                baseURL = chatGPT_BASE_URL
                uriEndPoint.method = 'POST'
                break;
            case 'config-mapper' :
                baseURL = CONFIG_MAPPER_BASE_URL
                break;

            default:
                break;
        }
        console.log(uriEndPoint.url, 'url')
        const API_URL = `${ baseURL }${ uriEndPoint.url }`;
        const headers = accessToken
            ? { ...getDefaultHeaders() }
            : undefined;
        console.log(API_URL, 'APi Url')

        return Axios({
            method: uriEndPoint.method,
            url: API_URL,
            headers,
            data: body,
            params: uriEndPoint?.params,
            responseType: uriEndPoint.responseType,
        });
    };

    const callApi = ({
        uriEndPoint = {
            url: '', method: 'GET', service: '', params: {}
        },
        body,
    }) => callAxiosApi({
        uriEndPoint,
        body,
    }).then((resp) => resp)
        .catch((err) => {
            console.log('ERROR:', err?.response);
            if (err?.response?.data?.statusCode === 403) {
                logoutUser();
            }
            return err?.response;
        });

    return {
        callApi,
        getDefaultHeaders,
    };
};

export default useCustomAxiosCall;