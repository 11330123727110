import { useState } from 'react';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import { toast } from 'react-toastify';

const useFileUpload = (onClose) => {
    const { callApi } = useCustomAxiosCall();
    const [ selectedFile, setSelectedFile ] = useState(null);
    const [ isUploading, setIsUploading ] = useState(false);
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[ 0 ]);
    };
    const handleClose=()=>{
        setSelectedFile(null)
        onClose()
    }
    const handleUpload = async () => {
        if (!selectedFile) {
            toast.error('Please select a file to upload.', { position: 'top-right' })
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);
        try {
            setIsUploading(true);
            const response = await callApi({
                uriEndPoint: {
                    url: '/upload-to-dropbox',
                    method: 'POST',
                    service: 'collectivedata'
                },
                body: formData
            });
            console.log(response, 'response')
            if (response.status ===200){
                handleClose()
                toast.success('inventory updated Successfully', { position: 'top-right' })
            }
        } catch (error) {
            toast.error('Something Went Wrong', { position: 'top-right' })
        }
        setIsUploading(false)
    };
    return { selectedFile, isUploading, handleFileChange, handleUpload, handleClose };
}

export default useFileUpload;