import React, { useState } from 'react';
import Header from './components/header/layout/Header.jsx';
import './index.css';
import './styles/theme.css';
import Sidebar from './components/sidebar/layout/Sidebar.jsx';
import { useLocation } from 'react-router-dom';
import LucideIcon from './commonComponents/lucideicon/LucideIcon.jsx';
import { useNavigate } from 'react-router-dom';
import NavigatePopup from './commonComponents/navigatePopup/NavigatePopup.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { clearActionId } from './redux/visitor/Slice';
import DateDropDown from './commonComponents/dateDropdown/DateDropdown.jsx';

const AppLoader = ({ isFilter, setIsFilter =()=>{}, children }) => {
    const { pathname } = useLocation();
    const [ openTooltip, setOpenTooltip ] = useState(false)
    const navigate = useNavigate();
    const actionId = useSelector((state) => state.tutorial.actionId)
    const currentPage = pathname.replace(/\//g, '');
    const showFilterOnPages = [ 'warehouse-overview' ]
    const showFiter = showFilterOnPages.includes(currentPage)
    const dispatch = useDispatch();

    const handleBack = () => {
        navigate(-1);
    }
    const handleForward = () => {
        navigate(1);
    }
    const onClose=()=>{
        setOpenTooltip(true);
        dispatch(clearActionId(''))
    }
    const clearFilter=()=>{
        if (isFilter?.filter===true){
            setIsFilter({
                start_date: 'dd/mm/yyyy',
                end_date: 'dd/mm/yyyy',
                filter: false,
            })
        }
    }
    return (
        <div className='appLoader'>
            <Header openTooltip={openTooltip} setOpenTooltip={setOpenTooltip} />
            <div className='appContainer'>
                {pathname !== '/organisation' && (<div><Sidebar /></div>)}
                <main className='appMainWrapper' style={pathname === '/organisation' ? { width: '100%' } : {}}>
                    {pathname !== '/organisation' && <div className='filterIconWrapper'>
                        <div className='iconWrapper'>
                            <LucideIcon name="CircleArrowLeft" color="#4470EC" className='icon' onClick={handleBack} /><LucideIcon name="CircleArrowRight" color="#4470EC" onClick={handleForward} />
                        </div>
                        {showFiter &&
                        <div className='filterWrapper'>
                            <DateDropDown options={[]} onDropDownChange={() => { }} setIsFilter={setIsFilter} defaultOption={isFilter?.start_date && isFilter?.end_date ? `${ isFilter?.start_date } - ${ isFilter?.end_date }` :'dd/mm/yyyy - dd/mm/yyyy'} />
                            <div onClick={clearFilter} className='clearButton'>Clear</div>
                        </div>
                        }
                    </div>}
                    {children}
                </main>
            </div>
            <NavigatePopup isOpen={actionId} onClose={onClose} setOpenTooltip={setOpenTooltip} openTooltip={openTooltip} />
        </div>
    );
}

export default AppLoader;
