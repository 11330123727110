// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fourStats_fourStatsContainer__pqb2v {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    margin: 2rem 0;
    background-color: white;
    padding: 1rem;

}

.fourStats_statContainer__HZcVJ {
    margin-top: 1rem;
    display: grid;
    gap: 2rem;
    padding: 1rem 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.fourStats_stasTile__-MjzC {
    padding: 1rem;
    background-color: #055DC5;
    color: white;
    border-radius: 12px;
}

.fourStats_stasTile__-MjzC .fourStats_label__wAQlZ {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 16.17px;
    letter-spacing: -0.14698123931884766px;
    text-align: center;
    padding-bottom: 8px;
}

.fourStats_stasTile__-MjzC .fourStats_count__IQvxp {
    font-family: Inter;
    font-size: 23.36px;
    font-weight: 700;
    line-height: 28.27px;
    letter-spacing: -0.14698123931884766px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/widgets/fourStats/layout/fourStats.module.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,mBAAmB;IACnB,cAAc;IACd,uBAAuB;IACvB,aAAa;;AAEjB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,SAAS;IACT,eAAe;IACf,gDAAgD;AACpD;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,sCAAsC;IACtC,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;IACpB,sCAAsC;IACtC,kBAAkB;AACtB","sourcesContent":[".fourStatsContainer {\n    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.12);\n    border-radius: 12px;\n    margin: 2rem 0;\n    background-color: white;\n    padding: 1rem;\n\n}\n\n.statContainer {\n    margin-top: 1rem;\n    display: grid;\n    gap: 2rem;\n    padding: 1rem 0;\n    grid-template-columns: repeat(2, minmax(0, 1fr));\n}\n\n.stasTile {\n    padding: 1rem;\n    background-color: #055DC5;\n    color: white;\n    border-radius: 12px;\n}\n\n.stasTile .label {\n    font-family: Inter;\n    font-size: 15px;\n    font-weight: 500;\n    line-height: 16.17px;\n    letter-spacing: -0.14698123931884766px;\n    text-align: center;\n    padding-bottom: 8px;\n}\n\n.stasTile .count {\n    font-family: Inter;\n    font-size: 23.36px;\n    font-weight: 700;\n    line-height: 28.27px;\n    letter-spacing: -0.14698123931884766px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fourStatsContainer": `fourStats_fourStatsContainer__pqb2v`,
	"statContainer": `fourStats_statContainer__HZcVJ`,
	"stasTile": `fourStats_stasTile__-MjzC`,
	"label": `fourStats_label__wAQlZ`,
	"count": `fourStats_count__IQvxp`
};
export default ___CSS_LOADER_EXPORT___;
