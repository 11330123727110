import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styles from './rules.module.css'
import { v4 as uuid } from 'uuid';
import Typography from '../../commonComponents/typography/Typography.jsx';
import LucideIcon from '../../commonComponents/lucideicon/LucideIcon.jsx';
import Button from '../../commonComponents/button/Button.jsx';

function Rules({ rules, updateRules, handleAddMoreRules }) {
    const relations=[ 'greater than', 'contain', 'is not contain', 'less than', 'Equal', 'not equal' ]
    const items=[ 'handbags', 'shoes', 'bag' ];
    const connectors=[ '3PL', 'whiplash' ];
    const condition=[ 'And', 'OR' ];
    const productDetail=[ 'DC1 quantity', 'size' ]
    const quantity=[ '1', '2', '3', '4', '5' ]

    const handleChange = (values, key='', name='', keyIndex, index, ) => {
        const ruleList = [ ...rules ];
        if(name==='rules' || name==='productDetails'){
            ruleList[ keyIndex ][ name ][ index ][ key ] = values;
            updateRules([ ...ruleList ]);
        } else {
            ruleList[ keyIndex ][ key ] = values;
            updateRules([ ...ruleList ]);
        }
    };
    const handleDelete = (itemIndex) => {
        updateRules(rules.filter((item, index) => index !== itemIndex));
    };
    // handle click event of the Remove button
    const handleRemoveClick = (keyIndex, index) => {
        const ruleList = [ ...rules ];
        ruleList[ keyIndex ].productDetails.splice(index, 1);
        updateRules([ ...ruleList ]);
    };
    // handle click event of the Add button
    const handleAddClick = (keyIndex) => {
        const ruleList = [ ...rules ];
        ruleList[ keyIndex ].productDetails = [ ...ruleList[ keyIndex ].productDetails , {
            specification: '',
            values: '',
            quantity: '',
            condition:''
        } ];
        updateRules([ ...ruleList ]);
    };

    const capitalizeFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    return (
        rules.map((fieldValues, keyIndex) => (
            <div className={styles.rulesContent} key={uuid()}>
                <div className={styles.subTitleContainer}>
                    <Typography>{`Rule ${ keyIndex + 1 }`}</Typography>
                    <div className={styles.buttonWrapper}>
                        <Button className={styles.addGroupButton} onClick={handleAddMoreRules}>Add Group</Button>
                        {rules.length>1 && <Button className={styles.deleteGroupButton} onClick={() => handleDelete(keyIndex)}>Delete Group</Button>}

                    </div>
                </div>
                {fieldValues.rules.map((values, index) => (
                    <div className={styles.fieldWrapper} key={uuid()}>
                        {index === 0 &&
                            <div className={styles.ifWrapper}>IF</div>}
                        <div className={styles.innerFieldsWrapper}>
                            <div className={styles.textfield}>
                                Categories
                            </div>
                            <div className={styles.selectWrapper}>
                                <Select
                                    labelId="demo-select-small-label"
                                    className={styles.select}
                                    id="demo-select-small"
                                    name="Relation"
                                    displayEmpty
                                    onChange={(e) => handleChange(e.target.value, 'values', 'rules', keyIndex, index)}
                                    renderValue={
                                        values?.values ? () => (capitalizeFirst(values.values)?.split('_')?.join(' ')) : () => <div className={styles.selectPlaceholder}>{relations[ 0 ]}</div>
                                    }
                                    MenuProps={{
                                        styles: {
                                            paper: styles.paper,
                                        },
                                    }}
                                    inputProps={{
                                        styles: {
                                            icon: styles.icon,
                                        },
                                    }}
                                >
                                    {relations.map((relation, index)=>
                                        <MenuItem key={index} value={relation}>{relation}</MenuItem>
                                    )}

                                </Select>
                            </div>

                            <div className={styles.selectWrapper}>
                                <Select
                                    labelId="demo-select-small-label"
                                    className={styles.select}
                                    id="demo-select-small"
                                    name="Values"
                                    displayEmpty
                                    onChange={(e) => handleChange(e.target.value, 'categories', 'rules', keyIndex, index)}
                                    renderValue={
                                        values?.categories ? () => (capitalizeFirst(values.categories)?.split('_')?.join(' ')) : () => <div className={styles.selectPlaceholder}>{items[ 0 ]}</div>
                                    }
                                    MenuProps={{
                                        styles: {
                                            paper: styles.paper,
                                        },
                                    }}
                                    inputProps={{
                                        styles: {
                                            icon: styles.icon,
                                        },
                                    }}
                                >
                                    {items.map((item)=>
                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                    )}
                                </Select>
                            </div>

                        </div>
                        {index === 0 &&
                            <Button className={styles.addConditionButton} onClick={() => handleAddClick(keyIndex)}>
                                Add Condition
                            </Button>
                        }
                    </div>
                ))}
                {fieldValues.productDetails.map((values, index) => (
                    <div key={index} className={styles.andOrSelectWrapper}>
                        <div className={styles.andOrSelect}>
                            <Select
                                labelId="demo-select-small-label"
                                className={styles.select}
                                id="demo-select-small"
                                name="Values"
                                displayEmpty
                                onChange={(e) => handleChange(e.target.value, 'condition', 'productDetails', keyIndex, index)}
                                renderValue={
                                    values?.condition ? () => (capitalizeFirst(values.condition)?.split('_')?.join(' ')) : () => <div className={styles.conditionPlaceholder}>{condition[ 0 ]}</div>
                                }
                                MenuProps={{
                                    styles: {
                                        paper: styles.paper,
                                    },
                                }}
                                inputProps={{
                                    styles: {
                                        icon: styles.icon,
                                    },
                                }}
                            >
                                {condition.map((ele)=>
                                    <MenuItem key={ele} value={ele}>{ele}</MenuItem>
                                )}
                            </Select>
                        </div>
                        <div className={styles.DcQualityWrapper}>
                            <div className={styles.selectWrapper}>
                                <Select
                                    labelId="demo-select-small-label"
                                    className={styles.select}
                                    id="demo-select-small"
                                    name="specification"
                                    displayEmpty
                                    onChange={(e) => handleChange(e.target.value, 'specification','productDetails', keyIndex, index)}
                                    renderValue={
                                        values?.specification ? () => (capitalizeFirst(values?.specification)?.split('_')?.join(' ')) :() => <div className={styles.selectPlaceholder}>{productDetail[ 0 ]}</div>
                                    }
                                    MenuProps={{
                                        styles: {
                                            paper: styles.paper,
                                        },
                                    }}
                                    inputProps={{
                                        styles: {
                                            icon: styles.icon,
                                        },
                                    }}
                                >
                                    {productDetail.map((detail, index)=>
                                        <MenuItem key={index} value={detail}>{detail}</MenuItem>
                                    )}

                                </Select>
                            </div>
                            <div className={styles.selectWrapper}>
                                <Select
                                    labelId="demo-select-small-label"
                                    className={styles.select}
                                    id="demo-select-small"
                                    name="values"
                                    displayEmpty
                                    onChange={(e) => handleChange(e.target.value, 'values', 'productDetails', keyIndex, index)}
                                    renderValue={
                                        values?.values ? () => (capitalizeFirst(values?.values)?.split('_')?.join(' ')) :() => <div className={styles.selectPlaceholder}>{relations[ 0 ]}</div>
                                    }
                                    MenuProps={{
                                        styles: {
                                            paper: styles.paper,
                                        },
                                    }}
                                    inputProps={{
                                        styles: {
                                            icon: styles.icon,
                                        },
                                    }}
                                >
                                    {relations.map((relation, index)=>
                                        <MenuItem key={index} value={relation}>{relation}</MenuItem>
                                    )}

                                </Select>
                            </div>

                            <div className={styles.selectWrapper}>
                                <Select
                                    labelId="demo-select-small-label"
                                    className={styles.select}
                                    id="demo-select-small"
                                    name="Values"
                                    displayEmpty
                                    onChange={(e) => handleChange(e.target.value, 'quantity', 'productDetails', keyIndex, index)}
                                    renderValue={
                                        values?.quantity ? () => (capitalizeFirst(values?.quantity)?.split('_')?.join(' ')) :() => <div className={styles.selectPlaceholder}>{quantity[ 0 ]}</div>
                                    }
                                    MenuProps={{
                                        styles: {
                                            paper: styles.paper,
                                        },
                                    }}
                                    inputProps={{
                                        styles: {
                                            icon: styles.icon,
                                        },
                                    }}
                                >
                                    {quantity.map((item)=>
                                        <MenuItem key={item} value={item}>{item}</MenuItem>
                                    )}
                                </Select>
                            </div>
                            {fieldValues.productDetails.length !== 1 && (
                                <div className={styles.removeIcon}>
                                    <LucideIcon name="X" size="24" onClick={() => handleRemoveClick(keyIndex, index)}/>
                                </div>
                            )}
                        </div>
                    </div>
                ))}

                <div className={styles.serveContainer}>
                    <div className={styles.serveTittle}><strong>Then</strong></div>
                    <div className={styles.serveInner}>
                        <div className={styles.innerFieldsWrapper}>
                            <div className={styles.textfield}>
                                Route Orders
                            </div>
                            <div className={styles.textfield}>
                                to
                            </div>

                            <div className={styles.selectWrapper}>
                                <Select
                                    labelId="demo-select-small-label"
                                    className={styles.select}
                                    id="demo-select-small"
                                    name="Values"
                                    displayEmpty
                                    onChange={(e) => handleChange(e.target.value, 'route_orders', '',  keyIndex)}
                                    renderValue={
                                        fieldValues?.route_orders ? () => (capitalizeFirst(fieldValues?.route_orders)?.split('_')?.join(' ')) : () => <div className={styles.selectPlaceholder}>{connectors[ 0 ]}</div>
                                    }
                                    MenuProps={{
                                        styles: {
                                            paper: styles.paper,
                                        },
                                    }}
                                    inputProps={{
                                        styles: {
                                            icon: styles.icon,
                                        },
                                    }}
                                >
                                    {connectors.map((connector)=>
                                        <MenuItem key={connector} value={connector}>{connector}</MenuItem>
                                    )}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))
    );
}

export default Rules