import React from 'react';
import styles from './gridWrapper.module.css'

const GridWrapper = ({ children, columnsCount=0, columnsTemplate='', style={} })=>{
    let gridColumnTemplate='';
    if(columnsCount>0){
        gridColumnTemplate = `repeat(${ columnsCount }, minmax(0, 1fr))`;
    }else{
        gridColumnTemplate = columnsTemplate;
    }
    return <div style={{
        gridTemplateColumns: gridColumnTemplate, ...style
    }} className={styles.statsContainer}>{children}</div>
}
export default GridWrapper;