import React from 'react'
import styles from './FormStep.module.css'
import ConfigMapperFieldV2 from '../../../views/ConfigMapperV2/layout/ConfigMapperFieldV2.jsx'
import useConfigMapperField from '../../../views/configMapper/functionality/useConfigMapperFeild.func';
import StepSlider from '../../stepSlider/layout/StepSlider.jsx';
import useFormStep1 from '../functionality/useFormStep1.func';
import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func';
import { useDispatch, useSelector } from 'react-redux';
import { setMappingConfiguration } from '../../../redux/mappingConfiguration/Slice';
import { selectConfigurationData } from '../../../redux/mappingConfiguration/Selector';
const FormStep = ({
    fieldInputData,
    sliderData,
    setConfigFormData = () => {},
    fetchedData = {},
    setActiveStep = () => {},
    setValidateData,
    configFormData={},
    activeStep=activeStep,
    collections=[],
}) => {
    // Importing functions from hooks
    const { convertToNested, flattenJSON } = useConfigMapperField();
    const { updateCustomFieldConfigJson } = useConfigMapperFieldV2();
    const {
        verifyConnectivityConfig,
        prepareFormData,
        handleFormValidation,
        validationLoading,
        checkNestedObjectEmpty,
    } = useFormStep1();

    // React States
    const [ formValues, setFormValues ] = React.useState({ ...flattenJSON({}) });
    const [ validationError, setValidationError ] = React.useState({});
    const [ fieldData, setFieldData ] = React.useState([
        ...JSON.parse(JSON.stringify(fieldInputData)),
    ]);

    // Assigning and declaring variables
    //   Redux
    const dispatch = useDispatch();
    const configurationStateData = useSelector(selectConfigurationData)?.mappingConfigurationData || {}

    // Event Handlers
    const inputChangeHandler = (event) => {
        if (event?.target?.name) {
            setFormValues((prevState) => {
                let newObj = prevState;
                newObj[ event?.target?.name ] = event?.target?.value;
                return newObj;
            });
            dispatch(setMappingConfiguration({ data: { [ event.target.name ]: event.target.value } }))
        }
    };

    const formSubmitHandler = async (event) => {
        setActiveStep(false)
        event.preventDefault();
        const formElements = event.target.elements;
        let finalFormData = prepareFormData(
            formElements,
            fieldData,
            formValues
        );
        let nestedFormData = convertToNested(finalFormData);
        console.log(nestedFormData, 'nestedFormData')
        let validationErrObj = handleFormValidation(
            fieldInputData,
            nestedFormData,
            setValidationError
        );
        if (checkNestedObjectEmpty(validationErrObj)) {
            nestedFormData.system = configFormData?.system
            await verifyConnectivityConfig(
                nestedFormData,
                setValidationError,
                setActiveStep,
                setConfigFormData,
                setValidateData,
            );

            return;
        }
    };
    console.log(activeStep, 'activeStep')
    // useEffect
    React.useEffect(() => {
        // Converting services to endpoints
        let updatedData =  {}, nestedUpdatedData={}
        let servcieEndpointsObj = {};
        if(Object.keys(fetchedData).length > 0) {
            updatedData = fetchedData
            for (const key in fetchedData?.services) {
                servcieEndpointsObj[ `endpoints#${ key }` ] =
                fetchedData?.services[ key ]?.endpoints;
            }
            dispatch(setMappingConfiguration({ data: { ...fetchedData, ...servcieEndpointsObj } }))
            nestedUpdatedData = updatedData
            console.log('#fetchedData', '#insideReduxLoop', fetchedData)
        }else{
            console.log('#insideReduxLoop')
            updatedData = configurationStateData
            nestedUpdatedData = convertToNested(updatedData)
            for (const key in configurationStateData) {
                if(key.includes('endpoints')){
                    servcieEndpointsObj[ `endpoints#${ key?.split('#')[ 1 ] }` ] = configurationStateData[ key ]
                }
            }
        }

        console.log(configurationStateData, updatedData, servcieEndpointsObj, fetchedData, '#readfromdb', nestedUpdatedData)
        setFormValues({
            ...updatedData, ...servcieEndpointsObj
        });
        setFieldData([ ...JSON.parse(JSON.stringify(fieldInputData)) ]);
        updateCustomFieldConfigJson(fieldData, setFieldData, nestedUpdatedData, {
            ...updatedData, ...servcieEndpointsObj
        });
    }, [ fetchedData, activeStep ]);

    return (
        <form
            className={`${ styles.formWrapper } ${
                sliderData?.showSlider ? styles.withSlider : ''
            }`}
            onSubmit={formSubmitHandler}
        >
            {sliderData?.showSlider && (
                <div className={styles.sliderWrapper}>
                    <StepSlider
                        vertical={sliderData?.isVertical}
                        activeStep={0}
                        stepsInfo={sliderData?.stepsInfo}
                        showStepNumber={sliderData?.showStepNumber}
                    />
                </div>
            )}

            <div className={styles.inputFieldsWrapper}>
                {fieldData?.map((fieldRow, index) => {
                    return (
                        <div
                            key={index}
                            className={`${ styles.row } ${
                                styles[ `col-${ fieldRow?.columnsCount || 3 }` ]
                            }`}
                        >
                            {fieldRow?.data?.map((inputData) => (
                                <ConfigMapperFieldV2
                                    fieldData={inputData}
                                    fieldListData={fieldData}
                                    key={inputData?.field_name}
                                    formData={formValues}
                                    setFieldData={setFieldData}
                                    onInputChange={inputChangeHandler}
                                    validationError={validationError}
                                    setValidationError={setValidationError}
                                    collections={collections}
                                />
                            ))}
                        </div>
                    );
                })}

                <div className={styles.formSubmitWrapper}>
                    <input
                        type="submit"
                        className={styles.primary}
                        value={validationLoading ? 'Verifying' : 'Verify'}
                        disabled={validationLoading}
                    />
                </div>
            </div>
        </form>
    );
};

export default FormStep