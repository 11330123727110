import React from 'react'
import styles from './FieldMappingsPreveiwPopup.module.css'
import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func'
import { capitalizeCamelCaseWords, capitalizeSnakeCaseWords } from '../../../helpers'
// import previewData from '../dummyData/previewDataDummy.json'
const FieldMappingsPreveiwPopup = ({ data, onClose, onVerifyClick }) => {
    const { getDisplayName } = useConfigMapperFieldV2()
    const fieldContent = [], customFieldContent = [];
    const customFields = data?.custom_fields;
    
    const processField = (key, value) => ({
        field_name: key,
        value: Array.isArray(value) ? value[ 0 ] : value
    });
    
    for (let key in customFields) {
        customFieldContent.push(processField(key, customFields[ key ]));
    }
    
    for (let key in data) {
        if (key !== 'custom_fields') {
            fieldContent.push(processField(key, data[ key ]));
        }
    }
   
    const getHtmlContent = (field) => {
        if(field.value && typeof field.value === 'object'){
            return <div key={field?.field_name} className={`${ styles.fieldWrapper } ${ styles.nestedFieldWrapper }`}>
                <p className={styles.fieldName}>{getDisplayName(field)}</p>
                { Object.keys(field.value).map(nestedField => 
                    <div key={nestedField} className={styles.fieldWrapper}>
                        <p>{capitalizeCamelCaseWords(capitalizeSnakeCaseWords(nestedField)) }</p>
                        <div className={styles.fieldValueWrapper}>{JSON.stringify(field?.value[ nestedField ])}</div>
                    </div>) } 
            </div>
        }else{
            return <div key={field?.field_name} className={styles.fieldWrapper}>
                <p>{getDisplayName(field)}</p>
                <div className={styles.fieldValueWrapper}>{field?.value}</div>
            </div>
        }
    }

    const getCustomFieldContent = (field) => {
        if(field.value && typeof field.value === 'object'){
            return <div key={field?.field_name} className={`${ styles.fieldWrapper } ${ styles.nestedFieldWrapper }`}>
                <p className={styles.fieldName}>{getDisplayName(field)}</p>
                { Object.keys(field.value).map(nestedField => 
                    <div key={nestedField} className={styles.fieldWrapper}>
                        <p>{capitalizeCamelCaseWords(capitalizeSnakeCaseWords(nestedField)) }</p>
                        <div className={styles.fieldValueWrapper}>{JSON.stringify(field?.value[ nestedField ])}</div>
                    </div>) } 
            </div>
        }else{
            return <div key={field?.field_name} className={styles.fieldWrapper}>
                <p>{getDisplayName(field)}</p>
                <div className={styles.fieldValueWrapper}>{field?.value}</div>
            </div>
        }
    }
    return (
        <div className={styles.popupWrapper}>
            <h1>Field Mappings Preview</h1>
            <div className={styles.fieldsWrapper}>
                <h2>Field Mappings</h2>
                <div className={styles.fieldsContainer}>
                    {fieldContent.map(field=>getHtmlContent(field))}
                </div>
                {customFieldContent.length>0 && <>
                    <h2>Custom Field Mappings</h2>
                    <div className={styles.fieldsContainer}>
                        {customFieldContent.map(field=>getCustomFieldContent(field))}
                    </div>
                </>}
            </div>
            <div className={styles.actionsContainer}>
                <button className={styles.primary} onClick={()=>{onClose()}}>Edit</button>
                <button onClick={onVerifyClick}>Verify</button>
            </div>
        </div>
    )
}

export default FieldMappingsPreveiwPopup
