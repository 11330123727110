import React, { useState } from 'react';
import styles from './configMapper.module.css'
import AppLoader from '../../../appLoader';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import threeplData from '../../../dummyData/ConfigurationMapper/threeplConfig.json'
import webshopData from '../../../dummyData/ConfigurationMapper/webshopConfig.json'
import erpData from '../../../dummyData/ConfigurationMapper/erpConfig.json'
import useConfigMapperField from '../functionality/useConfigMapperFeild.func';
import Accordion from './ConfigFieldAccordian.jsx';
import Spinner from '../../../commonComponents/spinner/Spinner.jsx';
import useConfigMapper from '../functionality/useConfigMapper.func';

const ConfigMapper = () => {
    const requiredServiceFields = [ 'custom_field_mappings', 'real_time_response_nested_field', 'static_query_params', 'collection_name' ];
    const tribeConfigData = {
        '3pl':threeplData,
        webshop:webshopData,
        erp:erpData
    }
    const { addEmptyObjectIfEmptyValue, convertToNested, flattenJSON, updateCustomFieldConfigJson, addCustomMappingEntry, removeByFieldName, setLoading, submitConfigData }  = useConfigMapperField();
    const [ fieldListData, setFieldListData ] = useState([ ...tribeConfigData[ '3pl' ]  ])
    const { data,loading, getConfigData, selectedTribe  }  = useConfigMapper();
    const [ formData, setFormData ]= useState({ ...flattenJSON(data) })

    React.useEffect(()=>{
        getConfigData('3pl')
    }, [])
    
    React.useEffect(()=>{
        const fieldList = [ ...fieldListData ]
        updateCustomFieldConfigJson(fieldList, setFieldListData, data)
        setFormData(flattenJSON(data))
        if(Object.keys(data).length === 0){
            setFormData({ tribe:selectedTribe })
        }
        setFieldListData([ ...fieldList ])
    }, [ data ])
 
    const submitHandler = async (event) => {
        event.preventDefault()
        setLoading(true)
        const formElements = event.target.elements;
        const formSubmitData = {}
        for (const element of formElements) {
            if(element.name.includes('[]')){
                if(formSubmitData[ element.name ]){
                    formSubmitData[ element.name ].push(element.value)    
                }else{
                    formSubmitData[ element.name ] = [ element.value ]    
                }
            }else{
                formSubmitData[ element.name ] = element.value 
            }
        }
        
        for(let fieldData of fieldListData){
            if(fieldData?.field_type === 'radio'){
                formSubmitData[ fieldData?.field_name ] = (typeof formData[ fieldData?.field_name ] === 'boolean')?formData[ fieldData?.field_name ]:(formData[ fieldData?.field_name ] === 'true')
            }
        }

        const finalFormData = { queryParameter:{},accessKeys:{} }
        for(let key in formSubmitData){
            if(key !== ''){
                if(key.includes('input_name[]')){
                    const inputKey = key?.split('#')?.slice(0, -1)?.join('#')
                    const valueKey = inputKey + '#input_value[]'
                    formSubmitData[ key ].forEach((val, i)=>{
                        if(val !== ''){
                            finalFormData[ inputKey+'#'+val ] = formSubmitData[ valueKey ][ i ] 
                        }
                    })
                }else if(!key.includes('input_value[]')){
                    finalFormData[ key ] = formSubmitData[ key ]
                }
            }
            if(key.includes('__array')){
                let oldKey = key
                if(!key.includes('data_accessor')){
                    key = key.replace('__array', '#fields')
                }else{
                    key = key.replace('__array', '')
                }
                finalFormData[ key ] = formSubmitData[ oldKey ]
                delete finalFormData[ oldKey ]
            }
        }
        const nestedFormData = convertToNested(finalFormData)
        const services = nestedFormData?.services;
        if (services) {
            for (const serviceKey in services) {
                const service = services[ serviceKey ];
                addEmptyObjectIfEmptyValue(service, requiredServiceFields)
                service.response_nested_field = (service?.response_nested_field === '')?null:service?.response_nested_field
            }
        }

        console.log(formData, 'stateData')
        console.log(finalFormData, 'FormattedData')
        console.log(nestedFormData, 'formSubmitData')
        console.log(await submitConfigData(nestedFormData))
    }
    const inputChangeHandler = (event) => {
        if(event?.target?.name){
            setFormData(prevState=>{
                let newObj = prevState;
                newObj[ event?.target?.name ] = event?.target?.value
                return newObj
            })
            if(event.target.name === 'tribe'){
                const fieldData = tribeConfigData[ event.target.value ]
                getConfigData(event.target.value)
                setFieldListData([ ...fieldData ])
            }
            if(event.target.name === 'totalCountFromApi'){
                if(event.target.value === 'true'){
                    const countEndpointFieldData = {
                        field_name:'totalCountApiEndpoints',
                        field_type:'object',
                        field_label:'Count API Endpoints',
                        can_add_fields:true,
                        field_children : [ 
                            {
                                field_type:'text',
                                field_name:'products',
                                field_id:'totalCountApiEndpointsCustom'
                            }, 
                            {
                                field_type:'text',
                                field_name:'orders',
                                field_id:'totalCountApiEndpointsCustom'
                            }
                        ]
                    }
                    const updatedFieldList = addCustomMappingEntry([ ...fieldListData ], '',countEndpointFieldData)
                    setFieldListData([ ...updatedFieldList ]) 
                }else{
                    const updatedFieldList = removeByFieldName([ ...fieldListData ], 'totalCountApiEndpoints')
                    setFieldListData([ ...updatedFieldList ]) 
                }

            }
        }
    }
    return <AppLoader>
        <Typography variant='gradient'>Configurations Mapper</Typography>
        {loading
            ? <Spinner height="15vh"/>
            :
            <section className={styles.configMapper}>
                <form onSubmit={submitHandler} className={ styles.configMapperWrapper }>
                    <Accordion data={fieldListData} fieldListData={fieldListData} onInputChange={inputChangeHandler} inputValues={formData} setFieldListData={setFieldListData}/>
                    <div className={styles.formSubmitWrapper}>
                        <input type='submit' value={'Save Changes'}/>
                    </div>
                </form>
            </section>
        }
    </AppLoader>
}

export default ConfigMapper