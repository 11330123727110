import React from 'react';
import styles from './fourStats.module.css';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import Spinner from '../../../../commonComponents/spinner/Spinner.jsx';

const FourStats=({ statsData={}, loading=false, error='' })=>{
    const { stats } = statsData;
    return (<div className={styles.fourStatsContainer}>
        {loading  ?
            <div className={styles.loaderContainer}>
                <Spinner height={'136px'}/></div> :
            error?<Typography variant='error'>{error}</Typography>
                : <>
                    <Typography variant='heading'>{statsData.title}</Typography>
                    <div className={styles.statContainer}>
                        {stats.map((stat, index) => <div key={index} className={styles.stasTile}>
                            <p className={styles.label}>{stat.label}</p>
                            <p className={styles.count}>{stat.count}</p>
                        </div>)}
                    </div>
                </>
        }
    </div>
    )
}

export default FourStats;