import React from 'react';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import styles from './connectorCardII.module.css';
import Modal from '../../../commonComponents/modal/Modal.jsx';
import { useConnectorCardIIFunc } from '../functionality/ConnectorCardII.func';
import connectorData from '../../../dummyData/ConnectorLogo.json'
const ConnectorCardII = ({
    connector,
    handleConnectConnector = () => { }
}) => {

    const { handleSubmit, handleCreateConnectorModal, handleChange, apiConnectors, isOpen, closeModal, data, error, isLoading } = useConnectorCardIIFunc({
        onConnectionSuccess: handleConnectConnector
    });
    const { name, machineName } = connector;

    return (
        <>
            <div className={styles.mainWrapper} onClick={() => handleCreateConnectorModal(connector)}>
                {connectorData.connectorLogo.map((item, index) =>
                    machineName === item.machine_name && <img key={index} src={item.image} alt={name} />
                )}
                <div className={styles.seperator} />
                <div>
                    <Typography className={styles.name} variant='title'>
                        {name}
                    </Typography>
                    {machineName === 'microsoft_dynamics_365' && isLoading && <Typography variant='secondary'>Authenticating</Typography>}
                    {machineName === 'microsoft_dynamics_365' && error.redirect && <Typography variant='error-sm'>{error.redirect}</Typography>}

                </div>

            </div>
            {isOpen && <Modal loading={isLoading} isOpen={isOpen} modalFieldData={data[ machineName ] || data.apiConnector} error={error} value={apiConnectors} handleChange={handleChange} onSubmit={handleSubmit} onClose={closeModal} />}
        </>
    )
};

export default ConnectorCardII;
