import Typography from '../../../commonComponents/typography/Typography.jsx'
import { capitalizeSnakeCaseWords } from '../../../helpers'
import styles from './WorkflowQueuePopup.module.css'
import { QueueGrid } from './QueueGrid.jsx'
export const WorkflowQueuePopup = ({ data }) => {
    console.log(data, 'datamodalkhahd')
    const queuesData = data?.task_list_data?.task_list || {}
    const { source, destination, task_handle, flow_name, logic, operation } = data.task_data
    const flowName = flow_name?.split(' - ').map(val=>capitalizeSnakeCaseWords(val)).join(' - ')
    return <div className={styles.workflowQueuePopupContainer}>
        {/* Task Details */}
        <Typography variant='title'>Task Details:</Typography>
        <div className={styles.content}>
            <p>Flow Name: {flowName }</p>
            <p>Source: {capitalizeSnakeCaseWords(source) }</p>
            <p>Destination: {capitalizeSnakeCaseWords(destination)}</p>
            <p>Task Handle: {capitalizeSnakeCaseWords(task_handle)}</p>
            <p>Logic: {capitalizeSnakeCaseWords(logic)}</p>
            <p>Operation: {capitalizeSnakeCaseWords(operation)}</p>
        </div>
        {/* Task Details End */}
        {
            (Object.keys(queuesData).length === 0) ? <Typography variant={'error'} customStyles={{ textAlign:'center', fontSize:'20px' }}>No Data Found</Typography>
                : Object.keys(queuesData).map(key => {
                    return <QueueGrid key={key} label={key} data={queuesData[ key ]} />
                })
        }
        {}
    </div>
}