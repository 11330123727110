import React, { useEffect } from 'react'
import styles from './loggerPopup.module.css';
import LucideIcon from '../lucideicon/LucideIcon.jsx';

function LoggerPopup({ isOpen, onClose, height, width, data }) {
    const { row } = data;
    console.log(row, 'row')
    useEffect(() => {
    // Add or remove the noScroll class
        if (isOpen) {
            document.body.classList.add(styles.noScroll);
        } else {
            document.body.classList.remove(styles.noScroll);
        }

        // Cleanup function to remove the class
        return () => {
            document.body.classList.remove(styles.noScroll);
        };
    }, [ isOpen ]);

    const handleOutsideModalClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose(e);
        }
    }

    if (!isOpen) return null;
    const overlayStyles={};
    height?overlayStyles.height=height:null;
    width?overlayStyles.width=width:null;
    return (
        <div className={styles.modalOverlayWrapper} onClick={handleOutsideModalClick}>
            <div className={styles.modalOverlay} style={overlayStyles}>
                <div className={styles.header}>
                    <div className={styles.title}>
                        <p>App: <span>{row?.app}</span></p>
                    </div>
                </div>
                <div className={styles.modal} >
                    <div className={styles.headerWrapper}>
                        <div>
                            <div className={styles.iconWrapper}>
                                <LucideIcon name={'MailCheck'} className={styles.icon} color={'white'}/>
                            </div>
                        </div>
                        <div className={styles.titleWrapper}>
                            <div className={styles.title}>
                                <div className={styles.message}>{row.message}</div>
                                <div>Severity: <span>{row.severity}</span></div>
                                <div>Timestamp: <span> {row.timestamp}</span></div>
                            </div>
                            <div className={styles.time}>{new Date().toLocaleString()}</div>
                        </div>
                    </div>
                    <div className={styles.contentWrapper}>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoggerPopup;