import { useState } from 'react'

export const useIntegrationCardFunc = () => {
    const [ statuses, setStatuses ] = useState({
        pipeStatus: false,
        consoleLogs: true
    });
    const [ scheduleCount, setScheduleCount ] = useState(1);
    const [ isAutoPilot, setIsAutoPilot ] = useState(false);

    const toggleAutoPilot = () => {
        setIsAutoPilot(!isAutoPilot)
    }

    const handleToggleStatus = (statusName) => setStatuses({ ...statuses, [ statusName ]: !statuses?.[ statusName ] })

    const addScheduleCount = () => setScheduleCount(scheduleCount + 1);

    return {
        statuses,
        scheduleCount,
        isAutoPilot,
        handleToggleStatus,
        addScheduleCount,
        toggleAutoPilot
    }
}