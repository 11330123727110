import { useEffect, useState } from 'react';
import useCustomAxiosCallTwo from '../../../../hooks/useCustomAxiosCallTwo';

const useInboundShipment = (shipmentId) => {
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState([]);
    const [ error, setError ] = useState('');
    const [ headersData, setHeadersData ] = useState([]);
    const { callApi } = useCustomAxiosCallTwo();
    const getInboundShipment = async () => {
        setError('');
        const response = await callApi({
            uriEndPoint: {
                url: `/get-data-id/inboundshipment/${ shipmentId }`, service: 'collectivedata'
            }
        });
        if (response && response?.status === 200 && !response?.data?.error) {
            setData( response?.data?.data || [])
            setHeadersData(response?.data?.header_data || [])
        } else {
            setError(response?.data?.error || 'Something Went Wrong... Please try again later.')
            setLoading(false)
        }
        setLoading(false);
    }
    useEffect(() => {
        getInboundShipment();
    }, []);

    return { data, loading, headersData, error }
}
export default useInboundShipment;
