import styles from './deliveryStat.module.css';

const DeliveryStat = ({ id='', deliveryData = [], avgDelivery = {} }) => {
    return <div id={id} className={styles.container}>
        <div className={styles.stat1}>
            {
                deliveryData.map(({ id, stat_name, count }, index) => (
                    <div key={id}>
                        <p className={styles.heading}>{stat_name}</p>
                        <p className={`styles.heading ${ index === 0 ? styles.danger : '' }`}>{count}</p>
                    </div>
                ))
            }
        </div>
        <div className={styles.stat2}>
            <p className={styles.heading}>{avgDelivery?.stat_name}</p>
            <p className={`styles.heading ${ styles.success }`}>{avgDelivery?.value}</p>
        </div>
    </div>
}
export default DeliveryStat;