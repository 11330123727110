import { useEffect, useState } from 'react';
import { selectOrganisationData } from '../../../../redux/organisation/Selector';
import { useSelector } from 'react-redux';
import useCustomAxiosCallTwo from '../../../../hooks/useCustomAxiosCallTwo';

export const useInventoryFunc= () => {
    const [ connectedConnectors, setConnectedConnectors ] = useState([]);
    const organisation = useSelector(selectOrganisationData);
    const { callApi } = useCustomAxiosCallTwo();
    const orgId = organisation?.organisationData?.id;

    const getConnectedConnectors = async () => {
        const response = await callApi({
            uriEndPoint: {
                url: `/organization/${ orgId }/connector`
            }
        });
        if (response?.status === 200) {
            setConnectedConnectors(response?.data?.data || [])
        }
    };
    useEffect(() => {
        getConnectedConnectors();
    }, [])

    return {
        connectedConnectors,
        getConnectedConnectors
    };
}