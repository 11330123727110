import React, { useEffect, useRef, useState } from 'react';
import 'vis-timeline/styles/vis-timeline-graph2d.css';
import { DataSet, Timeline } from 'vis-timeline/standalone';
import styles from './visTimeline.module.css';

const VisTimeline = ({ timeLineData, timeLineHeader }) => {
    const timelineRef = useRef(null);
    const popupRef = useRef(null);
    const [ selectedItem, setSelectedItem ] = useState(null);
    const [ popupPosition, setPopupPosition ] = useState({ top: 0, left: 0 }); // For popup position

    useEffect(() => {
        const items = new DataSet(timeLineData);

        const options = {
        };

        const timeline = new Timeline(timelineRef.current, items, options);

        timeline.on('select', (properties) => {
            if (properties.items.length) {
                const selectedId = properties.items[ 0 ];
                const selected = items.get(selectedId);
                setSelectedItem(selected);

                const rect = timelineRef.current.getBoundingClientRect();
                setPopupPosition({
                    top: properties.event.clientY+20 - rect.top,
                    left: properties.event.clientX - rect.left,
                });
            }
        });

        return () => {
            timeline.destroy();
        };
    }, [ timeLineData ]);

    const handleOutsideClick = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setSelectedItem(null);
        }
    };

    useEffect(() => {
        if (selectedItem) {
            document.addEventListener('mousedown', handleOutsideClick);
            window.addEventListener('scroll', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            window.removeEventListener('scroll', handleOutsideClick);
        };
    }, [ selectedItem ]);

    return (
        <div style={{ position: 'relative' }}>
            <div ref={timelineRef} className={styles.timeLine} style={{ height: '100%', minHeight: '200px' }}></div>
            {selectedItem && (
                <div ref={popupRef} className={styles.popup}
                    style={{
                        top: popupPosition.top + 10,
                        left: popupPosition.left + 10,
                    }}
                >
                    <div className={styles.popupContent}>
                        {timeLineHeader.map((item)=>
                            <p key={item} className={styles.popupParagraph}>{item?.name}:  {selectedItem[ item.value ]}</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default VisTimeline;