import React from 'react'
import AppLoader from '../../appLoader'
import styles from './accountSettings.module.css'

function AccountSettings() {

    return (
        <AppLoader>
            <div className={styles.mainWrapper}>
                No data found
            </div>
        </AppLoader>
    )
}

export default AccountSettings