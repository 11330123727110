import React from 'react'
import AppLoader from '../../appLoader'
import styles from './payments.module.css'

function Payments() {

    return (
        <AppLoader>
            <div className={styles.mainWrapper}>
                No data found
            </div>
        </AppLoader>
    )
}

export default Payments