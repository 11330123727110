import React from 'react'
import styles from './CustomInputArrayV2.module.css'
import LucideIcon from '../../../../commonComponents/lucideicon/LucideIcon.jsx'
import ArrayChildrenFields from './ArrayChildrenFields.jsx'
import { v4 as uuid } from 'uuid';

const CustomInputArrayV2 = ({ fieldData={}, parentField='' }) => {
    // state
    const [ childrenFields, setChildrenFields ] = React.useState(fieldData?.field_children || [])
    const [ inputData, setInputData ] = React.useState({
        field_name:fieldData?.field_name || '',
        data_accessor:fieldData?.data_accessor || ''
    })

    // Event Handlers
    const addFieldHandler = () => {
        let newFieldConent={
            field_type:'custom_array_children',
            field_id:uuid()
        }
        fieldData.field_children = fieldData?.field_children || [] 
        fieldData?.field_children.push(newFieldConent)
        setChildrenFields(prev=>{
            return [ ...prev, newFieldConent ]
        })
    }

    const inputChangeHandler = (e) => {
        const { name, value } = e.target
        setInputData(prev=>{
            if(name === 'input_field_name[]'){
                fieldData.field_name = value
                return { ...prev, field_name:value }
            }
            if(name === `${ parentField }#${ fieldData.field_name }__array#data_accessor`){
                fieldData.data_accessor = value
                return { ...prev, data_accessor:value }
            }
        })
    }

    const childrenInputChangeHandler = (chilrenInputData) => {
        if(chilrenInputData?.name !== '' && chilrenInputData?.value !== ''){
            // setInputData(prev=>{
            //     return {
            //         ...prev, fields:{
            //             ...prev.fields, 
            //             [ chilrenInputData?.name ]:chilrenInputData?.value
            //         }
            //     }
            // })
        }
    }

    return (
        <div className={styles.customArrayWrapper}>
            <div className={styles.inputWrapper}>
                <div className={styles.labelWrapper}>
                    Field Name
                </div>
                <input 
                    name={'input_field_name[]'} 
                    value={inputData?.field_name} 
                    onChange={inputChangeHandler}
                    id={fieldData?.field_id+'#field_name'}
                />
            </div>
            <div className={styles.inputWrapper}>
                <div className={styles.labelWrapper}>
                    Data Accessor
                </div>
                <input 
                    name={`${ parentField }#${ fieldData.field_name }__array#data_accessor`} 
                    value={inputData?.data_accessor} 
                    id={fieldData?.field_id+'#data_accessor'}
                    onChange={(e)=>{inputChangeHandler(e)}}/>
            </div>
            <div className={styles.inputWrapper}>
                <div className={styles.labelWrapper}>
                    Fields <LucideIcon name={'Plus'} onClick={addFieldHandler}/>
                </div>
            </div>
            <div className={styles.childrenFieldsWrapper}>
                {childrenFields.map((childData)=> 
                    <ArrayChildrenFields 
                        key={childData?.field_id} 
                        onInputChange={childrenInputChangeHandler} 
                        fieldData={childData} 
                        parentFieldName={parentField}
                        parentFieldData={fieldData}
                    /> 
                )}
            </div>
        </div>
    )
}

export default CustomInputArrayV2
