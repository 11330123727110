import { useEffect, useState } from 'react';
import useCustomAxiosCall from '../../../../hooks/useCustomAxiosCall';

const useStatCounter = (uriEndPoint = {}) => {
    const [ widgetError, setError ] = useState();
    const [ widgetLoading, setWidgetLoading ] = useState(false);
    const [ ordersStats, setOrdersStats ] = useState({});

    const { callApi } = useCustomAxiosCall();
    const getData = async () => {
        setWidgetLoading(true);
        const response = await callApi({ uriEndPoint });
        if (response && response?.status === 200 && !response?.data?.error) {
            setOrdersStats(response.data);
            setWidgetLoading(false);
        } else {
            setError(response?.data?.error || 'Something Went Wrong. Please Try Again Later');
            setWidgetLoading(false);
        }
    }

    useEffect(() => {
        if (uriEndPoint.url) {
            getData();
        }
    }, []);
    return { widgetLoading, ordersStats, widgetError };
}
export default useStatCounter;