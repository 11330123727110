import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const Dashboard = () => {
    const [ messages ] = useState([]);
    const { dashboardId } = useParams();

    return (
        <div>
            <h2>Dashboard - Topic: {dashboardId}</h2>
            <div>
                <h3>Messages:</h3>
                {messages.length > 0 ? (
                    <ul>
                        {messages.map((msg, index) => (
                            <li key={index}>{JSON.stringify(msg)}</li>
                        ))}
                    </ul>
                ) : (
                    <p>No messages received yet.</p>
                )}
            </div>
        </div>
    );
};

export default Dashboard;
