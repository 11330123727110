import React from 'react';
import AppLoader from '../../../../appLoader';
import Spinner from '../../../../commonComponents/spinner/Spinner.jsx';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import styles from './CollectiveDataDetail.module.css';
import { useParams } from 'react-router-dom';
import useCollectiveDataDetail from '../functionality/CollectiveDataDetail.func';
import ListItems from '../../../../commonComponents/listItems/ListItems.jsx';
import { capitalizeSnakeCaseWords, formatValue } from '../../../../helpers';

const CollectiveDataDetail = ()=>{
    const { shipmentNumber } = useParams();
    const { data } = useCollectiveDataDetail(shipmentNumber);

    const urlData={
        baseUrl:'/total-items',
        filterUrl:'/filter-item',
        dataValue:shipmentNumber,
        service: 'collectivedata'
    }

    const filterValues = [
        { name: 'Matched', value: 'matched' },
        { name: 'Unmatched', value: 'missmatched' },
        { name: 'All Items', value: '' },
        { name: 'Ship Notice Items', value: 'shipnotice' },
        { name: 'Shipment Items', value: 'inboundshipment' },
    ];

    let displayData = data?.data?.[ 0 ] || data?.data;
    const listItemsOptions = {
        mappings: {
            title: 'item.title',
            vendor: 'vendor_id',
            item:'item.sku',
            lot: 'lot.id',
            sku:'item.sku',
        }
    }
    return <AppLoader>
        <div className={styles.mainWrapper}>
            {data.loading
                ? <Spinner />
                : data.error ? <Typography variant='error'>{data.error}</Typography> : (
                    <>
                        {/* <Typography variant='heading'>Inbound Shipment </Typography> */}
                        { <div className={styles.card}>
                            <div className={styles.cardHeader}>
                            </div>
                            <div className={styles.cardBody}>
                                {data?.header?.map((header, index)=><div className={styles.tabRow} key={index}><p style={{    width: '15rem' }}>{ capitalizeSnakeCaseWords(header) } :</p> <strong>{ formatValue(displayData?.[ header ]) }</strong></div>)}
                            </div>
                        </div> }
                        <ListItems urlData={urlData} filterStatus={true} filterValues={filterValues} options={listItemsOptions} view='collectiveDetail' />
                    </>
                )}
        </div>
    </AppLoader>
}

export default CollectiveDataDetail