import React, { useEffect, useRef, useState } from 'react';
import styles from './navigatePopup.module.css';
import Button from '../button/Button.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { setActionId, setVisitedPage, setHideModal } from '../../redux/visitor/Slice';
import data from '../../dummyData/WalkThrough.json';
import { useLocation, useNavigate } from 'react-router-dom';  // Updated import
import steps from '../../dummyData/NavigateStep.json';
import LucideIcon from '../lucideicon/LucideIcon.jsx';
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

const ARROW_DIRECTION_UP = 'up';
const ARROW_DIRECTION_DOWN = 'down';
const ARROW_POSITION_DEFAULT = 220;
const MODAL_WIDTH = 450;
const ARROW_OFFSET = 190;
const BOTTOM_OFFSET= 50;

const NavigatePopup = ({ isOpen, onClose, setOpenTooltip, openTooltip }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [ stepIndex, setStepIndex ] = useState(0);
    const [ scrolled,setScrolled ]=useState(false);
    const [ position, setPosition ] = useState({
        top: '',
        left: '',
        width: '',
        arrowPositionLeft: '',
        arrowDirection: ARROW_DIRECTION_UP
    });
    const dispatch = useDispatch();
    const popupRef = useRef(null);
    const visitedPage = useSelector((state) => state.tutorial.visitedPage);
    const isModalColor=true;
    useEffect(() => {
        if (!visitedPage.includes(pathname.replace('/', ''))) {
            dispatch(setVisitedPage(pathname.replace('/', '')));
        }
        if(!openTooltip && isOpen && visited){
            setOpenTooltip(true)
        }

    }, [ pathname, isOpen ]);
    useEffect(() => {
        if (typeof document !== 'undefined' && isOpen!=='welcome') {
            const element = document.querySelector(`#${ steps?.[ pathname?.replace('/', '') ]?.[ stepIndex ] }`)
            const positions = element?.getBoundingClientRect();
            if(element){
                if(elementOutOfViewport(positions)){
                    element?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    })
                    setTimeout(()=>{
                        setScrolled(true)
                    },500)
                }
                else{
                    setElementPosition();
                }
            }
        }
        else{
            setPosition({
                top: '50%',
                left: '50%',
                width: '',
                arrowPositionLeft: '',
                arrowDirection: ARROW_DIRECTION_UP
            });
        }
    }, [  stepIndex, isOpen ])

    useEffect(()=>{
        if(scrolled){
            setElementPosition();
            setScrolled(false)
        }
    },[ scrolled ])

    const elementOutOfViewport=(elemPos)=>{
        const deviceHeight = window.innerHeight;
        if(elemPos?.top <0){
            return true;
        }

        if((elemPos?.top + BOTTOM_OFFSET + (popupRef?.current?.clientHeight || 0)) > deviceHeight){
            return true
        }
        return false
    }

    const setElementPosition = async () => {
        if (typeof document !== 'undefined') {
            const element = document.querySelector(`#${ steps?.[ pathname?.replace('/', '') ]?.[ stepIndex ] }`);
            if (element) {
                const positions = element?.getBoundingClientRect();
                let leftPos = Math.abs(positions.left + (positions.width / 2) - ARROW_OFFSET);
                let arrowLeft = ARROW_POSITION_DEFAULT;

                if ((leftPos + MODAL_WIDTH) > document.documentElement.clientWidth) {
                    leftPos = document.documentElement.clientWidth - (MODAL_WIDTH + 40);
                    arrowLeft = positions.left - leftPos + (positions.width / 2);
                } else if (leftPos < 0) {
                    leftPos = 0;
                    arrowLeft = positions.left + (positions.width / 2);
                }

                let topPos = positions.top;
                let arrowDirection = ARROW_DIRECTION_UP;

                if (elementOutOfViewport(positions)) {
                    topPos = positions.top - (popupRef.current?.clientHeight || 0);
                    arrowDirection = ARROW_DIRECTION_DOWN;
                }
                else {
                    topPos = element.clientHeight + positions.top;

                }
                setPosition({
                    top: topPos,
                    left: leftPos,
                    width: positions.width,
                    arrowPositionLeft: arrowLeft,
                    arrowDirection
                });
            }
        }
    }

    const handleClose = () => {
        onClose();
        document.body.classList.add(styles.scroll);
        dispatch(setActionId(''))
    };

    const lastElement = stepIndex === steps?.[ pathname?.replace('/', '') ]?.length - 1 || false;
    const handleNext = () => {
        if(lastElement){
            const dataValue=Object.keys(data);
            const elementIndex=dataValue.indexOf(pathname?.replace('/', ''))
            if(pathname?.replace('/', '')==='organisation'){
                const ele = document.querySelector('#organisationListing');
                if (ele) {
                    const children = ele.childNodes;
                    if (children.length > 0) {
                        children[ 0 ].click();
                    }
                }

            }
            else if(isOpen==='logs'){
                dispatch(setActionId(steps?.[ pathname?.replace('/', '') ]?.[ stepIndex ]))
            }
            else{
                navigate(`/${ dataValue[ elementIndex+1 ] }`)
            }
        }
        else if ( isOpen!=='welcome' && stepIndex < steps[ pathname.replace('/', '') ].length - 1) {
            const nextIndex = stepIndex + 1;
            setStepIndex(nextIndex);
            dispatch(setActionId(steps?.[ pathname?.replace('/', '') ]?.[ nextIndex ]));
        }
        else{
            dispatch(setActionId(steps?.[ pathname?.replace('/', '') ]?.[ stepIndex ]))
        }
    };

    const handleBack = () => {
        if (stepIndex > 0) {
            const prevIndex = stepIndex - 1;
            setStepIndex(prevIndex);
            dispatch(setActionId(steps?.[ pathname?.replace('/', '') ]?.[ prevIndex ]));
        }
    };
    const sliceData=visitedPage.slice(0, visitedPage.length-1);
    const visited = sliceData.includes(pathname.replace('/', '')) || false;

    const handleModal=()=>{
        handleClose()
        dispatch(setHideModal(true));
    }
    useEffect(() => {
    // Add or remove the noScroll class
        if (isOpen && !visited) {
            document.body.classList.add(styles.noScroll);
        } else {
            document.body.classList.remove(styles.noScroll);
        }

        // Cleanup function to remove the class
        return () => {
            document.body.classList.remove(styles.noScroll);
        };
    }, [ isOpen, visited ]);

    if (!isOpen) return null;
    const isCentered = isOpen === 'welcome' || isOpen === 'end';
    const modalStyles = {
        top: isCentered ? '50%' : position.top,
        left: isCentered ? '50%' : position.left,
        transform: isCentered ? 'translate(-50%, -50%)' : 'none',
        width: isCentered && '700px'
    };
    return (
        !visited &&
        <div className={styles.modalOverlayWrapper}>
            <div ref={popupRef} className={`${ styles.modalOverlay } ${ isModalColor && styles.bg_color }`} style={modalStyles}>
                {!isCentered && <div
                    className={`${ styles.arrow }  ${ position.arrowDirection === ARROW_DIRECTION_DOWN ?
                        `${ styles.downArrow } ${ isModalColor && styles.borderTop }` :
                        `${ styles.upArrow } ${ isModalColor && styles.borderBottom }`
                    } `}
                    style={{ left: position.arrowPositionLeft }}
                ></div>}
                <div className={`${ styles.toggleSidebar } ${ isModalColor && styles.text_color }` }>
                    <LucideIcon
                        name="X"
                        onClick={handleClose}
                        size="20"
                    />
                </div>
                <div className={styles.modal}>
                    <div className={`${ styles.mainContent } ${ isModalColor && styles.text_color }`}>
                        <Markdown
                            remarkPlugins={[ remarkGfm ]}
                            rehypePlugins={[ rehypeRaw ]}
                        >{(isOpen==='welcome'? data?.[ isOpen ]?.[ isOpen ]?.title : data?.[ pathname?.replace('/', '') ]?.[ isOpen ]?.title)}</Markdown>

                    </div>
                    <div className={styles.buttonWrapper}>
                        {isOpen === 'end'  ? (
                            <Button className={`${ styles.button } ${ isModalColor && styles.buttonColorChange }`} onClick={handleClose}>
                                Book a Call
                            </Button>
                        ) : (
                            <>
                                <Button className={`${ styles.button } ${ isModalColor && styles.buttonColorChange }`} onClick={isOpen === 'welcome' ?handleModal : handleBack } disabled={isOpen !== 'welcome' && stepIndex === 0}>
                                    {isOpen === 'welcome' ? 'Skip': 'Back'}
                                </Button>
                                <Button className={`${ styles.button } ${ isModalColor && styles.buttonColorChange }`} onClick={handleNext}>
                                    {isOpen === 'welcome' ? 'Start Tour' : 'Next'}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavigatePopup;
