import styles from './inboundShipments.module.css';
import React from 'react';
import AppLoader from '../../../../appLoader';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import Table from '../../../../commonComponents/table/Table.jsx';
import useTables from '../../../../commonComponents/table/useTables.func';
import { Link } from 'react-router-dom';
const InboundShipments = () => {
    const { data,
        columns: initialColumns,
        loading, error,
        currentPage,
        setCurrentPage,totalRecords,
        setPageSize  } = useTables({ url:'/inbound_shipments',service:'inbound-shipment' });

    const updatedColumns = initialColumns.map((column) => {
        if (column.field === 'shipment_id') {
            return {
                ...column,
                renderCell: (params) => (
                    <Link to={`/inbound-shipment/${ params.row.shipment_id }`}>
                        {params.row.shipment_id}
                    </Link>
                )
            };
        }
        return column;
    });
    return (
        <AppLoader>
            <div className={styles.adminDashboard}>
                <div className={styles.mainWrapper}>
                    <div className={styles.header}>
                        <Typography variant='heading'>Inbound Shipments</Typography>
                    </div>
                    {(!loading && error) ?
                        <div className={styles.heading}><Typography variant='error'>{error}</Typography></div>:
                        <Table
                            loading={loading}
                            headerList={updatedColumns.length > 1 ? updatedColumns : [] }
                            totalRecords={totalRecords}
                            setPageSize={setPageSize}
                            rowsData={data}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    }
                </div>
            </div>
        </AppLoader>
    )
};

export default InboundShipments;