import React from 'react';
import styles from './ConnectorCard.module.css';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import { Ellipsis, Pencil } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import connectorData from '../../../dummyData/ConnectorLogo.json';
import { formatDate } from '../../../helpers/index';
import { Tooltip } from '@mui/material';

const ConnectorCard = ({
    connector
}) => {
    const {
        name = '',
        machine_name = '',
        updated_at = '',
        mapping_verified = false
    } = connector;
    let displayName;
    switch (machine_name) {
        case 'microsoft_dynamics_365': displayName ='Dynamics 365'; break;
        case 'magento': displayName ='Magento'; break;
        case 'shopify': displayName ='Shopify'; break;
        case 'netsuite': displayName ='Netsuite'; break;
        case 'whiplash': displayName ='Whiplash'; break;
        case 'tiktok': displayName ='Tiktok'; break;
        default: displayName=name
            break;
    }

    const navigate = useNavigate();
    const handleEditConfig = (name, disabled=true) => {
        console.log(disabled, 'check routing config mapper')
        if(disabled) return;
        if (!name || !connectorData?.connectorLogo) return;
        const formattedName = name.toLowerCase().replace(/\s+/g, '_');
        const connectorConfigImage = connectorData.connectorLogo.find((data) =>
            data.machine_name.includes(formattedName)
        );
        const connectorConfig = connector?.machine_name?.includes(formattedName) ? { ...connector } : null;
        if (connectorConfig) {
            connectorConfig[ 'image' ] = connectorConfigImage?.image;
        }
        if (connectorConfig) {
            navigate('/configuration', { state: { connectorConfig } });
        }
    };
    const handleShipment = () => {
        navigate('/map-configurations')
    }
    return (
        <div id="connector" className={styles.connectorCard}>
            <div className={styles.headerContainer}>
                <div className={styles.titleContainer}>
                    {connectorData.connectorLogo.map((item) =>
                        machine_name === item.machine_name && <img key={item.machine_name} className={styles.logoImage} src={item.image} alt={machine_name} />
                    )}
                    <div>
                        <Typography className={styles.title} variant='title'>{displayName}</Typography>
                        <Typography className={styles.subTitle}>{displayName}</Typography>
                    </div>
                </div>
                <div className={styles.dateTimeContainer}>
                    <Ellipsis className={styles.menuIcon} />
                    <Typography variant='secondary'>{formatDate(updated_at)}</Typography>
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <button onClick={() => handleShipment(!mapping_verified)} className={styles.createDashboardBtn}>
                    <Pencil />
                    Map Configurations
                </button>
                
                <Tooltip title={mapping_verified ? '' : 'Please map fields to edit configurations'}>
                    <button
                        onClick={() => handleEditConfig(machine_name, !mapping_verified)}
                        className={styles.editConfigBtn}
                        disabled={!mapping_verified}
                    >
                        <Pencil />
                        Edit Configuration
                    </button>
                </Tooltip>
            </div>
        </div>
    )
};

export default ConnectorCard;