import { useEffect, useState } from 'react';
import useCustomAxiosCallTwo from '../../../hooks/useCustomAxiosCallTwo';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectOrganisationData } from '../../../redux/organisation/Selector';
import { capitalizeCamelCaseWords } from '../../../helpers';
import { toast } from 'react-toastify';

export const useConfigWriter = (params) => {
    const organisation = useSelector(selectOrganisationData);
    const orgId = organisation?.organisationData?.id;
    const navigate = useNavigate();
    const { callApi } = useCustomAxiosCallTwo();
    const [ ekyamStandard, setEkyamStandard ] = useState();
    const [ configWriterLoading, setConfigWriterLoading ] = useState(false);

    const handleWorkFlow = async () => {
        const payload = {
            'position': params?.position,
            'flows': params?.flows
        }
        const response = await callApi({
            uriEndPoint: {
                url: `/update-workflow/${ params.id }`,
                service: 'workflow',
                method: 'PUT'
            },
            body: { 'data': payload },
        });
        if (response) {
            navigate(`/work-flow/${ params.id }`, {
                state: {
                    workflowId: params.id,
                    organisation_id: orgId
                }
            });
        }
    }

    const handleSubmit = async (value, params) => {
        setConfigWriterLoading(true);
        const payload = {
            data: {
                'tribe': params.tribe,
                'base_url': params.base_url,
                'service': params.endpoints,
                'services': {
                    [ params.endpoints ]: {
                        'field_mappings': value,
                        'custom_field_mappings': {},
                        'end_url': '/graphql.json',
                        'collection_name': capitalizeCamelCaseWords(params?.tribe).concat(capitalizeCamelCaseWords(params?.endpoints))
                    }
                },
                'system': params.system,
                'accessKeys': [
                    params.accessKeys
                ]
            }
        }

        const response = await callApi({
            uriEndPoint: {
                url: '/writer-config-mapper',
                method: 'POST',
                service: 'config'
            },
            body: payload
        });
        if (response?.status === 200) {
            toast.success(response?.data?.msg, { position: 'top-right' })
            setConfigWriterLoading(false)
            handleWorkFlow();
        } else {
            toast.error('Something Went Wrong', { position: 'top-right' })
            setConfigWriterLoading(false)

        }
    };

    const getEkyamStardard = async () => {
        const parameter = {
            service: params?.sourceData?.service,
            system: params?.sourceData?.system,
            tribe: params?.sourceData?.tribe
        }
        const response = await callApi({
            uriEndPoint: {
                url: '/get-ekyam-standard',
                service: 'config',
                params: parameter
            },
        });
        if (response?.status === 200) {
            setEkyamStandard(response?.data?.data)
        }
        else {
            toast.error('Something Went Wrong', { position: 'top-right' })
        }
    }
    useEffect(() => {
        getEkyamStardard()
    }, [])

    return { configWriterLoading, ekyamStandard, handleSubmit };

}