import React, { useState } from 'react';
import styles from './toggleSwitch.module.css'
const ToggleSwitch = ({ onChange, value, onClick=()=>{} }) => {
    const [ isOn, setIsOn ] = useState(value);
    const toggleSwitch = () => {
        const newState = !isOn;
        setIsOn(newState);
        if (onChange) {
            onChange(newState);
        }
    }

    return (
        <div onClick={onClick}>
            <div className={styles.switch} onClick={toggleSwitch}>
                <div className={`${ styles.slider }  ${ isOn ? styles.on : styles.off }`}></div>
            </div>
        </div>
    )
}

export default ToggleSwitch