import React, { useEffect, useState } from 'react'
import styles from './stopwatch.module.css'
const Stopwatch=({ time })=> {
    const [ timeDifference, setTimeDifference ] = useState(0);

    useEffect(() => {
        const calculateTimeDifference=()=> {
            const shippedDate = new Date(time);
            const currentDate = new Date();
            const difference = shippedDate - currentDate;
            setTimeDifference(difference);
        }
        calculateTimeDifference();
        const interval = setInterval(() => {
            calculateTimeDifference()
        }, 1000);
        return () => clearInterval(interval);
    }, [ time ]);
    const formatTime = (difference) => {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        let formattedTime = '';
        if (days > 0) {
            formattedTime += `${ days }d:`;
        }
        if (hours > 0 || days > 0) {
            formattedTime += `${ hours }hr:`;
        }
        if (minutes > 0 || hours > 0 || days > 0) {
            formattedTime += `${ minutes }min:`;
        }
        if (seconds > 0 || minutes > 0 || hours > 0 || days > 0) {
            formattedTime += `${ seconds }sec`;
        }
        else{
            formattedTime = '00:00:00';
        }
        return formattedTime;
    };
    return (
        <div className={styles.time}>{formatTime(timeDifference)}</div>
    )
}

export default Stopwatch