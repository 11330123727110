import React, { useEffect, useState } from 'react';
// import useCustomAxiosCall from '../../hooks/useCustomAxiosCall';
import { flattenNestedData, getColumns } from '../../commonComponents/table/useTables.func';
import useCustomAxiosCallTwo from '../../hooks/useCustomAxiosCallTwo';

const useShipmntNoticeTable = (uriEndPoint = {},mappings = {}, header=[], rowData=[], hiddenColumns=[]) => {

    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ error, setError ] = useState('');
    const { callApi } = useCustomAxiosCallTwo();
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ pageSize, setPageSize ] = useState(10)
    const [ totalRecords, setTotalRecords ] = useState(0);
    const [ headerData, setHeaderData ] = useState([]);
    let flattenedData = [], columns = [];

    const getTableData = async () => {
        const newUrl = `${ uriEndPoint.url }?page=${ currentPage + 1 }&per_page=${ pageSize }`
        setLoading(true);

        const response = await callApi({
            uriEndPoint: {
                ...uriEndPoint,
                url: newUrl
            }
        });

        if (response && response.status === 200) {
            setData(response?.data?.data || [])
            setHeaderData(response?.data?.header_data || [])
            setTotalRecords(response?.data?.total_count ? response?.data?.total_count:response?.data?.data?.total_count || 0)
            setLoading(false);
        } else {
            setError('Something Went Wrong. Please Try Again Later');
            setLoading(false);
        }
    }

    columns = React.useMemo(() => getColumns(headerData, header, hiddenColumns), [ headerData, header ])
    flattenedData = React.useMemo(() => { const flattenedData = flattenNestedData(rowData, data, columns, mappings, setLoading); return flattenedData }, [ data ])
    useEffect(() => {
        if(uriEndPoint?.url){
            getTableData();
        }
    }, [ currentPage ]);

    return { columns, data: flattenedData, loading, error, currentPage, setPageSize, totalRecords, setCurrentPage }
}

export default useShipmntNoticeTable;