import { toast } from 'react-toastify';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import React from 'react';
// import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func';

const useFormStep2 = () => {
    const { callApi } = useCustomAxiosCall();
    // const [ error, setError ] = React.useState({})
    const [ fieldsPreviewData, setFieldsPreviewData ] = React.useState()
    const [ validationLoading, setValidationLoading ] = React.useState(false)
    // const { getValidatedFields, validateInputHandler } = useConfigMapperFieldV2();
    const verifyFieldMappings = async (formSubmitData, setOpen) => {
        setValidationLoading(true)
        const response = await callApi({
            uriEndPoint: {
                url: '/fields-preview',
                method: 'POST',
                service:'config-mapper'
            },
            body: { data:formSubmitData }
        });
        if(response?.status !== 200 || response?.data?.error){
            toast.error(response?.error || 'Could not fetch data', { position:'top-right' })
            setValidationLoading(false)
            return
        }
        setFieldsPreviewData(response?.data)
        setValidationLoading(false)
        setOpen(true)
        // Open modal
        return response
    }

    const prepareFormData = (formElements, fieldData, formValues) => {
        let formSubmitData = {}
        
        for (const element of formElements) {
            if(element.name.includes('[]')){
                if(formSubmitData[ element.name ]){
                    formSubmitData[ element.name ].push(element.value)    
                }else{
                    formSubmitData[ element.name ] = [ element.value ]    
                }
            }else{
                formSubmitData[ element.name ] = element.value 
            }
        }
        for(let fieldGroup of fieldData){
            for (const field of fieldGroup.data) {
                if(field?.field_type === 'radio'){
                    formSubmitData[ field?.field_name ] = (typeof formValues[ field?.field_name ] === 'boolean')?formValues[ field?.field_name ]:(formValues[ field?.field_name ] === 'true')
                }
            }
        }
        const finalFormData = {}
        for(let key in formSubmitData){
            if(key !== ''){
                if(key.includes('input_name[]')){
                    const inputKey = key?.split('#')?.slice(0, -1)?.join('#')
                    const valueKey = inputKey + '#input_value[]'
                    formSubmitData[ key ].forEach((val, i)=>{
                        if(val !== ''){
                            finalFormData[ inputKey+'#'+val ] = formSubmitData[ valueKey ][ i ] 
                        }
                    })
                }else if(!key.includes('input_value[]')){
                    finalFormData[ key ] = formSubmitData[ key ]
                }
            }
            if(key.includes('__array')){
                let oldKey = key
                if(!key.includes('data_accessor')){
                    key = key.replace('__array', '#fields')
                }else{
                    key = key.replace('__array', '')
                }
                finalFormData[ key ] = formSubmitData[ oldKey ]
                delete finalFormData[ oldKey ]
            }
        }
        return finalFormData
    }

    // const handleFormValidation = (fieldInputData, nestedFormData, setValidationError) => {
    //     let validatedFields = getValidatedFields(fieldInputData)
    //     let validationErrObj={}
    //     for (const field of validatedFields) {
    //         if(typeof nestedFormData?.[ field?.field_name ] === 'object'){
    //             validationErrObj[ field?.field_name ]={}
    //             for (const key in nestedFormData?.[ field?.field_name ]) {
    //                 const element = nestedFormData?.[ field?.field_name ][ key ];
    //                 validationErrObj[ field?.field_name ][ key ] = validateInputHandler(element, field, setValidationError)
    //                 console.log(element, key, field?.field_name, 'submitHandler', 'customInoutValidation', validationErrObj, validationErrObj[ field?.field_name ])
    //             }
    //             setValidationError(prev => { return { ...prev, ...validationErrObj }})
    //         }else{
    //             validationErrObj[ field?.field_name ] = validateInputHandler(nestedFormData?.[ field?.field_name ], field, setValidationError)
    //         }
    //     }
    //     console.log('customInoutValidation', validationErrObj)
    //     return validationErrObj

    // }

    const checkNestedObjectEmpty = (obj) => {
        return Object.keys(obj).length === 0 || Object.values(obj).every(value => (typeof value === 'object')?checkNestedObjectEmpty(value):value === '')
    }

    return { prepareFormData, validationLoading, checkNestedObjectEmpty, verifyFieldMappings, fieldsPreviewData }
}

export default useFormStep2