import React from 'react';
import styles from './Tabs.module.css';
import { capitalizeCamelCaseWords, capitalizeSnakeCaseWords } from '../../../helpers';

const Tabs = ({ tabs = [], tabsContent={}, tabsContentPanelStyles={} }) => {
    const [ activeTab, setActiveTab ] = React.useState()
    const [ fieldsData, setFieldsData ] = React.useState()
    // UseEffect
    React.useEffect(()=>{
        setFieldsData(tabsContent?.[ activeTab ] || [])
    }, [ activeTab ])
    React.useEffect(()=>{
        setActiveTab(tabs[ 0 ])
    }, [])
    console.log(activeTab, setActiveTab, fieldsData, setFieldsData)
    console.log(tabsContent, activeTab, '#TabsComponent', fieldsData)

    return (
        <div className={styles.tabsSectionWrapper}>
            <div className={styles.tabsWrapper}>
                {tabs.map((tab) => (
                    <div
                        key={tab}
                        className={`${ styles.tab } ${
                            tab === activeTab ? styles.activeTab : ''
                        }`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab === 'shipment3plDetails' ? 'Shipment 3PL Details' : capitalizeCamelCaseWords(
                            capitalizeSnakeCaseWords(tab)
                        )}
                    </div>
                ))}
            </div>
            <div
                className={styles.tabsContentWrapper}
                style={tabsContentPanelStyles}
            >
                {fieldsData}
            </div>
        </div>
    );
};

export default Tabs;