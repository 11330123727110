import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    mappingConfigurationData: {},
    serviceEndpointsData:{},
    servicesData:{}
};

const mappingConfiguration = createSlice({
    name: 'mappingConfiguration',
    initialState,
    reducers: {
        setMappingConfiguration: (state, action) => {
            state.mappingConfigurationData = { ...state.mappingConfigurationData, ...action.payload.data }
        },
        replaceMappingConfiguration: (state, action) => {
            Object.keys(state.mappingConfigurationData).map((key) => {
                if(key.includes(action.payload.key)){
                    delete state.mappingConfigurationData[ key ]
                }
            })
            state.mappingConfigurationData = { ...state.mappingConfigurationData, ...action.payload.data }
        },
        setServicesData: (state, action) => {
            if(action?.payload?.findByValue){
                let oldServiceData = state.servicesData[ action.payload.key ]
                const firstKey = Object.keys(action.payload.data)[ 0 ];
                for (const key in oldServiceData) {
                    if(oldServiceData[ key ] === action.payload.data[ firstKey ]){
                        if(key.split('#')[ 0 ] === firstKey.split('#')[ 0 ]){
                            delete oldServiceData[ key ]
                            oldServiceData[ firstKey ] = action.payload.data[ firstKey ]
                        }
                    }
                }
            }else{
                state.servicesData = {
                    ...state.servicesData,
                    [ action.payload.key ] : { ...state.servicesData[ action.payload.key ], ...action.payload.data }
                }
            }
        },
        setServicesFlattenedData: (state, action)=>{
            state.servicesData = { ...state.servicesData, [ action.payload.service ]: action.payload.serviceData }
        },
        setCustomServiceData : (state, action) => {
            if(action.payload.key){
                let oldServiceData = state.servicesData[ action.payload.key ]
                for (const key in oldServiceData) {
                    Object.keys(action.payload.data).map((customFieldKey)=>{
                        if(key.includes(customFieldKey)){
                            delete oldServiceData[ key ]
                        }
                        state.servicesData = {
                            ...state.servicesData,
                            [ action.payload.key ] : { ...oldServiceData, ...action.payload.data[ customFieldKey ] }
                        }
                    })
                }
            }

        },
        clearMappingConfiguration: (state) => {
            state.mappingConfigurationData = {};
            state.servicesData = {};
            state.serviceEndpointsData = {};
        }
    }
});

export const mappingConfigurationReducer = mappingConfiguration.reducer;
export const { setMappingConfiguration, clearMappingConfiguration, setServiceEndpoints, replaceMappingConfiguration, setServicesData,setServicesFlattenedData, setCustomServiceData } = mappingConfiguration.actions;