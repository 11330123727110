import React, { useState } from 'react';
import Filter from '../filter/Filter.jsx';
import styles from './listItems.module.css';
import ListItemTable from '../../components/listItemTable/ListItemTable.jsx';

const ListItems = ({ filterValues = [], urlData }) => {
    const [ filter, setFilter ] = useState('matched');
    let uriEndPoint = { service: urlData.service };

    if (filter !== '') {
        uriEndPoint.url = `${ urlData.filterUrl }/${ filter }/${ urlData.dataValue }`;
    } else {
        uriEndPoint.url = `${ urlData.baseUrl }/${ urlData.dataValue }`;
    }

    let tableType = [];
    let mappings = {};

    if (filter === 'missmatched' || filter === '') {
        tableType = [ 'shipnoticeItem', 'shipmentitem' ];
        mappings = { lot: 'lot.id' };
    } else if (filter === 'shipnotice') {
        tableType = [ '' ];
        mappings = { lot: 'lot.id' };
    } else if (filter === 'inboundshipment') {
        tableType = [ '' ];
    } else {
        tableType = [ '' ];
    }

    return (
        <div className={styles.mainWrapper}>
            <Filter value={filter} filterValues={filterValues} onFilterChange={setFilter} />
            {
                tableType.map((type, index) => (
                    <ListItemTable key={index} tableType={type} urlData={uriEndPoint} filter={filter} mappings={mappings} />
                ))
            }
        </div>
    );
};

export default ListItems;
