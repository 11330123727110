import { useState } from 'react'
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setWorkflowData } from '../../../redux/workflow/Slice';
import { workflowData } from '../../../redux/workflow/Selector';

const useWriterConnect = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const workflow = useSelector(workflowData);
    const getWorkflow = workflow?.length > 0 && workflow?.find(item => item[ id ]);
    const workFlowData = getWorkflow[ id ];
    const [ validationLoading, setValidationLoading ] = useState(false);
    const { callApi } = useCustomAxiosCall();
    const navigate = useNavigate();
    const handleNext = (data) => {
        console.log(data, 'writerconnect')
        dispatch(setWorkflowData({ [ id ]: { ...workFlowData, accessKeys: data.accessKeys, endpoint:data.endpoints[ 0 ] } }));
        navigate(`/config-writer/${ id }`, {
            state: {
                ...data, id: data.id, data: data.data, sourceData: data.sourceData
            }
        });
    }
    const verifyConnectivityConfig = async (formSubmitData, setValidationError) => {
        setValidationLoading(true);
        const response = await callApi({
            uriEndPoint: {
                url: '/validate-connectivity',
                method: 'POST',
                service: 'config-mapper'
            },
            body: { data: { ...formSubmitData, configType:'writer' } }
        });
        if (response?.status !== 200 || response?.data?.error) {
            setValidationLoading(false)
            toast.error(response?.data?.error || 'Something Went Wrong', { position: 'top-right' })
            return
        }
        setValidationLoading(false)
        const errObj = {}
        for (const key in response?.data) {
            const element = response?.data[ key ];
            if (element?.error) {
                errObj[ key ] = element?.error
            }
        }
        if (Object.keys(errObj).length > 0) {
            setValidationError(prev => {
                return {
                    ...prev,
                    endpoints: errObj
                }
            })
        } else {
            setValidationLoading(false);
            toast.success('Validation Successful', { position: 'top-right' })
            handleNext(formSubmitData)
        }
        return response
    }
    return (
        { verifyConnectivityConfig, validationLoading, id }
    )
}

export default useWriterConnect;