import React, { useState } from 'react';
import AppLoader from '../../../../appLoader';
import styles from './inventory.module.css'
import { useSelector } from 'react-redux';
import { connectorDataSelector } from '../../../../redux/connector/Selector';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import Table from '../../../../commonComponents/table/Table.jsx';
import useTables from '../../../../commonComponents/table/useTables.func';
import StatCounter from '../../../../components/widgets/statCounter/layout/StatCounter.jsx';
import { Link } from 'react-router-dom';
import useInventoryWidgets from '../functionality/inventoryWidgets.func';
import Spinner from '../../../../commonComponents/spinner/Spinner.jsx';
import useTablesTwo from '../../../../commonComponents/table/useTablesTwo.func';
import DropDown from '../../../../commonComponents/dropDown/DropDown.jsx';
import { getDropdownOptions } from '../../../../helpers/index';
import Button from '../../../../commonComponents/button/Button.jsx';
import FileUpload from '../../../../commonComponents/fileUpload/layout/FileUpload.jsx';

const Inventory = ()=>{
    const [ widgetData,widgetLoading ] = useInventoryWidgets({ url:'/widget-count', service:'collectivedata', type: 'inventory', fetch_by: 'type' });
    const connectors = useSelector(connectorDataSelector);
    const [ isOpenCsvModal, SetIsOpenModal ] = useState(false);
    const dropdownOptions = getDropdownOptions(connectors);

    // Add relevant dropdown options based on the tribe

    const [ dropdownValues, setDropdownValues ] = useState(
        dropdownOptions.length > 0
            ? dropdownOptions[ 0 ].value
            : ''
    );
    const {
        data,
        columns,
        loading,
        error,
        currentPage,
        setCurrentPage,
        totalRecords,
        setPageSize,
    } = useTablesTwo({
        url: '/get-data-by-collection',
        service: 'collectivedata',
        dropdownValues,
    });
    console.log('dropdownValues', dropdownValues)
    const { data: dataMismatch,
        columns: columnMismatch,
        loading: loadingMismatch, error: errorMismatch,
        currentPage: currentPageMismatch,
        setCurrentPage: setCurrentPageMismatch,totalRecords: totalRecordsMismatch,
        setPageSize: setPageSizeMismatch  } = useTables({ url: '/get-inventory-mismatch', service: 'collectivedata' });
    // const { data: dataWebshopInventroy,
    //     columns: columnWebshopInventroy,
    //     loading: loadingWebshopInventroy, error: errorWebshopInventroy,
    //     currentPage: currentPageWebshopInventroy,
    //     setCurrentPage: setCurrentPageWebshopInventroy, totalRecords: totalRecordsWebshopInventroy,
    //     setPageSize: setPageSizeWebshopInventroy } = useTables({ url: '/webshop-inventory', service: 'collectivedata' });

    const updatedColumns = columns.map((column) => {
        if (column.field === 'product_id' || column.field === 'sku') {
            return {
                ...column,
                renderCell: (params) => (
                    <Link
                        to={`/inventory/${ params.row.product_id }`}
                        state={{ productsDetail: data }}
                    >
                        {params.row.product_id}
                    </Link>
                ),
            };
        } else if (column.field === 'low_stock') {
            return {
                ...column,
                renderCell: (params) => (
                    <div
                        className={
                            params.row.low_stock === 'Yes'
                                ? styles.falseBlock
                                : styles.trueBlock
                        }
                    >
                        {params.row.low_stock}
                    </div>
                ),
            };
        } else if (column.field === 'style_id') {
            return {
                ...column,
                renderCell: (params) => (
                    <Link to={`/inventory-style-id/${ params.row.product_id }`} state={{ productsDetail: data }}>
                        {params.row.style_id}
                    </Link>
                )
            };
        }
        return column;
    });

    const dropdownChangeHandler = (value) => {
        setDropdownValues(value);
    };
    const handleOpenCsvModal = () => {
        SetIsOpenModal(true)
    }
    const onClose = () => {
        SetIsOpenModal(false)
    }

    console.log(updatedColumns, '#updatedColumns');
    return (
        <AppLoader>
            <div>
                <div className={styles.mainWrapper}>
                    <div className={styles.header}>
                        <Typography variant="gradient">
                            Inventory Overview
                        </Typography>
                    </div>
                    {widgetLoading ? (
                        <Spinner height={'10vh'} />
                    ) : (
                        <div className={styles.statsContainer}>
                            <StatCounter
                                data={{
                                    stat_name: 'Total SKUs',
                                    count: widgetData?.total_sku?.count || 0,
                                    icon: 'Layers',
                                    stat_level: widgetData?.total_sku?.flag,
                                }}
                            />
                            <StatCounter
                                data={{
                                    stat_name: 'Stock In hand',
                                    count:
                                        widgetData?.stock_in_hand?.count || 0,
                                    icon: 'SignalHigh',
                                    stat_level: widgetData?.stock_in_hand?.flag,
                                }}
                            />
                            <StatCounter
                                data={{
                                    stat_name: 'Inbound Supply',
                                    count:
                                        widgetData?.inbound_supply?.count || 0,
                                    icon: 'Signal',
                                    stat_level:
                                        widgetData?.inbound_supply?.flag,
                                }}
                            />
                            <StatCounter
                                data={{
                                    stat_name: 'Low Qty SKUs',
                                    count: widgetData?.low_qty_sku?.count || 0,
                                    icon: 'ReceiptIndianRupee',
                                    stat_level: widgetData?.low_qty_sku?.flag,
                                }}
                            />
                        </div>
                    )}
                    <div className={styles.headerWrapper}>
                        <div className={styles.flex}>
                            <Typography variant="heading">Inventory</Typography>
                            <DropDown
                                options={dropdownOptions}
                                defaultValue={dropdownValues}
                                showDownIcon={true}
                                onDropDownChange={dropdownChangeHandler}
                            />
                        </div>
                        <Button
                            className={styles.uploadButton}
                            onClick={handleOpenCsvModal}
                        >
                            Inventory Distribution
                        </Button>
                    </div>

                    {!loading && error ? (
                        <div className={styles.heading}>
                            <Typography variant="error">{error}</Typography>
                        </div>
                    ) : (
                        <Table
                            loading={loading}
                            headerList={updatedColumns}
                            totalRecords={totalRecords}
                            setPageSize={setPageSize}
                            rowsData={data}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            showAllColumns={true}
                        />
                    )}
                    <Typography variant="heading">
                        Inventory Mismatch
                    </Typography>
                    {!loadingMismatch && errorMismatch ? (
                        <div className={styles.heading}>
                            <Typography variant="error">{error}</Typography>
                        </div>
                    ) : (
                        <Table
                            loading={loadingMismatch}
                            headerList={columnMismatch}
                            totalRecords={totalRecordsMismatch}
                            setPageSize={setPageSizeMismatch}
                            rowsData={dataMismatch}
                            currentPage={currentPageMismatch}
                            setCurrentPage={setCurrentPageMismatch}
                        />
                    )}
                    {/* <Typography variant="heading">Webshop Inventory</Typography>
                    {!loadingMismatch && errorMismatch ? (
                        <div className={styles.heading}>
                            <Typography variant="error">
                                {errorWebshopInventroy}
                            </Typography>
                        </div>
                    ) : (
                        <Table
                            loading={loadingWebshopInventroy}
                            headerList={columnWebshopInventroy}
                            totalRecords={totalRecordsWebshopInventroy}
                            setPageSize={setPageSizeWebshopInventroy}
                            rowsData={dataWebshopInventroy}
                            currentPage={currentPageWebshopInventroy}
                            setCurrentPage={setCurrentPageWebshopInventroy}
                        />
                    )} */}
                </div>
            </div>
            <FileUpload open={isOpenCsvModal} onClose={onClose} />
        </AppLoader>
    );
}

export default Inventory;
