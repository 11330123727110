import React from 'react';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import styles from './gridCar.module.css';
// import LucideIcon from '../../../../commonComponents/lucideicon/LucideIcon.jsx';
// import { Link } from 'react-router-dom';
import LucideIcon from '../../../../commonComponents/lucideicon/LucideIcon.jsx';
import { Link } from 'react-router-dom';
import useGridCard from '../functionality/useGridCard.func';
import Spinner from '../../../../commonComponents/spinner/Spinner.jsx';

const GridCard = ({ id='', data }) => {
    const { data: trackingData, loading, error } = useGridCard({ url:'/shipment-tracking?page=1&per_page=3', service:'collectivedata' })
    const handleData=(data, index)=>{
        let updatedData;
        if(index===1){
            updatedData=(
                <div className={styles.statusContainer}>
                    <div className={styles.detailWrapper}>
                        <div className={styles.iconWrapper}>
                            <LucideIcon name="Truck"/>
                        </div>
                        <div>
                            <div className={styles.status}>{data?.shipment_status} </div>
                            <div className={styles.time}>{data?.shipment_created_date}</div>
                        </div>
                    </div>
                    {/* <div className={styles.orderNumber}>7353534646466 Order 45678</div> */}
                </div>)
        }
        else {
            updatedData=
                <div className={styles.shipmentId}>{data}</div>
        }
        return updatedData
    }
    return (
        <div id={id} className={styles.gridCard}>
            <div className={styles.header}>
                <Typography variant='heading'>{data.title}</Typography>
                {/* <Link href="/warehouse-dashboard">See All</Link> */}
            </div>
            <div className={styles.grid}>
                {loading
                    ? <Spinner height={'30vh'}/>
                    : error
                        ? <Typography variant='error'>{error}</Typography>
                        : <table>
                            <thead>
                                <tr>
                                    {data.headers.map((header, index) => <th key={index}>{header}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                { trackingData?.data?.map((row, index)=><tr key={index}>{
                                    <>
                                        <td className={styles.shipmentId}>{row?.shipment_number}</td>
                                        <td>{handleData(row, 1)}</td>
                                        <td>
                                            <Link to={`/inbound-shipment/${ row?.shipment_id }`}>
                                                <LucideIcon name="FilePenLine" size="16" color="#48505E"/>
                                            </Link>
                                        </td>
                                    </>
                                }
                                </tr>)}
                            </tbody>
                        </table>
                }
            </div>
        </div>
    )

};

export default GridCard;