import React from 'react';
import { MenuItem, Select } from '@mui/material';
import useConfigMapperField from '../../configMapper/functionality/useConfigMapperFeild.func';
import configData from '../data/fieldDropdownData.json'
import Typography from '../../../commonComponents/typography/Typography.jsx';
import styles from './CustomInputV2.module.css'
import CustomInputArrayV2 from './CustomInputArray/CustomInputArrayV2.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfigurationData } from '../../../redux/mappingConfiguration/Selector';
import { setMappingConfiguration, setServicesData } from '../../../redux/mappingConfiguration/Slice';
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';
import useConfigMapperFieldV2 from '../functionality/useConfigMapperFeildV2.func';
// import fieldMappingsData from '../data/fieldsMappings.json'
// import { capitalizeCamelCaseWords, capitalizeSnakeCaseWords } from '../../../helpers/index';
// import { updateObjectWithNestedKey } from '../../../helpers';
// import { setMappingConfiguration } from '../../../redux/mappingConfiguration/Slice';

const CustomInput  = ({
    fieldData={},
    fieldListData=[],
    formData={},
    parentField='',
    validateInputHandler=()=>{},
    parentFieldData={},
    setValidationError=()=>{},
    validationError={},
    service='',
    canDelete=false,
    setFieldData=()=>{},
    collections=[]
}) => {
    if(fieldData?.field_type === 'array'){
        return <CustomInputArrayV2 fieldData={fieldData} parentField={parentField}  setFieldData={setFieldData} fieldListData={fieldListData}/>
    }
    const [ customInputData, setCustomInputData ] = React.useState({ name: '', value:'' })
    const { findFieldById } = useConfigMapperField(fieldData)
    const { removeFieldById } = useConfigMapperFieldV2(fieldData)
    const dispatch = useDispatch()
    const configurationStateData = useSelector(selectConfigurationData);

    console.log(dispatch, configurationStateData, setMappingConfiguration, setServicesData, service)
    const customInputChangeHandler = (e, id='') => {
        let inputFieldId = ''
        if(id !== ''){
            inputFieldId = id;
        }else{
            inputFieldId =  e.target.id
        }
        console.log(inputFieldId, 'inputFieldId')
        if(inputFieldId.includes('#name')){
            const fieldNameValue = e.target?.value
            const id = inputFieldId.split('#')[ 0 ]
            const fieldObj = findFieldById(fieldListData, id)
            fieldObj[ 'field_name_value' ] = fieldNameValue
            setCustomInputData((prevState)=>{return { ...prevState, name:fieldNameValue }})
            if(service !== ''){
                dispatch(setServicesData({ key:service,findByValue:true, data : { [ `${ parentField }#${ fieldNameValue }` ]: fieldObj?.field_value } }))
            }else{
                dispatch(setMappingConfiguration({ findByValue:true, data: { [ `${ parentField }#${ fieldObj?.field_name_value }` ]: fieldObj?.fieldValue }  }))
            }
        }
        else if(inputFieldId.includes('#value')){
            let currentStateName = ''
            const fieldValue = e.target?.value
            const id = inputFieldId.split('#')[ 0 ]
            const fieldObj = findFieldById(fieldListData, id)
            fieldObj[ 'field_value' ] = fieldValue
            validateInputHandler(e.target.value, parentFieldData, setValidationError )
            setCustomInputData((prevState)=>{currentStateName=prevState.name; return { ...prevState, value:fieldValue } })
            console.log(customInputData,currentStateName,fieldObj, '#customFieldsStore')
            if(service !== ''){
                dispatch(setServicesData({ key:service,findByValue:false, data : { [ `${ parentField }#${ fieldObj?.field_name_value }` ]: fieldValue } }))
            }else{
                dispatch(setMappingConfiguration({ data: { [ `${ parentField }#${ fieldObj?.field_name_value }` ]: fieldValue }  }))
            }
        }
    }
    const getCustomInputName = (fieldData, parentField) => {

        if(configData?.[ parentField ]?.length > 0 && !fieldData?.field_options?.length ){
            fieldData.field_options = configData?.[ parentField ]
        }
        switch (fieldData?.name_field_type) {
            case 'dropdown':
                return   <Select
                    key={fieldData?.field_id}
                    value={fieldData?.field_name_value || fieldData?.field_options?.[ 0 ]?.value}
                    name={`${ parentField }#input_name[]`}
                    onChange={(e)=>{customInputChangeHandler(e, fieldData?.field_id + '#name')}}
                >
                    {fieldData?.field_options?.map((field_option) => (
                        <MenuItem
                            value={field_option?.value|| customInputData?.name}
                            key={field_option?.value}
                        >
                            {field_option?.title}
                        </MenuItem>
                    ))}
                </Select>

            default:
                return <input
                    type='text'
                    name={`${ parentField }#input_name[]`}
                    placeholder={'Type Here...'}
                    defaultValue={fieldData?.field_name_value || customInputData?.name || formData[ fieldData?.field_name ]}
                    key={fieldData?.field_id}
                    id={fieldData?.field_id + '#name'}
                    onBlur={(e)=>{customInputChangeHandler(e)}}
                />
        }
    }
    const fieldRmovalHandler = (e, id) => {
        removeFieldById(id, fieldListData, setFieldData)
    }
    const getCustomInputValue = (fieldData, parentField) => {
        console.log(fieldData, parentField, 'customValueDropdown')
        if(parentField === 'endpoints' && collections.length > 0) {
            const fieldOptions = [ { title:'Select', value:'select' } ]
            let value = fieldData?.field_value || fieldOptions?.[ 0 ]?.value
            let valueArray = value.split(',')
            collections.map(collection=>{
                fieldOptions.push({
                    title: collection,
                    value: collection
                })
            })
            return <Select
                key={fieldData?.field_id}
                value={valueArray}
                name={`${ parentField }#input_value[]`}
                onChange={(e) => { customInputChangeHandler(e, fieldData?.field_id + '#value') }}
                id={fieldData?.field_id + '#value'}
                // multiple
                // renderValue={(selected) => (
                //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                //         {selected.map((value) => (
                //             <Chip key={value} label={value} />
                //         ))}
                //     </Box>
                // )}
            >
                {fieldOptions?.map((field_option) => (
                    <MenuItem
                        value={field_option?.value || customInputData?.name}
                        key={field_option?.value}
                    >
                        {field_option?.title}
                    </MenuItem>
                ))}
            </Select>
        }else{
            return <input
                type='text'
                name={`${ parentField }#input_value[]` }
                placeholder='Type Here...'
                onBlur={(e)=>{customInputChangeHandler(e)}}
                defaultValue={fieldData?.field_value || customInputData?.value}
                id={fieldData?.field_id + '#value'}
            />
        }
    }
    const getCustomInput=(fieldData, parentField)=>{
        return <div className={styles.customInput}>
            {getCustomInputName(fieldData, parentField)}
            {getCustomInputValue(fieldData, parentField)}
            {canDelete && <span className={styles.iconWrapper}><LucideIcon onClick={(e)=>{fieldRmovalHandler(e, fieldData?.field_id)}}name={'X'}/></span>}
        </div>
    }
    return <div className={styles.customInputContainer}>
        {getCustomInput(fieldData, parentField)}
        {validationError?.[ parentField ]?.[ fieldData?.field_name_value || fieldData?.field_options?.[ 0 ]?.value ] !== '' && <Typography variant='error'>{validationError?.[ parentField ]?.[ fieldData?.field_name_value || fieldData?.field_options?.[ 0 ]?.value ]}</Typography>}
    </div>
}

export default CustomInput