// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.returnCounter_counterWrapper__n8TBV {
    display: flex;
    margin: 3rem 0;

}
.returnCounter_colors__6uMoA{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 10px;
}
.returnCounter_returnWrapper__PxNAN {
    display: flex;
    flex-direction: column;
}

.returnCounter_returnValue__F4aPw {
    font-family: DM Sans;
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: var(--color-black);
}

.returnCounter_returnKey__nJ9V5 {
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
    color: var(--color-silver-200);
    ;
}`, "",{"version":3,"sources":["webpack://./src/components/widgets/returnCounter/layout/returnCounter.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;;AAElB;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,gBAAgB;IAChB,8BAA8B;;AAElC","sourcesContent":[".counterWrapper {\n    display: flex;\n    margin: 3rem 0;\n\n}\n.colors{\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    margin-right: 10px;\n}\n.returnWrapper {\n    display: flex;\n    flex-direction: column;\n}\n\n.returnValue {\n    font-family: DM Sans;\n    font-size: 22px;\n    font-weight: 700;\n    line-height: 20px;\n    letter-spacing: -0.02em;\n    color: var(--color-black);\n}\n\n.returnKey {\n    font-family: DM Sans;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 20px;\n    letter-spacing: -0.02em;\n    text-align: left;\n    color: var(--color-silver-200);\n    ;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counterWrapper": `returnCounter_counterWrapper__n8TBV`,
	"colors": `returnCounter_colors__6uMoA`,
	"returnWrapper": `returnCounter_returnWrapper__PxNAN`,
	"returnValue": `returnCounter_returnValue__F4aPw`,
	"returnKey": `returnCounter_returnKey__nJ9V5`
};
export default ___CSS_LOADER_EXPORT___;
