import { icons } from 'lucide-react';

const LucideIcon = ({ name, size = 24, color, className = '', onClick, style }) => {
    const LucideIconComponent = icons[ name ];

    if (!LucideIconComponent) {
        console.error(`Icon '${ name }' not found.`);
        return null;
    }

    return <LucideIconComponent size={size} color={color} className={className} onClick={onClick} style={style} />
}
export default LucideIcon;