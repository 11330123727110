import React from 'react'
import styles from './FieldMappingsPreveiwPopup.module.css'
import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func'
import { capitalizeCamelCaseWords, capitalizeSnakeCaseWords, displayValue } from '../../../helpers'
import previewData from '../dummyData/previewDataDummy.json'
import Tabs from '../../tabs/layout/Tabs.jsx'
const FieldMappingsPreveiwPopup = ({ data=previewData, onClose, onVerifyClick }) => {
    const { getDisplayName } = useConfigMapperFieldV2()
    const fieldContent = [], customFieldContent = [];
    const customFields = data?.custom_fields;
    console.log(data, '#previewData', data)
    const processField = (key, value) => ({
        field_name: key,
        value: Array.isArray(value) ? value.join(', ') : value
    });

    for (let key in customFields) {
        customFieldContent.push(processField(key, customFields[ key ]));
    }

    for (let key in data) {
        if (key !== 'custom_fields') {
            fieldContent.push(processField(key, data[ key ]));
        }
    }

    const getHtmlContent = (field) => {
        console.log(typeof field.value, 'PreviewPopupNew', field.value)
        if(field.value && typeof field.value === 'object' ){
            console.log(field.value, 'PreviewPopup')
            if (Object.keys(field?.value).length > 0){
                return <div key={field?.field_name} className={`${ styles.fieldWrapper } ${ styles.nestedFieldWrapper }`}>
                    <p className={styles.fieldName}>{getDisplayName(field)}</p>
                    { Object.keys(field.value).map(nestedField =>
                        <div key={nestedField} className={styles.fieldWrapper}>
                            <p>{capitalizeCamelCaseWords(capitalizeSnakeCaseWords(nestedField)) }</p>
                            <div className={styles.fieldValueWrapper}>{JSON.stringify(field?.value[ nestedField ])}</div>
                        </div>) }
                </div>}
        }else{
            return <div key={field?.field_name} className={styles.fieldWrapper}>
                <p>{getDisplayName(field)}</p>
                <div className={styles.fieldValueWrapper}>{displayValue(field?.value)}</div>
            </div>
        }
    }

    const getCustomFieldContent = (field) => {
        if(field.value && typeof field.value === 'object'){
            return <div key={field?.field_name} className={`${ styles.fieldWrapper } ${ styles.nestedFieldWrapper }`}>
                <p className={styles.fieldName}>{getDisplayName(field)}</p>
                { Object.keys(field.value).map(nestedField =>
                    <div key={nestedField} className={styles.fieldWrapper}>
                        <p>{capitalizeCamelCaseWords(capitalizeSnakeCaseWords(nestedField)) }</p>
                        <div className={styles.fieldValueWrapper}>{JSON.stringify(field?.value[ nestedField ])}</div>
                    </div>) }
            </div>
        }else{
            return <div key={field?.field_name} className={styles.fieldWrapper}>
                <p>{getDisplayName(field)}</p>
                <div className={styles.fieldValueWrapper}>{displayValue(field?.value)}</div>
            </div>
        }
    }

    const tabs = [], tabsContent = {}
    Object.entries(data).map(([ key, value ])=>{
        tabs.push(key)
        const fieldsData = []

        if (Array.isArray(value) && value.length>0){
            Object.entries(value[ 0 ])?.map(([ key, value ])=>{
                fieldsData.push(getHtmlContent(processField(key, value)))
            })
        }
        tabsContent[ key ] = <div className={styles.fieldsContainer}>{fieldsData}</div>
    })
    console.log(onClose, onVerifyClick, getHtmlContent, getCustomFieldContent, tabs)
    console.log(tabs, tabsContent, '#previewTabsContent')
    return (
        <div className={styles.popupWrapper}>
            <h1>Field Mappings Preview</h1>
            <Tabs
                tabs={tabs}
                tabsContent={tabsContent}
            />
        </div>
    )
}

export default FieldMappingsPreveiwPopup
