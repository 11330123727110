import React from 'react';
import styles from './shipNotice.module.css';
import AppLoader from '../../../../appLoader';
import Spinner from '../../../../commonComponents/spinner/Spinner.jsx';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import { useParams } from 'react-router-dom';
// import CommonTable from '../../../../commonComponents/commonTable/layout/CommonTable.jsx';
import useShipmentNotice from '../functionality/ShipNotice.func';
// import DialogWrapper from '../../../../commonComponents/DialogWrapper/DialogWrapper.jsx';
// import ShipNoticeChart from '../../../../dummyData/ShipNoticeChart.json';
// import MultiBarChart from '../../../../commonComponents/charts/barChart/BarChart.jsx';
// import ShipNoticeChart from '../../../../dummyData/ShopNoticeChart.json'
// import LineChart from '../../../../commonComponents/charts/lineChart/LineChart.jsx';
import ShipmentNoticeTable from '../../../../components/shipmentNoticeTable/ShipmentNoticeTable.jsx';
const ShipmentNotice = () => {
    // const [ isOpen, setIsOpen ]=useState(false)
    const { shipNoticeId } = useParams();
    const { data, loading, error, shipmentHeaderData } = useShipmentNotice(shipNoticeId);
    const options = {
        mappings: {
            warehouse: 'warehouse.name',
            customer: 'customer.name',
            item: 'item.sku',
            lot: 'lot.id'
        }
    }
    // const onClose=()=>{
    //     setIsOpen(false)
    // }
    // const handleClickRow=()=>{
    //     setIsOpen(true)
    // }
    return (
        <AppLoader>
            <div className={styles.adminDashboard}>

                <div className={styles.mainWrapper}>
                    {loading ? <Spinner /> :  error?<Typography variant = 'error'>{ error }</Typography> : (
                        <>
                            <div className={styles.header}>
                                <Typography variant='heading'>Shipnotice ({shipNoticeId})</Typography>
                            </div>
                            <ShipmentNoticeTable headerList={shipmentHeaderData || []} rowsData={ data || [] } mappings={options?.mappings} totalRows={data?.length>0?1:0}/>
                            <div className={styles.header}>
                                <Typography variant='heading'>Shipnotice Items</Typography>
                            </div>
                            <ShipmentNoticeTable mappings={options.mappings} urlData={
                                {
                                    url: `/get-items-by-id/shipnotice/${ shipNoticeId }`,
                                    service: 'collectivedata',
                                }
                            }/>
                        </>
                    )}
                </div>
            </div>
            {/* <DialogWrapper isOpen={isOpen} onClose={onClose}>
                <LineChart
                    title='Inventory breakdown by product across'
                    height={200}
                    width={550}
                    data={ShipNoticeChart.data}
                    labels={ShipNoticeChart.labels}
                    colors={ShipNoticeChart.colors}
                />
            </DialogWrapper> */}
        </AppLoader >
    )
};

export default ShipmentNotice;