import React from 'react';
import styles from './SummaryItemSize.module.css';
import AppLoader from '../../../../appLoader';
import Spinner from '../../../../commonComponents/spinner/Spinner.jsx';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import { useParams } from 'react-router-dom';
import useSummary from '../functionality/Summary.func';
import ShipmentNoticeTable from '../../../../components/shipmentNoticeTable/ShipmentNoticeTable.jsx';
const SummaryItemSize = () => {
    const { invoiceNo, style } = useParams();
    const { data, loading, error, summaryHeaderData } = useSummary(invoiceNo, style);
    const options = {
        mappings: {
            warehouse: 'warehouse.name',
            customer: 'customer.name',
            item: 'item.sku',
            lot: 'lot.id'
        }
    }
    
    return (
        <AppLoader>
            <div className={styles.adminDashboard}>

                <div className={styles.mainWrapper}>
                    {loading ? <Spinner /> :  error?<Typography variant = 'error'>{ error }</Typography> : (
                        <>
                            <div className={styles.header}>
                                <Typography variant='heading'>Invoice number ({invoiceNo})</Typography>
                            </div>
                            <ShipmentNoticeTable headerList={summaryHeaderData || []} rowsData={ data || [] } mappings={options?.mappings} totalRows={data?.length>0?1:0}/>
                            <div className={styles.header}>
                                <Typography variant='heading'>Sku sizes</Typography>
                            </div>
                            <ShipmentNoticeTable mappings={options.mappings} urlData={
                                {
                                    url: `/all-invoice-size/${ invoiceNo }/${ style }`,
                                    service: 'collectivedata',
                                }
                            }/>
                        </>
                    )}
                </div>
            </div>
        </AppLoader >
    )
};

export default SummaryItemSize;