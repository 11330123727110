import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    shipNotice: {}
};

const shipNotice = createSlice({
    name: 'shipNotice',
    initialState,
    reducers: {
        setShipNotice: (state, action) => {
            state.shipNotice = action.payload;
        }
    }
});

export const shipNoticeReducer = shipNotice.reducer;
export const { setShipNotice } = shipNotice.actions;