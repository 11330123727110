import { useEffect, useState } from 'react'
import useCustomAxiosCall from '../../../../hooks/useCustomAxiosCall';

function useGridCard(uriEndPoint = {}) {
    const [ loading, setLoading ]=useState(false)
    const [ data, setData ]=useState()
    const [ error, setError ]=useState()
    const { callApi } = useCustomAxiosCall();
    const getTableData = async () => {
        setLoading(true)
        const response = await callApi({
            uriEndPoint: {
                ...uriEndPoint,
            }
        });

        if (response && response?.status === 200) {
            console.log(response?.data)
            setData(response?.data || [])
        } else {
            setError(response?.data?.error)
            console.log('error')
        }
        setLoading(false);
    }
    useEffect(() => {
        getTableData();
        window.scrollTo(0, 0)
    }, []);
    return (
        {  data,loading, error }
    )
}

export default useGridCard