import React, { useEffect, useState } from 'react';
import { capitalizeCamelCaseWords, getDateLocale } from '../../../../helpers';
import useCustomAxiosCallTwo from '../../../../hooks/useCustomAxiosCallTwo';

const useTablesTwo = (uriEndPoint = {}, header = [], rowData = [], mappings = {}, activeTabIndex) => {
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState([]);
    const [ error, setError ] = useState('');
    const { callApi } = useCustomAxiosCallTwo();
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ pageSize, setPageSize ] = useState(100)
    const [ totalRecords, setTotalRecords ] = useState(0);
    const [ headerData, setHeaderData ] = useState([]);
    let flattenedData = [], columns = [];

    const getTableData = async () => {
        const newUrl = `${ uriEndPoint.url }?page=${ currentPage + 1 }&per_page=${ pageSize }`
        setLoading(true);

        const response = await callApi({
            uriEndPoint: {
                ...uriEndPoint,
                url: newUrl
            }
        });

        if (response && response.status === 200) {
            setData(response?.data?.data || [])
            setHeaderData(response?.data?.header_data)
            setTotalRecords(response?.data?.total_count || 0)
        } else {
            setError('Something Went Wrong. Please Try Again Later');
        }
        setLoading(false);
    }

    columns = React.useMemo(() => getColumns(headerData, header), [ headerData, header ])
    flattenedData = React.useMemo(() => { const flattenedData = flattenNestedData(rowData, data, columns, mappings, setLoading); return flattenedData }, [ data, rowData ])

    useEffect(() => {
        if (uriEndPoint?.url?.length > 0 && (activeTabIndex === 0 || 1)) {
            getTableData();
        }
    }, [ currentPage, pageSize, uriEndPoint.url, activeTabIndex ]);

    return { columns, data: flattenedData, loading, error, currentPage, setPageSize, totalRecords, setCurrentPage }
}

export default useTablesTwo;

function flattenObject(obj, result = {}) {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (typeof obj[ key ] === 'object' && obj[ key ] !== null) {
                flattenObject(obj[ key ], result);
            } else {
                result[ key ] = obj[ key ];
            }
        }
    }
    return result;
}
export const flattenNestedData = (rowData = [], data = [], columns = [], mappings = {}) => {
    const flattenedData = (rowData.length > 0 ? rowData : data).map((item, index) => {
        const flattenedItem = { id: index + 1 };
        columns.forEach(columnObj => {
            let key = columnObj.field;
            if (mappings && key in mappings) {
                let displayData = item;
                const mappingKeys = mappings[ key ].split('.');
                mappingKeys.map(mappingKey => {
                    displayData = (typeof displayData === 'object') ? displayData?.[ mappingKey ] : displayData;
                })
                flattenedItem[ key ] = displayData;
            } else {
                if (Array.isArray(item[ key ])) {
                    flattenedItem[ key ] = item[ key ].toString();
                } else if (typeof item[ key ] === 'object' && item[ key ] !== null) {
                    Object.assign(flattenedItem, flattenObject(item[ key ]))
                } else if ((typeof item[ key ] === 'string') && item[ key ].includes('-04:00')) {
                    flattenedItem[ key ] = getDateLocale(item[ key ])
                } else if ((typeof item[ key ] === 'boolean')) {
                    flattenedItem[ key ] = (item[ key ]) ? 'Yes' : 'No'
                }
                else {
                    flattenedItem[ key ] = item[ key ];
                }
            }
        });
        return flattenedItem;
    });
    return flattenedData;
}

export const getColumns = (headerData = [], header = []) => (header.length > 0 ? header : headerData).map((key) => ({
    field: key,
    headerName: capitalizeCamelCaseWords(key.replaceAll('_', ' ')),
    flex: 1,
}))