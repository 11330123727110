import DatePicker from 'react-datepicker'
import styles from './businessLogic.module.css'

function CustomDatePicker({
    handleChange,
    value,
}){
    return (
        <DatePicker
            selected={value}
            onChange={handleChange}
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/MM/yyyy"
            className={styles.datepicker}
            maxDate={new Date()}
        />
    )
}

export default CustomDatePicker