import React, { useState } from 'react';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import styles from '../layout/logger.module.css';
import { flattenNestedData, getColumns } from '../../../commonComponents/table/useTables.func';
import { useEffect } from 'react';
const useLogger = ( uriEndPoint = {}, mappings={}) => {
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState([]);
    const [ error, setError ] = useState('');
    const { callApi } = useCustomAxiosCall();
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ pageSize,setPageSize ]=useState(100)
    const [ totalRecords, setTotalRecords ] = useState();
    const [ headerData, setHeaderData ] = useState([]);
    let flattenedData = [], columns = [];

    const getTableData = async () => {
        const newUrl = `${ uriEndPoint.url }?page=${ currentPage + 1 }&per_page=${ pageSize }`
        setLoading(true);

        const response = await callApi({
            uriEndPoint:{
                ...uriEndPoint,
                url:newUrl
            }
        });

        if (response && response.status === 200) {
            setData(response?.data?.entries || [])
            setHeaderData([ 'app', 'message', 'timestamp', 'severity' ])
            setTotalRecords(response?.data?.entries?.length || 0)
        } else {
            setError('Something Went Wrong. Please Try Again Later');
        }
        setLoading(false);
    }
    columns = React.useMemo(() => getColumns(headerData, []), [ headerData, [] ])
    flattenedData = React.useMemo(() => { const flattenedData = flattenNestedData([], data, columns, mappings, setLoading); return flattenedData }, [ data ])

    const updatedColumns = columns.map((column) => {
        if (column.field === 'status') {
            return {
                ...column,
                renderCell: (params) => {
                    let statusClass = '';

                    if (params.value === 200) {
                        statusClass = styles.status200;
                    } else if (params.value >= 400 && params.value < 500) {
                        statusClass = styles.status400;
                    } else if (params.value >= 500) {
                        statusClass = styles.status500;
                    }

                    return (
                        <div className={`${ styles.statusCell } ${ statusClass }`}>
                            <span>{params.value}</span>
                        </div>
                    );
                },
            };
        }
        return column;
    });
    useEffect(() => {
        if (uriEndPoint?.url?.length>0 ){
            getTableData();
            window.scrollTo(0, 0)
        }else{
            setLoading(false)
        }
    }, [ currentPage, pageSize, uriEndPoint.url ]);

    return { updatedColumns, data: flattenedData, loading, error, currentPage, setPageSize, totalRecords, setCurrentPage }
}

export default useLogger;