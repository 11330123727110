import React from 'react';
import styles from './switch.module.css';
import Typography from '../typography/Typography.jsx';

export const Switch = ({
    isChecked = true,
    handleToggle = () =>{},
    isDisabled=false
}) => {
    return (
        <div className={styles.switch}>
            <input className={`${ styles.status } ${ styles.statusOn }`} onChange={handleToggle} checked={ isDisabled || isChecked} type='radio' disabled={isDisabled} />
            <Typography>On</Typography>
            <input className={`${ styles.status } ${ styles.statusOff }`} onChange={handleToggle} checked={!isChecked && !isDisabled} type='radio' />
            <Typography>Off</Typography>
        </div>
    )
}