import React from 'react';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import styles from './connectorCardII.module.css';
import Modal from '../../../commonComponents/modal/Modal.jsx';
import { useConnectorCardIIFunc } from '../functionality/ConnectorCardII.func';
const ConnectorCardII = ({
    connector,
    handleConnectConnector = () => { }
}) => {

    const { handleSubmit, handleCreateConnectorModal, handleChange, apiConnectors, isOpen, closeModal, data, error, isLoading } = useConnectorCardIIFunc({
        onConnectionSuccess: handleConnectConnector,
        defaultConnector: connector
    });
    const { name, machineName,icon } = connector;
    return (
        <>
            <div
                className={styles.mainWrapper}
                onClick={() => handleCreateConnectorModal(connector)}
            >
                <div
                    className={styles.innerWrapper}
                >
                    <div>
                        <img src={icon} alt={name} />
                    </div>
                    <div className={styles.line}>
                        <div className={styles.seperator} />
                    </div>
                    <div>
                        <div>
                            <Typography className={styles.name} variant="title">
                                {name}
                            </Typography>
                            {machineName === 'microsoft_dynamics_365' &&
                                isLoading && (
                                <Typography variant="secondary">
                                    Authenticating
                                </Typography>
                            )}
                            {machineName === 'microsoft_dynamics_365' &&
                                error.redirect && (
                                <Typography variant="error-sm">
                                    {error.redirect}
                                </Typography>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isOpen && (
                <Modal
                    loading={isLoading}
                    isOpen={isOpen}
                    modalFieldData={data || data.apiConnector}
                    error={error}
                    value={apiConnectors}
                    handleChange={handleChange}
                    onSubmit={handleSubmit}
                    onClose={closeModal}
                />
            )}
        </>
    );
};

export default ConnectorCardII;
