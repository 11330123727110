import { useEffect, useRef, useState } from 'react';
import data from '../../../dummyData/AdminDashboard.json';
import chartData from '../../../dummyData/Charts.json';
import { useSelector } from 'react-redux';
import { dashboardSelector } from '../../../redux/dashbaord/Selector';
import { useNavigate } from 'react-router-dom';

const useAdminDashboard = () => {
    const [ lineChartWidth, setLineChartWidth ] = useState(400);
    const navigate = useNavigate();
    const [ stats, SetStats ] = useState([]);
    const [ lineChart1, setLineChart1 ] = useState({});
    const [ lineChart2, setLineChart2 ] = useState({});
    const [ pieChart, setPieChart ] = useState({});
    const [ barChart, setBarChart ] = useState({});
    const [ dataSources, setDataSource ] = useState({});
    const [ multiLineItems, setMultiLineItems ]=useState([])
    const [ ordersFulfilled, setOrdersFulfilled ]=useState([])
    const [ orderShippedData, setOrderShippedData ]=useState([])
    const [ orderCancelledData, setOrderCancelledData ]=useState([])
    const dashboardData = useSelector(dashboardSelector);
    const { products = [] } = dashboardData.data;

    const lineChartContainerRef = useRef(null);
    const setLineChartWidthHandler = () => {
        if (lineChartContainerRef.current) {
            setLineChartWidth(lineChartContainerRef.current?.offsetWidth - 70);
        }
    };

    const orderStatClickHandler = (url='') => {
        if(url!== ''){
            navigate(url);
        }
    }
    // const dispatch = useDispatch();

    // const handleConnect = () => {
    //     const serverUrl =  'app.ekyam.ai/v/api/notify' //'real-time-api-service.default.svc.cluster.local:5003';

    //     const newSocket = new WebSocket(`wss://${ serverUrl }/ws/65fa767afb67a318d3784e7a`); // static org_id

    //     newSocket.onopen = () => {
    //         console.log('WebSocket connection established');
    //     };

    //     newSocket.onmessage = (event) => {
    //         const message = JSON.parse(event.data);
    //         const eventName = message?.body?.event_name;
    //         // const isDuplicate = products.findIndex(obj => obj.id === message?.body?.data?.id) !== -1
    //         console.log(message, 'message');

    //         if (eventName === 'products_create') {
    //             dispatch(addProducts(message?.body?.data))
    //         } else if (eventName === 'orders_create') {
    //             dispatch(addOrders(message?.body?.data))
    //         }
    //     };

    //     newSocket.onclose = (e) => {
    //         console.log('WebSocket connection closed', e);
    //         handleConnect();
    //     };
    // }

    useEffect(() => {
        SetStats(data.stats);
        setLineChart1(chartData.lineChart);
        setLineChart2(chartData.lineChart2);
        setBarChart(chartData.barChart);
        setPieChart(chartData.pieChart);
        setDataSource(data.data_sources);
        setMultiLineItems(data.multi_line_items);
        setOrderShippedData(data.shipped_monthly);
        setOrderCancelledData(data.cancelled_orders)
        setOrdersFulfilled(data.orders_fulfilled)
        // handleConnect();

        setLineChartWidthHandler();
        window.addEventListener('resize', setLineChartWidthHandler);

        // dispatch(clearDashboardData())
        return () => window.removeEventListener('resize', setLineChartWidthHandler);
    }, []);

    return { lineChartContainerRef, lineChartWidth, stats, lineChart1, lineChart2, pieChart, barChart, dataSources, products, orderStatClickHandler, orderShippedData, orderCancelledData, multiLineItems, ordersFulfilled };
}
export default useAdminDashboard;
