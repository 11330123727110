import React from 'react';
import styles from './dropdownTwo.module.css';

const DropDownTwo = ({ showMenu, position, onClose, dropDownOption, handleOperation, selectedEdge }) => {
    return (
        <>
            {showMenu && (
                <div
                    className={styles.popupMenu}
                    style={{ width: '250px', top: position.top + 20, left: position.left - 20 }}
                >
                    {dropDownOption.map((option, index)=>
                        <div key={index} className={`${ styles.menuItem } ${ selectedEdge?.operation?.some(s => s === option?.value) && styles.active }`} onClick={()=>handleOperation(option.value)}>{option.name}</div>
                    )}
                </div>
            )}

            {showMenu && <div className={styles.overlay} onClick={onClose}></div>}
        </>
    );
}

export default DropDownTwo;