// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.integrations_integrationsList__Rc49q {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 47px 0;
}

.integrations_integrationHeader__bZCj1 {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.integrations_createIntegrationBtn__RRQ23 {
    align-items: center;
    color: var(--color-white);
    background: linear-gradient(88.9deg, #6328DD 0.83%, #446CEB 47.73%, #0FC1DC 99.56%);
    border: none;
    border-radius: 100px;
    display: flex;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    gap: 10px;
    outline: none;
    padding: 10px 15px;
    text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/views/integrations/layout/integrations.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,mFAAmF;IACnF,YAAY;IACZ,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,aAAa;IACb,kBAAkB;IAClB,0BAA0B;AAC9B","sourcesContent":["\n.integrationsList {\n    display: flex;\n    flex-direction: column;\n    gap: 2rem;\n    margin: 47px 0;\n}\n\n.integrationHeader {\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n}\n\n.createIntegrationBtn {\n    align-items: center;\n    color: var(--color-white);\n    background: linear-gradient(88.9deg, #6328DD 0.83%, #446CEB 47.73%, #0FC1DC 99.56%);\n    border: none;\n    border-radius: 100px;\n    display: flex;\n    font-family: Outfit;\n    font-size: 14px;\n    font-weight: 400;\n    gap: 10px;\n    outline: none;\n    padding: 10px 15px;\n    text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"integrationsList": `integrations_integrationsList__Rc49q`,
	"integrationHeader": `integrations_integrationHeader__bZCj1`,
	"createIntegrationBtn": `integrations_createIntegrationBtn__RRQ23`
};
export default ___CSS_LOADER_EXPORT___;
