// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stopwatch_time__623FX {
    font-family: Outfit;
    font-weight: 500;
    font-size: 24px;
}
@media screen and (max-width: 1378px) {
  .stopwatch_time__623FX {
    font-size: 18px;
}
}
`, "",{"version":3,"sources":["webpack://./src/commonComponents/stopwatch/stopwatch.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;AACA;EACE;IACE,eAAe;AACnB;AACA","sourcesContent":[".time {\n    font-family: Outfit;\n    font-weight: 500;\n    font-size: 24px;\n}\n@media screen and (max-width: 1378px) {\n  .time {\n    font-size: 18px;\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"time": `stopwatch_time__623FX`
};
export default ___CSS_LOADER_EXPORT___;
