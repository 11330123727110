import React from 'react';
import * as d3 from 'd3';

const Arc = ({ data, index, createArc, colors, format, showPercentage }) => (
    <g key={index} className="arc">
        <path className="arc" d={createArc(data)} fill={colors(index)} />
        <text
            transform={`translate(${ createArc.centroid(data) })`}
            textAnchor="middle"
            fill="#fefefe"
            fontSize="12"
        >
            {showPercentage &&format(data.value)}
        </text>
    </g>
);

const PieChart = ({
    data: payloadData = [],

    width = 300,
    height = 300,
    innerRadius = 0,
    outerRadius = 100,
    colors: customColors = [ '#4B9EFF', '#7F3FBF', '#FF7F0E' ],
    showPercentage=true
}) => {
    const createPie = d3
        .pie()
        .padAngle(0)
        .value(d => d.value)
        .sort((a, b) => a.value > b.value);

    const createArc = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius);

    const colors = d3.scaleOrdinal()
        .range(customColors);

    const format = d3.format('.0f'); // format with one decimal place

    const data = createPie(payloadData);

    return (
        <svg width={width} height={height}>
            <g transform={`translate(${ width / 2 }, ${ height / 2 })`}>
                {data.map((d, i) => (
                    <Arc
                        key={i}
                        index={i}
                        data={d}
                        createArc={createArc}
                        colors={colors}
                        format={(value) => `${ format(value) }%`}
                        showPercentage={showPercentage}
                    />
                ))}
            </g>
        </svg>
    );
};

export default PieChart;