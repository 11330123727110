import React from 'react'
import Table from '../../../../commonComponents/table/Table.jsx';
import useDataGridTable from '../functionality/DataGridTable.func';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import styles from './dataGridTable.module.css';

function DataGridTable({ title, headerList, rowsData, totalRows }) {
    const { columns, data: flattenedData, loading, currentPage, setPageSize, totalRecords, setCurrentPage
    } = useDataGridTable({}, headerList, rowsData );
    return (
        <div className={styles.tableWrapper}>
            <Typography variant='heading'>{title}</Typography>
            <Table
                loading={loading}
                headerList={columns.length > 1 ? columns : []}
                totalRecords={(totalRows) ? totalRows :totalRecords}
                setPageSize={setPageSize}
                rowsData={flattenedData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
        </div>
    )
}

export default DataGridTable