import React from 'react';
import styles from './Summary.module.css';
import AppLoader from '../../../../appLoader';
import Spinner from '../../../../commonComponents/spinner/Spinner.jsx';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import { useParams } from 'react-router-dom';
import useSummary from '../functionality/Summary.func';
import ShipmentNoticeTable from '../../../../components/shipmentNoticeTable/ShipmentNoticeTable.jsx';
// import Table from '../../../../commonComponents/table/Table.jsx';
import SummaryItemTable from '../../../../components/summaryTable/SummaryItemTable.jsx';
const Summary = () => {
    const { invoiceNo } = useParams();
    const { data, loading, error, summaryHeaderData } = useSummary(invoiceNo);
    const options = {
        mappings: {
            warehouse: 'warehouse.name',
            customer: 'customer.name',
            item: 'item.sku',
            lot: 'lot.id'
        }
    }
    
    return (
        <AppLoader>
            <div className={styles.adminDashboard}>

                <div className={styles.mainWrapper}>
                    {loading ? <Spinner /> :  error?<Typography variant = 'error'>{ error }</Typography> : (
                        <>
                            <div className={styles.header}>
                                <Typography variant='heading'>Invoice number ({invoiceNo})</Typography>
                            </div>
                            <ShipmentNoticeTable headerList={summaryHeaderData || []} rowsData={ data || [] } mappings={options?.mappings} totalRows={data?.length>0?1:0}/>
                            <div className={styles.header}>
                                <Typography variant='heading'>Items</Typography>
                            </div>
                            <SummaryItemTable mappings={options.mappings} urlData={
                                {
                                    url: `/get-items-by-id/pos/${ invoiceNo }`,
                                    service: 'collectivedata',
                                }
                            }/>
                        </>
                    )}
                </div>
            </div>
        </AppLoader >
    )
};

export default Summary;