// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs_tabsSectionWrapper__rKnbO{
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 10px;
    padding: 10px 0;
}
.Tabs_tabsWrapper__Vc30F .Tabs_tab__Q7Qkg{
    text-transform: capitalize;
    padding: 10px;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color:#000000B2;
    cursor: pointer;
    margin-right: 20px;
}
.Tabs_tabsWrapper__Vc30F .Tabs_tab__Q7Qkg.Tabs_activeTab__0819B{
    background-color: #4371EC;
    color: white;
    font-weight: 500;
    border-radius: 5px;
}

.Tabs_tabsWrapper__Vc30F{
    margin-right: 10px;
    /* overflow: auto;
    max-height: 300px; */
}

.Tabs_tabsContentWrapper__GBEY3{
    margin-bottom: 2rem;
    padding: 2rem;
    display: grid;
    background-color: #F5F4F4;
    min-height: 320px;
    align-items: baseline;
}`, "",{"version":3,"sources":["webpack://./src/components/tabs/layout/Tabs.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,eAAe;AACnB;AACA;IACI,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB;wBACoB;AACxB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,yBAAyB;IACzB,iBAAiB;IACjB,qBAAqB;AACzB","sourcesContent":[".tabsSectionWrapper{\n    display: grid;\n    grid-template-columns: 1fr 5fr;\n    gap: 10px;\n    padding: 10px 0;\n}\n.tabsWrapper .tab{\n    text-transform: capitalize;\n    padding: 10px;\n    font-family: Outfit;\n    font-size: 18px;\n    font-weight: 400;\n    line-height: 24px;\n    color:#000000B2;\n    cursor: pointer;\n    margin-right: 20px;\n}\n.tabsWrapper .tab.activeTab{\n    background-color: #4371EC;\n    color: white;\n    font-weight: 500;\n    border-radius: 5px;\n}\n\n.tabsWrapper{\n    margin-right: 10px;\n    /* overflow: auto;\n    max-height: 300px; */\n}\n\n.tabsContentWrapper{\n    margin-bottom: 2rem;\n    padding: 2rem;\n    display: grid;\n    background-color: #F5F4F4;\n    min-height: 320px;\n    align-items: baseline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsSectionWrapper": `Tabs_tabsSectionWrapper__rKnbO`,
	"tabsWrapper": `Tabs_tabsWrapper__Vc30F`,
	"tab": `Tabs_tab__Q7Qkg`,
	"activeTab": `Tabs_activeTab__0819B`,
	"tabsContentWrapper": `Tabs_tabsContentWrapper__GBEY3`
};
export default ___CSS_LOADER_EXPORT___;
