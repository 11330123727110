import React from 'react';
import styles from './statCounterIcon.module.css';
import LucideIcon from '../../../../commonComponents/lucideicon/LucideIcon.jsx';
import useStatCounterIcon from '../functionality/useStatCounterIcon.func';
import Spinner from '../../../../commonComponents/spinner/Spinner.jsx';
import Typography from '../../../../commonComponents/typography/Typography.jsx';

const StatCounterIcon = ({ id='', statName, count, iconName, keys, percentage, iconColor, urlEndPoint={}, onClick=()=>{}, height='' }) => {
    let indicationArraw = percentage > 0 ? 'ArrowUp' :'ArrowDown';
    const { data:statData, loading, error } = useStatCounterIcon(urlEndPoint);
    const clickHandler = () => {
        onClick();
    }
    const handlePOs=()=>{
        return (
            <div id={id} className={`${ styles.statCounter }`} onClick={clickHandler}>
                <div className={styles.icon} style={{ background: iconColor }}>
                    <LucideIcon name={iconName} size={30}/>
                </div>
                <div className={styles.stats}>
                    <p>{statName}</p>
                    <div className={styles.countWrapper}>
                        <p className={styles.statCount}>{urlEndPoint?.url ? (statData?.[ keys ]?.count || statData?.total_count || 0) : count}</p>
                        <span className={styles.percentageWrapper} style={{ background: iconColor }}>
                            <LucideIcon name={indicationArraw} size={15}/>
                            {percentage}%
                        </span>
                    </div>
                </div>
            </div>
        )
    }
    return (
        urlEndPoint?.url && loading  ?
            <div className={styles.loaderContainer}>
                <Spinner height={height ||'136px'}/></div> :
            error?<Typography variant='error'>{error}</Typography>:handlePOs()
    )

};

export default StatCounterIcon;