import React, { useEffect } from 'react'
import styles from './FormStep.module.css'
import ConfigMapperFieldV2 from '../../../views/ConfigMapperV2/layout/ConfigMapperFieldV2.jsx'
import useConfigMapperField from '../../../views/configMapper/functionality/useConfigMapperFeild.func';
import StepSlider from '../../stepSlider/layout/StepSlider.jsx';
import { capitalizeCamelCaseWords, capitalizeSnakeCaseWords, updateObjectWithNestedKey } from '../../../helpers';
import FormGroup from './FormGroup.jsx';
import useFormStep1 from '../functionality/useFormStep1.func';
import useFormStep2 from '../functionality/useFormStep2.func';
import FieldMappingsPreveiwPopup from './FieldMappingsPreveiwPopup.jsx';
import PopupWrapper from '../../../commonComponents/popupWrapper/PopupWrapper.jsx';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import { setMappingConfiguration, setServicesData } from '../../../redux/mappingConfiguration/Slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfigurationData, selectServicesData } from '../../../redux/mappingConfiguration/Selector';
import FormStep from './FormStep.jsx';
import configStepData from '../../../dummyData/ConfigurationMapperV2/ConfigMapperStepsData.json'
// import suggestionDummyData from '../../../views/ConfigMapperV2/data/getsuggestions.json'
const FormStep2 = ({
    fieldInputData,
    sliderData,
    fieldLayout = [],
    setConfigFormData = () => {},
    configFormData = {},
    setActiveFormStep = () => {},
    fetchedData = {},
    validateData,
    setValidateData = () => {},
    fetchedDataShown,
    setFetchedDataShown=()=>{},
    activeStep=activeStep,
    collections=[]
}) => {
    // states
    const [ activeServiceStep, setActiveStep ] = React.useState(0);
    const [ isVerified, setIsVerified ] = React.useState(false);
    const [ showServices, setShowServices ] = React.useState(false);
    const [ formValues, setFormValues ] = React.useState({});
    const [ fieldData, setFieldData ] = React.useState([
        ...JSON.parse(JSON.stringify(fieldInputData)),
    ]);
    const [ open, setOpen ] = React.useState(false);
    // Functions import
    const { convertToNested, addEmptyObjectIfEmptyValue } =
        useConfigMapperField();
    const { prepareFormData } = useFormStep1();
    const {
        verifyFieldMappings,
        fieldsPreviewData,
        validationLoading,
        getSuggestionsField,
        suggestionData,
        loading,
        addCollectionFieldsToJSONLayout,
        createCollectionPayload
    } = useFormStep2();

    // local functions
    const onClose = () => {
        setOpen(false);
    };

    // function isBoolean(value) {
    //     return value === 'true' || value === 'false';
    // }

    // Declaring and Assigning variables
    const requiredServiceFields = [
        'custom_field_mappings',
        'real_time_response_nested_field',
        'static_query_params',
        'collection_name'
    ];
    let servicesConfigObj = {};
    const dispatch = useDispatch()
    const configurationStateData = useSelector(selectConfigurationData)
    const mappingConfigurationStateData = configurationStateData?.mappingConfigurationData
    const servicesData = useSelector(selectServicesData)
    const nestedMappingConfigurationStateData = convertToNested(mappingConfigurationStateData)
    //getting sliders steps info
    const sliderStepInfo = React.useMemo(() => {
        return Object.keys(nestedMappingConfigurationStateData?.endpoints || {}).map((key) => ({
            label: capitalizeCamelCaseWords(capitalizeSnakeCaseWords(key)),
            value: key?.toLowerCase() + 'Service',
            key: key.toLowerCase(),
        }));
    }, [ showServices ]);
    console.log(sliderStepInfo, '#sliderStepInfo', mappingConfigurationStateData)
    const activeService = sliderStepInfo?.[ activeServiceStep ];
    const key = activeService?.label?.toLowerCase();

    // TODO
    // useEffect(() => {
    //     const key = activeService?.label?.toLowerCase();
    //     getSuggestionsField(key, validateData[ key ]?.data);
    // }, [ key ]);

    // consoles
    // Getting field mappings according to activeservice
    console.log(useEffect, validateData, getSuggestionsField, key, toast, isVerified, fieldLayout, fetchedData, suggestionData, addCollectionFieldsToJSONLayout, configurationStateData)

    React.useEffect(() => {
        setIsVerified(false);
        console.log('hello useeffect')
        // TODO
        // if (
        //     !sliderStepInfo ||
        //     !fieldMappingsData ||
        //     !fetchedData ||
        //     !suggestionData
        // )
        //     return;
        console.log(sliderStepInfo, '#sliderStepInfo')
        if(sliderStepInfo && sliderStepInfo.length > 0){
            const activeService = sliderStepInfo[ activeServiceStep ];
            console.log('#multipleService', activeService, sliderStepInfo, configFormData)
            addCollectionFieldsToJSONLayout(fetchedDataShown, setFetchedDataShown, fieldLayout, fetchedData, suggestionData, configurationStateData, activeService, fieldInputData, setFieldData, setFormValues, configFormData, nestedMappingConfigurationStateData)
        }
    }, [ sliderStepInfo, activeServiceStep ]);

    // Event Handlers
    const inputChangeHandler = (event) => {
        if (event?.target?.name) {
            setFormValues((prevState) => ({
                ...prevState,
                [ event.target.name ]: event.target.value,
            }));
            dispatch(setServicesData({ key:[ sliderStepInfo[ activeServiceStep ].key ],data : { [ event.target.name ]: event.target.value } }))
            console.log(updateObjectWithNestedKey, setMappingConfiguration, useDispatch, useSelector, selectConfigurationData, 'OUTOFFOCUSISSUE', setServicesData)
        }
    };

    const formSubmitHandler = (event) => {
        event.preventDefault();
        const formElements = event.target.elements;
        const finalFormData = prepareFormData(
            formElements,
            fieldData,
            formValues,
            activeService?.key
        );
        // Adding empty objects for required fields
        let convertedFinalFormData = convertToNested(finalFormData);
        addEmptyObjectIfEmptyValue(
            convertedFinalFormData,
            requiredServiceFields
        );
        // convert collection to array
        console.log(servicesData?.[ activeService?.key ],activeService.key, '#collectionFieldServicess', servicesData)
        const transformedData = createCollectionPayload(servicesData, activeService)

        convertedFinalFormData[ 'collections' ] = transformedData
        // delete unrequired fields
        delete convertedFinalFormData[ 'field_name[]' ];
        delete convertedFinalFormData[ 'input_field_name[]' ];
        delete convertedFinalFormData[ 'preview' ];
        delete convertedFinalFormData[ 'next' ];
        delete convertedFinalFormData[ 'input_field_name[]' ];
        if (convertedFinalFormData[ 'response_nested_field' ] === '') convertedFinalFormData[ 'response_nested_field' ] = null;
        console.log(transformedData, '#transformedData');
        console.log(
            convertedFinalFormData,
            'nestedcustommapping',
            formValues,
            finalFormData,
            servicesData, transformedData
        );
        setConfigFormData((prev) => {
            let oldServicesData = prev?.[ 'services' ] || {};
            oldServicesData[
                sliderStepInfo[ activeServiceStep ].value.replace('Service', '')
            ] = convertedFinalFormData;
            servicesConfigObj = { ...prev, services: { ...oldServicesData } };
            if(event.nativeEvent.submitter.name === 'preview'){
                console.log(servicesConfigObj, '#configFormDatapreview')
                verifyFieldMappings(servicesConfigObj, setOpen, activeService?.key);
            }
            return servicesConfigObj;
        });

        if(event.nativeEvent.submitter.name === 'next'){
            setActiveStep((prev) => {
                console.log(prev, 'activeServiceStep')
                if (prev === sliderStepInfo.length - 1) {
                    setActiveFormStep(1);
                    return prev;
                } else {
                    return ++prev;
                }
            });
            setFormValues({});
            window.scrollTo(0, 0);
        }
    };

    const verifyClickHandler = () => {
        setIsVerified(true);
        onClose();
    };

    const previousClickHandler = () => {
        setActiveStep((prev) => {
            if (prev === 0) {
                return prev;
            } else {
                return --prev;
            }
        });
        setFormValues({});
        window.scrollTo(0, 0);
    }
    console.log(fieldData, '#fieldData')
    // JSX
    return (
        <>
            <FormStep
                key={1}
                fieldInputData={[ ...configStepData[ 0 ].inputData ]}
                sliderData={configStepData[ 0 ]?.sliderData}
                setConfigFormData={setConfigFormData}
                fetchedData={fetchedData}
                setActiveStep={setShowServices}
                configFormData={configFormData}
                setValidateData={setValidateData}
                activeStep={activeStep}
                collections={collections}
            />
            {showServices && <form
                className={`${ styles.formWrapper } ${
                    sliderData?.showSlider ? styles.withSlider : ''
                }`}
                onSubmit={formSubmitHandler}
            >
                {sliderData?.showSlider && (
                    <div className={styles.sliderWrapper}>
                        <StepSlider
                            key={uuid()}
                            vertical={sliderData?.isVertical}
                            activeStep={activeServiceStep}
                            stepsInfo={sliderStepInfo}
                            showStepNumber={sliderData?.showStepNumber}
                        />
                    </div>
                )}

                <div className={styles.inputFieldsWrapper}>
                    {fieldData?.map((fieldRow, i) => {
                        if (fieldRow?.styleVariant === 'group') {
                            return <FormGroup
                                key={sliderStepInfo[ activeServiceStep ].value+i}
                                fieldData={fieldRow?.data[ 0 ]}
                                formValues={formValues}
                                onInputChange={inputChangeHandler}
                                loading={loading}
                                service={sliderStepInfo?.[ activeServiceStep ]?.value}
                            />
                        } else {
                            return (
                                <div
                                    key={
                                        sliderStepInfo[ activeServiceStep ].value +
                                        uuid()
                                    }
                                    className={`${ styles.row } ${
                                        styles[
                                            `col-${ fieldRow?.columnsCount || 3 }`
                                        ]
                                    }`}
                                >
                                    {fieldRow?.data?.map((inputData) => (
                                        <ConfigMapperFieldV2
                                            fieldData={inputData}
                                            fieldListData={fieldData}
                                            key={inputData?.id  || uuid()}
                                            formData={formValues}
                                            setFieldData={setFieldData}
                                            onInputChange={inputChangeHandler}
                                            service={sliderStepInfo?.[ activeServiceStep ]?.key}
                                            collections={collections}
                                        />
                                    ))}
                                </div>
                            );
                        }
                    })}

                    <div className={styles.formSubmitWrapper}>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                previousClickHandler();
                            }}
                        >
                            Previous
                        </button>
                        <input
                            type="submit"
                            className={styles.primary}
                            value={
                                validationLoading ? 'Please Wait...' : 'Preview'
                            }
                            name='preview'
                            disabled={validationLoading}
                        />
                        <input
                            type="submit"
                            className={styles.primary}
                            value={ 'Next' }
                            name='next'
                            disabled={validationLoading}
                        />
                    </div>
                </div>
            </form>
            }
            {open && fieldsPreviewData && (
            // {open && (
                <PopupWrapper
                    isOpen={open}
                    onClose={onClose}
                    width="60%"
                    showDots={false}
                    customOverLayStyles={{ overflow:'visible' }}
                >
                    <FieldMappingsPreveiwPopup
                        data={fieldsPreviewData}
                        onClose={onClose}
                        onVerifyClick={verifyClickHandler}
                    />
                </PopupWrapper>
            )}
        </>
    );
};

export default FormStep2