import React from 'react';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import styles from './statCounterII.module.css';
import useStatCounterII from '../functionality/StatCounterII.func';
import Spinner from '../../../../commonComponents/spinner/Spinner.jsx';
import { getClassByValue } from '../../../../views/inventory/list/functionality/inventoryWidgets.func';
import LucideIcon from '../../../../commonComponents/lucideicon/LucideIcon.jsx';

const StatCounterII = ({ id='', uriEndPoint={}, className, data={}, stat_level='' }) => {
    const { dataValue, stat_name }=data;
    const { data:statData, loading, error } = useStatCounterII(uriEndPoint);
    return (
        <>
            {loading?
                <div className={styles.loaderContainer}>
                    <Spinner height={'10vh'}/>
                </div>:
                error? <Typography variant='error'>{error}</Typography>
                    : <div id={id} className={`${ styles.mainWrapper } ${ className } ${ styles[ getClassByValue(statData?.[ stat_name?.key ]?.[ stat_name?.flagAccessor ] || dataValue?.[ stat_name?.key ]?.[ stat_name?.flagAccessor ||'flag' ] || stat_level || '') ] }`}>
                        { <div className={`${ styles.iconWrapper } icon`} >
                            <LucideIcon name="ShoppingBag"/>
                        </div>}
                        <div className={styles.contentWrapper}>
                            {stat_name?.data?.map((stat)=> <div key={stat?.statName} className={styles.flexWrapper}>
                                <Typography className={styles.statName}>{stat?.statName}</Typography>
                                {<Typography className={styles.statCount}>{statData?.[ stat_name?.key ]?.[ stat?.countAccessor ] || dataValue?.[ stat_name?.key ]?.[ stat?.countAccessor ] || stat?.count || 0}</Typography>}
                            </div>)}
                        </div>

                    </div>
            }
        </>
    )
};

export default StatCounterII;