import * as React from 'react';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';
import styles from './table.module.css';
import Spinner from '../spinner/Spinner.jsx';

export default function Table({
    headerList,
    rowsData,
    totalRecords = 0,
    setCurrentPage = () => {},
    setPageSize = () => {},
    loading,
    showAllColumns= false,
    props={},
    hiddenColumns=[],
    isShowAllHeader=true
}) {
    const [ paginationModel, setPaginationModel ] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [ data, setData ] = React.useState(headerList);
    const apiRef = useGridApiRef();
    const handleColumns = () => {
        if (!showAllColumns){
            const news = headerList.slice(5);
            const results = news.reduce((result, item) => {
                result[ item.field ] = false;
                return result;
            }, {});
            hiddenColumns.map(hiddenColumn => {
                const colRow = headerList.filter(header=>header?.field === hiddenColumn);
                if(colRow.length>0){
                    results[ hiddenColumn ] = false
                }
            })
            setData(results);
        } else {
            setData(headerList);
        }
    };

    React.useEffect(() => {
        if (headerList.length > 0 && isShowAllHeader) {
            handleColumns();
        }
    }, [ headerList, isShowAllHeader ]);

    React.useEffect(() => {
        setCurrentPage(paginationModel.page);
    }, [ paginationModel ]);

    React.useEffect(() => {
        setPageSize(paginationModel.pageSize);
    }, [ paginationModel ]);

    const CustomLoader = () => (
        <div>
            <Spinner height={`${ paginationModel.pageSize * 7 }vh`} />
        </div>
    );

    const NoDataMessage = () => (
        <div className={styles.noDataMessage}>
            <p>No data available</p>
        </div>
    );

    function CustomNoRowsOverlay() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <p>No data available</p>
            </div>
        );
    }
    if (!loading && headerList.length===0) {
        return <NoDataMessage />;
    }
    else if (loading || !data) {
        return <CustomLoader />;
    }
    return (
        <div className={styles.tableWrapper}>
            <DataGrid
                className={styles.dataGrid}
                autoHeight
                ref={apiRef}
                rows={rowsData}
                columns={headerList}
                disableRowSelectionOnClick
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: CustomLoader,
                }}
                columnVisibilityModel={{
                    ...data,
                }}
                onColumnVisibilityModelChange={(updateColumn) => {
                    setData(updateColumn);
                }}
                components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                }}
                rowCount={totalRecords}
                loading={loading}
                pageSizeOptions={[ 10 ]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sx={{
                    '--DataGrid-overlayHeight': '300px',

                }}
                {...props}
            />
        </div>
    );
}