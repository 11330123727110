import React, { useEffect, useRef } from 'react';
import styles from './authProgress.module.css';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import { useAuthProgressFunc } from '../functionality/AuthProgress.func';
import { useParams, useSearchParams } from 'react-router-dom';

const AuthProgress = () => {
    const { system } = useParams();
    const [ searchParams ] = useSearchParams();

    const { loading, error, generateAccessToken } = useAuthProgressFunc();
    // To run code only once
    const hasLoadedBefore = useRef(true);
    useEffect(() => {
        if (hasLoadedBefore.current) {
            let code = searchParams.get('code');
            if (!code) {
                code = searchParams.get('spapi_oauth_code');
            }
            if (code) {
                generateAccessToken(code, system)
            }
            hasLoadedBefore.current = false;
        }
    }, [])

    return (
        <div className={styles.authLoader}>
            <div className={styles.card}>
                {!error&&(loading
                    ? <Typography variant='gradient'>Connecting...<br /> Please Wait </Typography>
                    : <Typography variant='gradient'>Connection Successful. <br /> Redirecting...</Typography>)}
                {error && <Typography variant='error'> {error}</Typography>}
            </div>
        </div>
    )
};

export default AuthProgress;
