import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    hasVisited: false,
    actionId:'',
    visitedPage: [],
    isHideModal: false,
    isModalColor: false
};

const tutorial = createSlice({
    name: 'tutorial',
    initialState,
    reducers: {
        settutorial: (state, action) => {
            state.hasVisited = action.payload;
        },
        clearTutorial: (state) => {
            state.hasVisited= false
        },
        setActionId:(state,action)=>{
            state.actionId=action.payload
        },
        clearActionId:(state)=>{
            state.actionId=''
        },
        setVisitedPage:(state,action)=>{
            state.visitedPage?.push(action.payload);
        },
        clearVisitedPage:(state)=>{
            state.visitedPage =[]
        },
        setModalColor: (state, action) => {
            state.isModalColor = action.payload
        },
        setHideModal: (state, action)=>{
            state.isHideModal = action.payload
        },
    }
});

export const tutorialReducer = tutorial.reducer;
export const { settutorial, clearTutorial, setActionId, clearActionId, setVisitedPage, clearVisitedPage, setHideModal, setModalColor } = tutorial.actions;