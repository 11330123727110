import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    organisationData: {}
};

const organisation = createSlice({
    name: 'organisation',
    initialState,
    reducers: {
        setOrganisation: (state, action) => {
            state.organisationData = action.payload;
        }
    }
});

export const organisationReducer = organisation.reducer;
export const { setOrganisation, clearOrganisation } = organisation.actions;