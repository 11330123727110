import React from 'react';

const Button = ({ disabled=false, id='', onClick, className:styles, children }) => {
    return (
        <button disabled={disabled} id={id} type="button" className={styles} onClick={onClick}>
            {children}
        </button>
    );
}

export default Button;