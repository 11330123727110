import { useEffect, useState } from 'react';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import { useNavigate } from 'react-router-dom';

const useAdminDashboardTwo = (uriEndPoint) => {
    const [ widgetLoading, setWidgetLoading ] = useState(true);
    const [ widgetData, setWidgetData ] = useState();
    const [ widgetError, setError ] = useState();
    const { callApi } = useCustomAxiosCall();
    const navigate = useNavigate();

    const getData = async () => {
        setWidgetLoading(true);
        let newUrl='';

        newUrl = uriEndPoint?.filter ? `${ uriEndPoint.url }?filter=${ uriEndPoint.filter }&start_date=${ uriEndPoint.start_date }&end_date=${ uriEndPoint.end_date }` : `${ uriEndPoint.url }?type=${ uriEndPoint.type }&fetch_by=${ uriEndPoint.fetch_by }`
        const response = await callApi({
            uriEndPoint: {
                ...uriEndPoint,
                url: newUrl
            }
        });
        if (response && response?.status === 200 && !response?.data?.error) {
            setWidgetData(response?.data || {})
            setWidgetLoading(false);
        } else {
            setError(response?.data?.error || 'Something Went Wrong. Please Try Again Later');
            setWidgetLoading(false);
        }
    }
    const orderStatClickHandler = (url = '') => {
        if (url !== '') {
            navigate(url);
        }
    }
    useEffect(() => {
        if (uriEndPoint?.url){
            getData()
        }
    }, [ uriEndPoint ])
    return { widgetData, widgetLoading, widgetError, orderStatClickHandler };
}

export default useAdminDashboardTwo;

export const getClassByValue = (indicator = '') => {
    switch (indicator) {
        case 'good': return 'stockInHand';
        case 'low': return 'sales';
        case 'medium': return 'accountPayable';
        default: return 'inboundSupply';
    }
}