import React from 'react';
import styles from './Typography.module.css';

const Typography = ({ children, className, variant = '', onClick = () => {} }) => {
    return (
        <p className={`${ styles.typography } ${ styles[ variant ] } ${ className }`} onClick={onClick}>
            {children}
        </p>
    )
};

export default Typography;