import React from 'react';
import AppLoader from '../../../appLoader';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import Table from '../../../commonComponents/table/Table.jsx';
import { useParams } from 'react-router-dom';
import styles from './orders.module.css';
import { useOrders } from '../functionality/Orders.func';

const Orders = () => {
    const { orderType } = useParams();

    const { data,
        columns,
        loading, error,
        currentPage,
        setCurrentPage,totalRecords,
        setPageSize, displayTitle  } = useOrders({ url:'/webshop-orders-detail-view', service: 'collectivedata' }, orderType);

    return  <AppLoader>
        <div>
            <div className={styles.mainWrapper}>
                <div className={styles.headerWrapper}>
                    <div className={styles.header}>
                        <Typography variant='gradient'>Orders Overview</Typography>
                    </div>
                    <Typography variant='title'>{displayTitle}</Typography>
                </div>
                {(!loading && error) ?
                    <div className={styles.heading}><Typography variant='error'>{error}</Typography></div>:
                    <Table
                        loading={loading}
                        headerList={columns}
                        totalRecords={totalRecords}
                        setPageSize={setPageSize}
                        rowsData={data}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            </div>
        </div>
    </AppLoader>
}

export default Orders;