import React, { useEffect, useRef } from 'react';
import styles from './authProgress.module.css';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import { useAuthProgressFunc } from '../functionality/AuthProgress.func';
import { useSearchParams } from 'react-router-dom';

const AuthProgress = () => {
    const [ searchParams ] = useSearchParams();

    const { loading, error, sendAccessToken, handleConnectWhiplash,handleConnectTiktok  } = useAuthProgressFunc();
    // To run code only once
    const hasLoadedBefore = useRef(true);
    useEffect(() => {
        if (hasLoadedBefore.current) {
            const code = searchParams.get('code')
            if (searchParams.get('connector') === 'whiplash') {
                handleConnectWhiplash(code)
            } else if (searchParams.get('connector')?.includes('tiktok')) {
                handleConnectTiktok(code)
            } else {
                const connector = searchParams.get('connector') || ''
                sendAccessToken(code, connector)
            }
            hasLoadedBefore.current = false;
        }
    }, [])

    return (
        <div className={styles.authLoader}>
            <div className={styles.card}>
                {!error&&(loading
                    ? <Typography variant='gradient'>Connecting...<br /> Please Wait </Typography>
                    : <Typography variant='gradient'>Connection Successful. <br /> Redirecting...</Typography>)}
                {error && <Typography variant='error'> {error}</Typography>}
            </div>
        </div>
    )
};

export default AuthProgress;
