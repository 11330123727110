// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gridCar_gridCard__EuVhj {
    background-color: white;
    padding: 1rem 2rem;
    box-shadow: 0px 9px 36px 0px var(--card-box-shadow);
    border-radius: 12px;
}

.gridCar_gridCard__EuVhj .gridCar_header__RSHyb {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1.01px solid #F0F1F3
}

.gridCar_gridCard__EuVhj .gridCar_grid__aSMIW table {
    width: 100%;
}

.gridCar_gridCard__EuVhj .gridCar_grid__aSMIW table tr td,
.gridCar_gridCard__EuVhj .gridCar_grid__aSMIW table tr th {
    padding: 1rem 0;
    border-bottom: 1.01px solid #F0F1F3
}

.gridCar_gridCard__EuVhj .gridCar_grid__aSMIW table th {
    text-align: left;
}
.gridCar_iconWrapper__3Xg-L {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #344BFD;
}
.gridCar_detailWrapper__NU23q {
    display: flex;
    gap: 5px;
}
.gridCar_shipmentId__BZDb1 {
    font-weight: 500;
    font-size: 14px;
    color: var(--color-grey-400);
}
.gridCar_status__tQ\\+qB {
    font-weight: 400;
    font-size: 13px;
    color: var(--color-grey-400)
}
.gridCar_time__5DBeD {
    font-weight: 400;
    font-size: 11px;
    color: var(--color-grey-400);
    margin-top: 10px
}`, "",{"version":3,"sources":["webpack://./src/components/widgets/gridCard/layout/gridCar.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,mDAAmD;IACnD,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,aAAa;IACb,8BAA8B;IAC9B;AACJ;;AAEA;IACI,WAAW;AACf;;AAEA;;IAEI,eAAe;IACf;AACJ;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,QAAQ;AACZ;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,4BAA4B;AAChC;AACA;IACI,gBAAgB;IAChB,eAAe;IACf;AACJ;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,4BAA4B;IAC5B;AACJ","sourcesContent":[".gridCard {\n    background-color: white;\n    padding: 1rem 2rem;\n    box-shadow: 0px 9px 36px 0px var(--card-box-shadow);\n    border-radius: 12px;\n}\n\n.gridCard .header {\n    padding-bottom: 10px;\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 1.01px solid #F0F1F3\n}\n\n.gridCard .grid table {\n    width: 100%;\n}\n\n.gridCard .grid table tr td,\n.gridCard .grid table tr th {\n    padding: 1rem 0;\n    border-bottom: 1.01px solid #F0F1F3\n}\n\n.gridCard .grid table th {\n    text-align: left;\n}\n.iconWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    width: 40px;\n    height: 40px;\n    border-radius: 50px;\n    background-color: #344BFD;\n}\n.detailWrapper {\n    display: flex;\n    gap: 5px;\n}\n.shipmentId {\n    font-weight: 500;\n    font-size: 14px;\n    color: var(--color-grey-400);\n}\n.status {\n    font-weight: 400;\n    font-size: 13px;\n    color: var(--color-grey-400)\n}\n.time {\n    font-weight: 400;\n    font-size: 11px;\n    color: var(--color-grey-400);\n    margin-top: 10px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridCard": `gridCar_gridCard__EuVhj`,
	"header": `gridCar_header__RSHyb`,
	"grid": `gridCar_grid__aSMIW`,
	"iconWrapper": `gridCar_iconWrapper__3Xg-L`,
	"detailWrapper": `gridCar_detailWrapper__NU23q`,
	"shipmentId": `gridCar_shipmentId__BZDb1`,
	"status": `gridCar_status__tQ+qB`,
	"time": `gridCar_time__5DBeD`
};
export default ___CSS_LOADER_EXPORT___;
