import React from 'react';
import styles from './configMapperField.module.css'
import { capitalizeCamelCaseWords, capitalizeSnakeCaseWords } from '../../../helpers';
import useConfigMapperField from '../functionality/useConfigMapperFeild.func';
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';
import { v4 as uuid } from 'uuid';
import { MenuItem, Select } from '@mui/material';
import configData from '../../../dummyData/ConfigurationMapper/ConfigMapper.json'

const ConfigMapperField = ({ data,inputValues={}, onInputChange=()=>{}, parentField='', selected=false, setFieldListData=()=>{}, fieldList=[], toggle=()=>{} }) => {
    const { getFieldComponent, hasChidlren, addCustomMappingEntry, findFieldById } = useConfigMapperField(data)
    const paddingAmount = ((parentField !== '') ? parentField.split('#').length : 0);
    const addMoreFieldsClickHandler=(parentField, data)=>{
        let dropDownValues = [];
        const newFieldData = {
            field_type:'custom',
            name_field_type:data?.field_children[ 0 ]?.name_field_type, 
            field_name_value:'', 
            field_value:'', 
            field_id:uuid()
        }
        if(data?.field_children[ 0 ]?.name_field_type === 'dropdown'){
            dropDownValues = configData?.[ data?.field_name ] || []
            newFieldData.field_options = dropDownValues
        }
        const updatedFieldList = addCustomMappingEntry(fieldList, parentField, newFieldData)
        setFieldListData([ ...updatedFieldList ]) 
    }
    const [ customInputData, setCustomInputData ] = React.useState({ name:'', value:'' }) 
    data.field_id = (data?.field_id) ? data.field_id : uuid()
    
    const customInputChangeHandler = (e, id='') => {
        let inputFieldId = ''
        if(id !== ''){
            inputFieldId = id;
        }else{
            inputFieldId =  e.target.id
        }
        if(inputFieldId.includes('#name')){
            const fieldNameValue = e.target?.value
            const id = inputFieldId.split('#')[ 0 ]
            const fieldObj = findFieldById(fieldList, id)
            fieldObj[ 'field_name_value' ] = fieldNameValue
            setCustomInputData((prevState)=>{return { ...prevState, name:fieldNameValue }})

        }
        else if(inputFieldId.includes('#value')){
            const fieldValue = e.target?.value
            const id = inputFieldId.split('#')[ 0 ]
            const fieldObj = findFieldById(fieldList, id)
            fieldObj[ 'field_value' ] = fieldValue
            setCustomInputData((prevState)=>{return { ...prevState, value:fieldValue } })
        }
    }

    const getCustomNameInput = (field_type='', parentField='') => {
        const field_name = parentField?.split('#').splice(-1)?.[ 0 ]
        if(configData?.[ field_name ]?.length > 0 && !data?.field_options?.length ){
            data.field_options = configData?.[ field_name ]
        }
        switch (field_type) {
            case 'dropdown':
                return   <Select
                    key={data?.field_id}
                    value={data?.field_name_value || customInputData?.name}
                    name={`${ parentField }#input_name[]`}
                    onChange={(e)=>{customInputChangeHandler(e, data?.field_id + '#name')}}
                >
                    {data?.field_options?.map((field_option) => (
                        <MenuItem value={field_option?.value} key={field_option?.value}>{field_option?.title}</MenuItem>
                    ))}
                </Select>
                
            default:
                return <input type='text' name={`${ parentField }#input_name[]`} placeholder={'Type Here...'} value={data?.field_name_value || customInputData?.name} onChange={(e)=>{customInputChangeHandler(e)}} key={data?.field_id} id={data?.field_id + '#name'}/>
        }
    }

    return <div className={styles.formFieldWrapper} id={data?.field_type === 'array'?`${ parentField }#${ data?.field_name }#array`:`${ parentField }#${ data?.field_name }`} >
        <div className={styles.parentRow}>
            <div className={styles.row} style={{ marginLeft:`${ paddingAmount*120 }px` }} >
                <div className={`${ styles.formLabel } `}>
                    {
                        (data?.field_type === 'custom')
                            ? getCustomNameInput(data?.name_field_type, parentField)
                            : <>
                                <div className={styles.inputLabelWrapper} onClick={() => toggle()}>
                                    { hasChidlren(data) && selected === 'active' && <div className={styles.line}></div> }
                                    {(data?.field_label ? data?.field_label : capitalizeSnakeCaseWords(capitalizeCamelCaseWords(data?.field_name)))}
                                    {hasChidlren(data) && <span className={styles.iconWrapper}>{selected === 'active' ? <LucideIcon name={'ChevronUp'} size={20} /> : <LucideIcon name={'ChevronDown'} size={20}/>}</span>}
                                </div>
                                {data?.can_add_fields && selected === 'active' && <LucideIcon name={'CirclePlus'} size={20} onClick={()=>{addMoreFieldsClickHandler(`${ parentField }#${ data?.field_name }`, data)}} />}
                            </>
                    }
                    
                </div>
                <div className={styles.formInput} >
                    {   (data?.field_type === 'custom')
                        ? <div className={styles.customInputFieldWrapper}>
                            <input type='text' name={`${ parentField }#input_value[]` } placeholder='Type Here...' onChange={(e)=>{customInputChangeHandler(e)}} value={data?.field_value ||customInputData?.value} id={data?.field_id + '#value'}/> 
                        </div>
                        : getFieldComponent(data, inputValues, onInputChange, parentField,selected)
                    }
                </div>
            </div>
        </div>
    </div>

}

export default ConfigMapperField