// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch_switch__dYH\\+8 {
    align-items: center;
    display: flex;
    gap: 7px;
}

.switch_status__prrGB {
    appearance: none;
    border: 1px solid #878787;
    border-radius: 50%;
    cursor: pointer;
    height: 15px;
    outline: none;
    width: 15px;
}

.switch_statusOn__50HTu:checked {
    background-color: var(--color-green);
    border: 1px solid var(--color-green);
}

.switch_statusOff__t4KHj:checked {
    background-color: var(--color-red);
    border: 1px solid var(--color-red);
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/switch/switch.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,aAAa;IACb,WAAW;AACf;;AAEA;IACI,oCAAoC;IACpC,oCAAoC;AACxC;;AAEA;IACI,kCAAkC;IAClC,kCAAkC;AACtC","sourcesContent":[".switch {\n    align-items: center;\n    display: flex;\n    gap: 7px;\n}\n\n.status {\n    appearance: none;\n    border: 1px solid #878787;\n    border-radius: 50%;\n    cursor: pointer;\n    height: 15px;\n    outline: none;\n    width: 15px;\n}\n\n.statusOn:checked {\n    background-color: var(--color-green);\n    border: 1px solid var(--color-green);\n}\n\n.statusOff:checked {\n    background-color: var(--color-red);\n    border: 1px solid var(--color-red);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `switch_switch__dYH+8`,
	"status": `switch_status__prrGB`,
	"statusOn": `switch_statusOn__50HTu`,
	"statusOff": `switch_statusOff__t4KHj`
};
export default ___CSS_LOADER_EXPORT___;
