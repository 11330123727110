// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.barChart_barChartContainer__cRFRq {
    margin: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.barChart_barChartTitle__q\\+-ao {
    font-size: 20px;
    font-weight: 600;
}

.barChart_svg__XC4oX {
    overflow: visible;
}

.barChart_barChartHeader__iwMb0 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/commonComponents/charts/barChart/barChart.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":[".barChartContainer {\n    margin: 30px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.barChartTitle {\n    font-size: 20px;\n    font-weight: 600;\n}\n\n.svg {\n    overflow: visible;\n}\n\n.barChartHeader {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"barChartContainer": `barChart_barChartContainer__cRFRq`,
	"barChartTitle": `barChart_barChartTitle__q+-ao`,
	"svg": `barChart_svg__XC4oX`,
	"barChartHeader": `barChart_barChartHeader__iwMb0`
};
export default ___CSS_LOADER_EXPORT___;
