import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import styles from './barChart.module.css';

const MultiBarChart = ({
    title = '',
    data = [],
    width = 600,
    height = 400,
    labels = [],
    colors = []
}) => {
    const svgRef = useRef();
    const legendRef = useRef();
    
    useEffect(() => {
        if (!data || data.length === 0) return;
        const svg = d3.select(svgRef.current);
        const legend = d3.select(legendRef.current);
        
        // Clear existing chart
        svg.selectAll('*').remove();

        // Create scales
        const xScale = d3.scaleBand()
            .domain(data[ 0 ].map(d => d.label))
            .range([ 0, width ])
            .padding(0.2);
        
        const yScale = d3.scaleLinear()
            .domain([ 0, d3.max(data.flat(), d => +d.value) ])
            .range([ height, 0 ])
            .nice();

        // Create axes
        const xAxis = d3.axisBottom(xScale).ticks();
        const yAxis = d3.axisLeft(yScale);

        svg.append('g')
            .attr('transform', `translate(0, ${ height })`)
            .call(xAxis);
            
        svg.append('g')
            .call(yAxis);
            
        // Create bars
        const barGroup = svg.append('g');
        
        data.forEach((singleData, i) => {
            let color = colors[ i ];
            barGroup.selectAll(`.bar-group-${ i }`)
                .data(singleData)
                .enter()
                .append('rect')
                .attr('class', `bar-group-${ i }`)
                .attr('x', d => xScale(d.label) + xScale.bandwidth() / data.length * i)
                .attr('y', d => yScale(+d.value))
                .attr('width', xScale.bandwidth() / data.length)
                .attr('height', d => height - yScale(+d.value))
                .attr('fill', color)
                .attr('key', (d, index) => `bar-${ i }-${ index }`);
        });

        // Create legend
        const legendItems = legend.selectAll('.legend-item')
            .data(data)
            .join('g')
            .attr('class', 'legend-item')
            .attr('transform', (_, i) => `translate(${ i * 100 }, 0)`);

        legendItems.append('rect')
            .attr('width', 10)
            .attr('height', 20)
            .attr('fill', (_, i) => colors[ i ]);

        legendItems.append('text')
            .attr('x', 15)
            .attr('y', 20)
            .attr('font-size', '20px')
            .text((_, i) => labels[ i ]);

    }, [ data, height, width, labels ]);

    return (
        <div className={styles.barChartContainer}>
            <div className={styles.barChartHeader}>
                <span className={styles.barChartTitle}>{title}</span>
                <svg height={30} ref={legendRef} />
            </div>
            <svg className={styles.svg} ref={svgRef} width={width} height={height} />
        </div>
    );
};

export default MultiBarChart;
