import React, { useState } from 'react';
import styles from './getCheckout.module.css';
import Button from '../../commonComponents/button/Button.jsx';
import { useSelector } from 'react-redux';
import { selectOrganisationData } from '../../redux/organisation/Selector';
import AppLoader from '../../appLoader';

function GetCheckoutPage() {
    const [ query, setQuery ] = useState();
    const [ response, setResponse ] = useState('');
    const [ loading, setLoading ] = useState(false);
    console.log(response, 'response');
    const organizationData = useSelector(selectOrganisationData);
    const orgId = organizationData?.organisationData?.encrypt_id;

    const handleQueryChange = (e) => {
        setQuery(e.target.value);
    };
    console.log(query, '#query');
    const handleSubmit = async () => {
        setLoading(true);
        try {
            const res = await fetch(
                'https://dev-app.ekyam.ai/v/api/workflow/create-checkout',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-organisation-id': orgId,
                    },
                    body: query,
                }
            );
            if (res.status === 200) {
                const data = await res.json();
                setResponse(data);
                setLoading(false);
            }

        } catch (error) {
            console.error('Error sending query:', error);
            setResponse(`Error: ${ error.message }`);
            setLoading(false);
        }
        setLoading(false);

    };

    return (
        <AppLoader>
            <div className={styles.mainWrapper}>
                <div className={styles.innerWrapper}>
                    <textarea
                        className={styles.textarea}
                        style={{ width: '100%', padding: '10px' }}
                        cols="200"
                        rows="3"
                        onChange={handleQueryChange}
                        placeholder="Query"
                    />
                </div>
                <div className={styles.button}>
                    <Button
                        className={styles.saveAndCloseButton}
                        onClick={handleSubmit}
                    >
                        {loading ? '...Loading' : 'Submit'}
                    </Button>
                </div>
                <div className={styles.markdownWrapper}>
                    {`Checkout URL : ${ response.checkout_url  || '' }`}
                </div>
            </div>
        </AppLoader>
    );
}

export default GetCheckoutPage;
