import { useEffect, useState } from 'react';
import useCustomAxiosCallTwo from '../../../../hooks/useCustomAxiosCallTwo';
const useShipmentNotice = (shipnotceId) => {
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState([]);
    const [ error, setError ] = useState('');
    const { callApi } = useCustomAxiosCallTwo();
    const [ shipmentHeaderData, setShipmentHeaderData ] = useState([]);

    const getShipmentNotice = async () => {
        setError('')
        setLoading(true);
        const response = await callApi({
            uriEndPoint: {
                url: `/shipnotice-data-id/${ shipnotceId }`,
                service: 'collectivedata'
            }
        });
        if (response && response.status === 200 && !response.data.error) {
            setData(response.data?.data|| [])
            setShipmentHeaderData(response.data?.header_data || []);
        } else {
            setError(response?.data?.error || 'Something Went Wrong. Please Try Again Later');
            setLoading(false);
        }
        setLoading(false);
    }

    useEffect(() => {
        getShipmentNotice();
    }, []);

    return { data, loading, error, shipmentHeaderData }
}
export default useShipmentNotice;
