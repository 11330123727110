// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connectedConnectorSkeleton_skeletonContainer__wU0Tf {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(4, minmax(0, 0.7fr));
    margin: 2rem 0;
}`, "",{"version":3,"sources":["webpack://./src/components/skeletons/connectedConnector/connectedConnectorSkeleton.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,kDAAkD;IAClD,cAAc;AAClB","sourcesContent":[".skeletonContainer {\n    display: grid;\n    gap: 1.5rem;\n    grid-template-columns: repeat(4, minmax(0, 0.7fr));\n    margin: 2rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeletonContainer": `connectedConnectorSkeleton_skeletonContainer__wU0Tf`
};
export default ___CSS_LOADER_EXPORT___;
