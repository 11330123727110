import React, { useEffect, useState } from 'react';
import styles from './warehouseOverview.module.css';
import AppLoader from '../../../appLoader';
import CollectiveData from '../../collectiveData/list/layout/CollectiveData.jsx';
import FourStats from '../../../components/widgets/fourStats/layout/FourStats.jsx';
import data from '../../../dummyData/WarehouseOverview.json';
import LineChart from '../../../commonComponents/charts/lineChart/LineChart.jsx';
import GridWrapper from '../../../components/gridWrapper/layout/GridWrapper.jsx';
import StatCounterIcon from '../../../components/widgets/statCounterIcon/layout/StatCounterIcon.jsx';
import GridCard from '../../../components/widgets/gridCard/layout/GridCard.jsx';
import PieChartLabels from '../../../commonComponents/charts/pieChartLabels/PieChartLabels.jsx';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import Stopwatch from '../../../commonComponents/stopwatch/Stopwatch.jsx';
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';
import { getDateLocale } from '../../../helpers/index';
import ProductDetailData from '../../../dummyData/ProductDetails.json'
import useWarehouseOverviewDashboard from '../functionality/warehouseOverviewDashboard.func';
import Spinner from '../../../commonComponents/spinner/Spinner.jsx';
import { Link, useNavigate } from 'react-router-dom';
import useWarehouseOverviewDashboardTwo from '../functionality/warehouseOverviewDashboardTwo.func';
const WarehouseOverview = () => {
    const { lineChart, gridCard } = data;
    const navigate = useNavigate();
    const [ lineChartData, setLineChartData ]=useState([])
    const { data:nextShipmentData, loading, error } = useWarehouseOverviewDashboard({ url:'/eta-shipnotice', service:'collectivedata' });
    const shipmentData = nextShipmentData?.data?.[ 0 ]|| {};
    const { data:shipmentAccData, loading: shipmentAccLoading, error: shipmentAccError } = useWarehouseOverviewDashboardTwo({ url:'/widget-count', service:'collectivedata', type: 'inventory', fetch_by: 'type' });

    const { data:order3PLData, loading: order3PLLoading, error: order3PLError } = useWarehouseOverviewDashboardTwo({ url:'/total-3pl-orders', service:'collectivedata' });

    const nextShipment=[ { label: 'Shipment level No', value: shipmentData?.sender }, { label: 'Quantity', value:shipmentData?.shipnotice_item_quantity
    }, { label: 'Delivery Date', value: getDateLocale(shipmentData?.estimated_delivery_date) } ]

    useEffect(()=>{
        if(shipmentAccData?.shipment_accuracy?.data){
            const label=Object.keys(shipmentAccData)?.length>0 && Object.keys(shipmentAccData?.shipment_accuracy?.data?.[ '2024' ]) || [];
            const transformedData = [ {
                values: label?.length > 0 && label?.map(month => ({
                    Month: month,
                    value: Object.keys(shipmentAccData)?.length>0 && shipmentAccData?.shipment_accuracy?.data?.[ '2024' ][ month ]?.accuracy || 0
                }))
            } ];
            setLineChartData(transformedData);
        }
    },[ shipmentAccData ])

    const handleNavigationClick=(url='')=>{
        navigate(url);
    }
    const accuracyData= {
        title: '3PL Order',
        stats: [
            {
                label: 'Today',
                count: order3PLData?.data?.today
            },
            {
                label: 'Yesterday',
                count: order3PLData?.data?.yesterday
            },
            {
                label: 'Last 7 Days',
                count: order3PLData?.data?.last_7_days
            },
            {
                label: 'Last 28 Days',
                count: order3PLData?.data?.last_28_days
            }
        ]
    }
    return (
        <AppLoader>
            <Typography variant="gradient">Warehouse Overview</Typography>
            <GridWrapper columnsCount={0} columnsTemplate="1.5fr 2fr">
                <GridCard id="shipment_tracking" data={gridCard} />
                <GridWrapper columnsCount={1} style={{ margin:0 }}>
                    <div className={styles.nextShipmentWrapper}>
                        {loading
                            ? <Spinner height="15vh"/>
                            : error
                                ? <Typography variant='error'>{error}</Typography>
                                :
                                <Link to={`/shipment-notice/${ shipmentData?.shipnotice_id }`} style={{ textDecoration: 'none', color: 'black' }}>
                                    <div className={styles.nextShipment}>
                                        <div className={styles.innerHeader}>
                                            <div className={styles.iconWrapper}>
                                                <LucideIcon name='CalendarClock'/>
                                            </div>
                                            <div className={styles.nextShipmentTitle}>{ProductDetailData.nextShipment.title
                                            }</div>
                                        </div>
                                        <div className={styles.stopWatch}>
                                            <Stopwatch time={nextShipmentData?.data?.[ 0 ]?.estimated_delivery_date}/>
                                            <div>
                                                <LucideIcon name='AlarmClock'/>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={styles.shipmentDetails}>

                                        {nextShipment.map((obj, index)=>
                                            <div key={index}>
                                                <div className={styles.header}>{obj.label}</div>
                                                <div className={styles.value}>{obj.value}</div>

                                            </div>

                                        )}

                                    </div></Link>}
                    </div>
                    <div className={styles.statWrapper} >
                        <StatCounterIcon id="active-po"  statName="Order Pending" keys="active_pos" iconName="BarChartBig" percentage="20" iconColor="#FA5A7D" urlEndPoint={{ url:'/widget-count', service:'collectivedata', type: 'inventory', fetch_by: 'type' }} onClick={()=>{handleNavigationClick('/active-po')}}/>
                        {/* <StatCounterIcon data={data.stats[ 1 ]} /> */}
                    </div>
                </GridWrapper>
            </GridWrapper>
            <CollectiveData />
            <GridWrapper columnsCount={3}>
                {data.statsData.map((item, index)=>item.title==='3PL Order'?
                    <FourStats key={index} statsData={accuracyData} loading={order3PLLoading} error={order3PLError} />:
                    <FourStats key={index} statsData={item} />
                )}
            </GridWrapper>

            <GridWrapper columnsCount={2}>
                <div className={styles.lineChartContainer}>
                    <Typography variant="heading">Shipment Accuracy</Typography>
                    <br />
                    {shipmentAccLoading
                        ? <Spinner height="15vh"/>
                        : shipmentAccError
                            ? <Typography variant='error'>{shipmentAccError}</Typography>
                            :
                            <LineChart
                                title="Inventory breakdown by product across"
                                height={300}
                                width={550}
                                data={lineChartData}
                                labels={lineChart.labels}
                                colors={lineChart.colors}
                            />
                    }
                </div>
                <PieChartLabels data={data.pieChart} />
            </GridWrapper>
            {/* TODO: remove apploader from the component CollectiveData */}
        </AppLoader>
    );
};

export default WarehouseOverview;
