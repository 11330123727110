// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomInputV2_customInputContainer__qgpEZ{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.CustomInputV2_customInput__uHCj-{
    display: flex;
    gap: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/views/ConfigMapperV2/layout/CustomInputV2.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,SAAS;AACb","sourcesContent":[".customInputContainer{\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.customInput{\n    display: flex;\n    gap: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customInputContainer": `CustomInputV2_customInputContainer__qgpEZ`,
	"customInput": `CustomInputV2_customInput__uHCj-`
};
export default ___CSS_LOADER_EXPORT___;
