// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.node_iconWrapper__1vxRU {
       padding: 10px;
         box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    /* margin-bottom: 10px; */
    margin-top: 40px;
    /* margin-bottom: 20px; */
    position: absolute;
    top: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
        border-radius: 50%;
}
.node_cardIcon__-JNx3 {
    width: 28px;
    height: 28px;
    /* margin-bottom: 10px; */
    /* margin-top: 10px; */
    /* margin-bottom: 20px; */
}
.node_cardContent__sC-mo {
    background-color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
     display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    width: 184px;
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/node/node.module.css"],"names":[],"mappings":"AAAA;OACO,aAAa;SACX,2CAA2C;IAChD,yBAAyB;IACzB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,uBAAuB;QACnB,kBAAkB;AAC1B;AACA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,sBAAsB;IACtB,yBAAyB;AAC7B;AACA;IACI,uBAAuB;EACzB,aAAa;EACb,kBAAkB;EAClB,2CAA2C;KACxC,aAAa;IACd,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".iconWrapper {\n       padding: 10px;\n         box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n    /* margin-bottom: 10px; */\n    margin-top: 40px;\n    /* margin-bottom: 20px; */\n    position: absolute;\n    top: -40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n        border-radius: 50%;\n}\n.cardIcon {\n    width: 28px;\n    height: 28px;\n    /* margin-bottom: 10px; */\n    /* margin-top: 10px; */\n    /* margin-bottom: 20px; */\n}\n.cardContent {\n    background-color: white;\n  padding: 25px;\n  border-radius: 8px;\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n     display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 25px;\n    width: 184px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconWrapper": `node_iconWrapper__1vxRU`,
	"cardIcon": `node_cardIcon__-JNx3`,
	"cardContent": `node_cardContent__sC-mo`
};
export default ___CSS_LOADER_EXPORT___;
