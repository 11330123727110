import React, { useEffect, useRef, useState } from 'react';
import styles from './notificationDropdown.module.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { mrkAsReadNotification } from '../../../../redux/notification/Slice';

const NotificationsDropdown = ({ id='', options = [], customsSyles = {}, defaultOption='' }) => {
    const [ isOpen, setOpen ] = useState(false);
    const [ unreadCount, setUnreadCount ] = useState(0);
    const dispatch = useDispatch();
    const toggleDropdown = () => { setOpen(!isOpen) };
    const handleItemClick = (id) => {
        dispatch(mrkAsReadNotification(id))
        setUnreadCount(options?.filter(notification => !notification.read).length)
    }

    const btnRef = useRef();

    useEffect(() => {
        const closeDropdown = e => {
            if (btnRef.current && !btnRef.current.contains(e.target)) {
                setOpen(false);
            }
        }
        document.body.addEventListener('click', closeDropdown);
        setUnreadCount(options?.filter(notification => !notification.read).length)
        return () => document.body.removeEventListener('click', closeDropdown);
    }, []);

    return (
        <div id={id} className={styles.dropdown} onClick={toggleDropdown} ref={btnRef} style={customsSyles}>
            <div className={styles.dropdownHeader}>
                {defaultOption}
            </div>
            <div className={`${ styles.dropdownBody } ${ isOpen && styles.open }`}>
                <div className={styles.bodyHeader}>
                    <div>
                        Notifications <span> {unreadCount} New</span>
                    </div>
                    <Link to="/notifications">See All</Link>
                </div>
                {options.length > 0
                    ? options.map(item => (
                        <div key={item.id} className={`${ styles.dropdownItem } ${ item?.read ? '' : styles.unread }`} onClick={()=>{handleItemClick(item?.id)}}>
                            <div className={styles.content}>
                                <div className={styles.title}>{item?.name}</div>
                                {item?.time && <span className={styles.time}>{item?.time}</span>}
                            </div>
                            <div className={styles.description}>This is a test description Notification content</div>
                        </div>
                    ))
                    : <div className={`${ styles.dropdownItem } ${ styles.noData }`} >No New Notifications</div>
                }
            </div>
        </div>
    )
}

export default NotificationsDropdown;
