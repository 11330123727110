import React, { useState } from 'react';
import styles from './card.module.css';
import { formatDate } from '../../utils/CommonFunction';
import LucideIcon from '../lucideicon/LucideIcon.jsx';
import PopupWrapper from '../popupWrapper/PopupWrapper.jsx';

const Card = ({ title = '', id,  description = '', icon = '', lastModify = '', handleRoute, handleEdit, handleDelete }) => {
    const [ open, setOpen ] = useState(false)
    const onClose = (e) => {
        e.stopPropagation();
        setOpen(false)
    }

    return (
        <>
            <div id="organization" className={styles.card} onClick={handleRoute}>
                <div className={styles.cardHeader}>
                    <div className={styles.cardIconWrapper}>
                        {icon!==null?
                            <img className={styles.cardIcon} src={icon} alt='icon' />:
                            <div className={styles.circleMd}>
                                <p className={styles.cardIcons}>
                                    {title?.substring(0, 1)}
                                </p>
                            </div>}
                    </div>
                    <p className={styles.cardTitle}>{title}</p>
                </div>

                <div className={styles.cardBody}>
                    <p className={styles.cardDescription}>{description}</p>
                </div>
                <div className={styles.cardLastModify}>
                    <div>Last modifies : <span>{formatDate(lastModify)}</span></div>
                    <div className={styles.cardActions}>
                        <LucideIcon name="Pencil" size={30} className={styles.cardActionEdit} onClick={(e)=>{e.stopPropagation(); handleEdit(id)}} />
                        <LucideIcon name="Trash" size={30} className={styles.cardActionDelete} onClick={(e) => { e.stopPropagation(); setOpen(true); }}/>
                    </div>
                </div>
            </div>
            {open && <PopupWrapper isOpen={open} onClose={onClose} height={'25vh'} width={'32%'}>
                <div className={styles.modal}>
                    <p>Do you want to delete &quot;{title}&quot; organisation?</p>
                    <div className={styles.modalActions}>
                        <button className={styles.confirmBtn} onClick={(e) => { e.stopPropagation(); handleDelete(id); }}>Yes</button>
                        <button className={styles.cancelBtn} onClick={(e) => { e.stopPropagation(); onClose(e)}}>No</button>
                    </div>
                </div>
            </PopupWrapper>}
        </>
    );
}

export default Card;
