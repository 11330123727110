import React, { useEffect, useState } from 'react';
import styles from './configuration.module.css';
import configurationData from '../../../dummyData/Configuration.json';
import ToggleSwitch from '../../../commonComponents/toggleSwitch/ToggleSwitch.jsx';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import AppLoader from '../../../appLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import { capitalizeCamelCaseWords } from '../../../helpers/index';
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';
import parse from 'html-react-parser';
import { Switch } from '../../../commonComponents/switch/Switch.jsx';
import { useConfigurationFunc } from '../functionality/useConfiguration.func';
import Button from '../../../commonComponents/button/Button.jsx';
import useConfigurationTwoFunc from '../functionality/useConfigurationTwo.func';

const Configuration = () => {
    const [ isToggle, setIsToggle ] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { connectorConfig } = location.state || {};
    const { statuses, setStatuses, handleToggleStatus, handleClick, loading } = useConfigurationFunc(connectorConfig);
    const { data: syncValidateData } = useConfigurationTwoFunc({ url:'/sync-validate', service:'collectivedata' });

    useEffect(() => {
        if (connectorConfig?.tribe) {
            const updatedConfigurationCards = configurationData?.configurationCards?.[ connectorConfig?.tribe?.toLowerCase() ]?.map(card => {
                const updatedCard = {};
                for (const [ key, value ] of Object.entries(card)) {
                    updatedCard[ key ] = {};
                    for (const [ subKey, subValue ] of Object.entries(value)) {
                        updatedCard[ key ][ subKey.concat(' ', capitalizeCamelCaseWords(connectorConfig?.machine_name)) ] = subValue;
                    }
                }
                return updatedCard;
            });
            setStatuses(updatedConfigurationCards);
        }
    }, [ connectorConfig ]);

    const handleSwitchChange = (value) => {
        setIsToggle(value);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const isServiceSynced = (serviceName, systemName, action) => {
        const syncItem = syncValidateData?.find(item => item?.service === serviceName && item?.system===systemName  && item?.synced === true && item?.data_flow_type===action );
        return !!syncItem;
    };

    return (
        <AppLoader>
            <div className={styles.configureWrapper}>
                <div className={styles.header}>
                    <div className={styles.titleWrapper}>
                        <div className={styles.imageWrapper}>
                            <img src={connectorConfig?.image} alt="Configuration" />
                        </div>
                        <div className={styles.contentWrapper}>
                            <div className={styles.title}>
                                {capitalizeCamelCaseWords(connectorConfig?.machine_name)}<span>Version 2.13</span>
                            </div>
                            <div className={styles.subTitle}>
                                {capitalizeCamelCaseWords(connectorConfig?.machine_name)}
                                <ToggleSwitch value={isToggle} onChange={handleSwitchChange} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.iconWrapper}>
                        <LucideIcon name="Pencil" size={40} />
                        <LucideIcon name="Trash" size={40} />
                    </div>
                </div>
                <div className={styles.mainWrapper}>
                    <div className={styles.content}>
                        {parse(configurationData.content)}
                    </div>
                    <div className={styles.configuration}>
                        <div className={styles.headerWrapper}>
                            <Typography variant="gradient">Configuration</Typography>
                            <div className={styles.actionWrapper}>
                                <LucideIcon name="Pencil" size={40} />
                                <LucideIcon name="Copy" size={40} />
                            </div>
                        </div>
                        <div className={styles.cardContainer}>
                            {statuses && Object.keys(statuses)?.map((card, cardIndex) => (
                                <div key={cardIndex} className={styles.card}>
                                    {Object.keys(statuses?.[ card ]).map((title) => (
                                        <div key={title}>
                                            <div className={styles.title}>
                                                <Typography variant="title">{title}</Typography>
                                            </div>
                                            {Object.keys(statuses?.[ card ]?.[ title ]).map((option, optionIndex) => (
                                                <div key={optionIndex} className={styles.option}>
                                                    <div>{option}</div>
                                                    <Switch
                                                        handleToggle={() => handleToggleStatus(card, title, option) && !isServiceSynced(title.toLowerCase(), connectorConfig?.machine_name, option.split(' ')[ 0 ].toLowerCase())}
                                                        isChecked={statuses[ card ]?.[ title ]?.[ option ]}
                                                        // Disable the switch if the corresponding service is synced
                                                        isDisabled={isServiceSynced(title.toLowerCase(), connectorConfig?.machine_name, option.split(' ')[ 0 ].toLowerCase())}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.buttonWrapper}>
                        <div className={styles.navigation}>
                            <Button disabled={loading} onClick={handleClick}>
                                {loading ? 'Loading' : 'Save'}
                            </Button>
                        </div>
                        <div className={styles.navigation}>
                            <Button disabled={loading} onClick={handleBack}>
                                Back
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </AppLoader>
    );
};

export default Configuration;
