import { Handle } from '@xyflow/react';
import React from 'react';
// import Typography from '../typography/Typography.jsx';
import '@xyflow/react/dist/style.css';
import styles from './node.module.css';
import LucideIcon from '../lucideicon/LucideIcon.jsx';
import { handleShowGCPConnectorName } from '../../views/workFlowMultiple/functionality/helper';
import { capitalizeSnakeCaseWords } from '../../helpers/index';
export const SourceNode = ({ data }) => {
    console.log('dataaaaaaaaaaaa', data)
    const { label, icon, machine_name, handleNode } = data;

    return (
        <>
            <div className={styles.card}>
                <div className={styles.cardContent}>
                    <div className={styles.iconWrapper}>
                        <img
                            src={icon}
                            alt={machine_name}
                            className={styles.cardIcon}
                        />
                    </div>
                    <LucideIcon
                        name="Trash2"
                        size={12}
                        className={styles.deleteIcon}
                        onClick={() => handleNode(machine_name)}
                    />
                    <p className={styles.textCapitilize}>
                        {handleShowGCPConnectorName(
                            capitalizeSnakeCaseWords(label)
                        )}
                    </p>
                </div>
                <Handle type="source" position="right" />
            </div>
        </>
    );
};
export const DestinationNode = ({ data }) => {
    console.log('dataaaaaaaaaa', data)
    const { label, icon, machine_name, handleNode } = data;
    return (
        <>
            <div className={styles.card}>
                <div className={styles.cardContent}>
                    <div className={styles.iconWrapper}>
                        <img
                            src={icon}
                            alt={machine_name}
                            className={styles.cardIcon}
                        />
                    </div>
                    <LucideIcon
                        name="Trash2"
                        size={12}
                        className={styles.deleteIcon}
                        onClick={() => handleNode(machine_name)}
                    />
                    <p className={styles.textCapitilize}>
                        {handleShowGCPConnectorName(
                            capitalizeSnakeCaseWords(label)
                        )}
                    </p>
                </div>
                <Handle type="target" position="left" id="left" />
                <Handle type="source" position="right" id="right" />
                <Handle type="source" position="bottom" id="bottom" />
                <Handle type="target" position="top" id="top" />
            </div>
        </>
    );}