import React from 'react';
import styles from './modal.module.css';
import Button from '../button/Button.jsx';
import Input from '../input/Input.jsx';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import LucideIcon from '../lucideicon/LucideIcon.jsx';

const Modal = ({
    isOpen,
    onClose,
    handleChange,
    error,
    value,
    onSubmit,
    modalFieldData,
    loading,
    isShowPercentage = false,
    handlePercentage = () => {},
    releasePercentage,
    title='',
}) => {
    const handleOutsideModalClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    if (!isOpen) return null;

    return (
        <div
            className={styles.modalOverlayWrapper}
            onClick={handleOutsideModalClick}
        >
            <div className={styles.modalOverlay}>
                <div className={styles.header}>
                    <div className={styles.dotWrapper}>
                        <div className={styles.redDot} />
                        <div className={styles.orangeDot} />
                        <div className={styles.greenDot} />
                    </div>
                    <LucideIcon
                        name="X"
                        className={styles.closeIcon}
                        onClick={onClose}
                    />
                </div>
                <div className={styles.modal}>
                    {modalFieldData?.map((field, index) => {
                        switch (field.type) {
                            case 'heading':
                                return (
                                    <div
                                        className={
                                            isShowPercentage
                                                ? styles.showPercentage
                                                : styles.inputFieldWrapper
                                        }
                                        key={index}
                                    >
                                        <div>
                                            <div className={styles.inputLabel}>
                                                {field?.label}
                                            </div>
                                            <ReactMarkdown
                                                className={styles.captionText}
                                                rehypePlugins={[ rehypeRaw ]}
                                            >
                                                {field.captionText}
                                            </ReactMarkdown>
                                        </div>

                                        {isShowPercentage && (
                                            <div
                                                className={
                                                    styles.percentageToggle
                                                }
                                            >
                                                <span>{title}</span>
                                                <input
                                                    type="checkbox"
                                                    className={styles.toggle}
                                                    checked={releasePercentage}
                                                    onChange={handlePercentage}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            case 'text':
                                return (
                                    <div
                                        className={styles.inputFieldWrapper}
                                        key={index}
                                    >
                                        <div className={styles.inputLabel}>
                                            {field.label}
                                        </div>
                                        <Input
                                            type={field.type}
                                            placeholder={field.placeholder}
                                            value={value[ field.name ]}
                                            name={field.name}
                                            onChange={handleChange}
                                            className={styles.inputField}
                                        />
                                        <div
                                            className={`${
                                                styles.errorMessage
                                            } ${
                                                error[ field.name ] &&
                                                styles.showErrorMessage
                                            }`}
                                        >
                                            {' '}
                                            {error[ field.name ] || 'empty'}{' '}
                                        </div>
                                    </div>
                                );
                            default:
                                return null; // Return null for other cases
                        }
                    })}
                    {error?.response && (
                        <div className={`${ styles.errorText }`}>
                            {' '}
                            *
                            {error?.response
                                ?.replaceAll('-', ' ')
                                ?.toLowerCase()}{' '}
                        </div>
                    )}
                    <Button
                        onClick={onSubmit}
                        className={styles.button}
                        disabled={loading}
                    >
                        {loading ? 'Loading...' : 'Save & Cancel'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Modal;