import React from 'react';
// import styles from './Test.module.css'
// import AppLoader from '../../appLoader';
// import StepSlider from '../../components/stepSlider/layout/StepSlider.jsx';
// import LucideIcon from '../../commonComponents/lucideicon/LucideIcon.jsx';
import FieldMappingsPreveiwPopup from '../../components/formStep/layout/FieldMappingsPreveiwPopup.jsx';
const Test = ()=>{
    // const [] = React.useState(0) 
    // const stepsInfo = [
    //     { label:'Products' },
    //     { label:'Orders' },
    //     { label:'Shipnotice' },
    // ]
    // const stepsInfo1 = [
    //     { label:'Connectivity' },
    //     { label:'Services' },
    //     { label:'Other Configurations' },
    // ]
    return <FieldMappingsPreveiwPopup />
}

export default Test