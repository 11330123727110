import React from 'react';
import ConfigMapperField from './ConfigMapperField.jsx';
import useConfigMapperField from '../functionality/useConfigMapperFeild.func';

const SubLevelComp = ({ item, renderNestedLevels, inputValues={}, onInputChange=()=>{}, parentField='', setFieldListData=()=>{}, fieldList }) => {
    const [ selected, setselected ] = React.useState('');
    const toggle = () => {
        setselected(selected === '' ? 'active' : '');
    };
    const { hasChidlren } = useConfigMapperField()
    let customeStyles={
        maxHeight:'auto',
        width:'100%'
    }
    if(hasChidlren(item) && item?.allowed_fields_without_scroll < item?.field_children?.length){
        customeStyles = {
            overflow:'auto',
            maxHeight:'300px',
            width:'95%'
        }
    }

    let updatedParentField;
    if(parentField!== ''){
        updatedParentField  =`${ parentField }#${ item.field_name }`
    }else{
        updatedParentField = item?.field_name
    }
    if(item?.field_type === 'array'){
        updatedParentField+='__array'    
    }
    
    return (
        <>
            <div
                style={{ cursor: hasChidlren(item) ? 'pointer' : '',  width:'100%', padding:'10px 0' }}
            >
                <ConfigMapperField data={item} inputValues={inputValues} onInputChange={onInputChange} parentField={parentField} selected={selected} setFieldListData={setFieldListData} fieldList={fieldList} toggle={toggle}/>
            </div>
            <div style={hasChidlren(item)?{ ...customeStyles, display:(selected)?'block':'none' }:{}} >
                {hasChidlren(item) && renderNestedLevels(item.field_children, updatedParentField)}
            </div>
        </>
    );
};

const Accordion=({ data, inputValues={}, onInputChange=()=>{}, setFieldListData=()=>{}, fieldListData })=> {
    const renderNestedLevels = (data, parentField) => {
        return data.map((inputData, index) => (
            <SubLevelComp item={inputData} setFieldListData={setFieldListData} renderNestedLevels={renderNestedLevels} key={index} inputValues={inputValues} onInputChange={onInputChange} parentField={parentField} fieldList={fieldListData}/>
        ));
    };

    return renderNestedLevels(data);
}

export default Accordion