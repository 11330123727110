import React from 'react'
import Table from '../../commonComponents/table/Table.jsx';
import useSummaryItemTable from './SummaryItemTable.func';
import { Link, useParams } from 'react-router-dom';

function SummaryItemTable({ headerList, rowsData, mappings, urlData={}, totalRows='', props={}, hiddenColumns=[] }) {
    const { columns, data: flattenedData, loading, currentPage, setPageSize, totalRecords, setCurrentPage
    } = useSummaryItemTable(urlData, mappings, headerList, rowsData );
    const { invoiceNo } = useParams();
    let updatedColumns = columns.map((column) => {
        if (column.field === 'style') {
            return {
                ...column,
                renderCell: (params) => (
                    <Link to={`/summary-size/${ invoiceNo }/${ params.row.style }`}>
                        {params.row.style}
                    </Link>
                )
            };
        }
        return column;
    });
    return (
        <Table
            loading={loading}
            headerList={updatedColumns.length > 1 ? updatedColumns : []}
            totalRecords={(totalRows) ? totalRows :totalRecords}
            setPageSize={setPageSize}
            rowsData={flattenedData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            props={props}
            hiddenColumns={hiddenColumns}
        />
    )
}

export default SummaryItemTable