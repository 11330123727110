import React from 'react'
import styles from './FormStep.module.css'
import ConfigMapperFieldV2 from '../../../views/ConfigMapperV2/layout/ConfigMapperFieldV2.jsx'
import useConfigMapperField from '../../../views/configMapper/functionality/useConfigMapperFeild.func';
import StepSlider from '../../stepSlider/layout/StepSlider.jsx';
import useFormStep1 from '../functionality/useFormStep1.func';
import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func';
import { capitalizeCamelCaseWords, capitalizeSnakeCaseWords } from '../../../helpers';
const FormStep = ({
    fieldInputData,
    sliderData,
    setConfigFormData=()=>{},
    fetchedData={},
    setActiveStep=()=>{},
    selectedSystem='',
    selectedTribe='',
    setSelectedTribe = () => {},
    setSelectedSystem = () => {},
    connectedConnectors=[]
}) => {
    // Importing functions from hooks
    const { convertToNested, flattenJSON }  = useConfigMapperField();
    const { updateCustomFieldConfigJson }  = useConfigMapperFieldV2();
    const { verifyConnectivityConfig, prepareFormData, handleFormValidation, validationLoading, checkNestedObjectEmpty, addOptionsToDropdown } = useFormStep1();

    // React States
    const [ formValues, setFormValues ] = React.useState({ ...flattenJSON({}) })
    const [ validationError, setValidationError ] = React.useState({})
    const [ fieldData, setFieldData ] = React.useState( [ ...JSON.parse(JSON.stringify(fieldInputData)) ])

    // Assigning and declaring variables
    let connectedTribeConnectors = []

    // Event Handlers
    const inputChangeHandler = (event) => {
        if(event?.target?.name){
            setFormValues(prevState=>{
                let newObj = prevState;
                newObj[ event?.target?.name ] = event?.target?.value
                return newObj
            })
        }

        // Tribe Change Event -> Get connected connectors for the selected tribe and show in system dropdown
        console.log(connectedConnectors, 'connectedConnectors')
        if(event.target.name === 'tribe'){
            setSelectedTribe(event.target.value)
            connectedTribeConnectors = connectedConnectors.filter((connector)=>{
                return connector?.tribe?.toLowerCase() === event.target.value?.toLowerCase()
            })
            const options = [ { title:'Select System', value:'none' } ]
            connectedTribeConnectors.map(connector=>{
                options.push({ value:connector.name, title:capitalizeCamelCaseWords(capitalizeSnakeCaseWords(connector.name))  })
            })
            addOptionsToDropdown(fieldInputData,options,setFieldData,'system')
        }

        // SYstem change event- Fetch the configuration from the database for the selected system
        if(event.target.name === 'system' && event.target.value !== 'none'){
            setSelectedSystem(event.target.value)
        }
    }

    const formSubmitHandler = (event) => {
        event.preventDefault()
        const formElements = event.target.elements;
        let finalFormData = prepareFormData(formElements, fieldData, formValues)
        let nestedFormData = convertToNested(finalFormData)
        let validationErrObj = handleFormValidation(fieldInputData, nestedFormData, setValidationError )
        if(checkNestedObjectEmpty(validationErrObj)){
            verifyConnectivityConfig(nestedFormData, setValidationError, setActiveStep, setConfigFormData)
            return
        }
    }

    // useEffect
    React.useEffect(()=>{
        // Converting services to endpoints
        const servcieEndpoints = {}
        for (const key in fetchedData?.services) {
            servcieEndpoints[ `endpoints#${ key }` ] = fetchedData?.services[ key ]?.endpoints
            delete fetchedData?.services[ key ]?.endpoints
        }
        setFormValues({ ...flattenJSON({ ...fetchedData, tribe:selectedTribe, system:selectedSystem }), ...servcieEndpoints })
        setFieldData([ ...JSON.parse(JSON.stringify(fieldInputData)) ])
        updateCustomFieldConfigJson(fieldData, setFieldData, fetchedData, { ...flattenJSON({ ...fetchedData, tribe:selectedTribe, system:selectedSystem }), ...servcieEndpoints })
    }, [ fetchedData ])

    return  <form className={`${ styles.formWrapper } ${ (sliderData?.showSlider)?styles.withSlider:'' }`} onSubmit={formSubmitHandler} >
        {sliderData?.showSlider && <div className={styles.sliderWrapper}>
            <StepSlider vertical={sliderData?.isVertical} activeStep={0} stepsInfo={sliderData?.stepsInfo} showStepNumber={sliderData?.showStepNumber}/>
        </div>}

        <div className={styles.inputFieldsWrapper}>
            {fieldData?.map((fieldRow, index)=>{
                return <div key={index} className={`${ styles.row } ${ styles[ `col-${ fieldRow?.columnsCount || 3 }` ] }`}>
                    {fieldRow?.data?.map(inputData=>
                        <ConfigMapperFieldV2
                            fieldData={inputData}
                            fieldListData={fieldData}
                            key={inputData?.field_name}
                            formData={formValues}
                            setFieldData={setFieldData}
                            onInputChange={inputChangeHandler}
                            validationError={validationError}
                            setValidationError={setValidationError}
                        />
                    )}
                </div>
            })}

            <div className={styles.formSubmitWrapper}>
                <input type='submit' className={styles.primary} value={validationLoading?'Verifying':'Verify'} disabled={validationLoading}/>
                <button onClick={(e)=>{e.preventDefault()}}>Next</button>
            </div>
        </div>
    </form>
}

export default FormStep