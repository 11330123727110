import React from 'react';
import styles from './loginForm.module.css';
import Button from '../../../commonComponents/button/Button.jsx';
import { CircleX } from 'lucide-react';
import { EyeOff, Eye } from 'lucide-react';
import Input from '../../../commonComponents/input/Input.jsx';
import { useLoginfFormFunc } from '../functionality/LoginForm.func';
// import assets from '../../../assets';

const LoginForm = () => {
    const {
        formData,
        loading,
        showPassword,
        errorMessage,
        handleChange,
        handleLogin,
        handleClearUsername,
        handleTogglePassword

    } = useLoginfFormFunc()
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };
    // const {
    //     apple,
    //     facebook,
    //     google
    // } = assets
    return (
        <div className={styles.loginWrapper}>
            <form>
                <Input
                    type="text"
                    placeholder="Enter Email"
                    value={formData.username}
                    name="username"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    endIcon={<CircleX size={16} color='grey' onClick={handleClearUsername} />}
                    className={`${ styles.emailInput } ${ errorMessage[ 'username' ] ? styles.errorBorder : '' }`}

                />
                <div className={`${ styles.errorMessage } ${ errorMessage[ 'username' ] && styles.showErrorMessage }`}>{errorMessage[ 'username' ] || 'empty'}</div>
                <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={formData.password}
                    name="password"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    className={`${ styles.emailInput } ${ errorMessage[ 'password' ] ? styles.errorBorder : '' }`}
                    endIcon={showPassword ? (
                        <Eye size={16} color='grey' onClick={handleTogglePassword} />
                    ) : (
                        <EyeOff size={16} color='grey' onClick={handleTogglePassword} />
                    )}
                />
                <div className={`${ styles.errorMessage } ${ errorMessage[ 'password' ]  && styles.showErrorMessage }`}>{errorMessage[ 'password' ] || 'empty'}</div>
                <div className={styles.RecoverPasswordWrapper}>
                    <Button className={styles.RecoverPasswordButton} onClick={handleLogin}> Recover Password ? </Button>
                </div>
                <div>
                    <Button className={styles.button} onClick={handleLogin} >{loading ? 'Loading...' : 'Login'}</Button>
                </div>
            </form>
            {/* <div className={styles.textWithLines}>
                Or continue with
            </div>
            <div className={styles.iconWrapper}>
                <div className={styles.icon}>
                    <img src={google} />
                </div>
                <div className={styles.icon}>
                    <img src={apple} />
                </div>
                <div className={styles.icon}>
                    <img src={facebook} />
                </div>
            </div> */}
            <div className={`${ styles.errorMessage } ${ errorMessage[ 'msg' ]  && styles.showErrorMessage }`}>{errorMessage[ 'msg' ] || 'empty'}</div>
        </div>
    );
};

export default LoginForm;