import React from 'react';
import styles from './shipNotice.module.css';
import AppLoader from '../../../../appLoader';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
// import Spinner from '../../../../commonComponents/spinner/Spinner.jsx';
// import CommonTable from '../../../../commonComponents/commonTable/layout/CommonTable.jsx';
// import useCommonTable from '../../../../commonComponents/commonTable/functionality/useCommonTable.func';
import useTables from '../../../../commonComponents/table/useTables.func';
import { Link } from 'react-router-dom';
import Table from '../../../../commonComponents/table/Table.jsx';
const ShipmentNotices = () => {
    // const { loading, error, headerData, data, currentPage, totalPages, setCurrentPage } = useCommonTable({ status: true, pageSize: 10 }, { url: '/all-shipnotice', service:'ship-notice' });
    const { data,
        columns: initialColumns,
        loading, error,
        currentPage,
        setCurrentPage,totalRecords,
        setPageSize  } = useTables({ url: '/all-shipnotice', service:'ship-notice' });
    // const options = {
    //     link: {
    //         url: '/shipment-notice/_identifier_',
    //         identifier: 'shipnotice_id',
    //     }
    // }
    const updatedColumns = initialColumns.map((column) => {
        if (column.field === 'shipnotice_id') {
            return {
                ...column,
                renderCell: (params) => (
                    <Link to={`/shipment-notice/${ params.row.shipnotice_id }`}>
                        {params.row.shipnotice_id}
                    </Link>
                )
            };
        }
        return column;
    });
    return (
        <AppLoader>
            <div className={styles.adminDashboard}>
                <div className={styles.mainWrapper}>
                    <div className={styles.header}>
                        <Typography variant='heading'>Shipment Notices</Typography>
                    </div>
                    {(!loading && error) ?
                        <div className={styles.heading}><Typography variant='error'>{error}</Typography></div>:
                        <Table
                            loading={loading}
                            headerList={updatedColumns.length > 1 ? updatedColumns : [] }
                            totalRecords={totalRecords}
                            setPageSize={setPageSize}
                            rowsData={data}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    }
                </div>
            </div>
        </AppLoader>
    )
};

export default ShipmentNotices;