// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deliveryStat_container__70o5d {
    padding: 2rem 1.5rem;
    margin: 2rem 0;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    box-shadow: 0px 9px 36px 0px var(--card-box-shadow);
    justify-content: space-evenly;
    border-radius: 12px;
}

.deliveryStat_stat1__fIYy8 {
    margin: 0 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--color-black);
}
.deliveryStat_stat2__0-kS5 {
    margin-left: 1rem;
}

.deliveryStat_heading__3LE85 {
    font-family: Outfit;
    font-size: 18px;
    font-weight: 600;
    line-height: 33px;
}

.deliveryStat_danger__DR9WO {
    color: var(--color-red-100);
}
.deliveryStat_success__rtUJE {
    color: var(--color-green-100);
}
`, "",{"version":3,"sources":["webpack://./src/components/widgets/deliveryStat/layout/deliveryStat.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,cAAc;IACd,oCAAoC;IACpC,aAAa;IACb,sBAAsB;IACtB,mDAAmD;IACnD,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,aAAa;IACb,8BAA8B;IAC9B,2CAA2C;AAC/C;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;AAC/B;AACA;IACI,6BAA6B;AACjC","sourcesContent":[".container {\n    padding: 2rem 1.5rem;\n    margin: 2rem 0;\n    background-color: var(--color-white);\n    display: flex;\n    flex-direction: column;\n    box-shadow: 0px 9px 36px 0px var(--card-box-shadow);\n    justify-content: space-evenly;\n    border-radius: 12px;\n}\n\n.stat1 {\n    margin: 0 1rem;\n    padding-bottom: 1rem;\n    display: flex;\n    justify-content: space-between;\n    border-bottom: 2px solid var(--color-black);\n}\n.stat2 {\n    margin-left: 1rem;\n}\n\n.heading {\n    font-family: Outfit;\n    font-size: 18px;\n    font-weight: 600;\n    line-height: 33px;\n}\n\n.danger {\n    color: var(--color-red-100);\n}\n.success {\n    color: var(--color-green-100);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `deliveryStat_container__70o5d`,
	"stat1": `deliveryStat_stat1__fIYy8`,
	"stat2": `deliveryStat_stat2__0-kS5`,
	"heading": `deliveryStat_heading__3LE85`,
	"danger": `deliveryStat_danger__DR9WO`,
	"success": `deliveryStat_success__rtUJE`
};
export default ___CSS_LOADER_EXPORT___;
