import React from 'react';
import styles from './notificationDetailPopup.module.css';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';

const NotificationDetailPopup = ({ data }) =>{
    const { row } = data;
    return <div className={styles.notificationPopupWrapper}>
        <div className={styles.headerWrapper}>
            <div>
                <div className={styles.iconWrapper}>
                    <LucideIcon name={'MailCheck'} className={styles.icon} color={'white'}/>
                </div>
            </div>
            <div className={styles.titleWrapper}>
                <div className={styles.title}>
                    <Typography variant='heading'>{row?.title}</Typography>
                    <p>{row?.description}</p>
                    <p><strong>ID: </strong>{row?.id}</p>
                </div>
                <div className={styles.time}>{new Date().toLocaleString()}</div>
            </div>
        </div>
        <div className={styles.contentWrapper}>
        </div>
    </div>
}

export default NotificationDetailPopup;