import React from 'react';
import AppLoader from '../../../appLoader';
import styles from './myConnectors.module.css';
import ConnectorCard from '../../../components/connectorCard/layout/ConnectorCard.jsx';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import { ChevronRight } from 'lucide-react';

const MyConnectors = () => {

    return (
        <AppLoader>
            <div className={styles.myConnectors}>
                <Typography className={styles.connectorHeading} variant='gradient'>
                    Connectors
                </Typography>
                <div className={styles.breadcrumbs}>
                    <Typography>Connectors</Typography>
                    <ChevronRight className={styles.breadcrumbIcon} />
                    <Typography className={styles.currentBreadcrumb}>All System</Typography>
                </div>
                <div className={styles.connectorsGrid}>
                    <ConnectorCard connector={{
                        name: 'Shopify',
                        date: 'June 20th 2023 | 10:21 am'
                    }} />
                </div>
            </div>
        </AppLoader>
    )
};

export default MyConnectors;