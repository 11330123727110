// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomInputArrayV2_customArrayWrapper__1OCGJ{
    border: 1px solid #E6E6E6;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.CustomInputArrayV2_inputWrapper__cRX2E{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.CustomInputArrayV2_labelWrapper__2Vvui{
    align-items: center;
    display: flex;
}

.CustomInputArrayV2_childrenFieldsWrapper__8DLTF{
    display: grid;
    gap: 1rem;
    margin-left: 2rem;
}
.CustomInputArrayV2_childrenFieldsWrapper__8DLTF .CustomInputArrayV2_inputWrapper__cRX2E{
    gap: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/views/ConfigMapperV2/layout/CustomInputArray/CustomInputArrayV2.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,iBAAiB;AACrB;AACA;IACI,SAAS;AACb","sourcesContent":[".customArrayWrapper{\n    border: 1px solid #E6E6E6;\n    width: 100%;\n    padding: 1rem;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n}\n.inputWrapper{\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    align-items: center;\n}\n\n.labelWrapper{\n    align-items: center;\n    display: flex;\n}\n\n.childrenFieldsWrapper{\n    display: grid;\n    gap: 1rem;\n    margin-left: 2rem;\n}\n.childrenFieldsWrapper .inputWrapper{\n    gap: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customArrayWrapper": `CustomInputArrayV2_customArrayWrapper__1OCGJ`,
	"inputWrapper": `CustomInputArrayV2_inputWrapper__cRX2E`,
	"labelWrapper": `CustomInputArrayV2_labelWrapper__2Vvui`,
	"childrenFieldsWrapper": `CustomInputArrayV2_childrenFieldsWrapper__8DLTF`
};
export default ___CSS_LOADER_EXPORT___;
