import { useState } from 'react';
import useCustomAxiosCallTwo from '../../../hooks/useCustomAxiosCallTwo';
import { toast } from 'react-toastify';
import { capitalizeCamelCaseWords } from '../../../helpers';

export const useConfigurationFunc = (connectorConfig) => {
    const [ loading, setLoading ]=useState(false)
    const { callApi } = useCustomAxiosCallTwo();
    const [ statuses, setStatuses ] = useState({});

    const getEndpointall = (action, actionType) => {
        const actionMapping = {
            'pos': 'pos',
            'orders': 'orders',
            'inventory': 'inventory',
            'products': 'products',
            'customers': 'customers',
            'fulfillments': 'fulfillments',
            'returns': 'returns',
            'shipments': 'shipments',
            'ship notices': 'ship_notices'
        };

        const service = Object.keys(actionMapping).find(key => action.toLowerCase().includes(key));
        return service ? {
            url: `/${ actionType }/${ connectorConfig?.machine_name }/${ actionMapping[ service ] }`,
            service:'config'
        } : null;
    }

    const getData = async (endpoint, actionType, title) => {
        const response = await callApi({ uriEndPoint: endpoint });
        if (response.status === 200){
            toast(`${ capitalizeCamelCaseWords(connectorConfig?.machine_name) } ${ title } ${ actionType }ing `)
            return response.data
        }
    }

    const handleClick = async () => {
        setLoading(true);
        const apiCalls = [];

        for (const card in statuses) {
            for (const title in statuses[ card ]) {
                for (const option in statuses[ card ][ title ]) {
                    if (statuses[ card ][ title ][ option ] === true) {
                        const actionType = option.toLowerCase().includes('pull') ? 'pull' : 'push';
                        const endpoint = getEndpointall(title, actionType);
                        if (endpoint) {
                            apiCalls.push(getData(endpoint, actionType, title ));
                        }
                    }
                }
            }
        }

        try {
            const results = await Promise.all(apiCalls);
            results.forEach((result, index) => console.log(`Result for API call ${ index }:`, result));
        } catch (error) {
            console.error('One or more API calls failed:', error);
        }
        setLoading(false);
    };

    const handleToggleStatus = (card, title, option) => {
        setStatuses(prevStatuses => ({
            ...prevStatuses,
            [ card ]: {
                ...prevStatuses[ card ],
                [ title ]: {
                    ...prevStatuses[ card ][ title ],
                    [ option ]: !prevStatuses[ card ][ title ][ option ]
                }
            }
        }));
    };
    return {
        statuses,
        setStatuses,
        handleToggleStatus,
        handleClick,
        loading

    }
}
