import React from 'react';
import styles from './Search.module.css';
import { Search as SearchIcon } from 'lucide-react';
import Input from '../input/Input.jsx';

const Search = ({
    searchKeyword,
    id ='',
    onChange = () => {},
    onKeyDown = () => {}
}) => {
    return (
        <div className={styles.searchContainer} id={id}>
            <SearchIcon className={styles.searchIcon} />
            <Input onKeyDown={onKeyDown} onChange={onChange} value={searchKeyword} variant='transparent' placeholder='Search for content' />
        </div>
    )
};

export default Search;