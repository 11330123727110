import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    collectiveData: {}
};

const collectiveData = createSlice({
    name: 'collectiveData',
    initialState,
    reducers: {
        setCollectiveData: (state, action) => {
            state.collectiveData = action.payload;
        }
    }
});

export const collectiveDataReducer = collectiveData.reducer;
export const { setCollectiveData } = collectiveData.actions;