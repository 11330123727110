import React from 'react';
import styles from './tooltip.module.css';

const Tooltip = ({ text, isModalColor }) => {

    return (
        <div className={`${ styles.tooltipText } ${ isModalColor && styles.tooltipBackGroundColor }`}>{text}</div>
    );
};

export default Tooltip;