// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificationDetailPopup_notificationPopupWrapper__3pooW{
    padding: 15px 15px 0px 15px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
}

.notificationDetailPopup_notificationPopupWrapper__3pooW .notificationDetailPopup_headerWrapper__niQMH{
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}
.notificationDetailPopup_notificationPopupWrapper__3pooW .notificationDetailPopup_headerWrapper__niQMH .notificationDetailPopup_titleWrapper__P0G9Q{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center ;
    gap: 2rem;
}
.notificationDetailPopup_notificationPopupWrapper__3pooW .notificationDetailPopup_headerWrapper__niQMH .notificationDetailPopup_titleWrapper__P0G9Q .notificationDetailPopup_title__Z3-T5{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.notificationDetailPopup_notificationPopupWrapper__3pooW .notificationDetailPopup_headerWrapper__niQMH .notificationDetailPopup_titleWrapper__P0G9Q .notificationDetailPopup_time__b2EbD{
    width: 135px;
    color:#0029FF;
    background-color: #E4EBFF;
    border-radius: 12px;
    padding: 12px;
    font-family: Outfit;
    font-size: 10px;
    font-weight: 500;
    line-height: 4px;
    text-align: left;
}
.notificationDetailPopup_notificationPopupWrapper__3pooW .notificationDetailPopup_headerWrapper__niQMH .notificationDetailPopup_iconWrapper__BwjFA{
    background: green;
    border-radius: 30px;
    padding: 14px;
    display: flex;
    margin-right: 5px;
}
/* .notificationPopupWrapper .headerWrapper .iconWrapper .icon{} */
/* .notificationPopupWrapper .contentWrapper{
     margin-left: 2rem;
} */`, "",{"version":3,"sources":["webpack://./src/views/notifications/layout/notificationDetailPopup.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,SAAS;IACT,8BAA8B;AAClC;AACA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,oBAAoB;IACpB,SAAS;AACb;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,iBAAiB;AACrB;AACA,kEAAkE;AAClE;;GAEG","sourcesContent":[".notificationPopupWrapper{\n    padding: 15px 15px 0px 15px;\n    display: flex;\n    gap: 1rem;\n    flex-direction: column;\n    align-items: center;\n}\n\n.notificationPopupWrapper .headerWrapper{\n    width: 100%;\n    display: flex;\n    gap: 1rem;\n    justify-content: space-between;\n}\n.notificationPopupWrapper .headerWrapper .titleWrapper{\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center ;\n    gap: 2rem;\n}\n.notificationPopupWrapper .headerWrapper .titleWrapper .title{\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n.notificationPopupWrapper .headerWrapper .titleWrapper .time{\n    width: 135px;\n    color:#0029FF;\n    background-color: #E4EBFF;\n    border-radius: 12px;\n    padding: 12px;\n    font-family: Outfit;\n    font-size: 10px;\n    font-weight: 500;\n    line-height: 4px;\n    text-align: left;\n}\n.notificationPopupWrapper .headerWrapper .iconWrapper{\n    background: green;\n    border-radius: 30px;\n    padding: 14px;\n    display: flex;\n    margin-right: 5px;\n}\n/* .notificationPopupWrapper .headerWrapper .iconWrapper .icon{} */\n/* .notificationPopupWrapper .contentWrapper{\n     margin-left: 2rem;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationPopupWrapper": `notificationDetailPopup_notificationPopupWrapper__3pooW`,
	"headerWrapper": `notificationDetailPopup_headerWrapper__niQMH`,
	"titleWrapper": `notificationDetailPopup_titleWrapper__P0G9Q`,
	"title": `notificationDetailPopup_title__Z3-T5`,
	"time": `notificationDetailPopup_time__b2EbD`,
	"iconWrapper": `notificationDetailPopup_iconWrapper__BwjFA`
};
export default ___CSS_LOADER_EXPORT___;
