import { TextField } from '@mui/material';
import React from 'react';
import styles from './variation.module.css';
import LucideIcon from '../../commonComponents/lucideicon/LucideIcon.jsx';

function Variation({ variation, setVariation, validateJson, isValidJson, }) {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setVariation({ ...variation, [ name ]: value });
        validateJson(name, value);
    };
    return (
        <div className={styles.rulesContent}>
            <div className={styles.textFieldWrapper}>
                <div className={styles.inputContainer}>
                    <div className={styles.ruleHeader}>
                        Input
                        <LucideIcon name="CircleHelp" size={16} className={styles.closeIcon}/>
                    </div>
                    <div className={styles.textfield}>
                        <TextField
                            multiline
                            minRows={7}
                            maxRows={10}
                            autoComplete="off"
                            value={variation?.true}
                            onChange={handleChange}
                            name="true"
                            placeholder="{}"
                            error={!!(isValidJson?.true)}
                            helperText={isValidJson?.true}
                            type="text"
                            className={styles.textField}
                            autoFocus
                        />
                    </div>
                </div>
                <div className={styles.outputContainer}>
                    <div className={styles.ruleHeader}>
                        Output
                        <LucideIcon name="CircleHelp" size={16} className={styles.closeIcon}/>
                    </div>
                    <div className={styles.textfield}>
                        <TextField
                            multiline
                            maxRows={10}
                            minRows={7}
                            autoComplete="off"
                            value={variation?.false}
                            onChange={handleChange}
                            error={!!(isValidJson?.false)}
                            helperText={isValidJson?.false}
                            name="false"
                            placeholder="{}"
                            type="text"
                            className={styles.textField}
                            autoFocus
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Variation