import { useState } from 'react'

export const useHeaderFunc = () => {
    const [ searchKeyword, setSearchKeyword ] = useState('');

    const handleChange = (event) => setSearchKeyword(event.target.value);

    const handleKeyDown = (event) => {
        if (event.key === ' ' || event.key === 'Enter') {
            console.log(searchKeyword);
        }
    }

    return {
        searchKeyword,
        handleChange,
        handleKeyDown
    }
}