import React from 'react';
import AppLoader from '../../../../appLoader';
import useInboundShipment from '../functionality/InboundShipment.func';
import { useParams } from 'react-router-dom';
import styles from './inboundShipment.module.css'
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import Spinner from '../../../../commonComponents/spinner/Spinner.jsx';
import ShipmentNoticeTable from '../../../../components/shipmentNoticeTable/ShipmentNoticeTable.jsx';
const InboundShipment = () => {
    const { shipmentId } = useParams();
    const { data, loading, headersData, error } = useInboundShipment(shipmentId);
    const mappings={ shipment_item_amount:'shipmen_titem_amount' }
    return <AppLoader>
        <div className={styles.mainWrapper}>
            {loading
                ? <Spinner />
                : error ? <Typography variant='error'>{error}</Typography> :(
                    <>
                        <Typography variant='heading'>Inbound Shipment </Typography>
                        <ShipmentNoticeTable headerList={headersData} rowsData={  data|| []  } totalRows={1}/>

                        <Typography variant='title'>Line Items</Typography>
                        <ShipmentNoticeTable mappings={mappings} urlData={
                            {
                                url: `/inbound_shipments/${ shipmentId }`,
                                service: 'collectivedata'
                            }
                        } />
                    </>
                )}
        </div>
    </AppLoader>
};

export default InboundShipment;