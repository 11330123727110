// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders_headerWrapper__aEvji{
    margin-bottom: 1rem;
}
.orders_header__MuR8G{
    margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/views/orders/layout/orders.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".headerWrapper{\n    margin-bottom: 1rem;\n}\n.header{\n    margin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": `orders_headerWrapper__aEvji`,
	"header": `orders_header__MuR8G`
};
export default ___CSS_LOADER_EXPORT___;
