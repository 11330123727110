// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accountSettings_mainWrapper__eWYiH {
    display: flex;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/views/accountSettings/accountSettings.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".mainWrapper {\n    display: flex;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": `accountSettings_mainWrapper__eWYiH`
};
export default ___CSS_LOADER_EXPORT___;
