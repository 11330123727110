// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dataGridTable_tableWrapper__tLnI4 {
    margin: 2rem 0;
    background-color: white;
    padding: 1.5rem;
    box-shadow: 0px 9px 36px 0px var(--card-box-shadow);
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/widgets/dataGrid/layout/dataGridTable.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,uBAAuB;IACvB,eAAe;IACf,mDAAmD;IACnD,mBAAmB;AACvB","sourcesContent":[".tableWrapper {\n    margin: 2rem 0;\n    background-color: white;\n    padding: 1.5rem;\n    box-shadow: 0px 9px 36px 0px var(--card-box-shadow);\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": `dataGridTable_tableWrapper__tLnI4`
};
export default ___CSS_LOADER_EXPORT___;
