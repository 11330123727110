// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.authProgress_authLoader__a2O1E {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
}

.authProgress_card__KGJ2J {
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: 0px 9px 18px 0px #0000001C;
    padding: 6rem;
    border: 1px solid var(--primary-color)
}

.authProgress_card__KGJ2J p {
    font-family: Outfit;
    font-size: 40px;
    font-weight: 700;
    line-height: 51px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/views/authProgress/layout/authProgress.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,sCAAsC;IACtC,aAAa;IACb;AACJ;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".authLoader {\n    align-items: center;\n    display: flex;\n    justify-content: center;\n    height: 100vh;\n}\n\n.card {\n    background-color: var(--color-white);\n    border-radius: 10px;\n    box-shadow: 0px 9px 18px 0px #0000001C;\n    padding: 6rem;\n    border: 1px solid var(--primary-color)\n}\n\n.card p {\n    font-family: Outfit;\n    font-size: 40px;\n    font-weight: 700;\n    line-height: 51px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authLoader": `authProgress_authLoader__a2O1E`,
	"card": `authProgress_card__KGJ2J`
};
export default ___CSS_LOADER_EXPORT___;
