import validateField from './validateField';

const validateFieldData = (formData, setErrorMessage) => {
    const newErrors = {};

    Object.entries(formData).forEach(([ field, value ]) => {
        const fieldErrors = validateField(field, value, formData);

        if (fieldErrors.length > 0) {
            newErrors[ field ] = fieldErrors.join(', '); 
        } else {
            newErrors[ field ] = ''; 
        }
    });

    setErrorMessage(newErrors);

    const hasErrors = Object.values(newErrors).some(error => error.length > 0);

    return hasErrors;
};

export default validateFieldData;
