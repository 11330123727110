import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styles from './organisationSkeleton.module.css';

const OrganisationSkeleton = () => {
    return (
        <div className={styles.skeletonContainer}>
            {
                Array(8).fill({}).map((_, index) => (
                    <Skeleton
                        height={180}
                        width='100%'
                        borderRadius={10}
                        key={index}
                    />
                ))
            }
        </div>
    )
};

export default OrganisationSkeleton;