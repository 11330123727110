import React from 'react';
import AppLoader from '../../../appLoader';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import Table from '../../../commonComponents/table/Table.jsx';
import styles from './activePos.module.css';
import useTables from '../../../commonComponents/table/useTables.func';
import { Link } from 'react-router-dom';

const ActivePos = () => {

    const { data,
        columns,
        loading, error,
        currentPage,
        setCurrentPage,totalRecords,
        setPageSize } = useTables({ url:'/active-po-id', service:'collectivedata' });
        
    const updatedColumns = columns.map((column) => {
        if (column.field === 'shipment_id') {
            return {
                ...column,
                renderCell: (params) => (
                    <Link to={`/inbound-shipment/${ params.row.shipment_id }`}>
                        {params.row.shipment_id}
                    </Link>
                )
            };
        }
        return column;
    });

    return  <AppLoader>
        <div>
            <div className={styles.mainWrapper}>
                <div className={styles.headerWrapper}>
                    <div className={styles.header}>
                        <Typography variant='gradient'>Active POs</Typography>
                    </div>
                </div>
                {(!loading && error) ?
                    <div className={styles.heading}><Typography variant='error'>{error}</Typography></div>:
                    <Table
                        loading={loading}
                        headerList={updatedColumns}
                        totalRecords={totalRecords}
                        setPageSize={setPageSize}
                        rowsData={data}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            </div>
        </div>
    </AppLoader>
}

export default ActivePos;