import React from 'react'
import styles from './FormStep.module.css'
import ConfigMapperFieldV2 from '../../../views/ConfigMapperV2/layout/ConfigMapperFieldV2.jsx'
import useConfigMapperField from '../../../views/configMapper/functionality/useConfigMapperFeild.func';
import StepSlider from '../../stepSlider/layout/StepSlider.jsx';
import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func';
import { capitalizeCamelCaseWords, capitalizeSnakeCaseWords } from '../../../helpers';
import FormGroup from './FormGroup.jsx';
import fieldMappingsData from '../../../views/ConfigMapperV2/data/fieldsMappings.json'
import useFormStep1 from '../functionality/useFormStep1.func';
import useFormStep2 from '../functionality/useFormStep2.func';
import FieldMappingsPreveiwPopup from './FieldMappingsPreveiwPopup.jsx';
import PopupWrapper2 from '../../../commonComponents/popupWrapper2/PopupWrapper2.jsx';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';

const FormStep2 = ({
    fieldInputData,
    sliderData,
    fieldLayout=[],
    setConfigFormData=()=>{},
    configFormData={},
    setActiveFormStep = () => {},
    fetchedData = {}
}) => {

    // states
    const [ activeStep, setActiveStep ] = React.useState(0)
    const [ isVerified, setIsVerified ] = React.useState(false)
    const [ formValues, setFormValues ] = React.useState({})
    const [ fieldData, setFieldData ] = React.useState( [ ...JSON.parse(JSON.stringify(fieldInputData)) ])
    const [ open, setOpen ] = React.useState(false)

    // Functions import
    const { addChildrenToField, updateCustomFieldConfigJson } = useConfigMapperFieldV2(()=>{})
    const { convertToNested, addEmptyObjectIfEmptyValue, flattenJSON }  = useConfigMapperField();
    const { prepareFormData } = useFormStep1();
    const { verifyFieldMappings, fieldsPreviewData, validationLoading } = useFormStep2();

    // local functions
    const onClose = () => {
        setOpen(false)
    }

    // Declaring and Assigning variables
    const requiredServiceFields = [ 'custom_field_mappings', 'real_time_response_nested_field', 'static_query_params', 'collection_name' ];
    const sliderStepInfo = [];
    let servicesConfigObj = {}

    //getting sliders steps info
    for (const key in configFormData?.endpoints) {
        sliderStepInfo.push({ label:capitalizeCamelCaseWords(capitalizeSnakeCaseWords(key)),value:key+'Service' })
    }

    // consoles

    // Getting field mappings according to activeservice
    React.useEffect(() => {
        setIsVerified(false)
        const activeService = sliderStepInfo[ activeStep ];
        const serviceFields = fieldMappingsData[ activeService?.value ];

        if (activeService) {
            addChildrenToField(fieldInputData, serviceFields, setFieldData, 'field_mappings');
            setFormValues(prev => ({
                ...prev,
                ...flattenJSON({ ...fetchedData?.services?.[ activeService?.value.replace('Service', '') ] }),
                endpoints: configFormData?.endpoints?.[ activeService?.value.replace('Service', '') ],
            }));
            setFieldData([ ...JSON.parse(JSON.stringify(fieldLayout)) ])
            updateCustomFieldConfigJson([ ...JSON.parse(JSON.stringify(fieldLayout)) ], setFieldData, fetchedData?.services?.[ activeService?.value.replace('Service', '') ], { ...flattenJSON({ ...fetchedData?.services?.[ activeService?.value.replace('Service', '') ] }) })
        }
    }, [ activeStep ]);

    // Event Handlers
    const inputChangeHandler = (event) => {
        if(event?.target?.name){
            setFormValues(prevState=>{
                let newObj = prevState;
                newObj[ event?.target?.name ] = event?.target?.value
                return newObj
            })
        }
    }

    const formSubmitHandler = (event) => {
        event.preventDefault()
        const formElements = event.target.elements;
        const finalFormData = prepareFormData(formElements, fieldData, formValues)
        // Adding empty objects for required fields
        finalFormData.response_nested_field = (finalFormData?.response_nested_field === '')?null:finalFormData?.response_nested_field
        let convertedFinalFormData = convertToNested(finalFormData)
        addEmptyObjectIfEmptyValue(convertedFinalFormData, requiredServiceFields)
        // delete unrequired fields
        delete convertedFinalFormData[ 'field_name[]' ]
        delete convertedFinalFormData[ 'input_field_name[]' ]

        console.log(convertedFinalFormData, 'nestedcustommapping', formValues, finalFormData)
        setConfigFormData(prev=>{
            let oldServicesData = prev?.[ 'services' ] || {}
            oldServicesData[ sliderStepInfo[ activeStep ].value.replace('Service', '') ] = convertedFinalFormData
            servicesConfigObj = { ...prev, services:{ ...oldServicesData } }
            return servicesConfigObj
        })

        verifyFieldMappings(servicesConfigObj, setOpen)
    }

    const verifyClickHandler = () => {
        setIsVerified(true)
        toast.success('Fields have been verified', { position:'top-right' })
        onClose()
    }

    const nextClickHandler = () => {
        if(isVerified){
            setActiveStep(prev=>{
                if(prev === sliderStepInfo.length-1){
                    setActiveFormStep(2)
                    return prev
                }else{
                    return ++prev
                }
            })
            setFormValues({})
            window.scrollTo(0,0)
        }else{
            toast.error('Please Verify the fields before proceeding', { position:'top-right' })
        }
    }

    // JSX
    return  <>
        <form className={`${ styles.formWrapper } ${ (sliderData?.showSlider)?styles.withSlider:'' }`} onSubmit={formSubmitHandler} >
            {sliderData?.showSlider && <div className={styles.sliderWrapper}>
                <StepSlider key={uuid()} vertical={sliderData?.isVertical} activeStep={activeStep} stepsInfo={sliderStepInfo} showStepNumber={sliderData?.showStepNumber} />
            </div>}

            <div className={styles.inputFieldsWrapper}>
                {fieldData?.map((fieldRow)=>{
                    if(fieldRow?.styleVariant === 'group'){
                        return  <FormGroup key={sliderStepInfo[ activeStep ].value+uuid() } fieldData={fieldRow?.data[ 0 ]} formValues={formValues} onInputChange={inputChangeHandler}/>
                    }else{
                        return <div key={sliderStepInfo[ activeStep ].value+uuid()} className={`${ styles.row } ${ styles[ `col-${ fieldRow?.columnsCount || 3 }` ] }`}>
                            {fieldRow?.data?.map(inputData=>
                                <ConfigMapperFieldV2
                                    fieldData={inputData}
                                    fieldListData={fieldData}
                                    key={inputData?.id || uuid()}
                                    formData={formValues}
                                    setFieldData={setFieldData}
                                    onInputChange={inputChangeHandler}
                                />
                            )}
                        </div>
                    }
                })}

                <div className={styles.formSubmitWrapper}>
                    <input type='submit' className={styles.primary} value={validationLoading?'Please Wait...':'Preview'} disabled={validationLoading}/>
                    <button onClick={(e)=>{e.preventDefault();nextClickHandler() }} >Next</button>
                </div>
            </div>
        </form>
        {
            open && fieldsPreviewData &&
            <PopupWrapper2 isOpen={open} onClose={onClose} height="80vh" width="">
                <FieldMappingsPreveiwPopup data={fieldsPreviewData} onClose={onClose} onVerifyClick={verifyClickHandler}/>
            </PopupWrapper2>
        }
    </>
}

export default FormStep2