import React from 'react';
import { useIntegrationsFunc } from '../functionality/Integration.func';
import styles from './integrations.module.css';
import AppLoader from '../../../appLoader';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import { Plus } from 'lucide-react';
import IntegrationCard from '../.././../components/integrationCard/layout/IntegrationCard.jsx'

const Integrations = () => {
    const data = useIntegrationsFunc();
    return (
        <AppLoader>
            <div className={styles.integrationHeader}>
                <Typography variant='heading'>Integrations</Typography>
                <button id="create_new_integration" className={styles.createIntegrationBtn}>
                    <Plus />
                    create new integration
                </button>
            </div>
            <div className={styles.integrationsList}>
                {data?.integrations?.map((integration) => (
                    <IntegrationCard  data={integration} key={integration.id} />
                ))}
            </div>
        </AppLoader>
    )
}

export default Integrations;