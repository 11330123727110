import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOrganisationData } from '../../../redux/organisation/Selector';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import { useNavigate } from 'react-router-dom';
export const useAuthProgressFunc = () => {
    const [ error, setError ] = useState('')
    const [ loading, setLoading ] = useState(true)
    const navigate = useNavigate();
    const { callApi } = useCustomAxiosCall();
    const organisation = useSelector(selectOrganisationData);
    const orgId = organisation?.organisationData?.id;

    const sendAccessToken = async (accessToken, connector) =>{
        setError('');
        setLoading(true);
        if(!accessToken){
            setError('Unable to fetch Access Token');
            navigateToConnectorsPage();
        }
        const response = await callApi({
            uriEndPoint: {
                url: `/organization/${ orgId }/connector/dynamic/callback`,
                method: 'POST'
            },
            body: {
                name: connector ? connector : 'microsoft_dynamics_365',
                storeName: connector ? connector : 'Microsoft 365',
                code: accessToken
            }
        });
        if (response?.status !== 201) {
            if(response.data.message === 'ALREADY-CONNECTED'){
                setError('Already Connected')
            }else{
                setError('Something Went wrong.. Please try again later')
            }
        }
        if (response.data.status === 200) {
            navigate('/connector', {
                state: {
                    organisation_id: orgId
                } });
        } else {
            setError('Something Went Wrong!! Please check your credentials.')
        }
    }

    const navigateToConnectorsPage=()=>{
        setTimeout(() => {
            if(orgId){
                navigate('/connector', {
                    state: {
                        organisation_id: orgId
                    } });
            }else{
                navigate('/organisation')
            }
        }, 1500);
    }

    const handleConnectWhiplash = async (code,) => {
        const response = await callApi({
            uriEndPoint: {
                url: '/auth/generateToken',
                method: 'POST',
                service: 'whiplash'
            },
            body: {
                code: code,
                email: 'dev@ekyam.ai', // need to do dynamically or other way possible
                org_id: orgId
            }
        })

        if (response.status === 200) {
            navigate('/connector', {
                state: {
                    organisation_id: orgId
                } });
        } else {
            setError('Something Went Wrong!! Please check your credentials.')
        }
    }

    const handleConnectTiktok = async (authCode) => {
        const requestBody = {
            name: 'tiktok',
            authToken: authCode,
            org_id: orgId
        }
        const response = await callApi({
            uriEndPoint: {
                url: '/generate-token',
                method: 'POST',
            },
            body: requestBody
        })
        if (response?.status !== 201) {
            if(response.data.message === 'ALREADY-CONNECTED'){
                setError('Already Connected')
            }else{
                setError(response?.data?.message || 'Something Went wrong.. Please try again later')
            }
        }

        setLoading(false);
        navigateToConnectorsPage();
    }

    return { loading, error, sendAccessToken, handleConnectWhiplash,handleConnectTiktok };
}