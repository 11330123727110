import React from 'react'
import styles from './FormGroup.module.css'
// import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func'
import ConfigMapperFieldV2 from '../../../views/ConfigMapperV2/layout/ConfigMapperFieldV2.jsx'
// import { v4 as uuid } from 'uuid';
// import Spinner from '../../../commonComponents/spinner/Spinner.jsx';
import fieldMappingsData from '../../../views/ConfigMapperV2/data/fieldsMappings.json';
import Tabs from '../../tabs/layout/Tabs.jsx';
// import suggestionDummyData from '../data/getsuggestions.json'

const FormGroup = ({ formValues={}, onInputChange=()=>{}, loading, service='' }) => {

    // Assigning Variabless
    const fields = fieldMappingsData?.[ service ] || {}
    const tabsPanelContent={}
    Object.entries(fields).map(([ key, value ])=>{
        if(key !== 'references'){
            tabsPanelContent[ key.replace('Mappings', '') ] = <div className={styles.inputGroup} key={key}>
                {value.map((field) => {
                    return (
                        <ConfigMapperFieldV2
                            displayInRow={true}
                            fieldData={field}
                            key={`${ key }${ field?.field_name }`}
                            formData={formValues}
                            onInputChange={onInputChange}
                            parentFeild={`collections#${ key.replace('Mappings', '') }`}
                        />
                    );
                })}
            </div>
        }
    })
    const tabs = fieldMappingsData[ service ]?.collectionsFieldMappings;

    // Import functions
    console.log(fieldMappingsData)
    // const { getDisplayName } = useConfigMapperFieldV2()

    // Consoles
    console.log(loading)

    // JSX
    return (
        <div className={styles.groupWrapper}>
            <Tabs
                tabs={tabs}
                tabsContent = {tabsPanelContent}
                tabsContentPanelStyles={{ height:'500px', overflow:'auto' }}
            />
        </div>
    );
}

export default FormGroup
