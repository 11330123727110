// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logger_headerWrapper__jLAu2{
    display: flex;
    justify-content: space-between;
}

.logger_headerWrapper__jLAu2 .logger_filterWrapper__6\\+-JB{
    display: flex;
    gap: 1rem;
}

.logger_chartWrapper__BokPl{
    margin: 2rem 0;
    display: flex;
    justify-content: center;
}

.logger_gridContainer__YubS3 .logger_headerWrapper__jLAu2{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}
.logger_gridContainer__YubS3 .logger_headerWrapper__jLAu2 .logger_header__GgALj{
    color: #4669EA;
    display: flex;
    align-items: center;
    gap: 5px
}

.logger_statusCell__ezVct span{
    border-radius: 23px;
    padding: 6px 12px;
    color: white;
}

.logger_status200__IK-2X span{
    background-color:#16D6631A;
    color: #16D663;
  }
  .logger_status400__LZEbV span{
    background-color: #CEAD031A;
    color: #CEAD03;
  }
  .logger_status500__Vsco- span{
    background-color: #E71D361A;
    color: #E71D36;

  }`, "",{"version":3,"sources":["webpack://./src/views/logger/layout/logger.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,cAAc;IACd,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,SAAS;AACb;AACA;IACI,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB;AACJ;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,cAAc;EAChB;EACA;IACE,2BAA2B;IAC3B,cAAc;EAChB;EACA;IACE,2BAA2B;IAC3B,cAAc;;EAEhB","sourcesContent":[".headerWrapper{\n    display: flex;\n    justify-content: space-between;\n}\n\n.headerWrapper .filterWrapper{\n    display: flex;\n    gap: 1rem;\n}\n\n.chartWrapper{\n    margin: 2rem 0;\n    display: flex;\n    justify-content: center;\n}\n\n.gridContainer .headerWrapper{\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    gap: 1rem;\n}\n.gridContainer .headerWrapper .header{\n    color: #4669EA;\n    display: flex;\n    align-items: center;\n    gap: 5px\n}\n\n.statusCell span{\n    border-radius: 23px;\n    padding: 6px 12px;\n    color: white;\n}\n\n.status200 span{\n    background-color:#16D6631A;\n    color: #16D663;\n  }\n  .status400 span{\n    background-color: #CEAD031A;\n    color: #CEAD03;\n  }\n  .status500 span{\n    background-color: #E71D361A;\n    color: #E71D36;\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerWrapper": `logger_headerWrapper__jLAu2`,
	"filterWrapper": `logger_filterWrapper__6+-JB`,
	"chartWrapper": `logger_chartWrapper__BokPl`,
	"gridContainer": `logger_gridContainer__YubS3`,
	"header": `logger_header__GgALj`,
	"statusCell": `logger_statusCell__ezVct`,
	"status200": `logger_status200__IK-2X`,
	"status400": `logger_status400__LZEbV`,
	"status500": `logger_status500__Vsco-`
};
export default ___CSS_LOADER_EXPORT___;
