// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CollectiveDataDetail_mainWrapper__JuREU {
    background-color: var(--color-white);
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
    position: relative;
}
.CollectiveDataDetail_card__4wM3h {
    padding: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    margin-bottom: 2rem;
    background: #F5F5F5;
    border: 1px solid #DBE5FF;
    overflow: hidden;
}

.CollectiveDataDetail_tabRow__D1Ve7 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #DBE5FF;
        padding: 8px;
}

.CollectiveDataDetail_cardBody__NOrhc {
        display: grid;
        grid-template-columns: 1fr 1fr;
}

.CollectiveDataDetail_cardTitle__TgKd4 {
    font-family: 'Outfit';
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1;
    padding: 11px 8px;
    margin: 3px 0;
    border-left: 1px solid var(--color-gray-400);
}
`, "",{"version":3,"sources":["webpack://./src/views/collectiveData/view/layout/CollectiveDataDetail.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,UAAU;IACV,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,kCAAkC;IAClC,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;QACQ,aAAa;QACb,2BAA2B;QAC3B,mBAAmB;QACnB,yBAAyB;QACzB,YAAY;AACpB;;AAEA;QACQ,aAAa;QACb,8BAA8B;AACtC;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,4CAA4C;AAChD","sourcesContent":[".mainWrapper {\n    background-color: var(--color-white);\n    border-radius: 10px;\n    padding: 1rem;\n    width: 100%;\n    position: relative;\n}\n.card {\n    padding: 0;\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n    box-sizing: border-box;\n    transition: border-color 0.3s ease;\n    margin-bottom: 2rem;\n    background: #F5F5F5;\n    border: 1px solid #DBE5FF;\n    overflow: hidden;\n}\n\n.tabRow {\n        display: flex;\n        justify-content: flex-start;\n        align-items: center;\n        border: 1px solid #DBE5FF;\n        padding: 8px;\n}\n\n.cardBody {\n        display: grid;\n        grid-template-columns: 1fr 1fr;\n}\n\n.cardTitle {\n    font-family: 'Outfit';\n    font-weight: 600;\n    font-size: 1.125rem;\n    line-height: 1;\n    padding: 11px 8px;\n    margin: 3px 0;\n    border-left: 1px solid var(--color-gray-400);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": `CollectiveDataDetail_mainWrapper__JuREU`,
	"card": `CollectiveDataDetail_card__4wM3h`,
	"tabRow": `CollectiveDataDetail_tabRow__D1Ve7`,
	"cardBody": `CollectiveDataDetail_cardBody__NOrhc`,
	"cardTitle": `CollectiveDataDetail_cardTitle__TgKd4`
};
export default ___CSS_LOADER_EXPORT___;
