import * as React from 'react';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';
import styles from './table.module.css';
import Spinner from '../spinner/Spinner.jsx';

export default function Table({
    headerList,
    rowsData,
    totalRecords = 0,
    setCurrentPage = () => {},
    setPageSize = () => {},
    loading,
    props={},
    hiddenColumns=[]
}) {
    const [ paginationModel, setPaginationModel ] = React.useState({
        page: 0,
        pageSize: 10,
    });
    const [ data, setData ] = React.useState(null);
    const apiRef = useGridApiRef();
    const handleColumns = () => {
        const news = headerList.slice(5);
        const results = news.reduce((result, item) => {
            result[ item.field ] = false;
            return result;
        }, {});
        hiddenColumns.map(hiddenColumn => {
            const colRow = headerList.filter(header=>header?.field === hiddenColumn);
            if(colRow.length>0){
                results[ hiddenColumn ] = false
            }
        })
        setData(results);
    };

    React.useEffect(() => {
        if (headerList.length > 0) {
            handleColumns();
        }
    }, [ headerList ]);
    React.useEffect(() => {
        setCurrentPage(paginationModel.page);
    }, [ paginationModel ]);

    React.useEffect(() => {
        setPageSize(paginationModel.pageSize);
    }, [ paginationModel ]);
    console.log(paginationModel.pageSize, 'paginationModel.pageSize')
    const CustomLoader = () => (
        <div>
            <Spinner height={`${ paginationModel.pageSize * 7 }vh`} />
        </div>
    );

    const NoDataMessage = () => (
        <div className={styles.noDataMessage}>
            <p>No data available</p>
        </div>
    );

    if (!loading && rowsData?.length === 0) {
        return <NoDataMessage />;
    }
    else if (loading || !data) {
        return <CustomLoader />;
    }
    return (
        <div className={styles.tableWrapper}>
            <DataGrid
                className={styles.dataGrid}
                autoHeight
                ref={apiRef}
                rows={rowsData}
                columns={headerList}
                disableRowSelectionOnClick
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: CustomLoader,
                }}
                columnVisibilityModel={{
                    ...data,
                }}
                onColumnVisibilityModelChange={(updateColumn) => {
                    setData(updateColumn);
                }}
                rowCount={totalRecords}
                loading={loading}
                pageSizeOptions={[ 10 ]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sx={{
                    '--DataGrid-overlayHeight': '300px',

                }}
                {...props}
            />
        </div>
    );
}