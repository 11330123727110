const useNotificationsGrid = (styles) => {
    const getRowClassName = (params) => {
        if (params.row.read === 'No') {
            return styles.unread;
        }else{
            return styles.read;
        }
    };

    return { getRowClassName };
}

export default useNotificationsGrid;