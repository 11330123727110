// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropDown_dropdown__rbLc7 {
    background-color: var(--color-white);
    border-radius: 20px;
    border: 2px solid #D0D3D9;
    box-shadow: 0px 1px 2px 0px #1018280D;
    cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 17px;
    line-height: 20px;
    text-align: left;
    /* margin-right: 2rem; */
    position: relative;
}

.dropDown_dropdown__rbLc7:hover {
    border: 2px solid black;
}

.dropDown_dropdownHeader__F5AJb {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Outfit';
    text-transform: capitalize;
}

.dropDown_dropdownBody__Ltz58 {
    position: absolute;
    top: 3rem;
    box-shadow: 0px 4px 20px 0px #00000024;
    display: none;
    right: 0rem;
    border-radius: 10px;
    background: white;
    width: 10rem;
    z-index: 10;
    filter: blur(0px);
}

.dropDown_open__Fucx6 {
    display: block;
    max-height: 150px;
    overflow: auto;
}

.dropDown_dropdownItem__tD-hf {
    padding: 15px;
    border-bottom:0.5px solid #DFE7FF;
    text-transform: capitalize;
}

.dropDown_dropdownItem__tD-hf:hover {
    cursor: pointer;
}
.dropDown_groupByDate__1YpZ7 {
    display: flex;
    align-items: center;
    gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/dropDown/dropDown.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,yBAAyB;IACzB,qCAAqC;IACrC,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,qBAAqB;IACrB,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,sCAAsC;IACtC,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,iCAAiC;IACjC,0BAA0B;AAC9B;;AAEA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ","sourcesContent":[".dropdown {\n    background-color: var(--color-white);\n    border-radius: 20px;\n    border: 2px solid #D0D3D9;\n    box-shadow: 0px 1px 2px 0px #1018280D;\n    cursor: pointer;\n    font-family: Inter;\n    font-size: 14px;\n    font-weight: 500;\n    padding: 6px 17px;\n    line-height: 20px;\n    text-align: left;\n    /* margin-right: 2rem; */\n    position: relative;\n}\n\n.dropdown:hover {\n    border: 2px solid black;\n}\n\n.dropdownHeader {\n    cursor: pointer;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-family: 'Outfit';\n    text-transform: capitalize;\n}\n\n.dropdownBody {\n    position: absolute;\n    top: 3rem;\n    box-shadow: 0px 4px 20px 0px #00000024;\n    display: none;\n    right: 0rem;\n    border-radius: 10px;\n    background: white;\n    width: 10rem;\n    z-index: 10;\n    filter: blur(0px);\n}\n\n.open {\n    display: block;\n    max-height: 150px;\n    overflow: auto;\n}\n\n.dropdownItem {\n    padding: 15px;\n    border-bottom:0.5px solid #DFE7FF;\n    text-transform: capitalize;\n}\n\n.dropdownItem:hover {\n    cursor: pointer;\n}\n.groupByDate {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `dropDown_dropdown__rbLc7`,
	"dropdownHeader": `dropDown_dropdownHeader__F5AJb`,
	"dropdownBody": `dropDown_dropdownBody__Ltz58`,
	"open": `dropDown_open__Fucx6`,
	"dropdownItem": `dropDown_dropdownItem__tD-hf`,
	"groupByDate": `dropDown_groupByDate__1YpZ7`
};
export default ___CSS_LOADER_EXPORT___;
