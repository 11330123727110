// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connectors_connectorsWrapper__A8Wc- {
    margin-bottom: 2rem;
}

.connectors_connectorHeading__yr\\+LL {
    border-bottom: 2px solid var(--color-black);
    padding-bottom: 3px;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 8px;
}

.connectors_breadcrumbs__zsnx0 {
    align-items: center;
    display: flex;
    gap: 5px;
    margin: 1rem 0;
}

.connectors_connectorsGrid__JtsPp {
    display: grid;
    gap: 4rem 2rem;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    margin: 2rem 0;
}

.connectors_breadcrumbIcon__cdqsr {
    width: 24px;
    height: 20px;
}

.connectors_currentBreadcrumb__xPAhE {
    font-weight: 500 !important;
}

.connectors_subTitle__mj7-e {
    margin: 1rem 0;
    font-family: Outfit;
    font-size: 17px;
    font-weight: 400;
    line-height: 22.68px;
    text-align: left;
    text-decoration: underline;
    text-transform: capitalize;
}

.connectors_connectedConnctorsContainer__OSJob {
    margin-bottom: 2rem;
}

.connectors_connectedConnectorsGrid__tbQOl {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(4, minmax(0, 0.7fr));
    margin-top: 2rem;

}

.connectors_defaultConnectorsWrapper__ylWiz{
    margin: 1rem 0;
}
.connectors_title__hvJpo{
    margin-bottom: 2rem;
    font-size: 18px;
    font-weight: 500;
}
.connectors_defaultConnectorsWrapper__ylWiz .connectors_groupWrapper__AkzPX{
    margin: 1rem 0;
}`, "",{"version":3,"sources":["webpack://./src/views/connectors/layout/connectors.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,2CAA2C;IAC3C,mBAAmB;IACnB,uBAAkB;IAAlB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,QAAQ;IACR,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,gDAAgD;IAChD,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,0BAA0B;IAC1B,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,kDAAkD;IAClD,gBAAgB;;AAEpB;;AAEA;IACI,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB","sourcesContent":[".connectorsWrapper {\n    margin-bottom: 2rem;\n}\n\n.connectorHeading {\n    border-bottom: 2px solid var(--color-black);\n    padding-bottom: 3px;\n    width: fit-content;\n    margin-bottom: 8px;\n}\n\n.breadcrumbs {\n    align-items: center;\n    display: flex;\n    gap: 5px;\n    margin: 1rem 0;\n}\n\n.connectorsGrid {\n    display: grid;\n    gap: 4rem 2rem;\n    grid-template-columns: repeat(5, minmax(0, 1fr));\n    margin: 2rem 0;\n}\n\n.breadcrumbIcon {\n    width: 24px;\n    height: 20px;\n}\n\n.currentBreadcrumb {\n    font-weight: 500 !important;\n}\n\n.subTitle {\n    margin: 1rem 0;\n    font-family: Outfit;\n    font-size: 17px;\n    font-weight: 400;\n    line-height: 22.68px;\n    text-align: left;\n    text-decoration: underline;\n    text-transform: capitalize;\n}\n\n.connectedConnctorsContainer {\n    margin-bottom: 2rem;\n}\n\n.connectedConnectorsGrid {\n    display: grid;\n    gap: 1.5rem;\n    grid-template-columns: repeat(4, minmax(0, 0.7fr));\n    margin-top: 2rem;\n\n}\n\n.defaultConnectorsWrapper{\n    margin: 1rem 0;\n}\n.title{\n    margin-bottom: 2rem;\n    font-size: 18px;\n    font-weight: 500;\n}\n.defaultConnectorsWrapper .groupWrapper{\n    margin: 1rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"connectorsWrapper": `connectors_connectorsWrapper__A8Wc-`,
	"connectorHeading": `connectors_connectorHeading__yr+LL`,
	"breadcrumbs": `connectors_breadcrumbs__zsnx0`,
	"connectorsGrid": `connectors_connectorsGrid__JtsPp`,
	"breadcrumbIcon": `connectors_breadcrumbIcon__cdqsr`,
	"currentBreadcrumb": `connectors_currentBreadcrumb__xPAhE`,
	"subTitle": `connectors_subTitle__mj7-e`,
	"connectedConnctorsContainer": `connectors_connectedConnctorsContainer__OSJob`,
	"connectedConnectorsGrid": `connectors_connectedConnectorsGrid__tbQOl`,
	"defaultConnectorsWrapper": `connectors_defaultConnectorsWrapper__ylWiz`,
	"title": `connectors_title__hvJpo`,
	"groupWrapper": `connectors_groupWrapper__AkzPX`
};
export default ___CSS_LOADER_EXPORT___;
