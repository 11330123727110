// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner_loaderContainer__0mcB7 {
    height: 70vh;
    border-radius: 10px;
    width: 100%;
    /* background-color: #1e1e1e8c; */
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.spinner_loaderContainer__0mcB7.spinner_auto__lPEqF{
    height: 100%;
}
/* HTML: <div class="loader"></div> */
.spinner_loader__iSGsd {
    width: 17px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #314fa3;
    display: grid;
    animation: spinner_l22-0__v4sE5 2s infinite linear;
}

.spinner_loader__iSGsd:before {
    content: "";
    grid-area: 1/1;
    margin: 15%;
    border-radius: 50%;
    background: #e33220;
    transform: rotate(0deg) translate(150%);
    animation: spinner_l22__BT1Ot 1s infinite;
}

.spinner_loader__iSGsd:after {
    content: "";
    grid-area: 1/1;
    margin: 15%;
    border-radius: 50%;
    background: #f2b01b;
    transform: rotate(0deg) translate(150%);
    animation: spinner_l22__BT1Ot 1s infinite;
}

.spinner_loader__iSGsd:after {
    animation-delay: -.5s
}

@keyframes spinner_l22-0__v4sE5 {
    100% {
        transform: rotate(1turn)
    }
}

@keyframes spinner_l22__BT1Ot {
    100% {
        transform: rotate(1turn) translate(150%)
    }
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/spinner/spinner.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,iCAAiC;IACjC,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,YAAY;AAChB;AACA,qCAAqC;AACrC;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,kDAAmC;AACvC;;AAEA;IACI,WAAW;IACX,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,uCAAuC;IACvC,yCAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,uCAAuC;IACvC,yCAA0B;AAC9B;;AAEA;IACI;AACJ;;AAEA;IACI;QACI;IACJ;AACJ;;AAEA;IACI;QACI;IACJ;AACJ","sourcesContent":[".loaderContainer {\n    height: 70vh;\n    border-radius: 10px;\n    width: 100%;\n    /* background-color: #1e1e1e8c; */\n    background-color: #fff;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.loaderContainer.auto{\n    height: 100%;\n}\n/* HTML: <div class=\"loader\"></div> */\n.loader {\n    width: 17px;\n    aspect-ratio: 1;\n    border-radius: 50%;\n    background: #314fa3;\n    display: grid;\n    animation: l22-0 2s infinite linear;\n}\n\n.loader:before {\n    content: \"\";\n    grid-area: 1/1;\n    margin: 15%;\n    border-radius: 50%;\n    background: #e33220;\n    transform: rotate(0deg) translate(150%);\n    animation: l22 1s infinite;\n}\n\n.loader:after {\n    content: \"\";\n    grid-area: 1/1;\n    margin: 15%;\n    border-radius: 50%;\n    background: #f2b01b;\n    transform: rotate(0deg) translate(150%);\n    animation: l22 1s infinite;\n}\n\n.loader:after {\n    animation-delay: -.5s\n}\n\n@keyframes l22-0 {\n    100% {\n        transform: rotate(1turn)\n    }\n}\n\n@keyframes l22 {\n    100% {\n        transform: rotate(1turn) translate(150%)\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": `spinner_loaderContainer__0mcB7`,
	"auto": `spinner_auto__lPEqF`,
	"loader": `spinner_loader__iSGsd`,
	"l22-0": `spinner_l22-0__v4sE5`,
	"l22": `spinner_l22__BT1Ot`
};
export default ___CSS_LOADER_EXPORT___;
