import React from 'react'
import styles from './FormStep.module.css'
import ConfigMapperFieldV2 from '../../../views/ConfigMapperV2/layout/ConfigMapperFieldV2.jsx'
import useConfigMapperField from '../../../views/configMapper/functionality/useConfigMapperFeild.func';
import StepSlider from '../../stepSlider/layout/StepSlider.jsx';
import useFormStep1 from '../functionality/useFormStep1.func';
import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func';
import { useSelector } from 'react-redux';
import { selectOrganisationData } from '../../../redux/organisation/Selector';
import { useNavigate } from 'react-router-dom';
import configStepData from '../../../dummyData/ConfigurationMapperV2/ConfigMapperStepsData.json'

const FormStep3 = ({
    fieldInputData,
    sliderData,
    setConfigFormData = () => { },
    fetchedData = {},
    configFormData = {},
    setActiveFormStep = () => { }
}) => {
    // Importing Functions from hooks
    const { convertToNested, flattenJSON } = useConfigMapperField();
    const { updateCustomFieldConfigJson } = useConfigMapperFieldV2();
    const { submitConfigData, loading } = useConfigMapperField();
    const { prepareFormData } = useFormStep1();
    const organisation = useSelector(selectOrganisationData);
    console.log(organisation, '#organisation', fieldInputData)
    const orgId = organisation?.organisationData?.id;
    const navigate = useNavigate();
    // States
    const [ formValues, setFormValues ] = React.useState({ ...flattenJSON(fetchedData) })
    const [ validationError, setValidationError ] = React.useState({})
    const [ fieldData, setFieldData ] = React.useState([ ...configStepData[ 2 ].inputData ])
    const [ isSubmitted, setIsSubmitted ] = React.useState(false)
    console.log(fieldData, '#fieldData')
    const configFormSubmitData = { ...JSON.parse(JSON.stringify(configFormData)) }

    // Event Handlers
    const inputChangeHandler = (event) => {
        if (event?.target?.name) {
            setFormValues(prevState => {
                let newObj = prevState;
                newObj[ event?.target?.name ] = event?.target?.value
                return newObj
            })
        }
    }

    const formSubmitHandler = async (event) => {
        event.preventDefault()
        if (isSubmitted) return
        setIsSubmitted(true)
        const formElements = event.target.elements;
        let finalFormData = prepareFormData(formElements, fieldData, formValues)
        let nestedFormData = convertToNested(finalFormData)
        delete configFormSubmitData[ 'endpoints' ]
        setConfigFormData({ ...configFormSubmitData, ...nestedFormData })
        // TODO: Remove this after testing. the accessKeys will get from defaulConnector Collection
        await submitConfigData({
            ...configFormSubmitData,
            ...nestedFormData,
        }, setIsSubmitted)

        navigate('/connector', {
            state: {
                organisation_id: orgId
            }
        });
    }

    // useEffect
    React.useEffect(() => {
        setFormValues({ ...flattenJSON(fetchedData) })
        updateCustomFieldConfigJson(fieldData, setFieldData, fetchedData, flattenJSON(fetchedData))
    }, [ fetchedData ])

    // JSX
    return <form className={`${ styles.formWrapper } ${ (sliderData?.showSlider) ? styles.withSlider : '' }`} onSubmit={formSubmitHandler} >
        {sliderData?.showSlider && <div className={styles.sliderWrapper}>
            <StepSlider vertical={sliderData?.isVertical} activeStep={0} stepsInfo={sliderData?.stepsInfo} showStepNumber={sliderData?.showStepNumber} />
        </div>}

        <div className={styles.inputFieldsWrapper}>
            {fieldData?.map((fieldRow, index) => {
                return <div key={index} className={`${ styles.row } ${ styles[ `col-${ fieldRow?.columnsCount || 3 }` ] }`}>
                    {fieldRow?.data?.map(inputData =>
                        <ConfigMapperFieldV2
                            fieldData={inputData}
                            fieldListData={fieldData}
                            key={inputData?.field_name}
                            formData={formValues}
                            setFieldData={setFieldData}
                            onInputChange={inputChangeHandler}
                            validationError={validationError}
                            setValidationError={setValidationError}
                        />
                    )}
                </div>
            })}

            <div className={styles.formSubmitWrapper}>
                <button type='button' className={styles.secondary} onClick={() => setActiveFormStep(0)}>Back</button>
                <input type='submit' className={styles.primary} value={loading ? 'Saving...' : 'Save Changes'} disabled={loading || isSubmitted} />
            </div>
        </div>
    </form>
}

export default FormStep3