// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inboundShipment_mainWrapper__n5yJo {
    background-color: var(--color-white);
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
    position: relative;
}

/* .lineItemsContainer{
    max-height: 440px;
    overflow: auto;
}
.lineItemsContainer::-webkit-scrollbar {
    width: 10px;
}
.lineItemsContainer table{
width: 99%;
margin: 0 auto;} */`, "",{"version":3,"sources":["webpack://./src/views/inboundShipments/view/layout/inboundShipment.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,kBAAkB;AACtB;;AAEA;;;;;;;;;kBASkB","sourcesContent":[".mainWrapper {\n    background-color: var(--color-white);\n    border-radius: 10px;\n    padding: 1rem;\n    width: 100%;\n    position: relative;\n}\n\n/* .lineItemsContainer{\n    max-height: 440px;\n    overflow: auto;\n}\n.lineItemsContainer::-webkit-scrollbar {\n    width: 10px;\n}\n.lineItemsContainer table{\nwidth: 99%;\nmargin: 0 auto;} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": `inboundShipment_mainWrapper__n5yJo`
};
export default ___CSS_LOADER_EXPORT___;
