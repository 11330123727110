import styles from './organisations.module.css';
import AppLoader from '../../../appLoader';
import Card from '../../../commonComponents/card/Card.jsx';
import { useOranisationsFunc } from '../functionality/Organisation.func';
import Button from '../../../commonComponents/button/Button.jsx';
import { useNavigate } from 'react-router-dom';
import OrganisationSkeleton from '../../../components/skeletons/organisation/OrganisationSkeleton.jsx';
import Modal from '../../../commonComponents/modal/Modal.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { setOrganisation } from '../../../redux/organisation/Slice';
import { Plus } from 'lucide-react';
import { selectUserData } from '../../../redux/user/Selector';

const Organisation = () => {
    const { organisation, loading, error, createOrganizationData, handleSubmit, handleDelete, handleCreateOrganizationModal, handleChange, createNewOrganization, isOpen, closeModal, setIsEdit } = useOranisationsFunc();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectUserData);
    const userData =user?.userData?.data?.person;
    const handleRoute = (id, encrypt_id, databaseName) => {
        dispatch(setOrganisation({ id, encrypt_id, databaseName }));
        navigate('/connector', { state: { organisation_id: id } });
    };
    const renderOrganisations = () => (
        <div className={styles.organisationListing} id="organisationListing">
            {
                organisation?.map(({ _id, encrypt_org_id, name, description, icon, updated_at, database }) => (
                    <Card
                        key={_id}
                        id={_id}
                        title={name}
                        description={description}
                        icon={icon}
                        lastModify={updated_at}
                        handleRoute={() => handleRoute(_id,encrypt_org_id, database)}
                        handleDelete={handleDelete}
                        handleEdit={()=> {setIsEdit(_id); handleCreateOrganizationModal()}}
                    />
                ))
            }
        </div>
    )

    return (
        <AppLoader>
            <div className={styles.organisationContainer}>
                <div className={styles.organisationHeader}>
                    <span className={styles.organisationTitle}>Organisations</span>
                    <div className={styles.organisationBtnWrapper}>
                        {/*<Button className={styles.organisationInviteBtn}>Invite User</Button> */}
                        {userData?.roles?.includes('super_admin') && <Button id="organisationButton" className={styles.organisationButton} onClick={handleCreateOrganizationModal}><Plus color='white'/>Create New Organization</Button>}
                    </div>
                </div>
                {/* <div className={styles.organisationSubHeader}>
                    Organizations &gt; <span>Companies</span>
                </div> */}
                {
                    !isOpen && loading ? <OrganisationSkeleton /> : renderOrganisations()
                }
            </div>
            {isOpen && <Modal isOpen={isOpen} modalFieldData={createOrganizationData?.createOrganisations} error={error} value={createNewOrganization} handleChange={handleChange} onSubmit={handleSubmit} onClose={closeModal} /> }
        </AppLoader>
    );
};
export default Organisation;
