import React, { useEffect, useState } from 'react'
import AppLoader from '../../appLoader'
import styles from './productDetails.module.css'
import Typography from '../../commonComponents/typography/Typography.jsx'
import Input from '../../commonComponents/input/Input.jsx'
import LineChart from '../../commonComponents/charts/lineChart/LineChart.jsx'
import StatCounter from '../../components/widgets/statCounter/layout/StatCounter.jsx';
import LucideIcon from '../../commonComponents/lucideicon/LucideIcon.jsx'
import Stopwatch from '../../commonComponents/stopwatch/Stopwatch.jsx'
import { useLocation, useParams } from 'react-router-dom';
import ProductDetailData from '../../dummyData/ProductDetails.json'
import VisTimeline from '../visTimeline/VisTimeline.jsx'
import useProductDetails from './functionality/useProductDetails.func'
import { calculateDaysFromMonths, formatedDate, getDateLocale } from '../../helpers/index'
import Spinner from '../../commonComponents/spinner/Spinner.jsx'
import Button from '../../commonComponents/button/Button.jsx'
import DropDown from '../../commonComponents/dropDown/DropDown.jsx'
import Table from '../../commonComponents/table/Table.jsx';
import useTables from '../../commonComponents/table/useTables.func';

const ProductDetails=()=> {
    const [ productDetail, setProductDetail ]=useState({})
    const [ filterData, setFilterData ]=useState([])
    const [ fields, setFields ]=useState({
        'reservedQty':'',
        'reservedPushQty':'',
        'time':'',
        'GiftWithPurchase':''
    });
    const { timelineDateOptions, timeLineOptions, dropdownOptions, timeLinePopupKey }=ProductDetailData
    const { state }=useLocation();
    const { itemId }=useParams();
    const { productsDetail } = state

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const [ timelineDate, setTimelineDate ]=useState({
        start_date: formatedDate(new Date()),
        end_date: formatedDate(tomorrow)
    }
    )
    const [ platform, setPlatform ] = useState(dropdownOptions[ 0 ].value)
    const [ timeLine, setTimeLine ]=useState(timelineDateOptions[ 0 ].value);
    const dropdownChangeHandler = (value) => {
        setPlatform(value)
    }
    useEffect(()=>{
        if(productsDetail.length>0){
            const updatedData= productsDetail.find((obj)=>obj.product_id === itemId);
            setProductDetail(updatedData)
        }
    },[ productsDetail ])
    const parameters={
        start_date:timelineDate.start_date,
        end_date:timelineDate.end_date,
        sku:productDetail?.product_sku,
        filter: filterData.length>0 ? filterData.join(','): filterData
    }

    const { data: dataGrid,
        columns: columnsGridData,
        loading: loadingDataGrid, error: errorDataGrid,
        currentPage,
        setCurrentPage,totalRecords,
        setPageSize, rowData } = useTables({ url:'/order-timeline', service:'collectivedata', params:parameters }, [], [], {});
    const { data, loading, error } = useProductDetails({ url:'/eta-shipnotice', service:'collectivedata' });
    const shipmentData = data?.data?.[ 0 ]|| {};

    const handleChange=(e)=>{
        setFields({ ...fields, [ e.target.name ]:e.target.value })
    }
    const nextShipment=[ { label: 'Shipment level No', value: shipmentData?.sender }, { label: 'Quantity', value:shipmentData?.shipnotice_item_quantity
    }, { label: 'Delivery Date', value: getDateLocale(shipmentData?.estimated_delivery_date) } ]
    const getTimeLineDetail=(value)=>{
        const today = new Date();
        const date = new Date(today);
        date.setDate(today.getDate() - value);
        setTimelineDate(  { start_date: formatedDate(date),
            end_date: formatedDate(tomorrow) });

    }
    const handleTimelineFilter=(value) => {
        setTimeLine(value)
        if(value==='Today'){
            setTimelineDate({
                start_date: formatedDate(today),
                end_date: formatedDate(tomorrow)
            });
        }
        else if(value.includes('Month')){
            const getMonth=value.split(' ')[ 1 ]
            const getDays=calculateDaysFromMonths(getMonth)
            getTimeLineDetail(getDays)

        }
        else{
            const getDays=value.split(' ')[ 1 ]
            getTimeLineDetail(getDays)
        }
    }
    const handleFilter=(value)=>{
        if(!filterData.includes(value)){
            setFilterData([ ...filterData, value ])
        }
        else{
            const updatedArray = filterData.filter(item => item !== value);
            setFilterData(updatedArray)
        }
    }
    return (
        <AppLoader>
            <div className={styles.productDetailsWrapper}>
                <div className={styles.productDetailsHeader}>
                    <div className={styles.imageWrapper}>
                        <img src={productDetail?.image_src} alt='' />
                    </div>
                    <div className={styles.leftContent}>
                        <Typography className={styles.title} variant='title'>{productDetail?.product_name}</Typography>
                        <p className={styles.subHeading}>{productDetail?.description}</p>
                        <div className={styles.productDetails}>
                            <div className={styles.sku}>SKU  <span>{productDetail?.product_sku}</span></div>
                            <div className={styles.orderFullfill}>Order to be Fulfilled   {ProductDetailData.orderFulfilled}</div>
                            <div className={styles.inventoryInHand}>Existing Inventory In Hand   {productDetail?.quantity}</div>
                        </div>
                        <div className={styles.inputFieldsWrapper}>
                            {ProductDetailData.fields.map((obj, index)=>
                                <Input type={obj.text} key={index} className={styles.inputField} name={obj.name} value={fields[ obj.name ]} placeholder={obj.placeholder} onChange={handleChange}/>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.chartWidgetWrapper}>
                        <div className={styles.chartWrapper}>
                            <div className={styles.chartHeader}>
                                <div className={styles.chartIcon}>
                                    <LucideIcon name={ProductDetailData.lineChart.icon} />
                                </div>
                                <Typography variant='title'>{ProductDetailData.lineChart.title}</Typography>
                            </div>
                            <LineChart
                                data={ProductDetailData.lineChart?.data}
                                // labels={lineChart.labels}
                                height={275}
                                // // width={400}
                                colors={ProductDetailData.lineChart?.colors}
                            />
                        </div>
                        <div className={styles.widgetWrapper}>
                            <div className={styles.widgets}>
                                {ProductDetailData.stats.map((obj, index)=>
                                    <StatCounter key={index} data={{ stat_name: obj.stat_name, count: obj.count, icon: obj.icon }} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.pushProductNextShipmentWrapper}>
                        <div className={styles.pushProductsWrapper}>
                            <Input type="text" className={styles.inputField} name="reservedPushQty" placeholder='Enter Reserved Qty' onChange={handleChange} value={fields[ 'reservedPushQty' ]}/>
                            <DropDown options={dropdownOptions} className={styles.dropDown} defaultValue={platform} showDownIcon={true} bodyClassName={styles.dropDownBody} itemClassName={styles.dropdownItem} onDropDownChange={dropdownChangeHandler}/>
                            <Button className={`${ styles.button }`}>
                                Push
                            </Button>
                        </div>
                        <div className={styles.nextShipmentWrapper}>
                            {loading
                                ? <Spinner height="15vh"/>
                                : error
                                    ? <Typography variant='error'>{error}</Typography>
                                    :
                                    <>
                                        <div className={styles.nextShipment}>
                                            <div className={styles.innerHeader}>
                                                <div className={styles.iconWrapper}>
                                                    <LucideIcon name='CalendarClock'/>
                                                </div>
                                                <div className={styles.nextShipmentTitle}>{ProductDetailData.nextShipment.title
                                                }</div>
                                            </div>
                                            <div className={styles.stopWatch}>
                                                <Stopwatch time={data?.data?.[ 0 ]?.estimated_delivery_date}/>
                                                <div>
                                                    <LucideIcon name='AlarmClock'/>
                                                </div>
                                            </div>

                                        </div>
                                        <div className={styles.shipmentDetails}>

                                            {nextShipment.map((obj, index)=>
                                                <div key={index}>
                                                    <div className={styles.header}>{obj.label}</div>
                                                    <div className={styles.value}>{obj.value}</div>

                                                </div>

                                            )}

                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.timeLineContainer}>
                    <div className={styles.dropdownWrapper}>
                        <DropDown id="dropdown" options={timelineDateOptions} className={styles.dropDown} onDropDownChange={handleTimelineFilter} defaultOption={timeLine}/>
                    </div>
                    {loadingDataGrid
                        ? <Spinner height="15vh"/>
                        : errorDataGrid
                            ? <Typography variant='error'>{errorDataGrid}</Typography>
                            :
                            <>
                                <VisTimeline timeLineData={rowData.event_data} timeLineHeader={timeLinePopupKey}/>

                            </>
                    }
                    <div
                        className={styles.options}
                    >
                        {timeLineOptions.map((option, index) => (
                            <div key={index} className={styles.option}>
                                <input type='checkbox' onClick={()=>handleFilter(option.value)}/> <span className={styles.filterLabel}>{option.name}</span>
                            </div>
                        ))}
                    </div>
                    <div className={styles.timelineDetails}>
                        <Typography variant='heading'>Timeline Details</Typography>
                        {loadingDataGrid
                            ? <Spinner height="15vh"/>
                            : errorDataGrid
                                ? <Typography variant='error'>{errorDataGrid}</Typography>
                                :
                                <Table
                                    loading={loadingDataGrid}
                                    headerList={columnsGridData}
                                    totalRecords={totalRecords}
                                    rowsData={dataGrid}
                                    setPageSize={setPageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                        }
                    </div>
                </div>
            </div>
        </AppLoader>
    )
}

export default ProductDetails