import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    notificationsData: []
};

const notification = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        addNotification: (state, action) => {
            state.notificationsData = [ { name:action.payload.name, id:action.payload.id, time:action.payload.time }, ...state.notificationsData ];
            // if(state?.notificationsData?.length > 5){
            //     state?.notificationsData = 
            // }
        },
        mrkAsReadNotification: (state, action) => {
            console.log(action.payload);
            state.notificationsData =state.notificationsData.map(notification =>
                notification.value === action.payload ? { ...notification, read: true } : notification
            )
        }
    }
});

export const notificationReducer = notification.reducer;
export const { addNotification, mrkAsReadNotification } = notification.actions;