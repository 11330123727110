import { toast } from 'react-toastify';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import React from 'react';
import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func';

const useFormStep1 = () => {
    const { callApi } = useCustomAxiosCall();
    const [ error, setError ] = React.useState({})
    const [ validationLoading, setValidationLoading ] = React.useState(false)
    const { getValidatedFields, validateInputHandler } = useConfigMapperFieldV2();
    const verifyConnectivityConfig = async (formSubmitData, setValidationError, setActiveStep,setConfigFormData, ) => {
        setError({})
        setValidationLoading(true)
        const response = await callApi({
            uriEndPoint: {
                url: '/validate-connectivity',
                method: 'POST',
                service:'config-mapper'
            },
            body: { data:formSubmitData }
        });
        if(response?.status !== 200 || response?.data?.error){
            toast.error(response?.data?.error || 'Something Went Wrong', { position:'top-right' })
            setValidationLoading(false)
            return
        }
        const errObj = {}
        for (const key in response?.data) {
            const element = response?.data[ key ];
            if(element?.error){
                errObj[ key ] = element?.error
            }
        }
        if(Object.keys(errObj).length > 0) {
            setValidationError(prev=>{
                return {
                    ...prev,
                    endpoints:errObj
                }
            })
        }else{
            toast.success('Validation Successful', { position:'top-right' })
            setConfigFormData(prev=>{
                return { ...prev, ...formSubmitData }
            })
            setActiveStep(1)
        }
        setError(prev=>{return { ...prev, ...errObj }})
        setValidationLoading(false)
        return response
    }

    const prepareFormData = (formElements, fieldData, formValues) => {
        let formSubmitData = {}

        for (const element of formElements) {
            if(element.name.includes('[]')){
                if(formSubmitData[ element.name ]){
                    formSubmitData[ element.name ].push(element.value)
                }else{
                    formSubmitData[ element.name ] = [ element.value ]
                }
            }else{
                formSubmitData[ element.name ] = element.value
            }
        }
        for(let fieldGroup of fieldData){
            for (const field of fieldGroup.data) {
                if(field?.field_type === 'radio'){
                    formSubmitData[ field?.field_name ] = (typeof formValues[ field?.field_name ] === 'boolean')?formValues[ field?.field_name ]:(formValues[ field?.field_name ] === 'true')
                }
                if(field?.field_children && field?.field_children.length > 0){
                    for (const field_children of field.field_children) {
                        if(field_children?.field_type === 'radio'){
                            let inputName = `${ field?.field_name }#${ field_children?.field_name }`
                            console.log(inputName, formSubmitData[ inputName ], 'formValuesRadio', formValues[ inputName ], formValues)
                            formSubmitData[ inputName ] = (typeof formValues[ inputName ] === 'boolean')?formValues[ inputName ]:(formValues[ inputName ] === 'true')
                        }
                    }
                }
            }
        }
        const finalFormData = {}
        for(let key in formSubmitData){
            if(key !== ''){
                if(key.includes('input_name[]')){
                    const inputKey = key?.split('#')?.slice(0, -1)?.join('#')
                    const valueKey = inputKey + '#input_value[]'
                    formSubmitData[ key ].forEach((val, i)=>{
                        if(val !== ''){
                            finalFormData[ inputKey+'#'+val ] = formSubmitData[ valueKey ][ i ]
                        }
                    })
                }else if(!key.includes('input_value[]')){
                    finalFormData[ key ] = formSubmitData[ key ]
                }
            }
            if(key.includes('__list')){
                let newKey = key.replace('__list','')
                finalFormData[ newKey ] = formSubmitData[ key ].split(',').map(item=>item.trim())
                delete finalFormData[ key ]
            }
            if(key.includes('__array')){
                let oldKey = key
                if(!key.includes('data_accessor')){
                    key = key.replace('__array', '#fields')
                }else{
                    key = key.replace('__array', '')
                }
                finalFormData[ key ] = formSubmitData[ oldKey ]
                delete finalFormData[ oldKey ]
            }
        }
        return finalFormData
    }

    const handleFormValidation = (fieldInputData, nestedFormData, setValidationError) => {
        let validatedFields = getValidatedFields(fieldInputData)
        let validationErrObj={}
        for (const field of validatedFields) {
            if(typeof nestedFormData?.[ field?.field_name ] === 'object'){
                validationErrObj[ field?.field_name ]={}
                for (const key in nestedFormData?.[ field?.field_name ]) {
                    const element = nestedFormData?.[ field?.field_name ][ key ];
                    validationErrObj[ field?.field_name ][ key ] = validateInputHandler(element, field, setValidationError)
                }
                setValidationError(prev => { return { ...prev, ...validationErrObj }})
            }else{
                validationErrObj[ field?.field_name ] = validateInputHandler(nestedFormData?.[ field?.field_name ], field, setValidationError)
            }
        }
        return validationErrObj

    }

    const checkNestedObjectEmpty = (obj) => {
        return Object.keys(obj).length === 0 || Object.values(obj).every(value => (typeof value === 'object')?checkNestedObjectEmpty(value):value === '')
    }

    const addOptionsToDropdown = (config, options, setFieldData, targetField) => {
        config.map((element)=>{
            for (const fieldData of element.data) {
                if(fieldData?.field_name ===targetField){
                    fieldData.field_options = options
                }
            }
        })
        setFieldData([ ...config ])
    }

    return { verifyConnectivityConfig, prepareFormData, error, handleFormValidation, validationLoading, checkNestedObjectEmpty, addOptionsToDropdown }
}

export default useFormStep1