import React from 'react';
import styles from './StepSlider.module.css'; // CSS styles

const StepSlider = ({ activeStep, vertical=false, stepsInfo=[], showStepNumber=true }) => {
    return (
        <div className={`${ styles.stepSliderWrapper } ${ vertical?styles.vertical:'' }`}>
            {showStepNumber && <div className={styles.stepCounterWrapper}>
                Step { activeStep+1 }/ {stepsInfo.length}
            </div>}
            <div className={styles.stepper}>
                {stepsInfo.map((stepInfo, index)=>
                    <div key={ stepInfo?.label }
                        className={ `${ (activeStep === index)?styles.active:'' } ${ styles.step } ${ (activeStep > index)?styles.completed:'' }` }
                    >
                        <div className={styles.circle} ></div>
                        <p>{stepInfo?.label}</p>
                        <div className={styles.line}>
                            <div className={styles[ 'line-colored' ]}></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StepSlider;
