// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family:'OutFit';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-white);
}

code {
  font-family: 'OutFit';
}

p {
  margin: 0;
}

.sidebarWrapper {
  width: 15%;
}
.appMainWrapper {
  flex: 1 1;
  margin: 2rem 0 0 2rem;

}
.filterWrapper {
  display: flex;
  align-items: center;
  gap: 10px;

}
.clearButton{
   align-items: center;
    border: none;
    font-size: 12px;
    font-weight: 400;
    gap: 10px;
    outline: none;
    text-transform: capitalize;
    text-decoration: none;
    color: #8B909A;
    margin: 0px 5px;
    cursor: pointer;
}
.clearButton:hover{
  color:var(--color-black)
}
.filterIconWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.appContainer {
  display: flex;
  margin: 0rem 2.5rem 2rem 2.5rem;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;AACxB;AACA;EACE,oBAAoB;EACpB,mCAAmC;EACnC,kCAAkC;EAClC,oCAAoC;AACtC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,UAAU;AACZ;AACA;EACE,SAAO;EACP,qBAAqB;;AAEvB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;;AAEX;AACA;GACG,mBAAmB;IAClB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,aAAa;IACb,0BAA0B;IAC1B,qBAAqB;IACrB,cAAc;IACd,eAAe;IACf,eAAe;AACnB;AACA;EACE;AACF;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,+BAA+B;AACjC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');\n*{\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n}\nbody {\n  font-family:'OutFit';\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: var(--color-white);\n}\n\ncode {\n  font-family: 'OutFit';\n}\n\np {\n  margin: 0;\n}\n\n.sidebarWrapper {\n  width: 15%;\n}\n.appMainWrapper {\n  flex: 1;\n  margin: 2rem 0 0 2rem;\n\n}\n.filterWrapper {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n\n}\n.clearButton{\n   align-items: center;\n    border: none;\n    font-size: 12px;\n    font-weight: 400;\n    gap: 10px;\n    outline: none;\n    text-transform: capitalize;\n    text-decoration: none;\n    color: #8B909A;\n    margin: 0px 5px;\n    cursor: pointer;\n}\n.clearButton:hover{\n  color:var(--color-black)\n}\n.filterIconWrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n}\n.appContainer {\n  display: flex;\n  margin: 0rem 2.5rem 2rem 2.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
