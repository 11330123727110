import React, { useMemo } from 'react';
import styles from './writerConnect.module.css'
import ConfigMapperFieldV2 from '../../../views/ConfigMapperV2/layout/ConfigMapperFieldV2.jsx'
import useConfigMapperField from '../../../views/configMapper/functionality/useConfigMapperFeild.func';
import useFormStep1 from '../../../components/formStep/functionality/useFormStep1.func';
import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func';
import configData from '../../../dummyData/ConfigWriter.json'
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import AppLoader from '../../../appLoader';

function WriterConnect() {
    const { state } = useLocation();
    const params = state.parameter;
    const { callApi } = useCustomAxiosCall();
    const navigate = useNavigate();
    const { convertToNested, flattenJSON } = useConfigMapperField();
    const { updateCustomFieldConfigJson } = useConfigMapperFieldV2();
    const { prepareFormData, handleFormValidation, validationLoading, checkNestedObjectEmpty } = useFormStep1();
    const handleNext = (data) => {
        navigate('/config-writer', {
            state: {
                ...data, id: params.id, data: params.data, sourceData: params.sourceData
            }
        });
    }
    const verifyConnectivityConfig = async (formSubmitData, setValidationError) => {
        const response = await callApi({
            uriEndPoint: {
                url: '/validate-connectivity',
                method: 'POST',
                service: 'config-mapper'
            },
            body: { data: formSubmitData }
        });
        if (response?.status !== 200 || response?.data?.error) {
            toast.error(response?.data?.error || 'Something Went Wrong', { position: 'top-right' })
            return
        }
        const errObj = {}
        for (const key in response?.data) {
            const element = response?.data[ key ];
            if (element?.error) {
                errObj[ key ] = element?.error
            }
        }
        if (Object.keys(errObj).length > 0) {
            setValidationError(prev => {
                return {
                    ...prev,
                    endpoints: errObj
                }
            })
        } else {
            toast.success('Validation Successful', { position: 'top-right' })
            handleNext(formSubmitData)
        }
        return response
    }
    // React States
    const [ formValues, setFormValues ] = React.useState({ ...flattenJSON({ tribe: 'webshop', system: 'shopify' }) })
    const [ validationError, setValidationError ] = React.useState({})
    const [ fieldData, setFieldData ] = React.useState([ ...JSON.parse(JSON.stringify(configData)) ])

    const inputChangeHandler = (event) => {
        if (event?.target?.name) {
            setFormValues(prevState => {
                let newObj = prevState;
                newObj[ event?.target?.name ] = event?.target?.value
                return newObj
            })
        }

    }

    const formSubmitHandler = (event) => {
        event.preventDefault()
        const formElements = event.target.elements;
        let finalFormData = prepareFormData(formElements, fieldData, formValues)
        let nestedFormData = convertToNested(finalFormData)
        nestedFormData.tribe = params.tribe
        nestedFormData.system = params.system
        let validationErrObj = handleFormValidation(configData, nestedFormData, setValidationError)
        if (checkNestedObjectEmpty(validationErrObj)) {
            verifyConnectivityConfig(nestedFormData, setValidationError)
            return
        }
    }
    const fetchedData = useMemo(() => ({
        endpoints: {
            [ params.endpoints ]: '',
        },
        system: params.system,
        tribe: params.tribe,
        base_url: '',
    }), [ params ]);

    React.useEffect(() => {
        setFormValues({ ...flattenJSON({ ...fetchedData }) })
        setFieldData([ ...JSON.parse(JSON.stringify(configData)) ])
        updateCustomFieldConfigJson(fieldData, setFieldData, fetchedData, { ...flattenJSON({ ...fetchedData }) })
    }, [ fetchedData ])

    return (
        <AppLoader>
            <form className={`${ styles.formWrapper }`} onSubmit={formSubmitHandler} >
                <div className={styles.inputFieldsWrapper}>
                    {fieldData?.map((fieldRow, index) => {
                        return <div key={index} className={`${ styles.row } ${ styles[ `col-${ fieldRow?.columnsCount || 3 }` ] }`}>
                            {fieldRow?.data?.map(inputData =>
                                <ConfigMapperFieldV2
                                    fieldData={inputData}
                                    fieldListData={fieldData}
                                    key={inputData?.field_name}
                                    formData={formValues}
                                    setFieldData={setFieldData}
                                    onInputChange={inputChangeHandler}
                                    validationError={validationError}
                                    setValidationError={setValidationError}
                                />
                            )}
                        </div>
                    })}

                    <div className={styles.formSubmitWrapper}>
                        <input type='submit' className={styles.primary} value={validationLoading ? 'Verifying' : 'Verify'} disabled={validationLoading} />
                    </div>
                </div>
            </form>
        </AppLoader>
    )
}

export default WriterConnect;