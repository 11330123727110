import React, { useEffect, useState } from 'react'
import { flattenNestedData, getColumns } from '../../../commonComponents/table/useTables.func';
import useCustomAxiosCallTwo from '../../../hooks/useCustomAxiosCallTwo';

export const useOrders = (uriEndPoint, orderType) => {
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState([]);
    const [ error, setError ] = useState('');
    const { callApi } = useCustomAxiosCallTwo();
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ totalRecords, setTotalRecords ] = useState(0);
    const [ headerData, setHeaderData ] = useState([]);
    const [ pageSize, setPageSize ] = useState(10);
    let displayTitle, flattenedData = [], columns = [];

    switch (orderType) {
        case 'total': displayTitle='Total Orders'; break;
        case 'fulfillment': displayTitle='Completed Orders'; break;
        case 'pending': displayTitle='Pending Orders'; break;
        case 'partial': displayTitle='Partial Orders'; break;
        case 'shipped': displayTitle='Shipped Orders'; break;
        case 'refundpending': displayTitle='Pending Refund Orders'; break;
        case 'refundclosed': displayTitle ='Refund Closed Orders'; break;

        default: displayTitle='Orders'; break;
    }
    const getTableData = async () => {
        const newUrl = `${ uriEndPoint.url }?type=${ orderType }&page=${ currentPage + 1 }&per_page=${ pageSize }`
        const response = await callApi({
            uriEndPoint: {
                ...uriEndPoint,
                url: newUrl
            }
        });
        if (response && response.status === 200 && !response?.data?.error) {
            setData(response?.data?.data || [])
            setHeaderData(response?.data?.header_data || [])
            setTotalRecords(response?.data?.count || 0)
        } else {
            setError(response?.data?.error || 'Something Went Wrong. Please Try Again Later');
            setLoading(false);
        }
    }

    columns = React.useMemo(() => getColumns(headerData, []), [ headerData ])
    flattenedData = React.useMemo(() => { const flattenedData = flattenNestedData([], data, columns);setLoading(false); return flattenedData }, [ data ])

    useEffect(() => {
        setLoading(true);
        window.scrollTo(0, 0);
        getTableData();
    }, [ currentPage ]);

    return { columns, data: flattenedData, loading, error, currentPage, setPageSize, totalRecords, setCurrentPage, displayTitle }

}