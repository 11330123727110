import React from 'react'
import Table from '../../commonComponents/table/Table.jsx';
import useListItemTable from './ListItemTable.func';
import Typography from '../../commonComponents/typography/Typography.jsx';

function ListItemTable({ urlData, filter = '', tableType ='', mappings={} }) {
    const { data, columns,  currentPage, setPageSize,state, setCurrentPage
    } = useListItemTable(urlData, filter, tableType, mappings );
    return (
        <>
            {!data.loading && <><Typography variant='heading'>{state.title}</Typography>
                <Table
                    loading={state.loading}
                    headerList={columns.length > 1 ? columns : []}
                    rowsData={data}
                    totalRecords={state.totalRecords}
                    setPageSize={setPageSize}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </>
            }
        </>

    )
}

export default ListItemTable