import React, { useEffect, useRef, useState } from 'react';
import styles from './businessLogic.module.css';

import PopupWrapper from '../../popupWrapper/PopupWrapper.jsx';
import Input from '../../input/Input.jsx';
import Typography from '../../typography/Typography.jsx';
import Button from '../../button/Button.jsx';
import DropDownTwo from '../../dropdownTwo/DropdownTwo.jsx';
import { MenuItem, Select } from '@mui/material';
import DateDropDown from '../../dateDropdown/DateDropdown.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import businessLogicData from '../../../dummyData/BusinessLogic.json';
import { useBusinessLogicFunc } from '../functionality/businessLogic.func';
import RDBMSOperation from '../../RDBMSOperation/layout/RDBMSOperation.jsx';
import useCustomLogicFunc from '../functionality/customLogic.func';
import CustomDatePicker from './CustomDatePicker.jsx';

function BusinessLogicPopup({
    isOpen,
    onClose,
    height,
    width,
    handleOperation,
    businessLogic,
    setBusinessLogic,
    workflowData,
    error,
    index,
}) {
    const [ inputValue, setInputValue ] = useState('');

    const {
        response,
        customPreviewResponse,
        queryLoading,
        customQueryLoading,
        isEditing,
        previewResponse,
        customResponse,
        isCustomEditing,
        handleQueryChange,
        handleCustomQueryChange,
        handleSendQuery,
        handleSendCustomQuery,
        handleEditClick,
        handleCustomEditClick,
        handleCopy,
        handleCustomCopy,
        handleResponseChange,
        handleCustomResponseChange,
        handleBlur,
        handleCustomBlur,
        setCustomPreviewResponse,
        setCustomResponse,
        setResponse,
        setPreviewResponse,
        query,
        customQuery,
        setQuery,
        setCustomQuery,
    } = useCustomLogicFunc({ setBusinessLogic, index, inputValue });
    const {
        addLogicToConditions,
        setIsOpenDropdowns,
        handleAddClick,
        handleChange,
        handleRemoveClick,
        handleClose,
        initialState,
    } = useBusinessLogicFunc({
        setBusinessLogic,
        businessLogic,
        onClose,
        setCustomPreviewResponse,
        setCustomResponse,
        setResponse,
        setPreviewResponse,
        setQuery,
        setCustomQuery,
        setInputValue,
    });
    const {
        operations,
        logics,
        businessLogics,
        BusinessFunctions,
        BusinessLogicKeys,
    } = businessLogicData;
    const dropdownRefs = useRef({});
    const businessLogicRef = useRef(null);
    const [ showMenu, setShowMenu ] = useState(false);
    const [ isFilter, setIsFilter ] = useState({
        start_date: 'dd/mm/yyyy',
        end_date: 'dd/mm/yyyy',
    });
    function separateBusinessLogicAndOperation(data) {
        const { business_logic, operation } = data;
        return {
            business_logic,
            operation,
        };
    }
    useEffect(() => {
        const handleScroll = () => {
            setIsOpenDropdowns({}); // Close all dropdowns when scrolling
        };

        const businessLogicElement = businessLogicRef.current;
        if (businessLogicElement) {
            businessLogicElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (businessLogicElement) {
                businessLogicElement.removeEventListener(
                    'scroll',
                    handleScroll
                );
            }
        };
    }, []);
    useEffect(() => {
        if (
            workflowData &&
           workflowData?.flows?.length > 0 &&
           workflowData?.flows[ index ] &&
           isOpen === true
        ) {
            const dataaa = separateBusinessLogicAndOperation(
                workflowData?.flows[ index ]
            );
            const fixedData = {
                business_logic:
                   dataaa?.business_logic &&
                   dataaa?.business_logic?.conditions?.length > 0
                       ? addLogicToConditions(dataaa?.business_logic)
                       : initialState?.business_logic,
                operation: dataaa?.operation || [],
            };

            setBusinessLogic(fixedData);
        }
        else{
            const dataaa = separateBusinessLogicAndOperation(
                workflowData?.flows[ index ]
            );

            const fixedData = {
                business_logic: initialState?.business_logic,
                operation: dataaa?.operation || [],
            };

            setBusinessLogic(fixedData);
        }
    }, [ isOpen, index, workflowData ]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.values(dropdownRefs.current).every(
                (ref) => ref && !ref.contains(event.target)
            );
            if (isOutside) {
                setIsOpenDropdowns({});
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSave = (isMapping) => {
        handleOperation(isMapping);
    };
    const separateWords = (text) => {
        return text.replace(/([a-z])([A-Z])/g, '$1 $2');
    };

    // eslint-disable-next-line react/display-name
    // const CustomDateInput = ({ value, onClick, placeholder }) => {
    //     return (
    //         <div className={styles.datepickerContainer} onClick={onClick}>
    //             <input
    //                 type="text"
    //                 value={value}
    //                 placeholder={placeholder}
    //                 readOnly
    //                 className={styles.datepicker}
    //             />
    //             <LucideIcon name="CalendarDays" className={styles.icon} />
    //         </div>
    //     );
    // };
    const handleDate = (data, index, item) => {
        handleChange('value', data, index, item.method);
    };
    const handleDateOperation = (index, item) => {
        return (item.operator === 'between' ? (
            <DateDropDown
                options={[]}
                className={styles.select}
                onDropDownChange={() => {}}
                setIsFilter={setIsFilter}
                defaultOption={
                    isFilter?.start_date && isFilter?.end_date
                        ? `${ isFilter?.start_date } - ${ isFilter?.end_date }`
                        : 'dd/mm/yyyy - dd/mm/yyyy'
                }
                handleDate={(data) => handleDate(data, index, item)}
                dropdownRefs={dropdownRefs}
                businessLogicRef={businessLogicRef}
            />
        ) : (
            <>
                <CustomDatePicker
                    value={item.value||new Date()}
                    handleChange={(date) =>
                        handleChange('value', date, index, item.method)
                    }
                />
            </>

        ) );
    };
    if (!isOpen) return null;
    const overlayStyles = {};
    height ? (overlayStyles.height = height) : null;
    width ? (overlayStyles.width = width) : null;

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    return (
        <PopupWrapper
            isOpen={isOpen}
            onClose={handleClose}
            height={height}
            width={width}
        >
            <div className={styles.businessLogicWrapper}>
                <div className={styles.checkboxOuter}>
                    <div className={styles.title}>
                        <Typography variant="title">Operation</Typography>
                        <Button
                            className={styles.saveAndCloseButton}
                            onClick={() => handleSave(true)}
                        >
                            Mapping
                        </Button>
                    </div>
                    <div className={styles.checkboxContainer}>
                        {operations?.map((item) => (
                            <div
                                key={item?.name}
                                className={styles.checkboxWrapper}
                            >
                                <Input
                                    type="checkbox"
                                    checked={businessLogic?.operation?.includes(
                                        item?.value
                                    )}
                                    className={styles.checkbox}
                                    onChange={() =>
                                        handleChange('operation', item?.value)
                                    }
                                />
                                <span className={styles.checkboxName}>
                                    {item?.name}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div
                        className={`${ styles.errorMessage } ${
                            error.operation && styles.showErrorMessage
                        }`}
                    >
                        {error.operation || 'empty'}
                    </div>
                </div>
                <div className={styles.businessLogicWrapper}>
                    <Typography variant="title">Business Logic</Typography>
                    <div
                        className={styles.businessLogic}
                        ref={businessLogicRef}
                    >
                        {businessLogic?.business_logic?.conditions?.map(
                            (item, index) => (
                                <div key={index} className={styles.wrapper}>
                                    {index !== 0 && (
                                        <div className={styles.andOrSelect}>
                                            <Select
                                                labelId="demo-select-small-label"
                                                className={styles.select}
                                                id="demo-select-small"
                                                name="logic"
                                                displayEmpty
                                                onChange={(e) => {
                                                    handleChange(
                                                        'logic',
                                                        e.target.value,
                                                        index
                                                    );
                                                }}
                                                renderValue={
                                                    item?.logic
                                                        ? () =>
                                                            item?.logic
                                                                ?.split('_')
                                                                ?.join(' ')
                                                        : () => (
                                                            <div
                                                                className={
                                                                    styles.conditionPlaceholder
                                                                }
                                                            >
                                                                {
                                                                    logics[ 0 ]
                                                                        .value
                                                                }
                                                            </div>
                                                        )
                                                }
                                                MenuProps={{
                                                    styles: {
                                                        paper: styles.paper,
                                                    },
                                                }}
                                                inputProps={{
                                                    styles: {
                                                        icon: styles.icon,
                                                    },
                                                }}
                                            >
                                                {logics.map((ele) => (
                                                    <MenuItem
                                                        key={ele.name}
                                                        value={ele.name}
                                                    >
                                                        {ele.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    )}
                                    <div className={styles.methodWrapper}>
                                        <div className={styles.functionWrapper}>
                                            <Typography variant="sub-title">
                                                Function
                                            </Typography>
                                            <Select
                                                labelId="demo-select-small-label"
                                                className={styles.select}
                                                id="demo-select-small"
                                                name="logic"
                                                displayEmpty
                                                onChange={(e) => {
                                                    handleChange(
                                                        'method',
                                                        e.target.value,
                                                        index
                                                    );
                                                }}
                                                renderValue={() =>
                                                    item?.method ? (
                                                        separateWords(
                                                            item.method
                                                        )
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles.conditionPlaceholder
                                                            }
                                                        >
                                                            {
                                                                BusinessFunctions[ 0 ]
                                                                    .value
                                                            }
                                                        </div>
                                                    )
                                                }
                                                MenuProps={{
                                                    styles: {
                                                        paper: styles.paper,
                                                    },
                                                }}
                                                inputProps={{
                                                    styles: {
                                                        icon: styles.icon,
                                                    },
                                                }}
                                            >
                                                {BusinessFunctions.map(
                                                    (ele) => (
                                                        <MenuItem
                                                            key={ele.name}
                                                            value={ele.value}
                                                        >
                                                            {ele.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                            {item.method ===
                                                'CustomOperations' && (
                                                <div
                                                    className={
                                                        styles.classInput
                                                    }
                                                >
                                                    <Typography variant="sub-title">
                                                        Class Name
                                                    </Typography>
                                                    <input
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        value={
                                                            item?.className
                                                                ? item?.className
                                                                : inputValue
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className={styles.removeAddButton}>
                                            <Button
                                                className={
                                                    styles.addConditionButton
                                                }
                                                onClick={() =>
                                                    handleRemoveClick(index)
                                                }
                                            >
                                                Remove
                                            </Button>
                                            {index === businessLogic?.business_logic ?.conditions?.length - 1 && (
                                                <Button
                                                    className={
                                                        styles.addConditionButton
                                                    }
                                                    onClick={() =>
                                                        handleAddClick(1)
                                                    }
                                                >
                                                    Add
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={styles.bussinessLogicContent}
                                    >
                                        {item.method !== 'RDBMSOperations' && item.method !== 'CustomOperations' && (
                                            <div
                                                className={
                                                    styles.logicWrapper
                                                }
                                            >
                                                <Select
                                                    labelId="demo-select-small-label"
                                                    className={
                                                        styles.operationSelect
                                                    }
                                                    id="demo-select-small"
                                                    value={item.key || ''}
                                                    name="key"
                                                    displayEmpty
                                                    onChange={(e) => {
                                                        // Find the selected option
                                                        const selectedOption =
                                                                BusinessLogicKeys[ 0 ]?.fields[
                                                                    item?.method
                                                                ]?.find(
                                                                    (op) =>
                                                                        op.value ===
                                                                        e.target
                                                                            .value
                                                                );

                                                        // Pass the value and model_name to handleChange
                                                        handleChange(
                                                            'key',
                                                            e.target.value,
                                                            index,
                                                            selectedOption?.model_name
                                                        );
                                                    }}
                                                    renderValue={() => {
                                                        if (item.key) {
                                                            const operations =
                                                                    BusinessLogicKeys[ 0 ]
                                                                        ?.fields[
                                                                            item
                                                                                ?.method
                                                                        ] || [];
                                                            const selectedOption =
                                                                    operations.find(
                                                                        (op) =>
                                                                            op.value ===
                                                                            item.key
                                                                    );
                                                            return selectedOption
                                                                ? selectedOption.name
                                                                : 'Select an option';
                                                        }
                                                        return (
                                                            <div
                                                                className={
                                                                    styles.conditionPlaceholder
                                                                }
                                                            >
                                                                {BusinessLogicKeys[ 0 ]
                                                                    ?.fields[
                                                                        item
                                                                            ?.method
                                                                    ]?.[ 0 ]
                                                                    ?.name ||
                                                                        'Select an option'}
                                                            </div>
                                                        );
                                                    }}
                                                    MenuProps={{
                                                        styles: {
                                                            paper: styles.paper,
                                                        },
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 200,
                                                                overflowY:
                                                                        'auto',
                                                            },
                                                        },
                                                    }}
                                                    inputProps={{
                                                        styles: {
                                                            icon: styles.icon,
                                                        },
                                                    }}
                                                >
                                                    {BusinessLogicKeys[ 0 ]?.fields[
                                                        item?.method
                                                    ]?.map((ele) => (
                                                        <MenuItem
                                                            key={ele.value}
                                                            value={
                                                                ele.value
                                                            }
                                                        >
                                                            {ele.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>

                                                {businessLogics.map(
                                                    (options) =>
                                                        options.operation ===  item?.method && (
                                                            <Select
                                                                key={
                                                                    options.label
                                                                }
                                                                labelId="demo-select-small-label"
                                                                className={
                                                                    styles.operationSelect
                                                                }
                                                                id="demo-select-small"
                                                                name="logic"
                                                                displayEmpty
                                                                value={
                                                                    item?.operator ||
                                                                        ''
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) =>
                                                                    handleChange(
                                                                        'operator',
                                                                        e
                                                                            .target
                                                                            .value,
                                                                        index
                                                                    )
                                                                }
                                                                renderValue={() =>
                                                                    item.operator ? (
                                                                        item.operator.replace(
                                                                            /_/g,
                                                                            ' '
                                                                        )
                                                                    ) : (
                                                                        <div
                                                                            className={
                                                                                styles.conditionPlaceholder
                                                                            }
                                                                        >
                                                                            {' '}
                                                                            Logic
                                                                        </div>
                                                                    )
                                                                }
                                                                MenuProps={{
                                                                    PaperProps:
                                                                            {
                                                                                className:
                                                                                    styles.paper,
                                                                            },
                                                                }}
                                                                inputProps={{
                                                                    className:
                                                                            styles.icon,
                                                                }}
                                                            >
                                                                {options.subOptions.map(
                                                                    (
                                                                        ele
                                                                    ) => (
                                                                        <MenuItem
                                                                            key={
                                                                                ele.name
                                                                            }
                                                                            value={
                                                                                ele.value
                                                                            }
                                                                        >
                                                                            {
                                                                                ele.name
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </Select>
                                                        )
                                                )}
                                                <div
                                                    className={
                                                        styles.outputHeading
                                                    }
                                                >
                                                    {item.method ===
                                                        'DateOperations' ? (
                                                            handleDateOperation(
                                                                index,
                                                                item
                                                            )
                                                        ) : (
                                                            <Input
                                                                type="text"
                                                                placeholder="value"
                                                                value={
                                                                    item.value
                                                                }
                                                                name="name"
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        'value',
                                                                        e
                                                                            ?.target
                                                                            ?.value,
                                                                        index,
                                                                        item.method
                                                                    )
                                                                }
                                                                className={
                                                                    styles.valueSelect
                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                        {item.method === 'RDBMSOperations' && (
                                            <RDBMSOperation
                                                method={item.method}
                                                handleChange={handleChange}
                                                btnTitle="Get Query"
                                                title="Aggregation"
                                                secondTitle="Preview"
                                                response={response}
                                                handleSendQuery={
                                                    handleSendQuery
                                                }
                                                handleCopy={handleCopy}
                                                handleQueryChange={
                                                    handleQueryChange
                                                }
                                                handleEditClick={
                                                    handleEditClick
                                                }
                                                handleResponseChange={
                                                    handleResponseChange
                                                }
                                                handleBlur={handleBlur}
                                                queryLoading={queryLoading}
                                                isEditing={isEditing}
                                                previewResponse={
                                                    previewResponse
                                                }
                                                query={query}
                                                item={item?.aggregation ? item?.codePrompt : ''}
                                                codeQuery={item?.aggregation}
                                            />
                                        )}
                                        {item.method === 'CustomOperations' && (
                                            <RDBMSOperation
                                                method={item.method}
                                                handleChange={handleChange}
                                                btnTitle="Execute"
                                                title="Code Snippet"
                                                secondTitle="Preview"
                                                handleQueryChange={
                                                    handleCustomQueryChange
                                                }
                                                handleSendQuery={
                                                    handleSendCustomQuery
                                                }
                                                handleEditClick={
                                                    handleCustomEditClick
                                                }
                                                handleCopy={handleCustomCopy}
                                                handleResponseChange={
                                                    handleCustomResponseChange
                                                }
                                                handleBlur={handleCustomBlur}
                                                queryLoading={
                                                    customQueryLoading
                                                }
                                                isEditing={isCustomEditing}
                                                previewResponse={
                                                    customPreviewResponse
                                                }
                                                response={customResponse}
                                                query={customQuery}
                                                item={item?.codeSnippet ? item?.codePrompt : ''}
                                                codeQuery={item?.codeSnippet}
                                            />
                                        )}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.buttonWrapper}>
                <Button
                    className={styles.saveAndCloseButton}
                    onClick={() => handleSave()}
                >
                    Save
                </Button>
                <Button className={styles.button} onClick={handleClose}>
                    Close
                </Button>
            </div>
            <DropDownTwo
                showMenu={showMenu}
                onClose={() => setShowMenu(false)}
                dropDownOption={operations}
            />
        </PopupWrapper>
    );
}
export default BusinessLogicPopup;
