import React, { useState } from 'react';
import AppLoader from '../../../appLoader';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import styles from './notificationsGrid.module.css'
import DropDown from '../../../commonComponents/dropDown/DropDown.jsx';
import {  IconButton, InputBase, Paper } from '@mui/material';
import { ListFilterIcon } from 'lucide-react';
import ShipmentNoticeTable from '../../../components/shipmentNoticeTable/ShipmentNoticeTable.jsx';
import data from '../../../dummyData/Alerts.json';
import useNotificationsGrid from '../functionality/notifications.func';
import PopupWrapper from '../../../commonComponents/popupWrapper/PopupWrapper.jsx'
import NotificationDetailPopup from './NotificationDetailPopup.jsx';

const NotificationsGrid = () => {
    const [ rowSelectionModel, setRowSelectionModel ] = useState();
    const [ open, setOpen ]=useState(false)
    const [ modalData, setmodalData ] = useState({})
    const onClose = () => {
        setOpen(false)
    }
    const { getRowClassName } = useNotificationsGrid(styles);
    const rowClickHandler = (params) => {
        setmodalData(params)
        setOpen(true)
    }
    return <AppLoader>
        <div className={styles.notificationsContainer}>
            <Typography variant='gradient'>Notifications Overview</Typography>
            <div className={styles.filterWrapper}>
                <div className={styles.buttonGroup}>
                    <button className={styles.button}>All</button>
                    <button className={styles.button}>Unread</button>
                </div>
                <div className={styles.filter}>
                    <Paper
                        component="form"
                        sx={{ display: 'flex', alignItems: 'center', width: '100%', backgroundColor:'#F6F8FA',border:'0.93px solid #D0D7DE', boxShadow:'box-shadow: 0px 0.93px 0px 0px #D0D7DE33 inset' }}
                    >
                        <IconButton sx={{ p: '10px' }} aria-label="menu">
                            <ListFilterIcon size={20}/>
                        </IconButton>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Filter Notifications"
                            inputProps={{ 'aria-label': 'Filter Notifications' }}
                        />
                    </Paper>
                </div>
                <div className={styles.group}>
                    <DropDown customsSyles={{ borderRadius: '6px', border: 'none', background: 'none' }} defaultOption={'Group By: Date'} options={[ { name:'Date', value:'date' }, { name:'Service', value:'service' } ]}/>
                </div>
            </div>
            <div className={styles.gridWrapper}>
                <ShipmentNoticeTable
                    headerList={[ 'id','title', 'description', 'service_name', 'read' ]}
                    rowsData={ data.data }
                    totalRows={data.data.length}
                    props={
                        {
                            checkboxSelection:true,
                            onRowSelectionModelChange:(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            },
                            rowSelectionModel:rowSelectionModel,
                            getRowClassName:getRowClassName,
                            onRowClick:(params)=>{rowClickHandler(params)}
                        }
                    }
                    hiddenColumns={[ 'read' ]}
                />
            </div>
        </div>
        <PopupWrapper isOpen={open} onClose={onClose} height="" width="">
            <NotificationDetailPopup data={modalData}/>
        </PopupWrapper>
    </AppLoader>
}

export default NotificationsGrid;