import React from 'react';
import {
    getBezierPath,
    EdgeLabelRenderer,
    BaseEdge,
} from '@xyflow/react';
import Tooltip from '@mui/material/Tooltip';

const CustomEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    onClick,
    edges,
}) => {
    const [ edgePath, labelX, labelY ] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const handleClick = (event) => {
        event.stopPropagation();
        onClick(event, id);
    };

    function getOperationById(id) {
        let operation
        if(edges?.length>0){
            const edge = edges?.length && edges?.find(edge => edge.id === id);
            if(edge?.operation?.length>0){
                const transformedActions = edge.operation.map(action =>
                    action
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, char => char?.toUpperCase())
                );
                operation = transformedActions?.join(',\n');
            }
        }
        return operation
    }
    return (
        <>
            <BaseEdge id={id} path={edgePath} />
            <EdgeLabelRenderer>
                <Tooltip title={getOperationById(id)}>
                    <div
                        style={{
                            position: 'absolute',
                            transform: `translate(-50%, -50%) translate(${ labelX }px,${ labelY }px)`,
                            border: '1px solid black',
                            background: 'white',
                            padding: 10,
                            borderRadius: 5,
                            fontSize: 15,
                            fontWeight: 400,
                            pointerEvents: 'all',
                            cursor: 'pointer',
                            whiteSpace: 'pre-wrap'
                        }}
                        title={getOperationById(id)}
                        onClick={handleClick}
                        className="nodrag nopan"
                    >
                        {getOperationById(id) ? getOperationById(id): data?.label}
                    </div>
                </Tooltip>
            </EdgeLabelRenderer>
        </>
    );
};

export default CustomEdge;