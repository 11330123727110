import React from 'react';
import styles from './rdbmsOperation.module.css';
import DropDown from '../../dropDown/DropDown.jsx';
import Button from '../../button/Button.jsx';
import assets from '../../../assets/index';
import DynamicJsonTable from '../../table/DynamicJsonTable.jsx'; // adjust the path as needed

function RDBMSOperation({
    method = '',
    btnTitle = '',
    title = '',
    secondTitle = '',
    response,
    queryLoading,
    isEditing,
    previewResponse,
    handleQueryChange,
    handleSendQuery,
    handleEditClick,
    handleCopy,
    handleResponseChange,
    handleBlur,
    query,
    item,
    codeQuery,
}) {
    const { editIcon, copyIcon } = assets;
    const isActiveQuery = true;

    // Parse previewResponse if needed.
    let parsedResponse;
    try {
        parsedResponse =
            typeof previewResponse === 'string' && method === 'RDBMSOperations'
                ? JSON.parse(previewResponse)
                : previewResponse;
    } catch (error) {
        parsedResponse = null;
    }
    return (
        <div style={{ width: '100%' }}>
            {isActiveQuery ? (
                <div style={{ width: '100%' }}>
                    {/* Top Row: User Prompt and Aggregation side by side */}
                    <div className={styles.row}>
                        <div className={styles.userPromptSection}>
                            <h2>User Prompt</h2>
                            <textarea
                                className={styles.textarea}
                                style={{ width: '100%', padding: '10px' }}
                                cols="200"
                                rows="3"
                                onChange={handleQueryChange}
                                placeholder="Query"
                                value={item ? item : query}
                            />
                            <div className={styles.button}>
                                <Button
                                    className={styles.saveAndCloseButton}
                                    onClick={() => handleSendQuery(item)}
                                >
                                    {queryLoading ? '...Loading' : btnTitle}
                                </Button>
                            </div>
                        </div>
                        <div className={styles.aggregationSection}>
                            <div className={styles.icon}>
                                <h2>{title}</h2>
                                <div>
                                    <img
                                        src={editIcon}
                                        onClick={handleEditClick}
                                        alt="Edit"
                                    />
                                    <img
                                        src={copyIcon}
                                        onClick={handleCopy}
                                        alt="Copy"
                                    />
                                </div>
                            </div>
                            <textarea
                                value={codeQuery ? codeQuery : response}
                                disabled={!isEditing}
                                className={styles.markdownWrapper}
                                onChange={handleResponseChange}
                                onBlur={handleBlur}
                                cols="200"
                                rows="3"
                                style={{ width: '100%', padding: '10px' }}
                            />
                        </div>
                    </div>
                    {method !== 'CustomOperations' ? (
                        <div className={styles.fullWidthPreview}>
                            <h2>{secondTitle}</h2>
                            <div>
                                {parsedResponse ? (
                                    <DynamicJsonTable data={parsedResponse} />
                                ) : (
                                    <div className={styles.spaceDiv}> </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div>
                                <h2>{secondTitle}</h2>
                                <div>
                                    <textarea
                                        value={
                                            previewResponse || 'No data found'
                                        }
                                        className={styles.markdownWrapper}
                                        cols="200"
                                        rows="3"
                                        disabled
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div className={styles.rdbmsWrapper}>
                    <div className={styles.firstColumnRDMS}>
                        <div className={styles.dropdownWrapper}>
                            <DropDown
                                className={styles.select}
                                defaultOption="Order Table"
                            />
                            <DropDown
                                className={styles.select}
                                defaultOption="Customer Table"
                            />
                            <DropDown
                                className={styles.select}
                                defaultOption="Left Inner Join"
                            />
                            <DropDown
                                className={styles.select}
                                defaultOption="Name"
                            />
                            <DropDown
                                className={styles.select}
                                defaultOption="Customer Name"
                            />
                        </div>
                        <div className={styles.buttonWrapper}>
                            <Button className={styles.addConditionButton}>
                                Remove
                            </Button>
                            <Button className={styles.addConditionButton}>
                                Add
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RDBMSOperation;
