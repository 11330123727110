// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listItems_mainWrapper__DTWvn {
    background-color: var(--color-white);
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/listItems/listItems.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".mainWrapper {\n    background-color: var(--color-white);\n    border-radius: 10px;\n    padding: 1rem;\n    width: 100%;\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": `listItems_mainWrapper__DTWvn`
};
export default ___CSS_LOADER_EXPORT___;
