import React, { useState } from 'react'
import AppLoader from '../../appLoader';
import styles from './orderRerouting.module.css'
import StatCounter from '../../components/widgets/statCounter/layout/StatCounter.jsx';
import OrderReroutingData from '../../dummyData/OrderRerouting.json'
import Table from '../../commonComponents/table/Table.jsx';
import useTables from '../../commonComponents/table/useTables.func';
import Typography from '../../commonComponents/typography/Typography.jsx';
import Button from '../../commonComponents/button/Button.jsx';
import LucideIcon from '../../commonComponents/lucideicon/LucideIcon.jsx';
import AddRules from '../../components/addRules/AddRules.jsx';

const OrderRerouting=()=> {
    const { orders, rules }=OrderReroutingData;
    const [ open, setOpen ]=useState(false);

    const onClose=()=>{
        setOpen(false)
    }
    const { data,
        columns } = useTables( {}, orders.header, orders.rowData, {});

    const { data:data1,
        columns: columns1 } = useTables( {}, rules.header, rules.rowData, {});
    console.log(data1, 'datas', columns1)
    const handleClick=()=>{
        setOpen(true);
    }
    const updatedColumns = columns1.map((column) => {
        if (column.field === 'edit') {
            return {
                ...column,
                renderCell: (params) => (
                    <LucideIcon name="FilePenLine" size="18" color="#8B909A" onClick={()=> handleClick(params.row.id)}/>
                )
            };
        }
        if (column.field === 'delete') {
            return {
                ...column,
                renderCell: () => (
                    <LucideIcon name="Trash2" size="18" color="#8B909A" />
                )
            };
        }
        return column;
    });
    console.log(OrderReroutingData.stats, 'status')
    return (
        <AppLoader>
            <div className={styles.mainWrapper}>
                <div className={styles.widgets}>
                    {OrderReroutingData.stats.map((obj, index)=>
                        <StatCounter id={obj.id} key={index}  data={{ stat_name: obj.heading, count: obj.count, icon: obj.icon, percentage: obj.countPercentage, percentageIcon: obj.percentageIcon }} />
                    )}
                </div>
                <div className={styles.tableContainer}>
                    <div className={styles.tableHeader}>
                        <Typography variant='header-700'>{orders.heading}</Typography>
                    </div>
                    <Table
                        headerList={columns}
                        rowsData={data}
                        totalRecords={data.length}
                    />
                </div>
                <div className={styles.buttonWrapper}>
                    <Button className={styles.button} onClick={handleClick}>
                        Add Rules
                    </Button>
                </div>
                <div className={styles.rulesTableWrapper}>
                    <Table
                        headerList={updatedColumns}
                        rowsData={data1}
                        totalRecords={data1.length}
                    />
                </div>
            </div>
            <AddRules isOpen={open} onClose={onClose}/>
        </AppLoader>
    )
}

export default OrderRerouting