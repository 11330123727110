import { useEffect, useState } from 'react'
import useCustomAxiosCallTwo from '../../../hooks/useCustomAxiosCallTwo'

const useConfigurationTwoFunc = (uriEndPoint = {}) => {
    const [ loading, setLoading ] = useState(false)
    const [ data, setData ] = useState()
    const [ error, setError ] = useState()
    const { callApi } = useCustomAxiosCallTwo();
    const getData = async () => {
        setLoading(true)
        const response = await callApi({
            uriEndPoint: {
                ...uriEndPoint,
            }
        });

        if (response && response.status === 200) {
            console.log(response.data)
            setData(response.data)
        } else {
            setError(response.data.error)
        }
        setLoading(false);
    }
    useEffect(() => {
        getData();
        window.scrollTo(0, 0)
    }, []);
    return (
        { data, loading, error }
    )
}

export default useConfigurationTwoFunc;