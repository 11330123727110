import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Notifications from './components/notifications/layout/Notifications.jsx';
import Configuration from './components/platformConfiguration/layout/Configuration.jsx';
import ProductDetails from './components/productDetails/ProductDetails.jsx';
import { selectUserData } from './redux/user/Selector';
import { clearVisitedPage } from './redux/visitor/Slice';
import StoreProvider from './storeProvider/StoreProvider';
import AccountSettings from './views/accountSettings/AccountSettings.jsx';
import ActivePos from './views/activePos/layout/ActivePos.jsx';
import AdminDashbaord from './views/adminDashboard/layout/AdminDashboard.jsx';
import AuthProgress from './views/authProgress/layout/AuthProgress.jsx';
import CustomerSupportChatBot from './views/chatbot/layout/Chatbot.jsx';
import CollectiveData from './views/collectiveData/list/layout/CollectiveData.jsx';
import CollectiveDataDetail from './views/collectiveData/view/layout/CollectiveDataDetail.jsx';
import Connectors from './views/connectors/layout/Connectors.jsx';
import Dashboard from './views/dashBoard/Dashboard';
import InboundShipments from './views/inboundShipments/list/layout/InboundShipments.jsx';
import InboundShipment from './views/inboundShipments/view/layout/InboundShipment.jsx';
import Integrations from './views/integrations/layout/Integration.jsx';
import Inventory from './views/inventory/list/layout/Inventory.jsx';
import InventoryTable from './views/inventoryTable/layout/InventoryTable.jsx';
import Logger from './views/logger/layout/Logger.jsx';
import Login from './views/login/layout/Login';
import MyConnectors from './views/myConnectors/layout/MyConnector.jsx';
import NotificationsGrid from './views/notifications/layout/NotificationsGrid.jsx';
import OrderDashboard from './views/orderDashboard/layout/OrderDashboard.jsx';
import OrderRerouting from './views/orderRerouting/OrderRerouting.jsx';
import Orders from './views/orders/layout/Orders.jsx';
import Organisation from './views/organisations/layout/Organisations.jsx';
import Payments from './views/payments/Payments.jsx';
import ReturnsManagement from './views/returnsManagement/ReturnsManagement.jsx';
import ShipmentNotices from './views/shipNotice/list/layout/Shipnotices.jsx';
import ShipmentNotice from './views/shipNotice/view/layout/Shipnotice.jsx';
import WarehouseOverview from './views/warehouseOverviewDashboard/layout/WarehouseOverviewDashboard.jsx';
import QueueTacker from './views/queueTracker/layout/QueueTracker.jsx';
import ConfigMapperV2 from './views/ConfigMapperV2/layout/ConfigMapperV2.jsx';
import Test from './views/test/Test.jsx';
import Summary from './views/summary/view/layout/Summary.jsx';
import SummaryItemSize from './views/summaryItem/view/layout/SummaryItemSize.jsx';
import WorkFlowMultiple from './views/workFlowMultiple/layout/WorkFlowMultiple.jsx';
import Workflow from './views/workflow/layout/Workflow.jsx';
import ConfigWriter from './components/configWriter/layout/ConfigWriter.jsx';
import WriterConnect from './components/writerConnect/layout/WriterConnect.jsx';

const AppRoutes = ({ setShouldRenderChatbot }) => {
    const access_token = window.localStorage.getItem('access_token');
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const user = useSelector(selectUserData);
    const userData =user?.userData?.data?.person;
    const dispatch=useDispatch();

    useEffect(() => {
        if (pathname === '/' && access_token && userData) {
            navigate('/organisation');
        } else if (!access_token || !userData) {
            navigate('/');
            dispatch(clearVisitedPage([]))
        }
        const excludedPaths = [ '/', '/organisation' ];
        setShouldRenderChatbot(!excludedPaths.includes(pathname));
    }, [ pathname ]);

    return (
        <Routes>
            <Route exact path='/' element={<Login />} />
            <Route exact path='/dashboard/:dashboardId' element={<Dashboard />} />
            <Route exact path='/integration' element={<Integrations />} />
            <Route exact path='/organisation' element={<Organisation />} />
            <Route exact path='/connector' element={<Connectors />} />
            <Route exact path='/admin-dashboard' element={<AdminDashbaord />} />
            <Route exact path='/my-connectors' element={<MyConnectors />} />
            <Route exact path='/inbound-shipments' element={<InboundShipments />} />
            <Route exact path='/inbound-shipment/:shipmentId' element={<InboundShipment />} />
            <Route exact path='/shipment-notices' element={<ShipmentNotices />} />
            <Route exact path='/shipment-notice/:shipNoticeId' element={<ShipmentNotice />} />
            <Route exact path='/summary/:invoiceNo' element={<Summary />} />
            <Route exact path='/summary-size/:invoiceNo/:style' element={<SummaryItemSize />} />
            <Route exact path='/collective-data' element={<CollectiveData />} />
            <Route exact path='/collective-data/:shipmentNumber' element={<CollectiveDataDetail />} />
            <Route path='/callback' element={<AuthProgress />} />
            <Route exact path='/configuration' element={<Configuration />} />
            <Route exact path='/inventory' element={<Inventory />} />
            <Route exact path='/order-re-routing' element={<OrderRerouting />} />
            <Route exact path='/returns-management' element={<ReturnsManagement />} />
            <Route exact path='/warehouse-overview' element={<WarehouseOverview />} />
            <Route exact path='/inventory/:itemId' element={<ProductDetails />} />
            <Route exact path='/logs' element={<Logger />} />
            <Route exact path='/customer-orders' element={<OrderDashboard />} />
            <Route exact path='/inventory-detail/:inventoryType' element={<InventoryTable />} />
            <Route exact path='/orders/:orderType' element={<Orders />} />
            <Route exact path='/active-po' element={<ActivePos />} />
            <Route exact path='/active-po' element={<ActivePos />} />
            <Route exact path='/notifications' element={<NotificationsGrid />} />
            <Route exact path='/payments' element={<Payments />} />
            <Route exact path='/account-settings' element={<AccountSettings />} />
            <Route exact path='/queue-tracker' element={<QueueTacker />} />
            <Route exact path='/map-configurations' element={<ConfigMapperV2 />} />
            <Route exact path='/test' element={<Test />} />
            <Route exact path='/config-writer' element={<ConfigWriter />} />
            <Route exact path='/work-flow' element={<Workflow />} />
            <Route exact path='/work-flow/:id' element={<WorkFlowMultiple />} />
            <Route exact path='/writer-connect/:id' element={<WriterConnect />} />

            {/* not found page */}
            <Route path='*' element={<div>Not Found!! Redirect to <a href='/organisation'>Home</a></div>} />
        </Routes>
    );
}

const App = () => {
    const [ shouldRenderChatbot, setShouldRenderChatbot ] = useState(false);

    return (
        <StoreProvider>
            <Router>
                <Notifications />
                <AppRoutes setShouldRenderChatbot={setShouldRenderChatbot} />
                {shouldRenderChatbot && <CustomerSupportChatBot />}
            </Router>
        </StoreProvider>
    )
};

export default App;