import React from 'react'
import Table from '../../../commonComponents/table/Table.jsx';
import useTables from '../../../commonComponents/table/useTables.func';

function TableDialog({ modalData }) {
    console.log(modalData?.rowData, 'jsdahhjadha')
    const { data,
        columns } = useTables( {}, modalData?.header, modalData?.rowData, {});
    return(
        <Table
            headerList={columns} // Corrected: columns passed here
            rowsData={data} // Corrected: row data passed here
            totalRecords={data?.length || 0} // Ensure a default value of 0 to avoid errors
        />
    )
}

export default TableDialog;