import { Handle } from '@xyflow/react';
import React from 'react';
// import Typography from '../typography/Typography.jsx';
import '@xyflow/react/dist/style.css';
import styles from './node.module.css';
export const SourceNode = ({ data }) => (
    <>
        <div className={styles.card}>
            <div className={styles.cardContent}>
                <div className={styles.iconWrapper}>
                    <img src={data.icon} alt={data.machine_name} className={styles.cardIcon} />
                </div>
                <p>{data.label}</p>
            </div>
            <Handle type="source" position="right" />
        </div>
    </>
);
export const DestinationNode = ({ data }) => (
    <>
        <div className={styles.card}>
            <div className={styles.cardContent}>
                <div className={styles.iconWrapper}>
                    <img src={data.icon} alt={data.machine_name} className={styles.cardIcon} />
                </div>
                <p>{data.label}</p>
            </div>
            <Handle type="target" position="left" id="left" />
            <Handle type="source" position="right" id="right" />
            <Handle type="source" position="bottom" id="bottom" />
            <Handle type="target" position="top" id="top" />
        </div>
    </>
)