import React from 'react';
import { MenuItem, Select } from '@mui/material';
import useConfigMapperField from '../../configMapper/functionality/useConfigMapperFeild.func';
import configData from '../data/fieldDropdownData.json'

import Typography from '../../../commonComponents/typography/Typography.jsx';
import styles from './CustomInputV2.module.css'
import CustomInputArrayV2 from './CustomInputArray/CustomInputArrayV2.jsx';

const CustomInput  = ({
    fieldData={},
    fieldListData=[],
    formData={},
    parentField='',
    validateInputHandler=()=>{},
    parentFieldData={},
    setValidationError=()=>{},
    validationError={}
}) => {
    if(fieldData?.field_type === 'array'){
        return <CustomInputArrayV2 fieldData={fieldData} parentField={parentField}/>
    }
    const [ customInputData, setCustomInputData ] = React.useState({ name:'', value:'' }) 
    const { findFieldById } = useConfigMapperField(fieldData)
    const customInputChangeHandler = (e, id='') => {
        let inputFieldId = ''
        if(id !== ''){
            inputFieldId = id;
        }else{
            inputFieldId =  e.target.id
        }
        if(inputFieldId.includes('#name')){
            const fieldNameValue = e.target?.value
            const id = inputFieldId.split('#')[ 0 ]
            const fieldObj = findFieldById(fieldListData, id)
            fieldObj[ 'field_name_value' ] = fieldNameValue
            setCustomInputData((prevState)=>{return { ...prevState, name:fieldNameValue }})
        }
        else if(inputFieldId.includes('#value')){
            const fieldValue = e.target?.value
            const id = inputFieldId.split('#')[ 0 ]
            const fieldObj = findFieldById(fieldListData, id)
            fieldObj[ 'field_value' ] = fieldValue
            validateInputHandler(e.target.value, parentFieldData, setValidationError )
            setCustomInputData((prevState)=>{return { ...prevState, value:fieldValue } })
        }
    }
    const getCustomInputName = (fieldData, parentField) => {
        if(configData?.[ parentField ]?.length > 0 && !fieldData?.field_options?.length ){
            fieldData.field_options = configData?.[ parentField ]
        }
        switch (fieldData?.name_field_type) {
            case 'dropdown':
                return   <Select
                    key={fieldData?.field_id}
                    value={fieldData?.field_name_value || fieldData?.field_options?.[ 0 ]?.value}
                    name={`${ parentField }#input_name[]`}
                    onChange={(e)=>{customInputChangeHandler(e, fieldData?.field_id + '#name')}}
                >
                    {fieldData?.field_options?.map((field_option) => (
                        <MenuItem 
                            value={field_option?.value|| customInputData?.name} 
                            key={field_option?.value}
                        >
                            {field_option?.title}
                        </MenuItem>
                    ))}
                </Select>
                
            default:
                return <input 
                    type='text' 
                    name={`${ parentField }#input_name[]`} 
                    placeholder={'Type Here...'} 
                    value={fieldData?.field_name_value || customInputData?.name || formData[ fieldData?.field_name ]} 
                    key={fieldData?.field_id} 
                    id={fieldData?.field_id + '#name'}
                    onChange={(e)=>{customInputChangeHandler(e)}}
                />
        }
    }
    const getCustomInputValue = (fieldData, parentField) => {
        return <input 
            type='text' 
            name={`${ parentField }#input_value[]` } 
            placeholder='Type Here...' 
            onChange={(e)=>{customInputChangeHandler(e)}}
            value={fieldData?.field_value || customInputData?.value} 
            id={fieldData?.field_id + '#value'}
        />
    }
    const getCustomInput=(fieldData, parentField)=>{
        return <div className={styles.customInput}>
            {getCustomInputName(fieldData, parentField)}
            {getCustomInputValue(fieldData, parentField)}
        </div>
    }
    return <div className={styles.customInputContainer}>
        {getCustomInput(fieldData, parentField)}
        {validationError?.[ parentField ]?.[ fieldData?.field_name_value || fieldData?.field_options?.[ 0 ]?.value ] !== '' && <Typography variant='error'>{validationError?.[ parentField ]?.[ fieldData?.field_name_value || fieldData?.field_options?.[ 0 ]?.value ]}</Typography>}
    </div>
}

export default CustomInput