import { toast } from 'react-toastify';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';
import React from 'react';
import useConfigMapperFieldV2 from '../../../views/ConfigMapperV2/functionality/useConfigMapperFeildV2.func';
import fieldMappingsData from '../../../views/ConfigMapperV2/data/fieldsMappings.json'
import useConfigMapperField from '../../../views/configMapper/functionality/useConfigMapperFeild.func';
import { useDispatch } from 'react-redux';
import { setServicesFlattenedData } from '../../../redux/mappingConfiguration/Slice';
const useFormStep2 = () => {
    const dispatch = useDispatch()
    const { callApi } = useCustomAxiosCall();
    // const [ error, setError ] = React.useState({})
    const [ fieldsPreviewData, setFieldsPreviewData ] = React.useState()
    const [ validationLoading, setValidationLoading ] = React.useState(false)
    const [ suggestionData, setSuggestionData ] = React.useState(null)
    const [ loading, setLoading ] = React.useState(false)
    const { updateCustomFieldConfigJson } =
    useConfigMapperFieldV2(() => {});
    const { convertToNested, flattenJSON } = useConfigMapperField();

    const verifyFieldMappings = async (formSubmitData, setOpen, activeService) => {
        setValidationLoading(true)
        const response = await callApi({
            uriEndPoint: {
                url: '/fields-preview',
                method: 'POST',
                service:'config-mapper'
            },
            body: { data: { ...formSubmitData, service: activeService } }
        });
        if (response?.status !== 200 || response?.data?.error || response?.data?.msg){
            toast.error(response?.error || response?.data?.msg || 'Could not fetch data', { position:'top-right' })
            setValidationLoading(false)
            return
        }
        setFieldsPreviewData(response?.data)
        setValidationLoading(false)
        setOpen(true)
        // Open modal
        return response
    }

    const getSuggestionsField = async (list, systemKeys) => {
        try {
            setLoading(true)
            const response = await callApi({
                uriEndPoint: {
                    url: '/get-suggestions',
                    method: 'POST',
                    service: 'get-suggestion'
                },
                body: {
                    service: list,
                    system_keys: systemKeys
                }
            });
            if(response?.status === 200) {
                const responseData = response?.data;
                setSuggestionData(responseData)
                setLoading(false)
            }

        } catch (error) {
            console.error('error', error);
            setLoading(false)
        }
        setLoading(false)

    };
    const prepareFormData = (formElements, fieldData, formValues) => {
        let formSubmitData = {}

        for (const element of formElements) {
            if(element.name.includes('[]')){
                if(formSubmitData[ element.name ]){
                    formSubmitData[ element.name ].push(element.value)
                }else{
                    formSubmitData[ element.name ] = [ element.value ]
                }
            }else{
                formSubmitData[ element.name ] = element.value
            }
        }
        for(let fieldGroup of fieldData){
            for (const field of fieldGroup.data) {
                if(field?.field_type === 'radio'){
                    formSubmitData[ field?.field_name ] = (typeof formValues[ field?.field_name ] === 'boolean')?formValues[ field?.field_name ]:(formValues[ field?.field_name ] === 'true')
                }
            }
        }
        const finalFormData = {}
        for(let key in formSubmitData){
            if(key !== ''){
                if(key.includes('input_name[]')){
                    const inputKey = key?.split('#')?.slice(0, -1)?.join('#')
                    const valueKey = inputKey + '#input_value[]'
                    formSubmitData[ key ].forEach((val, i)=>{
                        if(val !== ''){
                            finalFormData[ inputKey+'#'+val ] = formSubmitData[ valueKey ][ i ]
                        }
                    })
                }else if(!key.includes('input_value[]')){
                    finalFormData[ key ] = formSubmitData[ key ]
                }
            }
            if(key.includes('__array')){
                let oldKey = key
                if(!key.includes('data_accessor')){
                    key = key.replace('__array', '#fields')
                }else{
                    key = key.replace('__array', '')
                }
                finalFormData[ key ] = formSubmitData[ oldKey ]
                delete finalFormData[ oldKey ]
            }
        }
        return finalFormData
    }

    const checkNestedObjectEmpty = (obj) => {
        return Object.keys(obj).length === 0 || Object.values(obj).every(value => (typeof value === 'object')?checkNestedObjectEmpty(value):value === '')
    }

    const addCollectionFieldsToJSONLayout = (fetchedDataShown, setFetchedDataShown, fieldLayout, fetchedData, suggestionData, configurationStateData, activeService, fieldInputData, setFieldData, setFormValues, configFormData, nestedMappingConfigurationStateData) => {
        let serviceValue = activeService.value.replace('Service', '')
        let flattenedData = {}, nestedUpdatedData = {}
        if (!fetchedDataShown?.serviceValue && Object.keys(fetchedData).length > 0) {
            flattenedData = flattenJSON({
                ...fetchedData?.services?.[
                    serviceValue
                ],
            });
            nestedUpdatedData = {
                ...fetchedData?.services?.[
                    serviceValue
                ],
            }
            setFetchedDataShown(prev => ({ ...prev, [ serviceValue ]: true }));
            console.log('#fetchedDatafromdb', nestedUpdatedData, flattenedData, fetchedDataShown)
        } else {
            flattenedData = configurationStateData?.servicesData?.[ serviceValue ] || {}
            nestedUpdatedData = convertToNested(flattenedData)
        }

        dispatch(setServicesFlattenedData({ service: serviceValue, serviceData: flattenedData }))
        console.log(Object.keys(fetchedData), '#readDatafrompayload', flattenedData, fetchedData)
        console.log(nestedUpdatedData, '#nestedUpdatedData', flattenedData)
        setFormValues((prev) => ({
            ...prev,
            ...flattenedData,
            endpoints:
                nestedMappingConfigurationStateData?.endpoints?.[
                    serviceValue
                ],
        }));

        const updatedFieldLayout = [
            ...JSON.parse(JSON.stringify(fieldLayout)),
        ];
        console.log(updatedFieldLayout, 'updatedFieldLayout')
        setFieldData(updatedFieldLayout);
        updateCustomFieldConfigJson(
            updatedFieldLayout,
            setFieldData,
            nestedUpdatedData,
            flattenedData
        );
    }

    const createCollectionPayload = (servicesData={}, activeService={}) => {
        console.log(servicesData, '#readcallimplementationforpayload')
        console.log(activeService, '#activeService')
        const transformedData = Object.entries(servicesData?.[ activeService?.key ] || {}).length > 0
            ? Object.entries(servicesData?.[ activeService?.key ]).reduce((acc, [ key, value ]) => {
                const parts = key.split('#'); // Split key into parts
                const [ collection, subCollection, ...fields ] = parts; // Extract parts

                if (collection !== 'collections' || !subCollection || value === '') return acc;

                // Parse boolean values if applicable
                const parseValue = (val) => {
                    if (val === 'true') return true;
                    if (val === 'false') return false;
                    return val;
                };

                value = parseValue(value); // Parse value to boolean if needed

                // Check if the key ends with '__object_list'
                const isObjectList = fields[ fields.length - 1 ]?.endsWith('__object_list');
                if (isObjectList) {
                    fields[ fields.length - 1 ] = fields[ fields.length - 1 ].replace('__object_list', '');
                }

                // Helper function to build nested objects
                const buildNestedObject = (keys, val) => {
                    if (keys.length === 1) {
                        return { [ keys[ 0 ] ]: val };
                    }
                    const [ current, ...rest ] = keys;
                    return { [ current ]: buildNestedObject(rest, val) };
                };

                const mergeDeep = (target, source) => {
                    for (const key of Object.keys(source)) {
                        if (source[ key ] instanceof Object && key in target) {
                            Object.assign(source[ key ], mergeDeep(target[ key ], source[ key ]));
                        }
                    }
                    return { ...target, ...source };
                };

                const existingItem = acc.find(item => item.collection_name === subCollection);
                if (isObjectList) {
                    // Handle "__object_list" case
                    const [ objectListKey, fieldName ] = fields; // The object list and its field name
                    if (!existingItem) {
                        acc.push({
                            collection_name: subCollection,
                            mappings: {
                                [ objectListKey ]: [ { [ fieldName ]: value } ],
                            },
                        });
                    } else {
                        const objectList = existingItem.mappings[ objectListKey ] || [];
                        let existingObject = objectList[ 0 ]; // Assuming a single object per list
                        if (!existingObject) {
                            existingObject = {};
                            objectList.push(existingObject);
                        }
                        existingObject[ fieldName ] = value;
                        existingItem.mappings[ objectListKey ] = objectList;
                    }
                } else {
                    // Handle normal key nesting
                    const nestedMapping = buildNestedObject(fields, value);
                    if (existingItem) {
                        existingItem.mappings = mergeDeep(existingItem.mappings, nestedMapping);
                    } else {
                        acc.push({
                            collection_name: subCollection,
                            mappings: nestedMapping,
                        });
                    }
                }

                return acc;
            }, [])
            : [];
        console.log(transformedData, '#readcallimplementation')
        const activeServiceMappings = fieldMappingsData?.[ activeService?.key+'Service' ] || {}
        const references = activeServiceMappings?.references

        // Adding Empty Fields for empty values
        if(activeServiceMappings?.collectionsFieldMappings){
            for (const field of activeServiceMappings.collectionsFieldMappings) {
                let fieldFound = false;
                const collectionFieldMappings = activeServiceMappings[ field + 'Mappings' ]
                const serviceMappings = {}
                for (const collectionData of transformedData) {
                    if(collectionData?.collection_name === field){
                        fieldFound = true;
                        collectionFieldMappings.map(mapping => {
                            if(!mapping.field_name.includes('#')){
                                if(collectionData?.mappings[ mapping.field_name ]){
                                    serviceMappings[ mapping.field_name ] = collectionData?.mappings[ mapping.field_name ]
                                }else{
                                    serviceMappings[ mapping.field_name ] = ''
                                }
                            }else{
                                if(!mapping.field_name.includes('__object_list')){
                                    // handle normal nested object
                                    const objectListKey = mapping.field_name.split('#')[ 0 ]
                                    const fieldName = mapping.field_name.split('#')[ 1 ]
                                    if(collectionData?.mappings[ objectListKey ]){
                                        const existingObject = collectionData?.mappings[ objectListKey ]
                                        existingObject[ fieldName ] = existingObject?.[ fieldName ] || ''
                                        serviceMappings[ objectListKey ] = { ...existingObject }
                                    }else{
                                        serviceMappings[ objectListKey ] = { ...serviceMappings[ objectListKey ], [ fieldName ]: '' }
                                    }
                                }else{
                                    // handle object list
                                    const objectListKey = mapping.field_name.split('#')[ 0 ]
                                    const fieldName = mapping.field_name.split('#')[ 1 ]
                                    const existingObject = serviceMappings[ objectListKey ]?.[ 0 ] || {}
                                    serviceMappings[ objectListKey ] = [ { ...existingObject, [ fieldName.replace('__object_list', '') ]: collectionData?.mappings[ objectListKey ]?.[ 0 ]?.[ fieldName.replace('__object_list', '') ] || '' } ]
                                    console.log(mapping.field_name, '#mappingFieldforpayload',fieldName, objectListKey, collectionData?.mappings[ objectListKey ])
                                }
                            }
                        })
                        collectionData.mappings = { ...collectionData.mappings, ...serviceMappings }
                        break;
                    }
                }
                // If collection field not found in transformed data, add empty fields
                if (!fieldFound) {
                    collectionFieldMappings.map(mapping => {
                        console.log(field, '#fieldNotFound',mapping,  collectionFieldMappings)
                        if(mapping.field_name.includes('#')){
                            const objectListKey = mapping.field_name.split('#')[ 0 ]
                            const fieldName = mapping.field_name.split('#')[ 1 ]
                            if (mapping.field_name.includes('__object_list')){
                                // handle object list
                                const existingObject = serviceMappings?.[ objectListKey ]?.[ 0 ] || {}
                                serviceMappings[ objectListKey ] = [ { ...existingObject, [ fieldName.replace('__object_list', '') ]: '' } ]

                            }else{
                                // handle normal nested object
                                serviceMappings[ objectListKey ] = { ...serviceMappings[ objectListKey ], [ fieldName ]: '' }
                            }
                        }else{
                            serviceMappings[ mapping.field_name ] = ''
                        }
                    })
                    transformedData.push({
                        collection_name: field,
                        mappings: serviceMappings
                    });
                }
            }
        }
        // Adding Reference Data

        references?.forEach((refernceData) => {
            transformedData.map(collectionData => {
                const localCollectionData = transformedData.find(collection => collection.collection_name === refernceData.local_collection)
                let foreignKeyValue = localCollectionData?.mappings[ refernceData.local_key ];
                if (refernceData.foreign_collection.includes(collectionData?.collection_name)) {
                    collectionData.mappings[ refernceData.foreign_key ] = foreignKeyValue
                }
            })
        })
        console.log(transformedData, '#transformedDataemptyfields')
        return transformedData
    }

    return { addCollectionFieldsToJSONLayout, createCollectionPayload, prepareFormData, validationLoading, checkNestedObjectEmpty, verifyFieldMappings, fieldsPreviewData, getSuggestionsField, suggestionData, loading }
}

export default useFormStep2