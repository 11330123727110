import { useEffect, useState } from 'react';
import useCustomAxiosCallTwo from '../../../../hooks/useCustomAxiosCallTwo';
const INIT_STATE = {
    data:[],header:[],loading:true,error:''
}
const useCollectiveDataDetail = (shipmentNumber) => {
    const uriEndPoint={ service:'collectivedata' };
    uriEndPoint.url = `/collective-items/${ shipmentNumber }`;
    const [ data, setData ] = useState(INIT_STATE);
    const { callApi } = useCustomAxiosCallTwo();
    const getData = async () => {
        setData(INIT_STATE);
        const response = await callApi({
            uriEndPoint
        });
        if (response && response.status === 200) {
            setData({ data: response?.data?.data || [], header: response?.data?.header_data || [],loading:false } )
        } else {
            setData({ error: 'Something Went Wrong... Please try again later.', loading:false })
        }
    }

    useEffect(() => {
        getData();
    }, [ ]);

    return { data  }
}
export default useCollectiveDataDetail;
