import { useState } from 'react';

export const useBusinessLogicFunc = ({ setBusinessLogic, businessLogic, onClose }) => {
    const [ isOpenDropdowns, setIsOpenDropdowns ] = useState({});
    function addLogicToConditions(data) {
        const { conditions, logical_groups } = data;

        const logicMap = {};
        logical_groups?.length > 0 && logical_groups.forEach((group) => {
            const { type, conditions: groupConditions } = group;

            groupConditions.forEach((conditionId) => {
                if (conditionId.startsWith('group_') || conditionId === 'cond_1') {
                    return;
                }

                if (!logicMap[ conditionId ]) {
                    logicMap[ conditionId ] = type;
                }
            });
        });

        const updatedConditions = conditions?.map((condition) => {
            return {
                ...condition,
                logic: logicMap[ condition.id ]
            };
        });

        return {
            ...data,
            conditions: updatedConditions,
        };
    }

    const toggleDropdown = (index) => {
        setIsOpenDropdowns(() => ({
            [ index ]: !isOpenDropdowns[ index ],
        }));
    };

    const initialState = {
        'operation': [],
        'business_logic': {
            'conditions': [
                {
                    'id': 'cond_1',
                    'key': '',
                    'value': '',
                    'operator': '',
                    'method': 'StringOperations',
                    'aggregation': '',
                    'codeSnippet': '',
                    'model_name': [],
                    'className': '',
                    'codePrompt': ''
                },
            ],
            'logical_groups': []
        }
    }
    const handleAddClick = () => {
        const conditions = businessLogic?.business_logic?.conditions || [];

        // Add a new default condition
        const newCondition = {
            id: `cond_${ conditions.length + 1 }`, // Generate unique ID
            key: '',
            value: '',
            operator: '',
            logic: 'AND',
            method: 'StringOperations',
            aggregation: '',
            codeSnippet: '',
            model_name: '',
            className: '',
            codePrompt: ''
        };
        // Update state with the new condition
        const updatedBusinessLogic = {
            ...businessLogic,
            business_logic: {
                ...businessLogic.business_logic,
                conditions: [ ...conditions, newCondition ],
            }
        };

        setBusinessLogic({
            ...updatedBusinessLogic,
            business_logic: {
                ...updatedBusinessLogic.business_logic,
            }
        });
    };
    const handleRemoveClick = (index) => {
        const logic = businessLogic?.business_logic;
        const ruleList = [ ...(logic?.conditions || []) ];
        ruleList.splice(index, 1);

        setBusinessLogic((prevState) => ({
            ...prevState,
            business_logic: {
                ...prevState.business_logic,
                conditions: ruleList.length > 0 ? ruleList : initialState?.business_logic?.conditions
            }
        }));
    };

    const handleChange = (key, value, index, operation) => {
        console.log('sdffaffadf', key, value, index, operation);

        setBusinessLogic((prevState) => {
            if (typeof index === 'number') {
                const updatedBusinessLogic = [ ...prevState.business_logic.conditions ];
                if (key === 'method') {
                    updatedBusinessLogic[ index ] = {
                        ...updatedBusinessLogic[ index ],
                        operator: '',
                        [ key ]: value,
                        value: ''
                    };
                }
                else if (key === 'key') {
                    updatedBusinessLogic[ index ] = {
                        ...updatedBusinessLogic[ index ],
                        [ key ]: value,
                        model_name: operation
                    };
                }
                else if (operation === 'NumericOperations') {
                    updatedBusinessLogic[ index ] = {
                        ...updatedBusinessLogic[ index ],
                        [ key ]: Number(value),
                    };
                } else {
                    updatedBusinessLogic[ index ] = {
                        ...updatedBusinessLogic[ index ],
                        [ key ]: value,
                        className: '',
                        codePrompt: '',
                        aggregation: '',
                        codeSnippet: ''
                    };
                }
                return {
                    ...prevState,
                    business_logic: {
                        ...prevState.business_logic,
                        conditions: updatedBusinessLogic
                    }
                };
            } else if (key === 'operation') {

                // Toggle operation value in the `operation` array
                const updatedOperation = prevState.operation.includes(value)
                    ? prevState.operation.filter((op) => op !== value)
                    : [ ...prevState.operation, value ];

                return {
                    ...prevState,
                    operation: updatedOperation,
                };
            }
            return prevState;
        });
    };
    const handleClose = () => {
        setBusinessLogic(initialState);
        onClose()
    }
    return {
        addLogicToConditions,
        isOpenDropdowns,
        setIsOpenDropdowns,
        handleChange,
        toggleDropdown,
        handleAddClick,
        handleRemoveClick,
        handleClose,
        initialState
    };
}