// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gridWrapper_statsContainer__7m8fw {
    display: grid;
    gap: 3rem;
    margin: 2rem 0;
}

@media screen and (max-width: 1378px) {
 .gridWrapper_statsContainer__7m8fw {
    gap: 2rem;
    grid-template-columns: repeat(3, minmax(0px, 1fr));
}
}`, "",{"version":3,"sources":["webpack://./src/components/gridWrapper/layout/gridWrapper.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,cAAc;AAClB;;AAEA;CACC;IACG,SAAS;IACT,kDAAkD;AACtD;AACA","sourcesContent":[".statsContainer {\n    display: grid;\n    gap: 3rem;\n    margin: 2rem 0;\n}\n\n@media screen and (max-width: 1378px) {\n .statsContainer {\n    gap: 2rem;\n    grid-template-columns: repeat(3, minmax(0px, 1fr));\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statsContainer": `gridWrapper_statsContainer__7m8fw`
};
export default ___CSS_LOADER_EXPORT___;
