import React from 'react';
import './Chatbot.css'; // Import the CSS file for styling
import { useChatBot } from '../functionality/ChatBotFunctionality'; // Import the functionality

const ChatBot = () => {
    const {
        isOpen,
        toggleChatBot,
        messages,
        input,
        setInput,
        sendMessage,
        handleKeyDown,
    } = useChatBot();

    return (
        <>
            <button className={ `chat-bot-toggle ${ isOpen ? 'open' : '' }` } onClick={toggleChatBot}>
                <img src="https://cdn.ekyam.ai/chatbot.png" alt="chat-icon" />
            </button>
            {isOpen && (
                <div className="chat-bot-container">
                    <div className="chat-bot-header">
                        <span>Ekyam Bot</span>
                        <button className="close-btn" onClick={toggleChatBot}>
                            X
                        </button>
                    </div>
                    <div className="chat-bot-messages">
                        {messages.map((message, index) => (
                            <div key={index} className={message.user ? 'message user-message' : 'message bot-message'}>
                                {message.user ? (
                                    <p>{message.text}</p>
                                ) : (
                                    <span dangerouslySetInnerHTML={{ __html: message.text.replace(/\n/g, '<br />') }} />
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="chat-bot-input-container">
                        <input
                            type="text"
                            className="chat-bot-input"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Reply to Ekyam Bot"
                            onKeyDown={handleKeyDown}
                        />
                        <button className="chat-bot-send" onClick={sendMessage}>
                            Send
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default ChatBot;
