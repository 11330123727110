import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    userData: {}
};

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.userData = action.payload;
        },
        clearUser: () => ({
            userData: {}
        })
    }
});

export const userReducer = user.reducer;
export const { setUser, clearUser } = user.actions;