import React from 'react';
import styles from './login.module.css';
import LoginForm from '../../../components/loginForm/layout/LoginForm';
import assets from '../../../assets';

const Login = () => {
    const { mainImage, logo, secondaryImage } = assets;
    return (
        <>
            <div className={styles.bulbImageContainer}>
                <img className={styles.bulbImage} src={mainImage} />
            </div>
            <div className={styles.loginContainer}>
                <div className={styles.loginInnerContainer}>
                    <div className={styles.loginOuterWrapper}>
                        <div className={styles.loginInner}>
                            <div className={styles.title}>
                                <img src={logo} />
                            </div>
                            <div className={styles.loginHeading}>
                                Log in to Ekyam direct
                            </div>
                        </div>
                    </div>
                    <div className={styles.loginImage}>
                        <div className={styles.secondaryImageWrapper}>
                            <img className={styles.secondaryImage} src={secondaryImage} />
                        </div>
                    </div>
                    <div className={styles.loginForm}>
                        <LoginForm />
                    </div>
                    <div className={styles.ellipse1}></div>
                    <div className={styles.ellipse2}></div>
                </div>
            </div>
        </>

    )
}

export default Login;
