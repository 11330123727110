import React from 'react';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import styles from './statCounter.module.css';
import LucideIcon from '../../../../commonComponents/lucideicon/LucideIcon.jsx';
import { getClassByValue } from '../../../../views/inventory/list/functionality/inventoryWidgets.func';
import useStatCounter from '../functionality/statCounter.func';
import Spinner from '../../../../commonComponents/spinner/Spinner.jsx'

const StatCounter = ({ id='', data, uriEndPoint={}, stat_name, icon, className, percentage, onClick=()=>{}  }) => {
    const { ordersStats, widgetLoading, widgetError }=useStatCounter(uriEndPoint)
    const { stat_name:dummyStatName, count, icon:dummyIcon, percentageIcon: dummyPercentageIcon, percentage: dummyPercentage, stat_level } = data || {};
    return (
        <>
            {widgetLoading ?
                <div className={styles.loaderContainer}>
                    <Spinner height={'8vh'} />
                </div>:
                widgetError?<Typography variant='error'>{widgetError}</Typography>
                    :<div id={id || stat_name?.replaceAll(' ', '_') || dummyStatName?.replaceAll(' ', '_')} className={`${ styles.statCounter } ${ styles[ getClassByValue(ordersStats.flag || stat_level) ] } ${ className }`} onClick={onClick}>
                        {(icon || dummyIcon) &&  <div className={`${ styles.iconWrapper } icon`} >
                            <LucideIcon  name={icon || dummyIcon} />
                        </div>}
                        <div className={styles.mainContent}>
                            <Typography className={icon &&  styles.title}>{stat_name|| dummyStatName}</Typography>
                            <div className={ icon || dummyIcon  ?  styles.countWrapper :  styles.countWrapperNew }>
                                <Typography className={styles.statCount}>{ordersStats.total_count || count || 0}</Typography>
                                <div>
                                    {(percentage ||dummyPercentage)  &&<div className={`${ styles.percentage } icon`}> <LucideIcon size="12"  name={dummyPercentageIcon} /><span>{percentage || dummyPercentage}</span></div>}
                                </div>
                            </div>
                        </div>
                    </div>}
        </>
    )

};

export default StatCounter;