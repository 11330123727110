import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { v4 as uuid } from 'uuid';
import { capitalizeCamelCaseWords, capitalizeSnakeCaseWords } from '../../../helpers';

const useConfigMapperFieldV2 = (setValidationError) => {

    const addCustomMappingEntry = (config, path, newField) => {
        const pathArray = path.split('#'); // Split the path by #
        let currentLevel = config
        // Traverse the config structure to the desired level
        pathArray.forEach((key) => {
            const foundItem = currentLevel.find(item => item.data.find(dataItem => dataItem?.field_name === key));
            if (foundItem) {
                if (foundItem.data[ 0 ].field_children) {
                    currentLevel = foundItem.data[ 0 ].field_children;
                } else {
                    console.log(`Field ${ key } does not contain children.`);
                    return config
                }
            } else {
                console.log(`Field ${ key } not found.`);
                return config
            }
        });
        let fieldAlreadyPresent = false
        if(newField?.field_name){
            currentLevel.forEach((obj, i)=>{
                if(obj.field_name === newField?.field_name){
                    console.log('ALready Present', obj.field_name, newField?.field_name)
                    fieldAlreadyPresent = true
                    currentLevel[ i ] = newField
                }
            })
        }
        if(!fieldAlreadyPresent){
            currentLevel.push(newField)
        }

        return config

    }

    const validateInputHandler = (value, fieldData, setValidationError)=>{
        let error, displayName = getDisplayName(fieldData)

        // setValidationError(error)
        if(fieldData?.validation && Object.keys(fieldData?.validation).length > 0){

            if(fieldData?.validation?.required && value === ''){
                error = `${ displayName } field is required`;
            }else if(fieldData?.validation?.min_length > value.length){
                error = `${ displayName } field must contain ${ fieldData?.validation?.min_length } characters`;
            }else{
                error=''
            }
            let errObj;
            setValidationError(prev=>{
                errObj = { ...prev, [ fieldData.field_name ]:error }
                return errObj
            })
            return error
        }

    }

    const getFieldComponent = (data, inputValues, onInputChange, parentField,selected=false, validateInputHandler, displayName) => {
        let inputName =  (parentField !== '')?`${ parentField }#${ data?.field_name }`:data?.field_name;
        inputName = (data?.field_type === 'array')?inputName+'#array':inputName
        inputName = (data?.field_type === 'list')?inputName+'__list':inputName
        const [ inputValue, setInputValue ] = React.useState(inputValues[ inputName ] || data?.field_value || '')
        const changeInputHandelr = (e)=>{
            setInputValue(e.target.value)
            onInputChange(e)
            validateInputHandler(e.target.value, data, setValidationError)
        }

        React.useEffect(()=>{
            setInputValue(inputValues[ inputName ] || data?.field_value || '')
        }, [ inputValues ])
        switch (data?.field_type) {
            case 'text':
                return <input
                    id={inputName}
                    key={inputName}
                    name={inputName}
                    value={(data?.field_value)?data?.field_value:inputValue}
                    label={displayName }
                    placeholder={'Enter '+data?.field_label ? data?.field_label :displayName}
                    size='small'
                    readOnly={data?.read_only || false}
                    onChange={(e)=>{changeInputHandelr(e)}}
                />

            case 'list':
                return <input
                    id={inputName}
                    key={inputName}
                    name={inputName}
                    value={(data?.field_value)?data?.field_value:inputValue}
                    label={displayName }
                    placeholder={'Please enter comma separated values'}
                    size='small'
                    readOnly={data?.read_only || false}
                    onChange={(e)=>{changeInputHandelr(e)}}
                />

            case 'dropdown':
                return (
                    <Select
                        labelId='tribe-label'
                        id={inputName}
                        key={inputName}
                        displayEmpty
                        size='small'
                        value={inputValue || data?.field_options[ 0 ]?.value}
                        name={inputName}
                        onChange={(e)=>{changeInputHandelr(e)}}
                    >
                        {data?.field_options.map((field_option) => (
                            <MenuItem value={field_option?.value} key={field_option?.value}>{field_option?.title}</MenuItem>
                        ))}
                    </Select>
                )

            case 'object':
                return (<div style={(selected )? { paddingLeft:'10px' }:{}}>
                    <Select
                        size='small'
                        value='object'
                        readOnly
                    >
                        <MenuItem value={'object'} key={'object'}>{'Object'}</MenuItem>
                    </Select>
                </div>
                )

            case 'array':
                return (<div style={(selected )? { paddingLeft:'10px' }:{}}>
                    <Select
                        size='small'
                        value='object'
                        readOnly
                    >
                        <MenuItem value={'object'} key={'object'}>{'Object'}</MenuItem>
                    </Select>
                </div>
                )

            case 'radio':
                return <div className='radioGroup'>
                    <input type='radio' name={inputName} value={true} checked={((data?.field_value || inputValue) === true) || ((data?.field_value || inputValue) === 'true')} onChange={(e)=>{changeInputHandelr(e)}}/> Yes
                    <input type='radio' name={inputName} value={false} checked={((data?.field_value || inputValue) === 'false') || ((data?.field_value || inputValue) === '')} onChange={(e)=>{changeInputHandelr(e)}}/> No
                </div>

            default:
                break;
        }
    }

    const addMoreFieldsClickHandler=(parentField, data, configData, setFieldData, fieldListData, isFieldTypeArray=false)=>{
        let newFieldData;
        if(isFieldTypeArray){
            newFieldData = {
                field_type:'array',
                can_add_fields:true,
                field_name_value:'',
                field_value:'',
                field_id:uuid()
            }
        }else{
            let dropDownValues = [];
            newFieldData = {
                field_type:'custom',
                name_field_type:data?.field_children[ 0 ]?.name_field_type,
                field_name_value:'',
                field_value:'',
                field_id:uuid()
            }
            if(data?.field_children[ 0 ]?.name_field_type === 'dropdown'){
                dropDownValues = configData?.[ data?.field_name ] || []
                newFieldData.field_options = dropDownValues
            }
        }
        const updatedFieldList = addCustomMappingEntry(fieldListData, parentField, newFieldData)
        setFieldData([ ...updatedFieldList ])
    }

    const getValidatedFields = (inputData) => {
        return inputData.flatMap(section =>
            section.data.filter(field => field.validation).map(field => ({
                field_name: field.field_name,
                validation: field.validation,
                field_label:field?.field_label
            }))
        );
    }

    const getDisplayName = (fieldData) => {
        return fieldData?.field_label?fieldData?.field_label:capitalizeSnakeCaseWords(capitalizeCamelCaseWords(fieldData?.field_name))
    }

    const updateCustomFieldConfigJson = (config, setConfig, rawData, fieldDataJson) => {
        // For Nested Fields
        const fieldMappings = rawData?.field_mappings;

        for (const key in fieldMappings) {
            const fieldMapping = fieldMappings[ key ];

            if (typeof fieldMapping === 'object') {
                const newKey = `${ key }__array`;

                const fieldChildren = {
                    ...fieldMapping.fields,
                    data_accessor: fieldMapping.data_accessor
                };

                fieldMappings[ newKey ] = fieldChildren;
                delete fieldMappings[ key ];
            }
        }

        // Generate unique array of parent fields from fieldDataJson
        const uniqueArray = Array.from(
            new Set(
                Object.keys(fieldDataJson)
                    .map(key => key.split('#').slice(0, -1).join('#'))
            )
        ).map(str => str.split('#'));
        uniqueArray.forEach((parentFieldArray) => {
            if(parentFieldArray.includes('custom_field_mappings') && parentFieldArray.length>1){
                return
            }
            let currentLevel = config;
            parentFieldArray.forEach((key) => {
                for (const fieldData of currentLevel) {
                    const foundItem = fieldData?.data?.find(item => item.field_name === key);
                    if (foundItem) {
                        // Proceed if the field allows adding children
                        if (foundItem?.can_add_fields) {
                            if (foundItem?.field_children?.length === 1 &&
                                !foundItem?.field_children[ 0 ]?.field_name_value) {

                                // Reset field_children if no field_name_value exists
                                foundItem.field_children = [];

                                // Find the matching data keys from fieldDataJson
                                const parentKeyToBeFindInDataJson = parentFieldArray.join('#');
                                const foundDataKeys = Object.keys(fieldDataJson)
                                    .filter(key => key.includes(parentKeyToBeFindInDataJson));

                                // Add found data to field_children
                                foundDataKeys.forEach(key => {
                                    if(key.split('#').length > 2){return}
                                    const fieldObj = {
                                        field_type: 'custom',
                                        field_id: uuid(),
                                        field_name_value: key.split('#').pop(),
                                        field_value: fieldDataJson[ key ]
                                    };

                                    if (key.includes('accessKeys') || key.includes('queryParameter') || key.includes('endpoints')) {
                                        fieldObj.name_field_type = 'dropdown';
                                    }

                                    foundItem?.field_children.push(fieldObj);
                                });
                            }
                        }
                        if (foundItem?.can_add_array) {
                            const targetData = rawData[ foundItem.field_name ];

                            for (const key in targetData) {
                                const fieldData = targetData[ key ];

                                if (typeof fieldData === 'object') {
                                    const customArrayChildren = Object.entries(fieldData.fields || {})
                                        .filter(([ fieldKey ]) => fieldKey !== 'data_accessor')
                                        .map(([ fieldName, fieldValue ]) => ({
                                            field_type: 'custom_array_children',
                                            field_id: uuid(),
                                            field_name: fieldName,
                                            field_value: fieldValue
                                        }));

                                    const customArrayElement = {
                                        field_type: 'array',
                                        can_add_fields: true,
                                        field_name: key.replace('__array', ''),
                                        data_accessor: fieldData.data_accessor,
                                        field_id: uuid(),
                                        field_children: customArrayChildren
                                    };

                                    foundItem.field_children.push(customArrayElement);
                                }
                            }
                        }

                        // Move deeper into the field_children if available
                        if (foundItem.field_children) {
                            currentLevel = foundItem.field_children;
                        }
                    }
                }
            });
        });

        setConfig(config)
    };

    const addChildrenToField = (config, children, setFieldData, targetField) => {
        config.map((element)=>{
            for (const fieldData of element.data) {
                if(fieldData?.field_name ===targetField){
                    fieldData.field_children = children
                }
            }
        })
        setFieldData([ ...config ])
    }

    return { validateInputHandler,getFieldComponent, addMoreFieldsClickHandler, getValidatedFields, getDisplayName, updateCustomFieldConfigJson, addChildrenToField }
}

export default useConfigMapperFieldV2