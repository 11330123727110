import React, { useState } from 'react';
import styles from './collectiveData.module.css';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
// import AppLoader from '../../../../appLoader';
// import PopupWrapper from '../../../../commonComponents/popupWrapper/PopupWrapper.jsx';
// import CollectiveDataPopup from '../../popup/layout/CollectiveDataPopup.jsx';
import Table from '../../../../commonComponents/table/Table.jsx';
import Filter from '../../../../commonComponents/filter/Filter.jsx';
import collectiveData from './collectiveData.func';
import { Link } from 'react-router-dom';
const CollectiveData = () => {
    // const [ open, setOpen ]=useState(false)
    // const [ id, setId ]=useState();
    const [ filter, setFilter ] = useState('');
    const { data,
        columns: initialColumns,
        loading, error,
        currentPage,
        setCurrentPage,totalRecords,
        setPageSize, filterValues } = collectiveData({ url: { baseUrl: '/collective-data', filterUrl: '/filter-data' }, service: 'collectivedata' }, filter);

    // const handleButtonClick=(key)=>{
    //     setId(key)
    //     setOpen(true)
    // }
    // const onClose = () => {
    //     setOpen(false)
    // }
    let title = 'Warehouse';
    let updatedColumns;
    switch (filter) {
        case 'shipnotice': {
            title = 'Ship Notices';
            updatedColumns = initialColumns.map((column) => {
                if (column.field === 'shipnotice_id') {
                    return {
                        ...column,
                        renderCell: (params) => (
                            <Link to={`/shipment-notice/${ params.row.shipnotice_id }`}>
                                {params.row.shipnotice_id}
                            </Link>
                        )
                    };
                }
                return column;
            });
        }
            break;
        case 'inboundshipment': {
            title = 'Inbound Shipments';
            updatedColumns = initialColumns.map((column) => {
                if (column.field === 'shipment_id') {
                    return {
                        ...column,
                        renderCell: (params) => (
                            <Link to={`/inbound-shipment/${ params.row.shipment_id }`}>
                                {params.row.shipment_id}
                            </Link>
                        )
                    };
                }
                return column;
            });
        }
            break;
        case 'pos': {
            title = 'Invoice No';
            updatedColumns = initialColumns.map((column) => {
                if (column.field === 'invoice_no') {
                    return {
                        ...column,
                        renderCell: (params) => (
                            <Link to={`/summary/${ params.row.invoice_no }`}>
                                {params.row.invoice_no}
                            </Link>
                        )
                    };
                }
                return column;
            });
        }
            break;
        // default: {
        //     options.onClick = {
        //         clickHandler: (key) => handleClick(key),
        //         identifier: 'view',
        //         dataKey: 'shipment_number'
        //     }
        //     if (newHeaders.length && !newHeaders.includes('view')) {
        //         newHeaders = ['view', ...headerData];
        //     }
        // }
        // break;
        default: {
            updatedColumns = initialColumns.map((column) => {
                if (column.field === 'shipment_number') {
                    return {
                        ...column,
                        renderCell: (params) => (
                            <Link to={`/collective-data/${ params.row.shipment_number }`}>
                                {params.row.shipment_number}
                            </Link>
                        )
                    };
                }
                return column;
            });
            title = 'Warehouse Detail';
        }
            break;
    }

    return (
        <>
            <div className={styles.adminDashboard}>
                <div className={styles.mainWrapper}>
                    <div className={styles.header}>
                        <Typography variant='heading'>{title}</Typography>
                        <Filter filterValues={filterValues} value={filter} onFilterChange={setFilter} />
                    </div>
                    {(!loading && error) ?
                        <div className={styles.heading}><Typography variant='error'>{error}</Typography></div>:
                        <Table
                            loading={loading}
                            headerList={updatedColumns.length > 1 ? updatedColumns: [] }
                            totalRecords={totalRecords}
                            setPageSize={setPageSize}
                            rowsData={data}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    }
                </div>
            </div>
            {/* <PopupWrapper isOpen={open} onClose={onClose}>
                <CollectiveDataPopup dataId={id} />
            </PopupWrapper> */}
        </>
    )
};

export default CollectiveData;