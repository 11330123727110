import { addEdge, applyEdgeChanges, applyNodeChanges, ReactFlow } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import React, { useCallback, useEffect, useState } from 'react';
import AppLoader from '../../../appLoader';
import FlowPopup from '../../../commonComponents/flowPopup/FlowPopup.jsx';
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';
import { DestinationNode, SourceNode } from '../../../commonComponents/node/Node.jsx';
import Typography from '../../../commonComponents/typography/Typography.jsx';
import { useWorkFlowMultipleFunc } from '../functionality/workFlowMuliple.func';
import styles from './workFlowMultiple.module.css';
import CustomEdge from '../../../commonComponents/customEdge/CustomEdge.jsx';
import Button from '../../../commonComponents/button/Button.jsx';
import connectorData from '../../../dummyData/ConnectorLogo.json';
import { capitalizeCamelCaseWords } from '../../../helpers/index';
import Spinner from '../../../commonComponents/spinner/Spinner.jsx';
import { useNavigate } from 'react-router-dom';
import BusinessLogicPopup from '../../../commonComponents/businessLogicPopup/BusinessLogicPopup.jsx';
import QueueTacker from '../../queueTracker/layout/QueueTracker.jsx';

const nodeTypes = {
    sourceNode: SourceNode,
    destinationNode: DestinationNode,
};

const WorkFlowMultiple = () => {
    const navigate=useNavigate()
    const { connectedConnectors, handleWorkFlow, handleConnectorName, handleRunWorkFlow, workFlowLoading, workflowData, workflowId, workFlowGetLoading, handleTribe }=useWorkFlowMultipleFunc();
    const [ title, setTitle ]=useState('')
    const [ isOpen, setIsOpen ] = useState();
    const [ selectedEdge, setSelectedEdge ] = useState(null)
    const [ addConnector, setAddConnector ]=useState({
        source:[] ,
        destination: []
    });
    const [ error, setError ] = useState({
        'operation': '',
    })
    const [ businessLogic, setBusinessLogic ] = useState({
        'operation': [],
        'business_logic':{
            'conditions': [
                {
                    'id': 'cond_1',
                    'key': '',
                    'value': '',
                    'operator': '',
                    'logic': '',
                    'method': ''
                },
            ],
            'logical_groups': [
            ]
        }
    })

    const [ nodes, setNodes ] = useState();
    const [ edges, setEdges ] = useState([]);
    const [ isOpenPopup, setIsOpenPopup ]=useState(false)
    const handleName=(name)=>{
        let displayName;
        switch(name) {
            case 'dynamics365': displayName = 'microsoft_dynamics_365'; break;

            default: displayName = name
                break;
        }
        return displayName
    }
    useEffect(() => {
        if (workflowData && typeof workflowData === 'object' && Object.keys(workflowData).length > 0) {
            const flows = workflowData?.flows || [];

            const transformedEdges = flows?.map(({ source, target, operation }) => ({
                source: handleName(source),
                target: handleName(target),
                data: { label: 'Operation' },
                type: 'custom',
                id: `edge-${ handleName(source) }-${ handleName(target) }`,
                operation,
            }));

            const nodesMap = {};
            flows?.forEach(({ source, target }, index) => {
                connectorData?.connectorLogo?.forEach((item) => {
                    if (!nodesMap[ source ]) {
                        if (item?.machine_name === handleName(source)) {
                            nodesMap[ source ] = {
                                id: handleName(source),
                                type: 'sourceNode',
                                data: {
                                    label: capitalizeCamelCaseWords(handleConnectorName(source, source)),
                                    icon: item?.image,
                                },
                                position: { x: 150, y: index * 100 },
                                measured: { width: 184, height: 150 },
                            };
                        }
                    }

                    if (!nodesMap[ target ]) {
                        if (item?.machine_name === handleName(target)) {
                            nodesMap[ target ] = {
                                id: handleName(target),
                                type: 'destinationNode',
                                data: {
                                    label: capitalizeCamelCaseWords(handleConnectorName(target, target)),
                                    icon: item.image,
                                },
                                position: { x: 520, y: index * 100 },
                                measured: { width: 184, height: 150 },
                            };
                        }
                    }
                });
            });
            const nodesData = Object.values(nodesMap).reduce((acc, curr) => {
                if (curr.type !== 'sourceNode') {
                    acc[ 'destination' ].push(curr)
                } else {
                    acc[ 'source' ].push(curr)
                }
                return acc
            }, {
                'destination': [],
                'source': []
            })
            setAddConnector(nodesData)
            setEdges(transformedEdges);
        }
    }, [ workflowData ]);
    const handleFlow = (title, connector) => {
        setAddConnector((prevState) => ({
            ...prevState,
            [ title ]: [
                ...prevState[ title ],
                ...connector.map((item,index) => ({
                    id: item.machine_name,
                    type: title==='source' ? 'sourceNode': 'destinationNode',
                    data: { label: item.name, icon: item.icon },
                    position: title==='source' ? { x: 150, y: addConnector.source.length===0 ? index * 100 : (addConnector.source.length + index) * 100 }: { x:520, y: addConnector.destination.length===0 ? index * 100 : (addConnector.destination.length+ index) * 100 },
                }))
            ]
        }));
    };
    useEffect(()=>{
        if (typeof document !== 'undefined') {
            const reactFlowText = document.querySelector('.react-flow__attribution');
            if (reactFlowText) {
                reactFlowText.style.display = 'none';
            }
        }
    }, [ workFlowGetLoading ])
    useEffect(() => {
        if (addConnector?.source && addConnector?.destination &&(Object.keys(addConnector?.source)?.length > 0 || Object.keys(addConnector?.destination)?.length > 0)) {
            const updatedNodes = [
                ...(nodes || []),
                ...addConnector.source,
                ...addConnector.destination,
            ];
            const uniqueNodes = updatedNodes.filter(
                (node, index, self) => index === self.findIndex((n) => n.id === node.id)
            );
            setNodes(uniqueNodes);
        }
    }, [ addConnector ]);

    const handleSourcePopup = (name) => {
        setTitle(name)
        setIsOpen(true)
    }
    const onClose=()=>{
        setIsOpen(false)
    }
    const handleEdgeClick = (event, edgeId) => {
        const edge=edges.find((edge)=>edge.id===edgeId)
        setSelectedEdge(edge);
        setIsOpenPopup(true)
    };

    const edgeTypes = {
        custom: (props) => <CustomEdge {...props} onClick={handleEdgeClick} handleWorkFlow={()=>handleWorkFlow(edges, workflowId)} edges={edges}/>,
    };
    const createDynamicLogicalGroups = (data) => {
        const logicalGroups = [];
        let groupIdCounter = 1;

        const createGroup = (type, conditions) => {
            return {
                id: `group_${ groupIdCounter++ }`,
                type: type,
                conditions: conditions,
            };
        };

        const logicGroups = {
            AND: [],
            OR: [],
            NONE: [],
        };
        data.forEach((condition) => {
            if (!condition.logic) {
                logicGroups.NONE.push(condition.id);
            } else if (condition.logic === 'AND') {
                logicGroups.AND.push(condition.id);
            } else if (condition.logic === 'OR') {
                logicGroups.OR.push(condition.id);
            }
        });

        if (logicGroups.AND.length > 0) {
            const groupConditions = [ ...logicGroups.NONE, ...logicGroups.AND ];
            const group1 = createGroup('AND', groupConditions);
            logicalGroups.push(group1);
        }
        if (logicGroups.OR.length > 0 && logicGroups.AND.length === 0) {
            const groupConditions = [ ...logicGroups.NONE, ...logicGroups.OR ];
            const group1 = createGroup('OR', groupConditions);
            logicalGroups.push(group1);
        }

        if (logicGroups.OR.length > 0 && logicGroups.AND.length > 0) {
            const group2Conditions = [
                `group_${ groupIdCounter - 1 }`,
                ...logicGroups.OR,
            ];
            const group2 = createGroup('OR', group2Conditions);
            logicalGroups.push(group2);
        }
        return {
            conditions: data,
            logical_groups: logicalGroups,
        }
    };
    const handleValidation = () => {
        let formError = {};
        let isValidate = false;
        Object.keys(businessLogic).forEach((key) => {
            console.log(key, 'keyss')
            if (businessLogic.operation.length===0) {
                formError[ key ] = `${ key.charAt(0).toUpperCase() + key.slice(1) } is required`;
                isValidate = true;
            }
        })
        setError({ ...error, ...formError })
        return isValidate;
    }
    const handleOperation = () => {
        const isValidate=handleValidation();
        if (!isValidate){
            const conditions = businessLogic?.business_logic?.conditions || [];
            const result = createDynamicLogicalGroups(conditions);
            const finalResult = { ...businessLogic, business_logic: result }
            // eslint-disable-next-line no-unused-vars
            finalResult.business_logic.conditions = finalResult.business_logic.conditions.map(({ logic, ...rest }) => {
                return rest;
            });
            setEdges((prevEdges) => {
                const updatedEdges = prevEdges.map((edge) => {
                    if (edge && edge.id === selectedEdge.id) {
                        return {
                            ...edge, ...finalResult

                        }
                    }
                    return edge;
                });
                const parameter= {
                    tribe: handleTribe(selectedEdge.target),
                    system: selectedEdge.target,
                    service: businessLogic?.operation.length>0 && businessLogic?.operation[ 0 ]?.split('_')[ 1 ],
                    endpoints: businessLogic?.operation.length > 0 && businessLogic?.operation[ 0 ]?.split('_')[ 1 ],
                    sourceData: {
                        tribe: handleTribe(selectedEdge?.source),
                        system: selectedEdge?.source,
                        service: businessLogic?.operation.length > 0 && businessLogic?.operation?.[ 0 ].split('_')[ 1 ],
                    },
                    data: updatedEdges,
                    id: workflowId,
                };
                navigate(`/writer-connect/${ workflowId }`, { state: { parameter } })

                return updatedEdges;
            });
        }
    };
    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [ setNodes ],
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [ setEdges ],
    );
    const onConnect = useCallback((params) => {
        const newEdge = {
            ...params,
            data: { label: 'Operation' },
            type: 'custom',
            id: `edge-${ params.source }-${ params.target }`,
            operation:[]
        };
        setEdges((eds) => addEdge(newEdge, eds));
        handleWorkFlow([ ...edges, newEdge ], workflowId)
    }, [ setEdges ]);

    const onClosePopup=()=>{
        setIsOpenPopup(false)
    }
    return (
        <AppLoader>
            <div className={styles.flowsContainer}>
                <div className={styles.flowsInner}>
                    <div className={styles.source} onClick={()=>handleSourcePopup('source')}>
                        <LucideIcon name="CloudDownload" size={28} className={styles.closeIcon} />
                        <Typography className={styles.addTitle}>Add Source</Typography>

                    </div>
                    <div className={styles.line} />
                    <div className={styles.circleButton}>
                        <LucideIcon name="Truck" size={18} color="#4470EC" className={styles.closeIcon} />
                    </div>
                    <div className={styles.line} />

                    <div className={styles.destination} onClick={()=>handleSourcePopup('destination')}>
                        <LucideIcon name="CloudUpload" size={28} className={styles.closeIcon} />
                        <Typography className={styles.addTitle}>Add Destination/Lookup</Typography>
                    </div>
                </div>
            </div>
            {workFlowGetLoading
                ? <Spinner height={'65vh'}/>:
                <div style={{ width: '90%', height: '70%', minHeight:'100px', position: 'relative' }}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onConnect={onConnect}
                        nodeTypes={nodeTypes}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        zoomOnScroll={false}
                        zoomOnPinch={false}
                        panOnScroll={false}
                        defaultZoom={1}
                        edgeTypes={edgeTypes}
                        attributionPosition={null}
                    >
                    </ReactFlow>
                </div>}
            <div className={styles.buttonWrapper}>
                <Button className={styles.saveAndCloseButton} onClick={() => handleWorkFlow(edges, workflowId)}> {workFlowLoading ? '...Loading' : 'Save'}</Button>
                <Button className={styles.saveAndCloseButton} onClick={() => handleRunWorkFlow(workflowId)}>Run</Button>
            </div>
            <QueueTacker workflowId={workflowId} />
            <BusinessLogicPopup isOpen={isOpenPopup} onClose={onClosePopup} width="50%" height='570px' handleOperation={handleOperation} businessLogic={businessLogic} setBusinessLogic={setBusinessLogic} workflowData={workflowData} error={error} />
            <FlowPopup isOpen={isOpen} onClose={onClose} title={title} connectedConnectors={connectedConnectors} setAddConnector={setAddConnector} addConnector={addConnector} handleFlow={handleFlow} nodes={nodes}/>
        </AppLoader>
    )
}
export default WorkFlowMultiple;