// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pieChartLabels_chartHeader__f-Upg {
    padding: 25px;
}

.pieChartLabels_barChartWrapper__s85ht {
    width: 100%;
    box-shadow: 0px 5.66px 24.27px 0px #0000002B;
    border-radius: 10px;
    background-color: white;
}

.pieChartLabels_barChart__ZV2rB {
    display: flex;
    padding: 0 1rem 1rem 1rem;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.pieChartLabels_colorBox__WTTqV {
    width: 45px;
    height: 25px;
    display: inline-block;
    margin-right: 8px;
    border-radius: 3px;
}

.pieChartLabels_labelWrapper__rph6r {
    display: flex;
    align-items: center;
}

.pieChartLabels_label__DzhV9 {
    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
}

.pieChartLabels_leftSideContent__nN365 {
    display: flex;
    flex-direction: column;
    gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/charts/pieChartLabels/pieChartLabels.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,4CAA4C;IAC5C,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,uBAAuB;IACvB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":[".chartHeader {\n    padding: 25px;\n}\n\n.barChartWrapper {\n    width: 100%;\n    box-shadow: 0px 5.66px 24.27px 0px #0000002B;\n    border-radius: 10px;\n    background-color: white;\n}\n\n.barChart {\n    display: flex;\n    padding: 0 1rem 1rem 1rem;\n    justify-content: center;\n    gap: 20px;\n    align-items: center;\n}\n\n.colorBox {\n    width: 45px;\n    height: 25px;\n    display: inline-block;\n    margin-right: 8px;\n    border-radius: 3px;\n}\n\n.labelWrapper {\n    display: flex;\n    align-items: center;\n}\n\n.label {\n    font-family: Outfit;\n    font-size: 14px;\n    font-weight: 400;\n}\n\n.leftSideContent {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartHeader": `pieChartLabels_chartHeader__f-Upg`,
	"barChartWrapper": `pieChartLabels_barChartWrapper__s85ht`,
	"barChart": `pieChartLabels_barChart__ZV2rB`,
	"colorBox": `pieChartLabels_colorBox__WTTqV`,
	"labelWrapper": `pieChartLabels_labelWrapper__rph6r`,
	"label": `pieChartLabels_label__DzhV9`,
	"leftSideContent": `pieChartLabels_leftSideContent__nN365`
};
export default ___CSS_LOADER_EXPORT___;
