import React from 'react'
import AppLoader from '../../appLoader'
import styles from './returnsManagement.module.css'
import useTables from '../../commonComponents/table/useTables.func';
import Table from '../../commonComponents/table/Table.jsx';
import Typography from '../../commonComponents/typography/Typography.jsx';
import ReturnManament from '../../dummyData/ReturnsManagment.json'
import StatCounter from '../../components/widgets/statCounter/layout/StatCounter.jsx';
import LineChart from '../../commonComponents/charts/lineChart/LineChart.jsx'
import PieChart from '../../commonComponents/charts/pieChart/PieChart.jsx';

function ReturnsManagement() {
    const { heading, subHeading, tableData, pieChart, lineChart }=ReturnManament;
    const { data,
        columns } = useTables( {}, tableData.header, tableData.rowData, {});

    return (
        <AppLoader>
            <div className={styles.mainWrapper}>
                <Typography variant='gradient'>{heading}</Typography>
                <div className={styles.subHeader}>
                    <Typography variant='heading'>{subHeading}</Typography>
                </div>
                <Table
                    headerList={columns}
                    rowsData={data}
                    totalRecords={data.length}
                />
            </div>
            <div className={styles.widgets}>
                {ReturnManament.cancelStatus.map((obj, index)=>
                    <StatCounter id={obj.id} key={index} className={styles.sales}  data={{ stat_name: obj.heading, count: obj.value, icon: obj.icon }} />
                )}
            </div>
            <div className={styles.chartWrapper}>
                <div className={styles.lineChart}>
                    <div className={styles.chartHeader}>
                        <Typography variant='title'>Return Rate Over Time</Typography>
                    </div>
                    <LineChart
                        data={lineChart.data}
                        height="300"
                        colors={lineChart.colors}
                    />
                </div>
                <div  className={styles.barChartWrapper}>
                    <div className={styles.chartHeader}>
                        <Typography variant='title'>Returns by Product</Typography>
                    </div>
                    <div className={styles.barChart}>
                        <div className={styles.leftSideContent}>
                            {pieChart?.data?.map((obj, index)=>
                                <div key={index} className={styles.labelWrapper}>
                                    <span className={styles.colorBox} style={{ backgroundColor: ReturnManament.pieChart.colors[ index ] }}></span>
                                    <div className={styles.label}>{obj.label}</div>
                                </div>
                            )}
                        </div>
                        <PieChart
                            data={pieChart.data}
                            colors={pieChart?.colors}
                        />
                    </div>
                </div>
            </div>
        </AppLoader>
    )
}

export default ReturnsManagement