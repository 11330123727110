import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    sidebarToggle: true
};

const sidebar = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setSidebarToggle: (state, action) => {
            state.sidebarToggle = action.payload;
        }
    }
});

export const sidebarReducer = sidebar.reducer;
export const { setSidebarToggle } = sidebar.actions;