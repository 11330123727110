import React from 'react'
import AppLoader from '../../../appLoader'
import Typography from '../../../commonComponents/typography/Typography.jsx'
import styles from './ConfigMapperV2.module.css'
import StepSlider from '../../../components/stepSlider/layout/StepSlider.jsx'
import FormStep from '../../../components/formStep/layout/FormStep.jsx'
import configStepData from '../../../dummyData/ConfigurationMapperV2/ConfigMapperStepsData.json'
import FormStep2 from '../../../components/formStep/layout/FormStep2.jsx'
import FormStep3 from '../../../components/formStep/layout/FormStep3.jsx'
import useConfigMapper from '../../configMapper/functionality/useConfigMapper.func'
import Spinner from '../../../commonComponents/spinner/Spinner.jsx'
// import dummyData from '../data/step1DummyData.json'
// import configFormDummyData from '../data/configOFrmDatadummy.json'
const ConfigMapperV2 = () => {
    // States
    const [ activeStep, setActiveStep ] = React.useState(0) 
    const [ configFormData, setConfigFormData ] = React.useState()

    // Import FUnctions from hooks
    const { getConfigData, loading, data, getConnectedConnectors, connectedConnectors, selectedSystem, selectedTribe, setSelectedSystem, setSelectedTribe } = useConfigMapper()
    
    // Declaring and assigning variables
    const stepsInfo = [
        { label:'Connectivity' },
        { label:'Services' },
        { label:'Other Configurations' },
    ]

    const formSteps = [
        <FormStep
            key={1}  
            fieldInputData={[ ...configStepData[ activeStep ].inputData ]} 
            sliderData={configStepData[ activeStep ]?.sliderData} 
            setConfigFormData={setConfigFormData}
            fetchedData={data}
            setActiveStep={setActiveStep}
            configFormData={configFormData}
            selectedTribe={selectedTribe}
            selectedSystem={selectedSystem}
            setSelectedTribe={setSelectedTribe}
            setSelectedSystem={setSelectedSystem}
            connectedConnectors={connectedConnectors}
        />,    
        <FormStep2 
            key={2}
            fieldInputData={[ ...configStepData[ activeStep ].inputData ]} 
            fieldLayout={[ ...configStepData[ activeStep ].inputData ]} 
            sliderData={configStepData[ activeStep ]?.sliderData} 
            fetchedData={data}
            configFormData={configFormData}
            // fetchedData={dummyData}
            // configFormData={configFormDummyData}
            setActiveFormStep={setActiveStep}
            setConfigFormData={setConfigFormData}
        />,
        <FormStep3 
            key={3}
            fieldInputData={[ ...configStepData[ activeStep ].inputData ]} 
            sliderData={configStepData[ activeStep ]?.sliderData} 
            setConfigFormData={setConfigFormData}
            fetchedData={data}
            configFormData={configFormData}
        />
    ]

    // Consoles
    console.log(configFormData, 'configFormDataFormSubitIssue',configStepData[ activeStep ],loading )
    
    // Use Effects
    React.useEffect(()=>{
        getConnectedConnectors()
    }, [])
    
    React.useEffect(()=>{
        if(selectedSystem && (selectedSystem !== '' || selectedSystem !== 'none')){
            getConfigData(selectedSystem)
        }
    }, [ selectedSystem ])

    //JSX 
    return <AppLoader>
        <section className={styles.configMapperWrapper}>
            <Typography variant='heading'>Configuration Mapping</Typography>
            <div className={styles.stepSliderWrapper}>
                <StepSlider activeStep={activeStep} stepsInfo={stepsInfo}/>
            </div>
            {loading?<Spinner /> :formSteps[ activeStep ]}
        </section>
    </AppLoader>
}

export default ConfigMapperV2