// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.input_inputContainer__YAgpZ {
    align-items: center;
    /* background: var(--input-background); */
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 16px;
}

.input_inputContainer__YAgpZ svg {
    cursor: pointer;
}

.input_input__VEbGQ {
    background-color: transparent;
    border: none;
    font-family: Outfit;
    font-size: 16px;
    letter-spacing: 0.6px;
    outline: none;
    width: 100%;
}

.input_input__VEbGQ::placeholder {
    color: var(--placeholder-color);
}

.input_transparent__w3WnH {
    background-color: transparent;
    padding: 0;
}

.input_transparent__w3WnH input {
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/input/input.module.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;IACnB,yCAAyC;IACzC,kBAAkB;IAClB,aAAa;IACb,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,qBAAqB;IACrB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,6BAA6B;IAC7B,UAAU;AACd;;AAEA;IACI,eAAe;AACnB","sourcesContent":["\n.inputContainer {\n    align-items: center;\n    /* background: var(--input-background); */\n    border-radius: 8px;\n    display: flex;\n    justify-content: space-between;\n    padding: 16px;\n}\n\n.inputContainer svg {\n    cursor: pointer;\n}\n\n.input {\n    background-color: transparent;\n    border: none;\n    font-family: Outfit;\n    font-size: 16px;\n    letter-spacing: 0.6px;\n    outline: none;\n    width: 100%;\n}\n\n.input::placeholder {\n    color: var(--placeholder-color);\n}\n\n.transparent {\n    background-color: transparent;\n    padding: 0;\n}\n\n.transparent input {\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `input_inputContainer__YAgpZ`,
	"input": `input_input__VEbGQ`,
	"transparent": `input_transparent__w3WnH`
};
export default ___CSS_LOADER_EXPORT___;
