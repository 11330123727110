import parse from 'html-react-parser';

const ordinalSuffix = (day) => {
    if (day > 3 && day < 21) return `${ day }th`;
    switch (day % 10) {
        case 1: return `${ day }st`;
        case 2: return `${ day }nd`;
        case 3: return `${ day }rd`;
        default: return `${ day }th`;
    }
}

const formatTime = (hour, minute) => {
    const meridiem = hour >= 12 ? 'pm' : 'am';
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    const paddedMinute = String(minute).padStart(2, '0');
    return `${ hour12 }:${ paddedMinute } ${ meridiem }`;
}

export const formatDate = (dateString) => {
    const months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];

    const date = new Date(dateString);

    const month = months[ date.getMonth() ];
    const day = date.getDate();
    const year = date.getFullYear();

    const hour = date.getHours();
    const minute = date.getMinutes();

    const formattedDate = `${ month } ${ ordinalSuffix(day) } ${ year } | ${ formatTime(hour, minute) }`;

    return formattedDate;
};

export const getDateLocale = (dateTimeString) =>{
    if (dateTimeString){
        const date =  new Date(dateTimeString);
        const month = date.getMonth()+1;
        const day = date.getDate();
        const year = date.getFullYear();
        const formattedDate = `${ month }/ ${ day } /${ year }`;
        return formattedDate
    }
    else{
        return '00/ 00/ 00'
    }
}
const ACRONYMS = {
    'Id': 'ID',
    'Sku': 'SKU',
    'Seo': 'SEO',
    'Url': 'URL',
    'Api': 'API',
    'Ui': 'UI',
    'Uuid': 'UUID',
    'Html': 'HTML',
    'Css': 'CSS',
    'Pdf': 'PDF',
    'Json': 'JSON',
    'Xml': 'XML',
    '3pl':'3PL',
    'Erp':'ERP',
    'Sftp':'SFTP',
    'Pos':'POs',
    'Crm':'CRM',
    'Gcp':'GCP'
};

export const capitalizeCamelCaseWords = (value)=>{
    if(!value) return '';
    const words = value?.split(/(?=[A-Z])/);
    const capitalizedWords = words?.map((word) => {
        return word?.charAt(0)?.toUpperCase() + word?.slice(1);
    });
    let result = capitalizedWords?.join(' ')

    Object.entries(ACRONYMS).forEach(([ key, replacement ]) => {
        result = result?.replace(new RegExp(key, 'g'), replacement);
    });
    // Add space between acronyms and following capitalized words
    result = result?.replace(/([A-Z]{2,})(?=[A-Z][a-z])/g, '$1 ');

    return result;
}

export const capitalizeSnakeCaseWords = (value) => {
    if (!value) return '';
    let result = value?.split('_')
        ?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))
        ?.join(' ');

    // Replace known acronyms
    Object.entries(ACRONYMS).forEach(([ key, replacement ]) => {
        result = result?.replace(new RegExp(key, 'g'), replacement);
    });
    // Add space between acronyms and following capitalized words
    result = result?.replace(/([A-Z]{2,})(?=[A-Z][a-z])/g, '$1 ');

    return result;
}

export const smallCaseWords = (value) => {
    if (!value) return '';
    return value?.split('_')?.map(word => word?.charAt(0)?.toLowerCase() + word?.slice(1))?.join(' ')?.replace('id', 'ID');
}

export const formatValue = (value)=>{
    if(value==='' || value===null || typeof value === 'object'){return '-'}
    if(typeof value==='boolean') {
        return value?<span style={ { color:'green' } }>Yes</span>:<span style={ { color:'red' } }>No</span>
    }
    return value;
}

export const toSnakeCase = (str) => {
    return str
        .replace(/([a-z])([A-Z])/g, '$1_$2')  // Handle camelCase
        .replace(/[\s\-]+/g, '_')             // Replace spaces and hyphens with underscores
        .toLowerCase();                       // Convert to lowercase
}
export const convertDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${ day }/${ month }/${ year }`;
};
export const formatedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${ year }-${ month }-${ day }`;
};
export const calculateDaysFromMonths = (month) => {
    const MS_IN_A_DAY = 1000 * 60 * 60 * 24;
    const currentDate = new Date();

    const threeMonthsAgo = new Date(currentDate);

    threeMonthsAgo.setMonth(currentDate.getMonth() - month);

    const differenceInDays = Math.floor((currentDate - threeMonthsAgo) / MS_IN_A_DAY);

    return differenceInDays;
};
// This function converts flat fields into nested fields
export const updateObjectWithNestedKey = (obj, key, value) => {
    console.log(obj, '#nestedobject')
    const keys = key.split('#');
    let current = obj;
    for (let i = 0; i < keys.length - 1; i++) {
        const part = keys[ i ];

        if (!current || !current[ part ] || typeof current[ part ] !== 'object') {
            current[ part ] = {};
        }else {
            current[ part ] = { ...current[ part ] };
        }

        current = current[ part ];
    }

    current[ keys[ keys.length - 1 ] ] = value;
    return obj
}
export const camelCaseWords = (inputValue) => {
    return inputValue
        .toLowerCase()
        .replace(/\b[a-z]/g, (char) => char.toUpperCase())
        .replace(/\s+/g, '');
}

export const displayValue = (val) => {
    console.log(val, typeof val, '#nestedobjecttypecheking')
    switch (typeof val) {
        case 'string':
            return parse(val)
        case 'number':
            return val.toString()
        case 'object':
            return JSON.stringify(val)
        case 'boolean':
            return val ? 'Yes' : 'No'
        case 'undefined':
        default:
            return val
    }
}

export const tribesToCheck = [
    'webshop',
    'marketplace',
    'saleschannel',
    'Market Place',
    'Webshop',
];

export const getFilteredData = (connectors) => {
    return connectors.filter((item) =>
        tribesToCheck.includes(item.tribe)
    );
};

export const getDropdownOptions = (connectors) => {
    const filteredData = getFilteredData(connectors);
    return [
        {
            name: 'All Sale Channels',
            value: 'all',
        },
        ...(
            filteredData?.map((item) => {
                return {
                    name: capitalizeSnakeCaseWords(item?.name),
                    value: item?.machine_name,
                };
            }) || []
        ),
    ];
};
