// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdownTwo_circleButton__yi-Sd {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.dropdownTwo_popupMenu__3hD4A {
  position: absolute;
  background-color: white;
  border-top-left-radius: 8px;
  border-bottom-left-radius:8px;
  border-left: 2px solid #4470EC;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdownTwo_menuItem__efa7S {
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 0.75px solid #446CEB40
}
.dropdownTwo_menuItem__efa7S:active {
 background-color: #446CEB1C;
}
.dropdownTwo_popupMenu__3hD4A:last-child {
border: none;
}
.dropdownTwo_active__8nyeD {
  background-color: #446CEB1C;
}
.dropdownTwo_menuItem__efa7S:hover {
  background-color: #446CEB1C;
;
}

.dropdownTwo_overlay__ThKpO {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 999;
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/dropdownTwo/dropdownTwo.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,2BAA2B;EAC3B,6BAA6B;EAC7B,8BAA8B;EAC9B,2CAA2C;EAC3C,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf;AACF;AACA;CACC,2BAA2B;AAC5B;AACA;AACA,YAAY;AACZ;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,2BAA2B;;AAE7B;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,uBAAuB;EACvB,YAAY;AACd","sourcesContent":[".circleButton {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  background-color: #e0e0e0;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n}\n\n.popupMenu {\n  position: absolute;\n  background-color: white;\n  border-top-left-radius: 8px;\n  border-bottom-left-radius:8px;\n  border-left: 2px solid #4470EC;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n  z-index: 1000;\n}\n\n.menuItem {\n  padding: 8px 12px;\n  cursor: pointer;\n  border-bottom: 0.75px solid #446CEB40\n}\n.menuItem:active {\n background-color: #446CEB1C;\n}\n.popupMenu:last-child {\nborder: none;\n}\n.active {\n  background-color: #446CEB1C;\n}\n.menuItem:hover {\n  background-color: #446CEB1C;\n;\n}\n\n.overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: transparent;\n  z-index: 999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circleButton": `dropdownTwo_circleButton__yi-Sd`,
	"popupMenu": `dropdownTwo_popupMenu__3hD4A`,
	"menuItem": `dropdownTwo_menuItem__efa7S`,
	"active": `dropdownTwo_active__8nyeD`,
	"overlay": `dropdownTwo_overlay__ThKpO`
};
export default ___CSS_LOADER_EXPORT___;
