import React, { useEffect, useRef, useState } from 'react';
import styles from './businessLogic.module.css'

import PopupWrapper from '../popupWrapper/PopupWrapper.jsx';
import Input from '../input/Input.jsx';
import Typography from '../typography/Typography.jsx';
import Button from '../button/Button.jsx';
import DropDownTwo from '../dropdownTwo/DropdownTwo.jsx';
import { MenuItem, Select } from '@mui/material';
import LucideIcon from '../../commonComponents/lucideicon/LucideIcon.jsx';

const dropDownOption = [
    { name: 'Push orders', value: 'push_orders' },
    { name: 'Push products', value: 'push_products' },
    { name: 'Shipment sync', value: 'shipment_sync' },
    { name: 'Inventory sync', value: 'inventory_sync' }
]
const options = [
    { label: 'String Functions', operation: 'StringOperations', subOptions: [ 'start_with', 'ends_with', 'contains' ] },
    { label: 'Mathematical Functions', operation: 'NumericOperations', subOptions: [ 'greater than', 'contains', 'is not contain', 'less than', 'equal', 'not equal' ] },
    { label: 'RDBMS Functions', operation: 'RDBMSFunctions', subOptions: [ 'Inner Join', 'Left Outer Join', 'Right Outer Join', 'Full Outer Join' ] },
    { label: 'Custom Functions', subOptions: [ 'Method 1', 'Method 2' ] },
];
const dropDownOptionOne = [
    { name: 'AND', value: 'AND' },
    { name: 'OR', value: 'OR' },
]

function BusinessLogicPopup({ isOpen, onClose, height, width, handleOperation, businessLogic, setBusinessLogic, workflowData, error }) {
    const [ isOpenDropdowns, setIsOpenDropdowns ] = useState({});
    const dropdownRefs = useRef({});
    const [ showMenu, setShowMenu ] = useState(false);
    function separateBusinessLogicAndOperation(data) {
        const { business_logic, operation } = data;
        return {
            business_logic,
            operation,
        };
    }
    function addLogicToConditions(data) {
        console.log(data, 'dataaaaa')
        const { conditions, logical_groups } = data;

        const logicMap = {};

        logical_groups?.length>0 && logical_groups.forEach((group) => {
            const { type, conditions: groupConditions } = group;

            groupConditions.forEach((conditionId) => {
                if (conditionId.startsWith('group_') || conditionId==='cond_1') {
                    return;
                }

                if (!logicMap[ conditionId ]) {
                    logicMap[ conditionId ] = type;
                }
            });
        });

        // Update conditions with the appropriate logic
        const updatedConditions = conditions?.map((condition) => {
            return {
                ...condition,
                logic: logicMap[ condition.id ]
            };
        });

        return {
            ...data,
            conditions: updatedConditions,
        };
    }
    useEffect(() => {
        if (workflowData && workflowData?.flows?.length > 0 && workflowData?.flows[ 0 ]?.business_logic){
            const dataaa = separateBusinessLogicAndOperation(workflowData.flows[ 0 ]);
            const fixedData={
                business_logic: addLogicToConditions(dataaa?.business_logic),
                operation:dataaa?.operation
            }
            setBusinessLogic(fixedData)
        }
    }, [ isOpen ])
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside all dropdowns
            const isOutside = Object.values(dropdownRefs.current).every(
                (ref) => ref && !ref.contains(event.target)
            );

            if (isOutside) {
                setIsOpenDropdowns({});
            }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Cleanup event listener on unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = (index) => {
        console.log(index, 'index');
        setIsOpenDropdowns(() => ({
            [ index ]: !isOpenDropdowns[ index ],
        }));
    };

    const handleChange = (key, value, index, operation) => {
        setBusinessLogic((prevState) => {
            if (typeof index === 'number') {
                // Update a specific `business_logic` item by index
                const updatedBusinessLogic = [ ...prevState.business_logic.conditions ];
                if (key === 'operator') {
                    updatedBusinessLogic[ index ] = {
                        ...updatedBusinessLogic[ index ],
                        method: operation,
                    };
                }
                console.log(updatedBusinessLogic, 'updatedBusinessLogic')
                updatedBusinessLogic[ index ] = {
                    ...updatedBusinessLogic[ index ],
                    [ key ]: value,
                };

                if (key === 'operator') {
                    toggleDropdown(index);
                }

                return {
                    ...prevState,
                    business_logic: {
                        ...prevState.business_logic,
                        conditions: updatedBusinessLogic
                    }
                };
            } else if (key === 'operation') {
                // Toggle operation value in the `operation` array
                const updatedOperation = prevState.operation.includes(value)
                    ? prevState.operation.filter((op) => op !== value)
                    : [ ...prevState.operation, value ];

                return {
                    ...prevState,
                    operation: updatedOperation,

                };
            }
            return prevState;
        });
    };

    const handleClose = () => {
        setBusinessLogic({
            'operation': [],
            'business_logic': {
                'conditions': [
                    {
                        'id': '',
                        'key': '',
                        'value': '',
                        'operator': '',
                        'method': ''
                    },
                ],
                'logical_groups': []
            }
        }
        );
        onClose()
    }
    const handleSave = () => {
        handleOperation()
    }

    const handleRemoveClick = (index) => {
        const logic = businessLogic?.business_logic;

        // Copy the conditions array
        const ruleList = [ ...(logic?.conditions || []) ];

        // Remove the condition at the specified index
        ruleList.splice(index, 1);

        // Update state with the modified conditions array
        setBusinessLogic((prevState) => ({
            ...prevState,
            business_logic: {
                ...prevState.business_logic,
                conditions: ruleList
            }
        }));
    };

    const handleAddClick = () => {
        const conditions = businessLogic?.business_logic?.conditions || [];

        // Add a new default condition
        const newCondition = {
            id: `cond_${ conditions.length + 1 }`, // Generate unique ID
            key: '',
            value: '',
            operator: '',
            logic: 'AND',
            method: ''
        };

        // Update state with the new condition
        const updatedBusinessLogic = {
            ...businessLogic,
            business_logic: {
                ...businessLogic.business_logic,
                conditions: [ ...conditions, newCondition ],
            }
        };

        setBusinessLogic({
            ...updatedBusinessLogic,
            business_logic: {
                ...updatedBusinessLogic.business_logic,
            }
        });
    };

    console.log(businessLogic, 'setRuless')
    if (!isOpen) return null;
    const overlayStyles = {};
    height ? overlayStyles.height = height : null;
    width ? overlayStyles.width = width : null;
    return (
        <PopupWrapper isOpen={isOpen} onClose={handleClose} height={height} width={width}>
            <div className={styles.businessLogicWrapper}>
                <div className={styles.checkboxOuter}>
                    <Typography variant='title'>Operation</Typography>
                    <div className={styles.checkboxContainer}>
                        {dropDownOption?.map((item) =>
                            <div key={item?.name} className={styles.checkboxWrapper}>
                                <Input type="checkbox" checked={businessLogic?.operation?.includes(item?.value)} className={styles.checkbox} onChange={() => handleChange('operation', item?.value)} />
                                <span className={styles.checkboxName}>{item?.name}</span>
                            </div>
                        )}
                    </div>
                    <div className={`${ styles.errorMessage } ${ error.operation && styles.showErrorMessage }`}> {error.operation || 'empty'} </div>
                </div>
                <div className={styles.businessLogicWrapper}>
                    <Typography variant='title'>Business Logic</Typography>
                    {businessLogic?.business_logic?.conditions?.map((item, index) => (
                        <div key={index} className={styles.wrapper}>
                            {index !== 0 &&
                                <div className={styles.andOrSelect}>
                                    <Select
                                        labelId="demo-select-small-label"
                                        className={styles.select}
                                        id="demo-select-small"
                                        name="logic"
                                        displayEmpty
                                        onChange={(e) => {
                                            handleChange('logic', e.target.value, index)
                                        }}
                                        renderValue={
                                            item?.logic ? () => (item?.logic?.split('_')?.join(' ')) : () => <div className={styles.conditionPlaceholder}>{dropDownOptionOne[ 0 ].value}</div>
                                        }
                                        MenuProps={{
                                            styles: {
                                                paper: styles.paper,
                                            },
                                        }}
                                        inputProps={{
                                            styles: {
                                                icon: styles.icon,
                                            },
                                        }}
                                    >
                                        {dropDownOptionOne.map((ele) =>
                                            <MenuItem key={ele.name} value={ele.name}>{ele.name}</MenuItem>
                                        )}
                                    </Select>
                                </div>}
                            <div className={styles.bussinessLogicContent}>
                                <div style={{ display: 'flex' }}>
                                    <Input
                                        type='text'
                                        placeholder="key"
                                        value={item.key}
                                        name='name'
                                        onChange={(e) => handleChange('key', e?.target?.value, index)}
                                        className={styles.select}
                                    />
                                    {/* <div className={styles.line} /> */}
                                    <div className={styles.logicHeading}>
                                        <div className={styles.dropdownOne}>
                                            <button
                                                onClick={() => toggleDropdown(index)}
                                                style={{
                                                    width: '100%',
                                                    border: 'none',
                                                    backgroundColor: '#fff',
                                                    cursor: 'pointer',
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-evenly',
                                                    gap: '5px'
                                                }}
                                            >
                                                {item.operator || 'Logic'}
                                                <LucideIcon name="ChevronDown" />
                                            </button>

                                            {isOpenDropdowns[ index ] && (
                                                <div
                                                    ref={(el) => (dropdownRefs.current[ index ] = el)}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '100%',
                                                        left: '0',
                                                        width: '100%',
                                                        backgroundColor: '#fff',
                                                        border: '1px solid #ccc',
                                                        zIndex: '1000',
                                                        maxHeight: '270px',
                                                        overflowY: 'auto',
                                                    }}
                                                >
                                                    {options?.map((category, ind) => (
                                                        <div key={ind} style={{ padding: '10px' }}>
                                                            <div style={{ fontWeight: 'bold', color: '#4A90E2', marginBottom: '5px' }}>{category?.label}</div>
                                                            {category?.subOptions.map((option, idx) => (
                                                                <div
                                                                    key={idx}
                                                                    style={{
                                                                        padding: '5px 10px',
                                                                        cursor: 'pointer',
                                                                        borderRadius: '4px',
                                                                    }}
                                                                    onClick={() => handleChange('operator', option, index, category?.operation)}
                                                                    onMouseOver={(e) => (e.target.style.backgroundColor = '#f0f0f0')}
                                                                    onMouseOut={(e) => (e.target.style.backgroundColor = 'transparent')}
                                                                >
                                                                    {option}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className={styles.line} /> */}
                                    <div className={styles.outputHeading}>
                                        <Input
                                            type='text'
                                            placeholder="value"
                                            value={item.value}
                                            name='name'
                                            onChange={(e) => handleChange('value', e?.target?.value, index)}
                                            className={styles.select}
                                        />
                                    </div>
                                    {index !== 0 && (
                                        <div className={styles.removeIcon}>
                                            <LucideIcon name="X" size="24" onClick={() => handleRemoveClick(index)} />
                                        </div>
                                    )}
                                </div>
                                {index === 0 &&
                                    <Button className={styles.addConditionButton} onClick={() => handleAddClick(1)}>
                                        Add
                                    </Button>}
                            </div>
                        </div>
                    ))}
                </div>

            </div>
            <div className={styles.buttonWrapper}>
                <Button className={styles.saveAndCloseButton} onClick={handleSave}>Save</Button>
                <Button className={styles.button} onClick={handleClose}>Close</Button>
            </div>
            <DropDownTwo
                showMenu={showMenu}
                // position={position}
                onClose={() => setShowMenu(false)}
                dropDownOption={dropDownOption}
            // handleOperation={handleOperation}
            />
        </PopupWrapper>
    )
}

export default BusinessLogicPopup;
