import React, { useEffect } from 'react';
import styles from './header.module.css';
import Search from '../../../commonComponents/search/Search.jsx';
import { BellRing, Settings } from 'lucide-react';
import { useHeaderFunc } from '../functionality/Header.func';
import assets from '../../../assets';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { selectUserData } from '../../../redux/user/Selector';
import DropDown from '../../../commonComponents/dropDown/DropDown.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrganisationData } from '../../../redux/organisation/Selector';
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';
import { selectNotificationData } from '../../../redux/notification/Selector';
import NotificationsDropdown from '../../notifications/notificationsDropdown/layout/NotificationsDropDown.jsx';
import { clearVisitedPage, setActionId, setHideModal } from '../../../redux/visitor/Slice';
import data  from '../../../dummyData/NavigateStep.json'
import Tooltip from '../../../commonComponents/tooltip/Tooltip.jsx';
import Button from '../../../commonComponents/button/Button.jsx';
import { clearMappingConfiguration } from '../../../redux/mappingConfiguration/Slice';
const Header = ({ openTooltip, setOpenTooltip }) => {
    const { searchKeyword, handleChange, handleKeyDown } = useHeaderFunc();
    const isVisited=useSelector((state)=> state.tutorial?.hasVisited);
    const isHideModal = useSelector((state) => state.tutorial.isHideModal)
    const isModalColor = true;
    const dispatch=useDispatch();
    const organisation = useSelector(selectOrganisationData);
    const orgId = organisation?.organisationData?.id;
    const {  logo } = assets;
    const navigate = useNavigate();
    const user = useSelector(selectUserData);
    const userData =user?.userData?.data?.person;
    const { pathname } = useLocation();
    console.log(isVisited, 'isVisited', data?.[ pathname?.replace('/', '') ]?.[ 0 ])
    const handleClick =(name) => {
        if(name==='Sign out'){
            localStorage.removeItem('access_token');
            dispatch(clearVisitedPage([]))
            dispatch(setActionId(''))
            navigate('/');
        }
    }
    useEffect(()=>{
        if((isVisited || userData?.email==='demo@ekyam.ai') && pathname!=='/organisation' && !isHideModal){
            dispatch( setActionId(data?.[ pathname?.replace('/', '') ]?.[ 0 ]))
        }
    },[ isHideModal ])
    const handleTooltip=()=>{
        setOpenTooltip(false);
        dispatch(setHideModal(false))
        dispatch(clearVisitedPage([]))
        dispatch( setActionId(data?.[ pathname?.replace('/', '') ]?.[ 0 ]))
    }
    const tooltipContent=()=>{
        return (
            <div className={styles.tooltipWrapper}>
                <p className={styles.helperText}>Need a hand? Click here for a quick guide!</p>
                <Button className={`${ styles.tooltipButton } ${ isModalColor && styles.tooltipButtonColor }`} onClick={handleTooltip} variant="gredient">Ok</Button>
            </div>
        )
    }
    const notifications = useSelector(selectNotificationData)?.notificationsData?.slice(0,5);
    const dropDownValues=[ { name:'Sign Out', value:'Sign out' } ];
    return (
        <div className={styles.headerWrapper}>
            <div className={styles.header}>
                <div className={styles.leftHeader}>
                    <div id="logo" className={styles.imgWrapper}>
                        <Link to='/'><img src={logo} alt='' /></Link>
                    </div>
                </div>
                <div className={styles.rightHeader}>
                    <Search id="search" onKeyDown={handleKeyDown} searchKeyword={searchKeyword} onChange={handleChange} />
                    {orgId && pathname!=='/organisation' &&
                    <>
                        <Link id="connectorButton" to="/connector" state= {{ organisation_id: orgId }} className={`${ styles.button } ${ pathname==='/connector'?styles.active:'' }`}>
                            <LucideIcon name="Cable"/>
                            Connectors
                        </Link>
                    </>
                    }
                    <NotificationsDropdown id="notification" options={notifications} customsSyles={{ padding: 0,border: 'none' }} defaultOption={<BellRing className={styles.icon} />}/>
                    <Settings id="settings" className={styles.icon} onClick={()=>{dispatch(clearMappingConfiguration())} }/>
                    {(isVisited || userData?.email==='demo@ekyam.ai') && pathname!=='/organisation' &&
                    <div className={styles.alert}>
                        <LucideIcon name="CircleAlert"/>
                        {(openTooltip || isHideModal) && <Tooltip text={tooltipContent()} isModalColor={isModalColor}/>}
                    </div>}
                    <DropDown id="dropdown" options={dropDownValues} onDropDownChange={handleClick} defaultOption={`${ userData?.first_name } ${ userData?.last_name }`}/>
                    {/* <img className={styles.avatar} src={avatar} />
                <div>
                    <p className={styles.username}>Maretta Daniel</p>
                    <p className={styles.email}>Danialmareeta@mail.com</p>
                </div> */}
                </div>
            </div>
        </div>
    );
}

export default Header;