import { useState, useCallback } from 'react';
import useCustomAxiosCall from '../../../hooks/useCustomAxiosCall';

const useConfigMapper = (systemName='') => {
    const [ loading, setLoading ] = useState(false);
    const [ collectionsLoading, setCollectionsLoading ] = useState(false);
    const [ data, setData ] = useState({});
    const [ collections, setCollections ] = useState([])
    const { callApi } = useCustomAxiosCall();
    const getConfigData = useCallback(async (tribe) => {
        setLoading(true);
        try {
            const response = await callApi({
                uriEndPoint: {
                    url: `/tribe-config/${ tribe }`,
                    method: 'GET',
                    service: 'config-mapper',
                },
            });

            if (response?.status === 200 && !response?.data?.error) {
                setData(response?.data?.data || {});
                return response?.data?.data;
            } else {
                console.error('Error fetching data:', response?.data?.error || 'Unknown error');
            }
        } catch (error) {
            console.error('API call failed:', error);
        } finally {
            setLoading(false);
        }

    }, [ callApi ]);

    const getMongoCollections = async () => {
        setCollectionsLoading(true)
        const response = await callApi({
            uriEndPoint: {
                url: `/collections?system=${ systemName }`,
                service: 'config-mapper'
            }
        });
        console.log(response, 'collectionsresponse')
        if (response?.status === 200) {
            setCollections(response?.data || [])
        }
        setCollectionsLoading(false)
    };

    return { data, loading, getConfigData, getMongoCollections, collections, collectionsLoading };
};

export default useConfigMapper;