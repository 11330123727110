import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCustomAxiosCallTwo from '../../../hooks/useCustomAxiosCallTwo';
import { toast } from 'react-toastify';
import connectorData from '../../../dummyData/ConnectorLogo.json';
import { handleTribe, createDynamicLogicalGroups, handleConnectorNames } from './helper';
import { workflowData } from '../../../redux/workflow/Selector';
import { useDispatch, useSelector } from 'react-redux';
import { setWorkflowData } from '../../../redux/workflow/Slice';
import { AiAgentSelector } from '../../../redux/AiAgentchatbot/Selector';
import { setAgents } from '../../../redux/AiAgentchatbot/Slice';

export const useWorkFlowMultipleFunc = () => {
    const [ connectedConnectors, setConnectedConnectors ] = useState([]);
    const [ workFlowLoading, setWorkFlowLoading ] = useState()
    const [ workFlowGetLoading, setWorkFlowGetLoading ] = useState()
    const [ workflows, setWorkFlows ] = useState();
    const [ addConnector, setAddConnector ] = useState({
        source: [],
        destination: []
    });
    console.log(addConnector, 'addConnector')
    const dispatch =useDispatch();
    const [ edges, setEdges ] = useState([]);
    const navigate = useNavigate();
    const { state } = useLocation();
    const organisation_id = (state && 'organisation_id' in state) ? state?.organisation_id : null;
    const workflowId = state.workflowId;
    const workflow = useSelector(workflowData);
    const getsWorkflow = workflow?.length > 0 && workflow?.find(item => item[ workflowId ]);
    const workflowDatas = getsWorkflow?.[ workflowId ]
    const aiAgentData = useSelector(AiAgentSelector);
    const agentValues = aiAgentData?.agents?.[ workflowId ];
    const { callApi } = useCustomAxiosCallTwo();
    const [ deleteDialog, setDeleteDialog ] = useState({
        isOpen: false,
        name: ''
    })
    const [ businessLogic, setBusinessLogic ] = useState({
        'operation': [],
        'business_logic': {
            'conditions': [
                {
                    'id': 'cond_1',
                    'key': '',
                    'value': '',
                    'operator': '',
                    'logic': '',
                    'method': '"StringOperations"',
                    'aggregation': '',
                    'codeSnippet': '',
                    'model_name': [],
                    'className': '',
                    'codePrompt': ''
                },
            ],
            'logical_groups': [
            ]
        }
    })
    console.log(businessLogic, '#business')
    if (!organisation_id) {
        navigate('/organisation')
    }

    const getConnectedConnectors = async () => {
        const response = await callApi({
            uriEndPoint: {
                url: `/organization/${ organisation_id }/connector`
            }
        });
        if (response?.status === 200) {
            setConnectedConnectors(response?.data?.data || [])
        }
    };
    const handlePositionPayload = (nodes) => {
        let position = [];
        if (nodes?.length > 0) {
            position = nodes.map((item) => ({ 'key': item.data.machine_name, 'value': item.position, 'type': item.type }))
        }
        return position;
    }
    const handleId = (id) => {
        return id.split('-').slice(1).join('-');
    }
    const getWorkflow = async () => {
        setWorkFlowGetLoading(true)
        const response = await callApi({
            uriEndPoint: {
                url: `/get-workflow/${ workflowId }`,
                service: 'workflow',
            },
        });
        if (response?.status === 200) {
            setWorkFlows(response?.data?.data)
            dispatch(setAgents({ workflowId, agents: response?.data?.data?.aiAgents }));

            setWorkFlowGetLoading(false)
        }
        else {
            setWorkFlowGetLoading(false)
        }
    }
    const handleReduxState = (flowData, nodes, parameter)=>{
        const simplifiedEdges = flowData.map(({ source, target, operation, sourceHandle, targetHandle, business_logic, id }) => ({
            id: handleId(id),
            source: source,
            target: target,
            sourceHandle,
            targetHandle,
            active: true,
            operation,
            business_logic
        }));
        const payload = {
            'position': handlePositionPayload(nodes),
            'flows': simplifiedEdges
        }
        dispatch(setWorkflowData({ [ workflowId ]: { ...payload, ...parameter } }));

    }
    const handleWorkFlow = async () => {
        setWorkFlowLoading(true)
        const payload = {
            'position': workflowDatas?.position,
            'flows': workflowDatas?.flows,
            'aiAgents': agentValues || []
        }

        console.log(payload, 'customworkflow')
        // // TODO: this should be removed static queues code
        // // store the workflow queues
        // const queueRes = await callApi({
        //     uriEndPoint: {
        //         url: `/save-queues-by-workflow-id/${ workflowId }`,
        //         service: 'workflow',
        //         method: 'POST'
        //     },
        //     body: { 'data': payload },
        // });

        // console.log(queueRes, 'queueRes')
        // // store the workflow queues ends
        // TODO end: this should be removed static queues code
        const response = await callApi({
            uriEndPoint: {
                url: `/update-workflow/${ workflowId }`,
                service: 'workflow',
                method: 'PUT'
            },
            body: { 'data': payload },
        });
        if (response?.status === 200) {
            setWorkFlowLoading(false)
            getWorkflow()
            toast.success('Workflow updated successfully', { position: 'top-right' })
        }
        else {
            setWorkFlowLoading(false)
            toast.error(response?.data?.msg || 'Something Went Wrong', { position: 'top-right' })
        }
    }

    const handleRunWorkFlow = async (flowData, workFlowId) => {
        const systems = [ 'dropbox', 'snowflake', 'netsuite', ];
        let isTrue = false;
        const presentSystems = flowData?.flatMap(flow => [ flow?.source, flow?.target ]);
        if (presentSystems && presentSystems?.length>0){
            isTrue = systems?.every(system => presentSystems?.includes(system));
        }
        if (isTrue){
            const responseOne = await callApi({
                uriEndPoint: {
                    url: '/fetch-excel-data?system=excel',
                    service: 'collectivedata',
                },
            });
            const responseTwo = await callApi({
                uriEndPoint: {
                    url: '/fetch-excel-data?system=snowflake',
                    service: 'collectivedata',
                },
            });
            if (responseOne?.status===200 && responseTwo?.status===200){
                toast.success('Workflow run successfully', { position: 'top-right' })
            } else {
                toast.error('Something went wrong', { position: 'top-right' })
            }
        } else{
            const response = await callApi({
                uriEndPoint: {
                    url: '/run-workflow',
                    service: 'workflow',
                    headers: { 'x-workflow-id': workFlowId }
                },
            });
            if (response?.status === 200) {
                toast.success('Workflow run successfully', { position: 'top-right' })
            } else {
                toast.error('Something went wrong', { position: 'top-right' })

            }
        }
    }
    const getConfigWriter = async (tribe, system, service) => {
        let result = false;
        const parameter = {
            service: service,
            system: system,
            tribe: tribe
        }
        const response = await callApi({
            uriEndPoint: {
                url: '/get-writer-config',
                service: 'config',
                params: parameter
            },
        });
        if (response?.data?.status === 'success') {
            result = true
        }
        else {
            result = false
        }
        return result
    }

    const handlePosition = (position, source, key) => {
        const positions = position.find((item) => item.key === source)
        return positions?.[ key ] || {}
    }

    const handleNode = (label) => {
        console.log(label, 'labelaa')
        setDeleteDialog({ ...deleteDialog, isOpen: true, name: label })
    }

    const workflowStructure=()=>{
        if (workflows && typeof workflows === 'object' && Object.keys(workflows).length > 0) {
            const flows = workflows?.flows || [];
            const position = workflows?.position || [];
            const transformedEdges = flows?.map(({ source, target, operation, sourceHandle, targetHandle, business_logic }) => ({
                source: source,
                target: target,
                sourceHandle: sourceHandle || '',
                targetHandle: targetHandle || '',
                data: { label: 'Operation' },
                type: 'custom',
                id: `edge-${ source }-${ target }`,
                operation,
                business_logic
            }));
            let nodesMap = {}
            position?.forEach((data) => {
                connectorData?.connectorLogo?.forEach((item) => {
                    console.log(data.key, item?.machine_name, 'fdggfdggdgfdgd')
                    if (!nodesMap[ data.key ]) {
                        if (handleConnectorNames(item?.machine_name)  === data.key) {
                            nodesMap[ data.key ] = {
                                id: data.key,
                                type: handlePosition(position, data.key, 'type'),
                                data: {
                                    handleNode: handleNode,
                                    label: data.key,
                                    icon: item?.image,
                                    machine_name: data.key
                                },
                                position: handlePosition(position, data.key, 'value'),
                                measured: { width: 184, height: 150 },
                            };
                        }
                    }
                });
            });
            console.log(nodesMap, 'nodesMap', transformedEdges)
            const nodesData = Object.values(nodesMap).reduce((acc, curr) => {
                if (curr.type !== 'sourceNode') {
                    acc[ 'destination' ].push(curr)
                } else {
                    acc[ 'source' ].push(curr)
                }
                return acc
            }, {
                'destination': [],
                'source': []
            })
            const payload = {
                'position': position,
                'flows': transformedEdges
            }
            setAddConnector(nodesData)
            setEdges(transformedEdges);
            dispatch(setWorkflowData({ [ workflowId ]: payload }));
        }
    }
    useEffect(() => {
        if (workflowId) {
            getWorkflow();
        }
    }, [ workflowId ])

    useEffect(() => {
        getConnectedConnectors();
    }, [])

    useEffect(() => {
        workflowStructure();
    }, [ workflows ]);
    return {
        connectedConnectors,
        handleWorkFlow,
        workFlowLoading,
        workFlowGetLoading,
        handleRunWorkFlow,
        workflowId,
        workflowData: workflows,
        handleTribe,
        getConfigWriter,
        setBusinessLogic,
        businessLogic,
        addConnector,
        setAddConnector,
        edges,
        setEdges,
        deleteDialog,
        setDeleteDialog,
        handleNode,
        createDynamicLogicalGroups,
        dispatch,
        setWorkflowData,
        handleReduxState,
        workflowDatas,
        agentValues
    };
}