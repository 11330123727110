import styles from './returnCounter.module.css';

const ReturnCounter = ({ data }) => {
    return <div>
        {data.map(stat => (
            <div key={stat.value} className={styles.counterWrapper}>
                <div className={styles.colors} style={{ backgroundColor: stat.color }}/>
                <div key={stat.key} className={styles.returnWrapper}>
                    <span className={styles.returnValue}>{stat.value}</span>
                    <span className={styles.returnKey}>{stat.key}</span>
                </div>
            </div>
        ))}
    </div>
}
export default ReturnCounter;