import React, { useEffect, useState } from 'react';
import Typography from '../../../../commonComponents/typography/Typography.jsx';
import styles from './table.module.css';
import LucideIcon from '../../../../commonComponents/lucideicon/LucideIcon.jsx';
import DataGrid from '../../../../commonComponents/table/Table.jsx';
import useTablesTwo from '../functionality/useTableTwo.func';
const Table = ({
    title = '',
    data = {},
}) => {

    const tabsList = Object.keys(data);
    if (tabsList?.length === 0) {
        return null;
    }
    const [ activeTabIndex, setActiveTabIndex ] = useState(0);
    let [ endPoints, setEndPoints ]=useState('');

    const activeTabData = data[ tabsList[ activeTabIndex ] ] ||[];
    const header = data?.[ tabsList?.[ activeTabIndex ] ][ 0 ];
    useEffect(()=>{
        switch (activeTabIndex) {
            case 0:
                setEndPoints('/customers')
                break
            case 1:
                setEndPoints('/orders');
                break;
            default:
                break
        }
    },[ activeTabIndex ])
    const { data: rowData,
        columns,
        loading, error,
        currentPage,
        setCurrentPage,totalRecords,
        setPageSize } = useTablesTwo({ url: endPoints, service: 'collectivedata',  }, header && Object.keys(header).length > 0 &&  Object.keys(data[ tabsList[ activeTabIndex ] ][ 0 ]), activeTabData, {}, activeTabIndex);
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.header}>
                <Typography variant='title'>{title}</Typography>
                <div className={styles.flex}>
                    <div className={styles.searchContainer}>
                        <LucideIcon name="Search" />
                        <input placeholder='Search...' />
                    </div>
                    <button className={styles.filterBtn}>
                        <LucideIcon name="SlidersHorizontal" />
                        Filter
                    </button>
                </div>
            </div>

            <div className={styles.tabsWrapper}>
                <div className={styles.tabsList}>
                    {tabsList.map((tab,index) => (
                        <Typography onClick={() => setActiveTabIndex(index)} className={`${ styles.tabTitle } ${ activeTabIndex === index && styles.activeTab }`} key={tab}>
                            {tab.replaceAll('_', ' ')}
                        </Typography>
                    ))}
                </div>
                <button className={styles.addBtn}>
                    Add
                    <LucideIcon name="Plus" />
                </button>
            </div>

            {(!loading && error) ?
                <div className={styles.heading}><Typography variant='error'>{error}</Typography></div>:
                <DataGrid
                    loading={loading}
                    headerList={columns}
                    totalRecords={header ? rowData.length :  totalRecords}
                    setPageSize={setPageSize}
                    rowsData={rowData}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            }
        </div>
    )
};

export default Table;