import { smallCaseWords } from '../../../helpers';
export const handleConnectorName = (machine_name, name) => {
    let displayName;
    switch (machine_name) {
        case 'microsoft_dynamics_365': displayName = 'Dynamics365'; break;
        case 'magento': displayName = 'Magento'; break;
        case 'shopify': displayName = 'Shopify'; break;
        case 'netsuite': displayName = 'Netsuite'; break;
        case 'whiplash': displayName = 'Whiplash'; break;
        case 'tiktok': displayName = 'Tiktok'; break;
        default: displayName = name
            break;
    }
    return displayName
}
export const handleTribe = (system) => {
    let tribe;
    switch (system) {
        case 'microsoft_dynamics_365': tribe = 'erp'; break;
        case 'magento': tribe = 'erp'; break;
        case 'shopify': tribe = 'webshop'; break;
        case 'netsuite': tribe = 'erp'; break;
        case 'whiplash': tribe = '3pl'; break;
        case 'tiktok': tribe = 'webshop'; break;
        case 'shopware': tribe = 'webshop'; break;
        case 'bigcommerce': tribe = 'webshop'; break;
        case 'woocommerce': tribe = 'webshop'; break;
        case 'snowflake': tribe = 'webshop'; break;
        case 'salesforce': tribe = 'erp'; break;
        default: tribe = system
            break;
    }
    return tribe
}
export const handleConnectorNames = (machine_name) => {
    let displayName;
    switch (machine_name) {
        case 'microsoft_dynamics_365': displayName = 'dynamics365'; break;
        default: displayName = machine_name
            break;
    }
    return displayName
}
export const handleShowGCPConnectorName = (machine_name) => {
    let displayName;
    switch (machine_name) {
        case 'gcp': displayName = 'GCP'; break;
        default: displayName = machine_name
            break;
    }
    return displayName
}
export const createDynamicLogicalGroups = (data) => {
    const logicalGroups = [];
    let groupIdCounter = 1;
    const createGroup = (type, conditions) => {
        return {
            id: `group_${ groupIdCounter++ }`,
            type: type,
            conditions: conditions,
        };
    };
    const logicGroups = {
        AND: [],
        OR: [],
        NONE: [],
    };
    data.forEach((condition) => {
        if (!condition.logic) {
            logicGroups.NONE.push(condition.id);
        } else if (condition.logic === 'AND') {
            logicGroups.AND.push(condition.id);
        } else if (condition.logic === 'OR') {
            logicGroups.OR.push(condition.id);
        }
    });
    if (logicGroups.AND.length > 0) {
        const groupConditions = [ ...logicGroups.NONE, ...logicGroups.AND ];
        const group1 = createGroup('AND', groupConditions);
        logicalGroups.push(group1);
    }
    if (logicGroups.OR.length > 0 && logicGroups.AND.length === 0) {
        const groupConditions = [ ...logicGroups.NONE, ...logicGroups.OR ];
        const group1 = createGroup('OR', groupConditions);
        logicalGroups.push(group1);
    }
    if (logicGroups.OR.length > 0 && logicGroups.AND.length > 0) {
        const group2Conditions = [
            `group_${ groupIdCounter - 1 }`,
            ...logicGroups.OR,
        ];
        const group2 = createGroup('OR', group2Conditions);
        logicalGroups.push(group2);
    }
    return {
        conditions: data,
        logical_groups: logicalGroups,
    }
};
export const getServices = (businessLogic) => {
    const services = [ 'inventry', 'orders', 'data', 'shipment', 'products' ]
    const service = services.find(item => smallCaseWords(businessLogic?.operation?.[ 0 ]).includes(item));
    return service;
}