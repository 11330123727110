// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search_searchContainer__VX8iM {
    align-items: start;
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: 0px 4px 14px 0px var(--search-box-shadow);
    border: Mixed solid #E6E6E6;
    display: flex;
    gap: 1rem;
    padding: 0.8rem 1.5rem;
}

.Search_searchIcon__TAEnW {
    height: 20px;
    width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/commonComponents/search/Search.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oCAAoC;IACpC,mBAAmB;IACnB,qDAAqD;IACrD,2BAA2B;IAC3B,aAAa;IACb,SAAS;IACT,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":[".searchContainer {\n    align-items: start;\n    background-color: var(--color-white);\n    border-radius: 10px;\n    box-shadow: 0px 4px 14px 0px var(--search-box-shadow);\n    border: Mixed solid #E6E6E6;\n    display: flex;\n    gap: 1rem;\n    padding: 0.8rem 1.5rem;\n}\n\n.searchIcon {\n    height: 20px;\n    width: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchContainer": `Search_searchContainer__VX8iM`,
	"searchIcon": `Search_searchIcon__TAEnW`
};
export default ___CSS_LOADER_EXPORT___;
