import React from 'react';
import styles from './ConfigMapperFieldV2.module.css'
import useConfigMapperField from '../../configMapper/functionality/useConfigMapperFeild.func';
import configData from '../data/fieldDropdownData.json'
import LucideIcon from '../../../commonComponents/lucideicon/LucideIcon.jsx';
import { v4 as uuid } from 'uuid';
import useConfigMapperFieldV2 from '../functionality/useConfigMapperFeildV2.func';
import CustomInput from './CustomInputV2.jsx';
import Typography from '../../../commonComponents/typography/Typography.jsx'
const ConfigMapperFieldV2 = (
    {
        fieldData={},
        formData={},
        fieldListData=[],
        setFieldData=()=>{},
        onInputChange=()=>{},
        validationError={},
        setValidationError=()=>{},
        parentFeild='',
        service='',
        displayInRow=false,
        collections=[]
    }
) => {
    const { hasChidlren } = useConfigMapperField(fieldData)
    const { getFieldComponent, validateInputHandler, addMoreFieldsClickHandler, getDisplayName } = useConfigMapperFieldV2(setValidationError)
    const displayName = getDisplayName(fieldData)
    const getFieldContent = (fieldData) => {
        switch (fieldData?.field_type) {
            case 'nested':
                return <div className={styles.nestedInputWrapper}>
                    {fieldData.field_children.map(fiedNestedData =>
                        <div key={fiedNestedData?.field_id} className={`${ styles.inputWrapper }`}>
                            <ConfigMapperFieldV2 fieldData={fiedNestedData} fieldListData={fieldListData} formData={formData} parentFeild={fieldData?.field_name} validateInputHandler={validateInputHandler} setValidationError={setValidationError} validationError={validationError} onInputChange={onInputChange}/>
                        </div>
                    )}
                </div>

            case 'object':
                return fieldData.field_children.map((customInputData) =>
                    <div key={customInputData?.field_id}  className={`${ styles.inputWrapper } ${ (fieldData.field_type === 'object')? styles.customInputWrapper :'' }`}>
                        <CustomInput
                            service={service}
                            fieldData={customInputData}
                            canDelete={fieldData?.field_children?.length > 1}
                            fieldListData={fieldListData}
                            formData={formData}
                            setFieldData={setFieldData}
                            parentField={fieldData.field_name}
                            validateInputHandler={validateInputHandler}
                            parentFieldData={fieldData}
                            setValidationError={setValidationError}
                            validationError={validationError}
                            collections={collections}
                        />

                    </div>
                )

            case 'array':
                return fieldData.field_children.map(arrayInputData =>
                    <div key={arrayInputData?.field_id || uuid()} className={`${ styles.inputWrapper } ${ (fieldData.field_type === 'array')? styles.arrayInputWrapper :'' } }`}>
                        {getDisplayName(arrayInputData)}
                        {getFieldComponent(arrayInputData,formData, onInputChange, parentFeild ,false, validateInputHandler, getDisplayName(arrayInputData))}
                    </div>
                )

            default:
                return <>
                    <div className={`${ styles.inputWrapper } ${ (displayInRow)?styles.displayRow:'' } }`}>
                        {getFieldComponent(fieldData,formData, onInputChange, parentFeild ,false, validateInputHandler, displayName)}
                    </div>
                    {validationError[ fieldData?.field_name ] && validationError?.[ fieldData?.field_name ] !== '' && <Typography variant='error'>{validationError?.[ fieldData?.field_name ]}</Typography>}
                </>
        }
    }
    fieldData.field_id = (fieldData?.field_id) ? fieldData.field_id : uuid()

    return <div key={fieldData?.field_name} className={`${ styles.formInputWrapper } ${ (displayInRow)?styles.displayRow:'' }`}>
        <div className={styles.labelWrapper}>
            { displayName }
            {hasChidlren(fieldData) && fieldData?.can_add_fields &&
                <span className={styles.iconWrapper} title='Add Text Custom Field'>
                    <LucideIcon name={'CirclePlus'} onClick={()=>{addMoreFieldsClickHandler(`${ fieldData?.field_name }`, fieldData, configData, setFieldData, fieldListData)}}/>
                </span>
            }
            {hasChidlren(fieldData) && fieldData?.can_add_array &&
                <span className={styles.iconWrapper} title='Add Nested Custom Field'>
                    <LucideIcon name={'CircleFadingPlus'} onClick={()=>{addMoreFieldsClickHandler(`${ fieldData?.field_name }`, fieldData, configData, setFieldData, fieldListData, fieldData?.can_add_array)}}/>
                </span>
            }
        </div>

        {getFieldContent(fieldData)}
    </div>
}
export default React.memo(ConfigMapperFieldV2)