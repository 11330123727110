import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    data: {}
};

const dashboard = createSlice({
    initialState: INITIAL_STATE,
    name: 'dashboard',
    reducers: {
        setDashboardData: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload
                }
            }
        },
        clearDashboardData: () => ({
            data: {}
        }),
        addOrders: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    orders: [ ...state.data.orders || [], action.payload ]
                }
            }
        },
        addProducts: (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    products: [ ...state.data.products || [], action.payload ]
                }
            }
        }
    }
});

export const { setDashboardData, addOrders, addProducts, clearDashboardData } = dashboard.actions;
export const dashboardReducer = dashboard.reducer;
