import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './flowPopup.module.css';

import aiAgentsData from '../../dummyData/AIAgent.json';
import Button from '../button/Button.jsx';
import LucideIcon from '../lucideicon/LucideIcon.jsx';
import Typography from '../typography/Typography.jsx';
import { capitalizeCamelCaseWords } from '../../helpers/index';
import { setAgents } from '../../redux/AiAgentchatbot/Slice';

function FlowPopupV2({ isOpen, onClose, height, width, workflowId, matchedAgents }) {
    const [ selectedAgents, setSelectedAgents ] = useState(matchedAgents || []);
    const dispatch = useDispatch();
    console.log(workflowId, '#workflowId');
    const handleOutsideModalClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose(e);
        }
    };
    if (!isOpen) return null;
    const overlayStyles = {};
    if (height) overlayStyles.height = height;
    if (width) overlayStyles.width = width;

    const toggleAgentSelection = (value, name, image) => {
        setSelectedAgents((prev) => {
            const isSelected = prev.some((agent) => agent.value === value);
            if (isSelected) {
                return prev.filter((agent) => agent.value !== value);
            } else {
                return [ ...prev, { value, name, image } ];
            }
        });
    };

    const handleSave = () => {
        const agentValues = selectedAgents?.length>0 && selectedAgents?.map(
            (agent) => agent?.value
        );
        dispatch(setAgents({ workflowId, agents: agentValues }));
        onClose();
    };

    return (
        <div
            className={styles.modalOverlayWrapper}
            onClick={handleOutsideModalClick}
        >
            <div
                className={`${ styles.agentModal } ${ styles.modalOverlay }`}
                style={overlayStyles}
            >
                <div className={styles.header}>
                    <Typography variant="title">Select AI Agents</Typography>
                    <div className={styles.button}>
                        <LucideIcon
                            name="Search"
                            size={18}
                            className={styles.closeIcon}
                        />
                        Choose Application
                    </div>
                </div>
                <div className={styles.innerContent}>
                    <Typography variant="title-14" className={styles.subTitle}>
                        AI Agents
                    </Typography>
                    <div className={styles.widgets}>
                        {aiAgentsData?.agentsData?.map((item, index) => (
                            <div
                                className={styles.connectors}
                                key={index}
                                onClick={() =>
                                    toggleAgentSelection(
                                        item.value,
                                        item.name,
                                        item.image
                                    )
                                }
                            >
                                <img
                                    className={`${ styles.logoImage } ${
                                        selectedAgents.some(
                                            (agent) =>
                                                agent.value === item.value
                                        )
                                            ? styles.active
                                            : ''
                                    }`}
                                    src={item.image}
                                    alt={item.machine_name}
                                />
                                <Typography variant="title-10">
                                    {capitalizeCamelCaseWords(item.name)}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <Button className={styles.buttonClose} onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        className={styles.saveAndCloseButton}
                        onClick={handleSave}
                        disabled={selectedAgents.length === 0}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default FlowPopupV2;
