// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggleSwitch_switch__Xb3qL {
    position: relative;
    width: 42px;
    height: 18px;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
  }
  
  .toggleSwitch_slider__vQk6t {
    position: absolute;
    top: -3px;
    left: 0px;
    width: 23px;
    height: 23px;
    background-color: var(--color-skyblue-100);
    border-radius: 50%;
    transition: transform 0.3s;
  }
  
  .toggleSwitch_on__E44kL {
    transform: translateX(18px);
  }`, "",{"version":3,"sources":["webpack://./src/commonComponents/toggleSwitch/toggleSwitch.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,kBAAkB;IAClB,0BAA0B;EAC5B;;EAEA;IACE,2BAA2B;EAC7B","sourcesContent":[".switch {\n    position: relative;\n    width: 42px;\n    height: 18px;\n    background-color: #ccc;\n    border-radius: 34px;\n    cursor: pointer;\n  }\n  \n  .slider {\n    position: absolute;\n    top: -3px;\n    left: 0px;\n    width: 23px;\n    height: 23px;\n    background-color: var(--color-skyblue-100);\n    border-radius: 50%;\n    transition: transform 0.3s;\n  }\n  \n  .on {\n    transform: translateX(18px);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `toggleSwitch_switch__Xb3qL`,
	"slider": `toggleSwitch_slider__vQk6t`,
	"on": `toggleSwitch_on__E44kL`
};
export default ___CSS_LOADER_EXPORT___;
