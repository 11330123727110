import React from 'react'
import styles from './popupWrapper2.module.css';

function PopupWrapper2({ isOpen, onClose, children, height, width }) {
    const handleOutsideModalClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose(e);
        }
    }
    if (!isOpen) return null;
    const overlayStyles={};
    height?overlayStyles.height=height:null;
    width?overlayStyles.width=width:null;
    return (
        <div className={styles.modalOverlayWrapper} onClick={handleOutsideModalClick}>
            <div className={styles.modalOverlay} style={overlayStyles}>
                <div className={styles.modal} >
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PopupWrapper2;